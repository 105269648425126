import { handleAnnual } from "@/components/Billing/utils";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToAnnualCheckout() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  posthog?.capture("clicked_annual_subscription");
  handleAnnual(getToken);
  return <></>;
}
