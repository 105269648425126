import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// Layouts
import { BillingLayout } from "@/routes/BillingLayout";
import { DashboardLayout } from "@/routes/DashboardLayout";
import { PublicPubLayout } from "@/routes/PublicPubLayout";
import { RootLayout } from "@/routes/RootLayout";

// Routes
import { ErrorPage } from "@/routes/ErrorPage";
import { SignInPage } from "@/routes/SignInPage";
import { SignUpPage } from "@/routes/SignUpPage";
import { SignUpTrialPage } from "@/routes/SignUpTrialPage";
import { UserProfilePage } from "@/routes/UserProfilePage";
import { AdminPage } from "./routes/AdminPage";
import { PortfolioPage } from "./routes/PortfolioPage";
import { PerformancePage } from "./routes/PerformancePage";

// Redirects
import { RedirectToAnnualCheckout } from "./routes/RedirectToAnnualCheckout";
import { RedirectToBillingPortal } from "./routes/RedirectToBillingPortal";
import { RedirectToMonthlyCheckout } from "./routes/RedirectToMonthlyCheckout";
import { RedirectToTrialCheckout } from "./routes/RedirectToTrialCheckout";
import { RedirectToFlanks } from "./routes/RedirectToFlanks";
import { RedirectToSGFinDex } from "./routes/RedirectToSGFinDex";

// Settings
import { NotificationSettings } from "./components/Settings/NotificationSettings";
import { ShortcutSettings } from "./components/Settings/ShortcutSettings";
import { SecuritySettings } from "./components/Settings/SecuritySettings";
import { IntegrationSettings } from "./components/Settings/IntegrationSettings";
import { FlanksNewCredentials } from "./routes/FlanksNewCredentials";
import { ComingSoonPage } from "./routes/ComingSoonPage";
import { SettingsLayout } from "./components/Settings/SettingsLayout";
import { PubPage } from "./routes/PubPage";
import { ProfileSettings } from "./components/Settings/ProfileSettings";
import { PubPostPage } from "./routes/PubPostPage";
import config from "./lib/config";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { PeekHomePage } from "@/routes/PeekHomePage.tsx";

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.feedbackIntegration({
      isEmailRequired: true,
      showName: false,
      autoInject: true,
      showBranding: false,
      colorScheme: "light",
      triggerLabel: "Report",
      formTitle: "Report an issue",
      messagePlaceholder:
        "Describe the issue you're experiencing. Please include as many details as possible.",
      successMessageText:
        "Thank you! Our team will review your issue and get back to you shortly.",
    }),
  ],
  environment: config.sentryEnvironment,
  // only enable sentry tracking in production or in staging to reduce noise
  enabled:
    config.sentryEnvironment === "production" ||
    config.sentryEnvironment === "development",
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/v2/*",
    element: <Navigate to="/" />,
  },
  {
    element: <RootLayout />,
    children: [
      {
        path: "/sign-up",
        element: <SignUpPage />,
      },
      {
        path: "/sign-in",
        element: <SignInPage />,
      },
      {
        path: "/billing-portal",
        element: <RedirectToBillingPortal />,
      },
      {
        path: "/checkout-trial",
        element: <RedirectToTrialCheckout />,
      },
      {
        path: "/checkout-annual",
        element: <RedirectToAnnualCheckout />,
      },
      {
        path: "/checkout-monthly",
        element: <RedirectToMonthlyCheckout />,
      },
      {
        path: "/flanks-link",
        element: <RedirectToFlanks />,
      },
      {
        path: "/flanks-new-credentials",
        element: <FlanksNewCredentials />,
      },
      {
        path: "/sgfindex-link",
        element: <RedirectToSGFinDex />,
      },
      {
        element: <PublicPubLayout />,
        children: [
          {
            path: "/peek-pub",
            element: <PubPage isLoggedIn={false} />,
          },
        ],
      },
      {
        element: <BillingLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/sign-up-trial",
            element: <SignUpTrialPage />,
          },
          {
            path: "/admin",
            element: <AdminPage />,
          },
        ],
      },
      {
        element: <DashboardLayout />,
        errorElement: <ErrorPage />,
        children: [
          { path: "/", element: <PeekHomePage /> },
          {
            path: "portfolio",
            element: <PortfolioPage />,
          },
          {
            path: "performance",
            element: <PerformancePage />,
          },
          {
            path: "plan",
            element: <ComingSoonPage />,
          },
          {
            path: "progress",
            element: <ComingSoonPage />,
          },
          {
            path: "pub",
            element: <PubPage />,
          },
          {
            path: "post/:slug",
            element: <PubPostPage />,
          },
          {
            path: "preferences",
            element: <SettingsLayout />,
            children: [
              {
                path: "",
                element: <ProfileSettings />,
              },
              {
                path: "notifications",
                element: <NotificationSettings />,
              },
              {
                path: "shortcuts",
                element: <ShortcutSettings />,
              },
              {
                path: "security",
                element: <SecuritySettings />,
              },
              {
                path: "integrations",
                element: <IntegrationSettings />,
              },
            ],
          },
          {
            path: "/profile",
            element: <UserProfilePage />,
          },
          {
            path: "/pub",
            element: <PubPage />,
          },
          {
            path: "post/:slug",
            element: <PubPostPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
