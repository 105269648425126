import { X } from "lucide-react";
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  FormatOptionLabelMeta,
  components,
  SingleValueProps,
} from "react-select";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import AsyncSelect from "react-select/async";
import { cn } from "../../lib/utils";
import { truncateText } from "@/lib/utils";

interface CustomOptionType {
  value: string;
  label: string;
  currency?: string;
  stockExchange?: string;
  exchangeShortName?: string;
  createNew?: string;
}

export const StockReactSelect = forwardRef<
  ElementRef<typeof AsyncSelect>,
  ComponentPropsWithoutRef<typeof AsyncSelect>
>((props, ref) => {
  return (
    <AsyncSelect
      ref={ref}
      {...props}
      unstyled
      // cacheOptions
      components={{
        DropdownIndicator,
        ClearIndicator,
        MenuList,
        SingleValue,
      }}
      formatOptionLabel={formatOptionLabel}
      escapeClearsValue={true}
      closeMenuOnSelect={true}
      tabSelectsValue={true}
      classNames={{
        control: ({ isFocused }) =>
          cn(
            "flex items-center w-full rounded-md border border-input bg-background px-3 py-0 text-sm ring-offset-background h-8 !min-h-min",
            isFocused && "outline-none ring-2 ring-ring ring-offset-2",
          ),
        placeholder: () => "text-muted-foreground",
        dropdownIndicator: () => "",
        menu: () => "mt-1 border bg-white rounded-md",
        valueContainer: () => "flex items-center h-8",
        container: () => "h-8",
        option: ({ isFocused }) => cn("", isFocused && "bg-accent"),
      }}
      onBlur={(e) => {
        if (
          (e.relatedTarget as HTMLElement)?.id === "first" ||
          (e.relatedTarget as HTMLElement)?.id === "quantity"
        ) {
          (e.relatedTarget as HTMLElement)?.focus();
        }
      }}
    />
  );
});

function SingleValue({ children, ...props }: SingleValueProps) {
  const typedData = props.data as {
    label: string;
    value: string;
    exchangeShortName: string;
    createNew?: string;
  };
  return (
    <>
      <components.SingleValue {...props}>
        {typedData.createNew ? (
          <div className="w-full">{children}</div>
        ) : (
          <div className="flex flex-row items-center space-x-2">
            <div className=" py- h-fit rounded-full bg-accent px-2 text-sm">
              <span className="font-semibold">{typedData.value}</span>
            </div>
            <div className="w-full">{children}</div>
            <div className="">
              <span className="text-muted-foreground">
                {typedData.exchangeShortName}
              </span>
            </div>
          </div>
        )}
      </components.SingleValue>
    </>
  );
}
function DropdownIndicator<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: DropdownIndicatorProps<OptionType, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      &nbsp;
    </components.DropdownIndicator>
  );
}

function ClearIndicator<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: ClearIndicatorProps<OptionType, IsMulti, Group>) {
  return (
    <components.ClearIndicator {...props}>
      <X className="h-4 w-4 text-muted-foreground" />
    </components.ClearIndicator>
  );
}

function MenuList<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: MenuListProps<OptionType, IsMulti, Group>) {
  return <components.MenuList {...props} className="space-y-1 p-1 text-sm" />;
}

function formatOptionLabel(
  data: unknown,
  { context }: FormatOptionLabelMeta<unknown>,
) {
  const {
    value,
    label,
    currency,
    stockExchange,
    exchangeShortName,
    createNew,
  } = data as CustomOptionType;

  return context === "menu" ? (
    <div className="m-2 flex cursor-pointer flex-row rounded-md p-2">
      {createNew ? (
        <div>{createNew}</div>
      ) : (
        <>
          <div className="min-w-16">
            <span className="text-ellipsis font-semibold">
              {truncateText(value, 6)}
            </span>
          </div>
          <div className="mx-2 flex w-full flex-col">
            <span>{label}</span>
            {stockExchange && (
              <span className="text-muted-foreground">
                {exchangeShortName} ({stockExchange})
              </span>
            )}
          </div>
          <div className="flex min-w-16 justify-end">
            {currency && (
              <span className="h-fit rounded-full bg-border/40 px-2 py-1 text-sm text-muted-foreground">
                {currency}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <div>
      <span>{label}</span>
    </div>
  );
}
