import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { addAssetFormSchema } from "../components/AddAsset/schema";
import { usePostHog } from "posthog-js/react";
import { queryClient } from "@/lib/queryClient";
import {
  assetLiabilityMap,
  GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
} from "@/lib/constants";
import { AssetLiabilityV2 } from "@/lib/types";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function useAddAssetMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const { mutate: addAsset, isPending } = useMutation({
    mutationFn: (input: z.infer<typeof addAssetFormSchema>) =>
      callAddAsset(input, getToken),
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });

      const now = new Date();

      const previousData = queryClient.getQueryData([
        GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
      ]);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        (old: AssetLiabilityV2[]) => {
          const existingAccount = old.find(
            (assetLiability) =>
              assetLiability.account.name === updatedData.name,
          );
          const timestamp = Date.now();

          if (!existingAccount) {
            const newAccountId = timestamp + old.length;
            const newAssetLiabilities = updatedData.assetLiabilities.map(
              (asset, index) => {
                const tempSnapshot = {
                  assetLiabilityId: timestamp + index,
                  timestamp: now.toISOString(),
                  totalValue: asset.quantity * asset.unitValue,
                  prevTotalValue: 0,
                  quantity: asset.quantity,
                  prevQuantity: 0,
                  unitValue: asset.unitValue,
                  prevUnitValue: 0,
                  pnl: 0,
                  prevPnl: 0,
                  averageCost: asset.unitValue,
                  capitalFlow: asset.quantity * asset.unitValue,
                  totalCapitalInvested: asset.quantity * asset.unitValue,
                  totalValueChange: asset.quantity * asset.unitValue,
                  totalValueChangePercentage: 0,
                  unitValueChange: asset.unitValue,
                  unitValueChangePercentage: 0,
                  quantityChange: asset.quantity,
                  quantityChangePercentage: 0,
                  pnlChange: 0,
                  pnlChangePercentage: 0,
                  pnlPercentage: 0,
                  original: {
                    pnl: 0,
                    prevPnl: 0,
                    unitValue: 1,
                    unitValueChange: 1,
                    totalValue: asset.quantity * asset.unitValue,
                    prevTotalValue: 0,
                    prevUnitValue: 0,
                    averageCost: 1,
                    capitalFlow: asset.quantity * asset.unitValue,
                    pnlChange: 0,
                    totalCapitalInvested: asset.quantity * asset.unitValue,
                  },
                };

                return {
                  ...asset,
                  id: timestamp + index,
                  account: {
                    id: newAccountId,
                    name: updatedData.name,
                    accountSyncTask: [],
                  },
                  accountId: newAccountId,
                  isIncludedInNWCalculation: true,
                  isLiquid: true,
                  assetClass: updatedData.type,
                  assetOrLiability: assetLiabilityMap[asset.type],
                  snapshots: {
                    day: [tempSnapshot],
                    week: [tempSnapshot],
                    month: [tempSnapshot],
                  },
                };
              },
            );

            return [...old, ...newAssetLiabilities];
          } else {
            const newAssetLiabilities = updatedData.assetLiabilities.map(
              (asset, index) => {
                const tempSnapshot = {
                  assetLiabilityId: timestamp + index,
                  timestamp: now.toISOString(),
                  totalValue: asset.quantity * asset.unitValue,
                  prevTotalValue: 0,
                  quantity: asset.quantity,
                  prevQuantity: 0,
                  unitValue: asset.unitValue,
                  prevUnitValue: 0,
                  pnl: 0,
                  prevPnl: 0,
                  averageCost: asset.unitValue,
                  capitalFlow: asset.quantity * asset.unitValue,
                  totalCapitalInvested: asset.quantity * asset.unitValue,
                  totalValueChange: asset.quantity * asset.unitValue,
                  totalValueChangePercentage: 0,
                  unitValueChange: asset.unitValue,
                  unitValueChangePercentage: 0,
                  quantityChange: asset.quantity,
                  quantityChangePercentage: 0,
                  pnlChange: 0,
                  pnlChangePercentage: 0,
                  pnlPercentage: 0,
                  original: {
                    pnl: 0,
                    prevPnl: 0,
                    unitValue: 1,
                    unitValueChange: 1,
                    totalValue: asset.quantity * asset.unitValue,
                    prevTotalValue: 0,
                    prevUnitValue: 0,
                    averageCost: 1,
                    capitalFlow: asset.quantity * asset.unitValue,
                    pnlChange: 0,
                    totalCapitalInvested: asset.quantity * asset.unitValue,
                  },
                };

                return {
                  ...asset,
                  id: timestamp + index,
                  account: existingAccount.account,
                  accountId: existingAccount.account.id,
                  isIncludedInNWCalculation: true,
                  isLiquid: true,
                  assetClass: updatedData.type,
                  assetOrLiability: assetLiabilityMap[asset.type],
                  snapshots: {
                    day: [tempSnapshot],
                    week: [tempSnapshot],
                    month: [tempSnapshot],
                  },
                };
              },
            );

            return [...old, ...newAssetLiabilities];
          }
        },
      );

      return { previousData };
    },
    onSuccess: async ({ input }) => {
      await queryClient.refetchQueries({
        queryKey: [GET_USER_AGGREGATED_METRICS_QUERY_KEY],
      });

      const changes = input.assetLiabilities.map((asset) => {
        return {
          currency: asset.currency,
          fullname: asset.fullName ?? "",
          name: asset.name,
          type: asset.type,
        };
      });

      posthog.capture("submit_assets_liabilities_form_success", {
        ...input,
        assetLiabilities: changes,
      });
    },
    onError: (error, _, context) => {
      console.error(error);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });
    },
  });

  return { addAsset, isPending };
}

async function callAddAsset(
  input: z.infer<typeof addAssetFormSchema>,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  const { data } = await axios.post(
    `${config.backendUrl}/user/account`,
    input,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data, input };
}
