import { Link, matchPath, useLocation } from "react-router-dom";
import { routes } from "./routes";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { Badge } from "../ui/badge";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";

export function Menu({
  close,
  isCollapsed = false,
}: {
  close?: () => void;
  isCollapsed?: boolean;
}) {
  const { pathname } = useLocation();

  return (
    <nav
      className={cn(
        "flex flex-1 flex-col justify-start gap-y-2",
        isCollapsed && "pt-4",
      )}
    >
      {routes.map((item, index) => {
        const isCurrent = matchPath(item.href, pathname);

        const linkContent = (
          <TooltipProvider delayDuration={300} key={index}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className={cn(
                    "text-left text-base hover:bg-beige/90 w-full py-1 text-gray-700 justify-start",
                    isCurrent && "bg-beige",
                  )}
                  disabled={item.disabled}
                >
                  <item.icon size={16} />
                  {!isCollapsed && <span className="ml-1">{item.title}</span>}
                  {item.disabled && !isCollapsed && (
                    <Badge
                      variant="orange"
                      className="grow-0.5 ml-1 h-4 p-0 px-1 text-[9px]"
                    >
                      Soon
                    </Badge>
                  )}
                </Button>
              </TooltipTrigger>
              {isCollapsed && (
                <TooltipContent
                  side="right"
                  className="bg-black"
                  hideWhenDetached={true}
                >
                  <p className="text-white">{item.title}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        );

        return item.disabled ? (
          linkContent
        ) : (
          <Link to={item.href} key={item.title} onClick={close}>
            {linkContent}
          </Link>
        );
      })}
    </nav>
  );
}
