import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import axios from "@/lib/axios.ts";
import { UserAggregatedMetric } from "@/lib/types.ts";
import config from "@/lib/config.ts";
import { usePeekUser } from "./usePeekUser";

export const GET_USER_AGGREGATED_METRICS_QUERY_KEY = [
  "user",
  "aggregated-metrics",
];

export const useGetUserAggregatedMetrics = () => {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();

  return useQuery({
    queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
    queryFn: async () => {
      const token = await getToken();
      if (!token) throw new Error("User not authenticated");
      const { data } = await axios.get<{
        dailyBreakdown: UserAggregatedMetric[];
      }>(`${config.backendUrl}/user/aggregated-metrics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.dailyBreakdown;
    },
    enabled: peekUserContext.state === "SUCCESS",
  });
};
