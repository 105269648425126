import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_SYNC_TASKS_QUERY_KEY } from "@/lib/constants";
import { KnockSyncTask } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { usePeekUser } from "./usePeekUser";

export function useCheckSyncTasksQuery() {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [GET_SYNC_TASKS_QUERY_KEY],
    queryFn: () => getSyncTasks(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });
  return { data, isLoading, isError, error };
}

async function getSyncTasks(
  getToken: (options?: { template: string }) => Promise<string | null>,
): Promise<KnockSyncTask[]> {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<KnockSyncTask[]>(
    `${config.integrationsUrl}/tasks/check`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}
