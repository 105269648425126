import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

export function ComingSoonPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-10 p-6 text-center sm:p-10">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        Coming Soon!
      </h1>
      <p>We are working hard to bring you this feature. Stay tuned!</p>
      <Link to="/">
        <Button>Back to home</Button>
      </Link>
    </div>
  );
}
