import {
  useState,
  useCallback,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { KnockSyncTask } from "../lib/types";
import { useCheckSyncTasksQuery } from "./useCheckSyncTasksQuery";

// Add these constants at the top of the file
const BACKGROUND_INTEGRATORS = ["PLAID", "SNAPTRADE", "ZERION", "FLANKS"];
const STATUS_IN_PROGRESS = ["PENDING", "IN_PROGRESS", "INTEGRATOR_NOT_READY"];
const STATUS_ERROR = ["EXPIRED", "FAILED"];

interface SyncTasksContextType {
  addSyncTask: (task: KnockSyncTask) => void;
  removeSyncTask: (task: KnockSyncTask) => void;
  syncTasks: KnockSyncTask[];
  syncingAccountIds: number[];
  pendingSyncTasks: KnockSyncTask[];
  errorSyncTasks: KnockSyncTask[];
}

const SyncTasks = createContext<SyncTasksContextType>({
  addSyncTask: () => {},
  removeSyncTask: () => {},
  syncTasks: [],
  syncingAccountIds: [],
  pendingSyncTasks: [],
  errorSyncTasks: [],
});

export function SyncTasksProvider({ children }: { children: ReactNode }) {
  const { data, isLoading } = useCheckSyncTasksQuery();

  const [syncTasks, setSyncTasks] = useState<KnockSyncTask[]>([]);

  // Add pendingSyncTasks using useMemo
  const pendingSyncTasks = useMemo(() => {
    return syncTasks.filter(
      (task) =>
        BACKGROUND_INTEGRATORS.includes(task.integrator) &&
        STATUS_IN_PROGRESS.includes(task.status),
    );
  }, [syncTasks]);

  const errorSyncTasks = useMemo(() => {
    return syncTasks.filter(
      (task) =>
        BACKGROUND_INTEGRATORS.includes(task.integrator) &&
        STATUS_ERROR.includes(task.status),
    );
  }, [syncTasks]);

  useEffect(() => {
    if (!isLoading && data) {
      setSyncTasks(data);
    }
  }, [isLoading, data]);

  const addSyncTask = useCallback((task: KnockSyncTask) => {
    setSyncTasks((prevTasks) => {
      // We may already have the same sync task in this array.
      // This happens when user clicks on "Sync" button.
      // We need to add a sync task to the array so that the sync button changes state.
      // Knock will also send a sync-start notification and try to add into the sync tasks array.
      // So, we prevent duplicates by checking if the task already exists in the array.
      const existingTaskIndex = prevTasks.findIndex(
        (t) =>
          t.account.id === task.account.id && t.integrator === task.integrator,
      );
      if (existingTaskIndex !== -1) {
        const updatedTasks = [...prevTasks];
        updatedTasks[existingTaskIndex] = {
          ...updatedTasks[existingTaskIndex],
          status: task.status,
          updatedAt: task.updatedAt,
        };
        return updatedTasks;
      }
      return [...prevTasks, task];
    });
  }, []);

  const removeSyncTask = useCallback((taskToRemove: KnockSyncTask) => {
    setSyncTasks((prevTasks) =>
      prevTasks.filter(
        (task) =>
          task.account.id !== taskToRemove.account.id &&
          task.integrator !== taskToRemove.integrator,
      ),
    );
  }, []);

  const syncingAccountIds = useMemo(() => {
    return pendingSyncTasks.map((task) => task.account.id);
  }, [pendingSyncTasks]);

  return (
    <SyncTasks.Provider
      value={{
        addSyncTask,
        removeSyncTask,
        syncTasks,
        syncingAccountIds,
        pendingSyncTasks,
        errorSyncTasks,
      }}
    >
      {children}
    </SyncTasks.Provider>
  );
}

export function useSyncTasks() {
  const context = useContext(SyncTasks);
  if (context === undefined) {
    throw new Error("useSyncTasks must be used within a SyncTasksProvider");
  }
  return context;
}
