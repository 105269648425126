import { ElementRef, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";

import { CustomReactSelect } from "../common/CustomReactSelect";
import CreatableSelect from "react-select/creatable";
import { searchItems } from "./searchItems";
import { CustomSearchItems } from "./types";
import { InputActionMeta } from "react-select";

export function AddAssetFirstStep({
  form,
  setConfirmationDialogOpen,
  clearCategory,
  options,
  accountType,
  nextStep,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<{ name: string; type: any; assetLiabilities: any[] }>;
  setConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  clearCategory: () => void;
  options: { value: string; label: string }[];
  accountType: CustomSearchItems;
  nextStep: () => void;
}) {
  const { name: userInputAccountName } = form.getValues();
  const [inputValue, setInputValue] = useState(userInputAccountName);
  const nameRef = useRef<ElementRef<typeof CreatableSelect>>(null);

  useEffect(() => {
    // Set focus to first input
    nameRef?.current?.focus();
    // On key down esc, clear category if empty else show confirmation dialog
    const onKeydown = (e: KeyboardEvent) => {
      const { isDirty } = form.formState;
      if (e.key === "Escape") {
        // Must listen within useEffect to get latest form state
        if (isDirty) {
          setConfirmationDialogOpen(true);
        } else {
          clearCategory();
        }
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, [clearCategory, form.formState, setConfirmationDialogOpen]);
  return (
    <FormField
      control={form.control}
      name="name"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>
              {searchItems[accountType]?.accountNameInputLabel}
            </FormLabel>
            <FormControl>
              <CustomReactSelect
                ref={nameRef}
                options={options}
                inputValue={inputValue}
                onInputChange={(value: string, { action }: InputActionMeta) => {
                  if (action !== "input-blur" && action !== "menu-close") {
                    setInputValue(value);
                    field.onChange(value);
                  }
                }}
                onChange={(value) => {
                  field.onChange(
                    (value as { value: string; label: string })?.value,
                  );
                  nextStep();
                }}
                openMenuOnFocus
                noOptionsMessage={() => "No accounts found."}
                closeMenuOnSelect
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
