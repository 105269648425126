import { SignIn } from "@clerk/clerk-react";
import { SignUpPageLayout } from "./SignUpPageLayout";

export function SignInPage() {
  return (
    <SignUpPageLayout>
      <div className="relative flex items-center justify-center rounded-lg rounded-r-none bg-white">
        <SignIn
          redirectUrl={"/"}
          signUpUrl="/sign-up"
          appearance={{
            elements: {
              card: {
                boxShadow: "none",
              },
              footerActionLink: {
                color: "#4966AF",
              },
            },
          }}
        />
      </div>

      <div className="flex max-w-full items-center justify-center bg-beige p-4">
        <iframe
          width="500"
          height="315"
          src="https://www.youtube.com/embed/biItRTF_2Zw?si=faRjalpop_59Aat7&autoplay=1&mute=0&controls=0"
          title="Peek V2 Demo"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </SignUpPageLayout>
  );
}
