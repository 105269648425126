import { ETFHolding } from "@/hooks/useAnalysis";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { InfoIcon } from "lucide-react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

function Value({
  value,
  size = "large",
}: {
  value: number | string;
  size?: "large" | "small";
}) {
  return (
    <div className="flex gap-x-2 self-end">
      <span className={size === "large" ? "text-3xl" : "text-2xl"}>
        {value}
      </span>
    </div>
  );
}

export default function ETFHoldingsRow({
  ETFHolding,
  accordionValue,
}: {
  ETFHolding: ETFHolding;
  accordionValue: string;
}) {
  return (
    <AccordionItem value={accordionValue}>
      <AccordionTrigger>
        {ETFHolding.assetFullName} ({ETFHolding.assetName})
      </AccordionTrigger>
      <AccordionContent className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-2">
          <Card className="w-full bg-gray-100 p-4 shadow-none">
            <div className="flex flex-row items-center">No of overlaps</div>
            <div className="gap-y-2">
              <Value value={ETFHolding.exposedETFs.length + 1} />
            </div>
          </Card>
          <Card className="w-full bg-gray-100 p-4 shadow-none">
            <div className="flex flex-row items-center">
              True exposure
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <InfoIcon
                      size={12}
                      className="ml-2 text-gray-400 hover:cursor-pointer"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="bg-black" side="top">
                    <p className="text-white">
                      Including your indirect exposure from ETFs
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="gap-y-2">
              <Value value={ETFHolding.trueWeightageIncludingETF} />
            </div>
          </Card>
        </div>
        <div className="">
          <Table>
            <TableCaption>
              Your <i>{ETFHolding.assetName}</i> positions
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-full text-xs text-gray-400">
                  Position
                </TableHead>
                <TableHead className="text-right text-xs text-gray-400">
                  % of net worth
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">Direct Position</TableCell>
                <TableCell className="text-right">
                  {ETFHolding.weightageExcludingETF}
                </TableCell>
              </TableRow>
              {ETFHolding.exposedETFs.map((exposedETF, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">
                    {exposedETF.etfName}
                  </TableCell>
                  <TableCell className="text-right">
                    {exposedETF.relativeWeightage}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
