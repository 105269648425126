import React, { useLayoutEffect } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";

type CancelAddAssetConfirmationProps = {
  confirmationDialogOpen: boolean;
  setConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  handleConfirmationCancel: () => void;
};

export default function CancelAddAssetConfirmation({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  closeDialog,
  handleConfirmationCancel,
}: CancelAddAssetConfirmationProps) {
  const leaveRef = React.useRef<HTMLButtonElement>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  // Focus leave button when dialog opens
  useLayoutEffect(() => {
    if (confirmationDialogOpen) {
      const timer = setTimeout(() => {
        if (leaveRef.current) {
          leaveRef.current.focus();
        }
      }, 0); // set timeout to 0 to add the focus operation to the end of the event loop

      // Clean up function to clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timer);
    }
  }, [confirmationDialogOpen]);

  return (
    <AlertDialog
      open={confirmationDialogOpen}
      onOpenChange={setConfirmationDialogOpen}
    >
      <AlertDialogContent
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            cancelRef.current?.click();
            e.stopPropagation();
          }
        }}
      >
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to leave?</AlertDialogTitle>
          <AlertDialogDescription>
            Looks like you have unsaved changes. If you leave now, you'll lose
            any information you've entered.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            id="continue-editing"
            ref={cancelRef}
            onClick={handleConfirmationCancel}
            onKeyDown={(e) => {
              // To stop enter event from propagating to the form
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
          >
            Continue Editing
          </AlertDialogCancel>
          <AlertDialogAction ref={leaveRef} onClick={closeDialog}>
            Leave
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
