import { Toaster } from "@/components/ui/sonner";
import config from "@/lib/config";
import { queryClient } from "@/lib/queryClient";
import { ClerkProvider } from "@clerk/clerk-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

export function RootLayout() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      navigate={navigate}
      publishableKey={config.clerkPublishableKey}
      appearance={{
        elements: {
          formButtonPrimary:
            "bg-customOrange hover:bg-orange-500 transition-all border-true-blue text-white px-6 py-3 text-sm leading-4 font-medium shadow-sm focus:outline-none hover:bg-true-blue capitalize",
          card: "rounded-lg",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
      <Toaster position="top-center" richColors theme="light" />
    </ClerkProvider>
  );
}
