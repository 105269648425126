import { useEffect, useRef } from "react";
import { SignUp } from "@clerk/clerk-react";
import { SignUpPageLayout } from "./SignUpPageLayout";
import { TestimonialCarousel } from "@/components/ui/testimonial-carousel";

export function SignUpPage() {
  const ref = useRef(null as HTMLDivElement | null);

  useEffect(() => {
    const interval = setInterval(() => {
      const element = ref.current?.querySelector(".cl-alertText");
      if (
        element &&
        element.textContent ===
          "You are not allowed to access this application."
      ) {
        element.innerHTML =
          "<p>Your email address is not whitelisted.</p><p><a class='underline text-blue-400' href='https://form.typeform.com/to/L8zZgj9r'>Join the waitlist here</a> to access Peek.<p>";
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <SignUpPageLayout>
      <div className="relative flex items-center justify-center rounded-lg rounded-r-none bg-white">
        <SignUp
          redirectUrl={"/"}
          signInUrl="/sign-in"
          appearance={{
            elements: {
              card: {
                boxShadow: "none",
              },
              footerActionLink: {
                color: "#4966AF",
              },
            },
          }}
        />
      </div>

      <TestimonialCarousel />
    </SignUpPageLayout>
  );
}
