import { useState } from "react";
import { Button } from "@/components/ui/button";
import { CalendarDays } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import { Goal } from "@/lib/types";
import { goalOptions } from "../constants";
import { NumberInputIcon } from "@/components/ui/number-input-icon";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";

type StepSevenProps = {
  preferredCurrency: Currency;
  onNext: (data: Goal) => void;
  onSkip: () => void;
  onChangeGoals: () => void;
  goal: Goal;
  isLoading: boolean;
};

export function StepSeven({
  preferredCurrency,
  onNext,
  onSkip,
  onChangeGoals,
  goal,
  isLoading,
}: StepSevenProps) {
  const [ownGoal, setOwnGoal] = useState("");
  const [formData, setFormData] = useState({
    targetNetWorth: 0,
    targetDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
  });

  const updateFormData = (
    field: keyof typeof formData,
    value: string | Date | number,
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = () => {
    if (goal.goalOptionIndex !== undefined && goal.goalOptionIndex < 6) {
      onNext({
        targetNetWorth: formData.targetNetWorth,
        targetDate: formData.targetDate,
        name: goalOptions[goal.goalOptionIndex].title,
      });
    } else {
      onNext({
        targetNetWorth: formData.targetNetWorth,
        targetDate: formData.targetDate,
        name: ownGoal,
      });
    }
  };

  return (
    <>
      <div className="w-full rounded-lg border border-gray-200 bg-beige p-4">
        <div className="flex flex-row items-center gap-2">
          {goal.goalOptionIndex !== undefined && goal.goalOptionIndex < 6 && (
            <>
              <img
                src={goalOptions[goal.goalOptionIndex].image}
                alt={goalOptions[goal.goalOptionIndex].title}
                className="size-9"
              />
              <p className="flex-1 py-4 font-semibold text-gray-700">
                {goalOptions[goal.goalOptionIndex].title}
              </p>
            </>
          )}

          {goal.goalOptionIndex !== undefined && goal.goalOptionIndex === 6 && (
            <div className="flex flex-col space-y-2">
              <div className="mb-4 flex flex-row space-x-2">
                <img
                  src={goalOptions[goal.goalOptionIndex].image}
                  alt={goalOptions[goal.goalOptionIndex].title}
                  className="size-9"
                />
                <div className="flex flex-row items-center space-x-4">
                  <p>I want to</p>
                  <Input
                    placeholder="type your financial goal here."
                    className="min-w-60 flex-1"
                    value={ownGoal}
                    onChange={(e) => setOwnGoal(e.target.value)}
                  />
                </div>
              </div>

              <div className="pb-4">
                {goalOptions[goal.goalOptionIndex].title}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-row items-center gap-2">
          <p className="flex-none text-gray-700">
            I want to achieve net worth of{" "}
          </p>
          <NumberInputIcon
            locale={getLocaleForCurrency(preferredCurrency)}
            placeholder="5000000"
            startAdornment={
              <span className="text-sm">{preferredCurrency}</span>
            }
            wrapperClassName="bg-white py-2"
            className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            value={formData.targetNetWorth.toString()}
            onChange={(e) => updateFormData("targetNetWorth", +e.target.value)}
            autoFocus
          />
          <p className="text-gray-700">by</p>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="flex-1">
                <span className="font-medium text-gray-700">
                  {formData.targetDate
                    ? format(formData.targetDate, "d MMM yyyy")
                    : "Select date"}
                </span>
                <CalendarDays className="ml-auto h-4 w-4 text-gray-400" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              side="top"
              className="w-auto bg-white p-0"
            >
              <Calendar
                mode="single"
                disabled={(date) =>
                  date < new Date("1900-01-01") || date <= new Date()
                }
                initialFocus
                captionLayout="dropdown"
                selected={formData.targetDate}
                onSelect={(date) => date && updateFormData("targetDate", date)}
                fromYear={new Date().getFullYear()}
                toYear={new Date().getFullYear() + 100}
                classNames={{
                  vhidden: "vhidden hidden",
                }}
                defaultMonth={formData.targetDate}
                onMonthChange={(date) =>
                  date && updateFormData("targetDate", date)
                }
              />
            </PopoverContent>
          </Popover>
        </div>

        <Button
          variant="ghost"
          className="mt-4 w-full bg-gray-100 text-gray-700"
          onClick={onChangeGoals}
          disabled={isLoading}
        >
          Change goals
        </Button>
      </div>

      <div className="mt-4 flex w-full flex-row justify-between">
        <Button
          variant="link"
          onClick={onSkip}
          className="text-gray-400"
          disabled={isLoading}
        >
          Skip goal setting
        </Button>

        <Button
          variant="default"
          onClick={handleNext}
          disabled={
            isLoading ||
            (goal.goalOptionIndex !== undefined && goal.goalOptionIndex < 6
              ? !formData.targetNetWorth || !formData.targetDate
              : !formData.targetNetWorth || !formData.targetDate || !ownGoal)
          }
        >
          Next
        </Button>
      </div>
    </>
  );
}
