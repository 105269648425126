import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Profile } from "@/lib/types";

type FamilyDetailsProps = {
  formData: Profile;
  setFormData: (data: Profile) => void;
};

export default function FamilyDetails({
  formData,
  setFormData,
}: FamilyDetailsProps) {
  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium text-gray-700">Family Details</h4>
      <Separator />

      <div>
        <label className="block py-2 text-gray-500">Marital status</label>
        <Select
          wrapperClass="flex-1 w-full"
          onValueChange={(value) =>
            setFormData({ ...formData, maritalStatus: value })
          }
          value={formData.maritalStatus}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="single">Single</SelectItem>
            <SelectItem value="married">Married</SelectItem>
            <SelectItem value="preferNotToSay">Prefer not to say</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div>
        <label className="block py-2 text-gray-500">No. of Dependents</label>
        <Select
          wrapperClass="flex-1 w-full"
          onValueChange={(value) =>
            setFormData({ ...formData, numberOfDependents: parseInt(value) })
          }
          value={formData.numberOfDependents?.toString() || "0"}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">0</SelectItem>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">2</SelectItem>
            <SelectItem value="3">3</SelectItem>
            <SelectItem value="4">4</SelectItem>
            <SelectItem value="5">5</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
