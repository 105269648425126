import axios from "@/lib/axios";
import config from "@/lib/config";
import {
  GET_PORTFOLIO_ANALYSIS_QUERY_KEY,
  GET_ASSET_ALLOCATION_ANALYSIS_QUERY_KEY,
  GET_ETF_HOLDINGS_ANALYSIS_QUERY_KEY,
} from "@/lib/constants";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { usePeekUser } from "./usePeekUser";

export interface Analysis {
  score: number;
  title: string;
  description: string;
  good?: string;
  bad?: string;
  recommendation: string[];
  insights: Insight[];
  risk_profile?: string;
  target_allocation?: Allocation[];
  actual_allocation?: Allocation[];
  overlaps?: ETFHolding[];
}
export interface ETFHolding {
  assetName: string;
  assetFullName: string;
  weightageExcludingETF: string;
  trueWeightageIncludingETF: string;
  standAloneShares: number;
  totalAssetValueInUSD: number;
  totalShares: number;
  exposedETFs: {
    etfName: string;
    relativeShares: number;
    relativeWeightage: string;
  }[];
}

export interface Allocation {
  assetClass: string;
  percentageOfNetWorth: string;
}
interface Insight {
  title: string;
  description: string;
}

type PeekUserLoading = {
  state: "LOADING";
};

type PeekUserError = {
  state: "ERROR";
  message?: string;
};

export type PeekUserSuccess = {
  state: "SUCCESS";
  concentrationRiskData: Analysis[];
  assetAllocationData: Analysis[];
  ETFHoldingsData: Analysis[];
};

export type AnalysisType = PeekUserLoading | PeekUserError | PeekUserSuccess;

const AnalysisContext = createContext<AnalysisType>({
  state: "LOADING",
});

export function AnalysisProvider({ children }: { children: React.ReactNode }) {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();

  const {
    data: portfolioData,
    isLoading: portfolioLoading,
    isError: portfolioError,
  } = useQuery({
    queryKey: [GET_PORTFOLIO_ANALYSIS_QUERY_KEY],
    queryFn: async () => getPortfolioAnalysis(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });

  const {
    data: assetData,
    isLoading: assetLoading,
    isError: assetError,
  } = useQuery({
    queryKey: [GET_ASSET_ALLOCATION_ANALYSIS_QUERY_KEY],
    queryFn: async () => getAssetAllocationAnalysis(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });

  const {
    data: etfHoldingsData,
    isLoading: etfHoldingsLoading,
    isError: etfHoldingsError,
  } = useQuery({
    queryKey: [GET_ETF_HOLDINGS_ANALYSIS_QUERY_KEY],
    queryFn: async () => getETFHoldingsAnalysis(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });

  const isLoading = portfolioLoading || assetLoading || etfHoldingsLoading;
  const isError = portfolioError || assetError || etfHoldingsError;

  if (isLoading) {
    return (
      <AnalysisContext.Provider value={{ state: "LOADING" }}>
        {children}
      </AnalysisContext.Provider>
    );
  }

  if (isError) {
    return (
      <AnalysisContext.Provider value={{ state: "ERROR" }}>
        {children}
      </AnalysisContext.Provider>
    );
  }

  return (
    <AnalysisContext.Provider
      value={{
        state: "SUCCESS",
        concentrationRiskData: [...(portfolioData ?? [])],
        assetAllocationData: [...(assetData ?? [])],
        ETFHoldingsData: [...(etfHoldingsData ?? [])],
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
}

async function getPortfolioAnalysis(
  getToken: (options?: { template: string }) => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<Analysis[]>(
    `${config.backendUrl}/analysis/portfolio`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}

async function getAssetAllocationAnalysis(
  getToken: (options?: { template: string }) => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<Analysis[]>(
    `${config.backendUrl}/analysis/asset-allocation`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}

async function getETFHoldingsAnalysis(
  getToken: (options?: { template: string }) => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<Analysis[]>(
    `${config.backendUrl}/analysis/etf-holdings`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}

export function useAnalysis() {
  return useContext(AnalysisContext);
}
