import React from "react";
import { Chart } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { shortNumber } from "@/lib/utils";
import { draw } from "patternomaly";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "@/components/ui/button";
import iconConcierge from "/images/icon-concierge.svg";
import { Fullscreen } from "lucide-react";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";
import { useCurrency } from "@/hooks/useCurrency";
import { UserAggregatedMetric } from "@/lib/types";

interface Props {
  snapshots: UserAggregatedMetric[];
  endDate: Date;
  grouping: "day" | "week" | "month";
}

ChartJS.register(
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
  BarController,
  zoomPlugin,
  TimeScale,
);

export const HomeChart: React.FC<Props> = ({ snapshots, grouping }) => {
  const { currency } = useCurrency();
  const chartRef = React.useRef<ChartJS<"bar">>(null);

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: ChartJS) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;

      if (chart.getActiveElements().length > 0) {
        const xCoor = x.getPixelForValue(
          tooltip?.dataPoints[0]?.dataIndex ?? 0,
        );

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = "rgb(0,0,0,0.8)";
        ctx.moveTo(xCoor, top);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  const parseSnapshotData = (snapshots: UserAggregatedMetric[]) => {
    if (snapshots.length < 1) {
      return {
        labels: [],
        investibleCashes: [],
        liquidTotalNetWorths: [],
        illiquidTotalNetWorths: [],
      };
    }

    const labels: Date[] = [];
    const investibleCashes: number[] = [];
    const liquidTotalNetWorths: number[] = [];
    const illiquidTotalNetWorths: number[] = [];

    snapshots.forEach((snapshot) => {
      const date = new Date(snapshot.date);
      labels.push(date);
      investibleCashes.push(snapshot.investibleCash);
      liquidTotalNetWorths.push(snapshot.liquidTotalNetWorth);
      illiquidTotalNetWorths.push(snapshot.illiquidTotalNetWorth);
    });

    return {
      labels,
      investibleCashes,
      liquidTotalNetWorths,
      illiquidTotalNetWorths,
    };
  };

  // Parse snapshot data
  const snapshotData = parseSnapshotData(snapshots);

  const datasets: ChartDataset<"bar", number[]>[] = [];

  const createBarDataset = (
    label: string,
    data: number[],
    color: string,
  ): ChartDataset<"bar", number[]> => ({
    label,
    data,
    backgroundColor: data.map((value) =>
      value < 0
        ? draw("diagonal", "rgb(220, 38, 38, 0.0)", "#dc2626", 5)
        : draw("diagonal", "rgb(220, 38, 38, 0.0)", color, 5),
    ),
    borderColor: data.map((value) => (value < 0 ? "#dc2626" : color)),
    borderWidth: 1,
    barPercentage: 0.8,
    type: "bar",
    yAxisID: "y",
  });

  datasets.push(
    createBarDataset(
      "Investible Cash",
      snapshotData.investibleCashes,
      "#4966AF",
    ),
  );
  datasets.push(
    createBarDataset(
      "Illiquid Assets",
      snapshotData.illiquidTotalNetWorths,
      "#FE875C",
    ),
  );
  datasets.push(
    createBarDataset(
      "Liquid Assets",
      snapshotData.liquidTotalNetWorths,
      "#41989D",
    ),
  );

  const data: ChartData<"bar", number[], string> = {
    labels: snapshotData.labels.map((date) => {
      if (grouping === "day" || grouping === "week") {
        return format(date, "dd/MM/yyyy");
      }
      return format(date, "MMM yy");
    }),
    datasets,
  };
  const options: ChartOptions<"bar"> = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    transitions: {
      zoom: {
        animation: {
          duration: 400,
          easing: "easeOutQuad",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        type: "category",
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Total",
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          mode: "x",
          drag: {
            enabled: true,
            backgroundColor: "#F4EEE380",
          },
          pinch: {
            enabled: true,
          },
        },
      },
      tooltip: {
        titleFont: {
          family: "'Manrope', sans-serif",
          size: 18,
        },
        bodyFont: {
          family: "'Manrope', sans-serif",
          size: 14,
          lineHeight: 1.4,
        },
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${currency} ${shortNumber(
              context.raw as number,
            )}`;
          },
        },
        caretSize: 0,
        padding: 12,
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
      chartRef.current.options.scales!.x!.min = undefined;
      chartRef.current.update();
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="h-[400px]">
        <Chart
          ref={chartRef}
          type="bar"
          options={options}
          data={data}
          plugins={[hoverLine]}
        />
      </div>
      {snapshotData.labels.length > 7 && (
        <div className="flex items-center space-x-4 rounded-md border p-4">
          <img src={iconConcierge} alt="concierge" className="h-6 w-6" />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none text-gray-700">
              Did you know?
            </p>
            <p className="text-sm text-gray-500">
              You can drag on the chart to zoom in
            </p>
          </div>
          <Button
            variant="outline"
            onClick={handleResetZoom}
            className="gap-x-2"
          >
            <Fullscreen size={16} />
            Reset Zoom
          </Button>
        </div>
      )}
    </div>
  );
};
