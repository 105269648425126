import { Star } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

type TestimonialCardProps = {
  description: string;
  stars: number;
  name: string;
  company: string;
};

const TestimonialCard = ({
  description,
  stars,
  name,
  company,
}: TestimonialCardProps) => {
  return (
    <CarouselItem className="flex justify-center py-2">
      <div className="max-w-sm rounded-sm bg-white p-8 shadow-md">
        <div className="flex flex-row items-center space-x-2">
          {[...Array(stars)].map((_, index) => (
            <Star key={index} size={16} className="fill-sunset text-sunset" />
          ))}
        </div>

        <p className="py-4 font-semibold text-gray-700">{description}</p>

        <div className="flex flex-row items-center space-x-2">
          <img src="/images/logo-small.svg" className="size-10" />
          <div>
            <p className="font-semibold text-gray-700">{name}</p>
            <p className="text-sm text-gray-700">{company}</p>
          </div>
        </div>
      </div>
    </CarouselItem>
  );
};

export const TestimonialCarousel = () => {
  const testimonials = [
    {
      description:
        "What I love so far: automated integration from DBS and Saxo, auto conversion of currency (didn't realize how much time I was spending connecting EUR, SGD, USD, hkd and etc.), and the various ways to slice and dice the data including tagging (very AWS-like).",
      stars: 5,
      name: "Marc",
      company: "Amazon Web Services",
    },
    {
      description:
        "This is the only app I've seen that will do real time price tracking of both crypto and stocks :open_mouth:",
      stars: 5,
      name: "Joshua",
      company: "Apple",
    },
    {
      description:
        "I tried a few integrations and it works seamlessly. A great product!",
      stars: 5,
      name: "John Li",
      company: "Engineering Leader in FinTech",
    },
    {
      description:
        "I find it quite captivating, and its user experience is truly remarkable—optimally designed and effortlessly comprehensible.",
      stars: 5,
      name: "Deeshana Basnayake",
      company: "Luhn",
    },
    {
      description:
        'Could I say, love love love the "Peek Pub". Especially the "Playground" section. Keep up the great work Peek team!',
      stars: 5,
      name: "Cindy Adelia",
      company: "",
    },
    {
      description:
        "I have enjoyed using Peek over the last 2 weeks. Let me start off with that. I have found myself logging into Peek to literally peek what had happened to my overall finances rather than open my brokerage account.",
      stars: 5,
      name: "Anand Kumar",
      company: "",
    },
  ];

  return (
    <div className="flex max-w-full flex-col items-center justify-center bg-beige p-4 md:w-[500px]">
      <h3 className="pb-4">Hear what our users have to say</h3>

      <Carousel className="w-full items-center">
        <CarouselContent className="items-center">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              description={testimonial.description}
              stars={testimonial.stars}
              name={testimonial.name}
              company={testimonial.company}
            />
          ))}
        </CarouselContent>
        <div className="mt-2 flex flex-row items-center justify-center gap-x-2">
          <CarouselPrevious className="relative -left-0 top-0 -translate-y-0 border-0 text-orange-500 shadow-lg" />
          <CarouselNext className="relative -right-0  top-0 -translate-y-0 border-0 text-orange-500 shadow-lg" />
        </div>
      </Carousel>
    </div>
  );
};
