import { useState } from "react";
import { TableRowData } from "./types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AssetLiabilityTable } from "./AssetLiabilityTable";

export function ValidTable({
  data,
}: {
  data: { assets: TableRowData[]; liabilities: TableRowData[] };
}) {
  const [tab, setTab] = useState("assets");

  return (
    <Tabs
      value={tab}
      onValueChange={setTab}
      className="mt-4 rounded-md border border-gray-200"
    >
      <div className="w-full items-center justify-between border-b-[1px] border-gray-200 bg-border/50 py-2">
        <TabsList className="flex gap-x-2 bg-transparent">
          <TabsTrigger value="assets" className="flex gap-x-2">
            Assets
          </TabsTrigger>
          <TabsTrigger value="liabilities" className="flex gap-x-2">
            Liabilities
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="assets" className="m-0">
        <AssetLiabilityTable data={data.assets} />
      </TabsContent>

      <TabsContent value="liabilities" className="m-0">
        <AssetLiabilityTable data={data.liabilities} />
      </TabsContent>
    </Tabs>
  );
}
