import MotionNumber from "motion-number";
import { easeOut } from "framer-motion";
import { cn } from "@/lib/utils";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";
import { useLayoutEffect, useState } from "react";

export function DisplayValue({
  value,
  size = "large",
  currency = "",
  highlightNumbers = false,
  compact = false,
}: {
  value?: number | string;
  size?: "large" | "small";
  currency?: string;
  highlightNumbers?: boolean;
  compact?: boolean;
}) {
  const locale = getLocaleForCurrency((currency || "USD") as Currency);

  const isPercentage = typeof value === "string" && value.includes("%");
  const [numericValue, setNumericValue] = useState<number | string>(0);

  useLayoutEffect(() => {
    if (value !== undefined) {
      setNumericValue(
        isPercentage ? parseFloat(value.replace("%", "")) / 100 : value,
      );
    }
  }, [isPercentage, value]);

  return (
    <div className="flex gap-x-1 self-end font-normal text-gray-700">
      {currency && (
        <span className={size === "large" ? "text-xl" : "text-xs"}>
          {currency}
        </span>
      )}

      {numericValue !== undefined && (
        <MotionNumber
          className={cn(
            size === "large" ? "text-3xl" : "text-2xl",
            highlightNumbers &&
              parseFloat(numericValue as string) < 0 &&
              "text-red-600",
            highlightNumbers &&
              parseFloat(numericValue as string) >= 0 &&
              "text-darkCyan",
          )}
          value={numericValue}
          format={
            isPercentage
              ? { style: "percent", maximumFractionDigits: 2 }
              : compact
                ? { notation: "compact", maximumFractionDigits: 2 }
                : { style: "decimal", maximumFractionDigits: 2 }
          }
          locales={locale}
          transition={{
            // Applied to layout animations on individual characters:
            layout: { type: "spring", duration: 0.7, bounce: 0 },
            // Used for the digit animations:
            y: { type: "spring", duration: 0.7, bounce: 0.4 },

            // Opacity applies to entering/exiting characters.
            // Note the use of the times array, explained below:
            opacity: { duration: 0.7, ease: easeOut, times: [0, 0.3] }, // 0.3s perceptual duration
          }}
        />
      )}
    </div>
  );
}
