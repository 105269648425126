import { addAssetFormSchema, assetSchema } from "@/components/AddAsset/schema";
import { CustomSearchItems } from "@/components/AddAsset/types";
import { z } from "zod";
import { DEFAULT_CURRENCY } from "./constants";
import {
  InputRowData,
  Section,
  SubSection,
  TableRowData,
} from "@/components/AddAsset/QuickPaste/types";
import { AssetLiabilityV2, assetRequiresQuantity } from "./types";

export const ASSETS_TYPE_MAP: Record<
  CustomSearchItems,
  z.infer<typeof assetSchema>["type"][]
> = {
  ALTERNATIVE_INVESTMENT: [
    "ANGEL_INVESTMENT",
    "HEDGE_FUND",
    "PRIVATE_CREDIT",
    "PRIVATE_EQUITY",
  ],
  BANK_ACCOUNT: ["CASH", "FIXED_DEPOSIT"],
  COLLECTIBLE: ["ART", "WINE", "WATCH"],
  CREDIT_CARD: ["CREDIT_CARD"],
  TOKEN: ["TOKEN"],
  NFT: ["NFT"],
  INSURANCE: ["LIFE_INSURANCE"],
  LOAN: ["LOAN"],
  OTHER: ["OTHER"],
  PRECIOUS_METAL: ["GOLD", "SILVER", "PALLADIUM", "PLATINUM"],
  PRIVATE_COMPANY: ["ESOP", "RSU", "SHARE"],
  STOCK: ["STOCK"],
  ETF: ["ETF"],
  MUTUAL_FUND: ["MUTUAL_FUND"],
  BOND: ["BOND"],
  REIT: ["REIT"],
  REAL_ESTATE: ["REAL_ESTATE"],
  RETIREMENT_FUND: [
    "SG_CPF_OA",
    "SG_CPF_RA",
    "SG_CPF_SA",
    "SG_CPF_MA",
    "SG_SRS",
  ],
  ROBO_ACCOUNT: ["ROBO"],
};

/**
 * Returns the default values, asset types and other details for the given asset category
 */
export function getFormValues(accountType: CustomSearchItems) {
  const assetTypes: z.infer<typeof assetSchema>["type"][] =
    ASSETS_TYPE_MAP[accountType];

  const requiresQuantity = !["REAL_ESTATE"].includes(accountType);

  const requiresUnitValue = ![
    "BANK_ACCOUNT",
    "INSURANCE",
    "RETIREMENT_FUND",
    "LOAN",
    "CREDIT_CARD",
  ].includes(accountType);

  const requiresName = ![
    "RETIREMENT_FUND",
    "PRECIOUS_METAL",
    "PRIVATE_COMPANY",
  ].includes(accountType);

  const requiresType = assetTypes.length !== 1;

  const usesExternalApiForPrice = [
    "STOCK",
    "REIT",
    "ETF",
    "BOND",
    "MUTUAL_FUND",
    "TOKEN",
  ].includes(accountType);

  const isLiability = ["LOAN", "CREDIT_CARD"].includes(accountType);

  const defaultValues: Omit<
    z.infer<typeof assetSchema>,
    "unitValue" | "quantity"
  > & {
    unitValue: string | number;
    quantity: string | number;
  } = {
    name: "",
    type: assetTypes[0],
    currency: DEFAULT_CURRENCY,
    // Defaults to 1 for types that don't require quantity
    quantity: requiresQuantity ? "" : 1,
    unitValue: requiresUnitValue ? "" : 1,
  };

  return {
    defaultValues,
    assetTypes,
    requiresName,
    requiresQuantity,
    requiresUnitValue,
    requiresType,
    usesExternalApiForPrice,
    isLiability,
  };
}

const optionalAssetName = [
  "CASH",
  "FIXED_DEPOSIT",
  "GOLD",
  "SILVER",
  "PALLADIUM",
  "PLATINUM",
  "ESOP",
  "RSU",
  "SHARE",
  "SG_CPF_OA",
  "SG_CPF_RA",
  "SG_CPF_SA",
  "SG_CPF_MA",
  "SG_SRS",
  "US_401K",
  "US_IRA",
  "US_529",
];

export const validateAssetName = (data: z.infer<typeof assetSchema>) => {
  return optionalAssetName.includes(data.type) || data.name;
};

export const assetNameValidationError = {
  message: "Name is required for this asset type",
  path: ["name"],
};

export function getValidTableData(inputData: InputRowData[]): {
  assets: TableRowData[];
  liabilities: TableRowData[];
} {
  const groupedByAccountType = new Map();

  // Grouping by AccountType
  inputData.forEach((entry) => {
    const accountType = entry.accountType;
    if (!groupedByAccountType.has(accountType)) {
      groupedByAccountType.set(accountType, []);
    }
    groupedByAccountType.get(accountType).push(entry);
  });

  // Transforming each group
  const transformedData = Array.from(
    groupedByAccountType,
    ([accountType, accountTypeGroup]) => {
      const accountTypeSection: Section = {
        asset: accountType,
        type: "section",
        subRows: [],
      };

      const groupedByAsset = new Map();

      // Grouping by the account type
      accountTypeGroup.forEach((entry: InputRowData) => {
        const asset = entry.accountName;
        if (!groupedByAsset.has(asset)) {
          groupedByAsset.set(asset, []);
        }
        groupedByAsset.get(asset).push(entry);
      });

      // Transforming each sub-group
      groupedByAsset.forEach((assetGroup, asset) => {
        const assetSubSection: SubSection = {
          asset: asset,
          type: "subSection",
          subRows: assetGroup.map((assetData: InputRowData) => ({
            asset: assetData.assetName,
            fullName: assetData.fullName,
            type: "asset",
            assetType: assetData.assetType,
            currency: assetData.currency,
            unitValue: assetData.unitValue,
            quantity: assetData.quantity,
            update: assetData.id ? true : false,
          })),
        };
        accountTypeSection.subRows.push(assetSubSection);
      });

      return accountTypeSection;
    },
  );

  const assets: TableRowData[] = [];
  const liabilities: TableRowData[] = [];

  transformedData.forEach((section) => {
    ["LOAN", "CREDIT_CARD"].includes(section.asset)
      ? liabilities.push(section)
      : assets.push(section);
  });

  return { assets, liabilities };
}

export const transformArray = (arr: string[]) =>
  ({
    accountName: arr[0],
    accountType: arr[1],
    assetName: arr[2],
    assetType: arr[3],
    fullName: arr[4] || "",
    currency: arr[5],
    quantity: arr[6],
    unitValue: arr[7],
  }) as InputRowData;

export function convertToAccountData(assetArray: InputRowData[]) {
  const convertedArray: z.infer<typeof addAssetFormSchema>[] = [];

  assetArray.forEach((asset) => {
    const existingAccountIndex = convertedArray.findIndex(
      (acc) => acc.name === asset.accountName && acc.type === asset.accountType,
    );

    if (existingAccountIndex !== -1) {
      // If account already exists, push asset/liability to existing account
      convertedArray[existingAccountIndex].assetLiabilities.push({
        currency: asset.currency,
        name: asset.assetName,
        quantity: +asset.quantity,
        type: asset.assetType,
        unitValue: +asset.unitValue,
        fullName: asset.fullName,
      });
    } else {
      // If account doesn't exist, create new account
      convertedArray.push({
        name: asset.accountName,
        type: asset.accountType,
        assetLiabilities: [
          {
            currency: asset.currency,
            name: asset.assetName,
            quantity: +asset.quantity,
            type: asset.assetType,
            unitValue: +asset.unitValue,
            fullName: asset.fullName,
          },
        ],
      });
    }
  });

  return convertedArray;
}

export function findExistingAsset(
  assetToFind: z.infer<typeof assetSchema>,
  accountName: z.infer<typeof addAssetFormSchema>["name"],
  assetLiabilitiesAccounts: AssetLiabilityV2[],
) {
  const found = assetLiabilitiesAccounts.find(
    (assetLiability) =>
      assetLiability.account.name === accountName &&
      assetLiability.name === assetToFind.name &&
      assetLiability.type === assetToFind.type &&
      assetLiability.currency === assetToFind.currency,
  );
  if (found) {
    return { existingAsset: found };
  }
  return {};
}

export function findExistingAssetIdWithRow(
  row: InputRowData,
  assetLiabilitiesAccounts: AssetLiabilityV2[],
) {
  const found = assetLiabilitiesAccounts.find(
    (assetLiability) =>
      assetLiability.account.name === row.accountName &&
      assetLiability.name === row.assetName &&
      assetLiability.type === row.assetType &&
      assetLiability.currency === row.currency,
  );

  return found ? found.id : null;
}

export function combineWithExistingAsset(
  existingAsset: AssetLiabilityV2,
  assetToAdd: z.infer<typeof assetSchema>,
) {
  const existingQuantity = existingAsset.snapshots.day[0].quantity;
  const existingUnitValue = existingAsset.snapshots.day[0].unitValue;

  return {
    name: assetToAdd.name,
    type: assetToAdd.type,
    quantity: assetRequiresQuantity(assetToAdd.type)
      ? assetToAdd.quantity + existingQuantity
      : existingQuantity,
    unitValue: assetRequiresQuantity(assetToAdd.type)
      ? assetToAdd.unitValue // use the latest unitValue
      : assetToAdd.unitValue + existingUnitValue,
    currency: assetToAdd.currency,
  };
}

export function getParsedAccountType(accountType: string) {
  return (
    ["ETF", "STOCK", "MUTUAL_FUND", "BOND", "REIT"].includes(accountType)
      ? "PUBLIC_MARKET"
      : ["TOKEN", "NFT"].includes(accountType)
        ? "CRYPTO"
        : accountType
  ) as z.infer<typeof addAssetFormSchema>["type"];
}

export function mergeSameAsset(data: z.infer<typeof assetSchema>[]) {
  const mergedData: { [key: string]: z.infer<typeof assetSchema> } = {};

  data.forEach((item) => {
    const key = `${item.name}_${item.type}_${item.currency}`;
    if (mergedData[key]) {
      if (assetRequiresQuantity(item.type)) {
        mergedData[key].quantity += item.quantity;
        mergedData[key].unitValue = item.unitValue; // use the latest unitValue
      } else {
        mergedData[key].unitValue += item.unitValue;
      }
    } else {
      // If entry doesn't exist, create a new one
      mergedData[key] = { ...item };
    }
  });

  return Object.values(mergedData);
}

export function dateTrunc(precision: string, date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const dayOfWeek = (date.getDay() + 6) % 7; // Mon=0, Tue=1, ..., Sun=6

  const startOfWeek = new Date(date);
  startOfWeek.setDate(day - dayOfWeek); // changes it the most recent Monday in the past
  startOfWeek.setHours(0, 0, 0, 0); // Set to start of the dayOfWeek

  switch (precision) {
    case "year":
      return new Date(year, 0, 1, 0, 0, 0, 0).toISOString();
    case "month":
      return new Date(year, month, 1, 0, 0, 0, 0).toISOString();
    case "week":
      return startOfWeek.toISOString();
    case "day":
      return new Date(year, month, day, 0, 0, 0, 0).toISOString();
    default:
      throw new Error("Unsupported precision");
  }
}
