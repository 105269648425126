import Loader from "@/components/common/Loader";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useDialog } from "@/hooks/useDialog";
import { AlertTriangle } from "lucide-react";
import { useLayoutEffect, useRef, useState } from "react";

export function DeleteConfirmationDialog({
  name,
  confirmCallback,
  isPending,
  confirmationWord = "DELETE",
  title = "Are you sure you want to delete this?",
  description = "This action cannot be undone, and all related data will be permanently removed.",
}: {
  name: string;
  confirmCallback: () => void;
  isPending: boolean;
  confirmationWord?: string;
  title?: string;
  description?: React.ReactNode | string;
}) {
  const leaveRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const { isDialogOpen, setDialogOpen } = useDialog();

  // Focus leave button when dialog opens
  useLayoutEffect(() => {
    if (isDialogOpen(name)) {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0); // set timeout to 0 to add the focus operation to the end of the event loop

      // Clean up function to clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timer);
    }
  }, [isDialogOpen, name]);

  return (
    <AlertDialog
      open={isDialogOpen(name)}
      onOpenChange={(open) => setDialogOpen(name, open)}
    >
      <AlertDialogContent
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setDialogOpen(name, false);
            e.stopPropagation();
          }
        }}
      >
        <AlertDialogHeader>
          <div className="flex justify-center text-red-500">
            <AlertTriangle className="h-8 w-8" />
          </div>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription asChild>{description}</AlertDialogDescription>
        </AlertDialogHeader>

        <div className="my-4">
          <Input
            ref={inputRef}
            type="text"
            placeholder={`Type ${confirmationWord.toUpperCase()} to confirm`}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Escape") {
                setDialogOpen(name, false);
              } else if (
                e.key === "Enter" &&
                inputValue.toUpperCase() === confirmationWord.toUpperCase()
              ) {
                setInputValue("");
                confirmCallback();
              }
            }}
            disabled={isPending}
          />
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel
            ref={cancelRef}
            onClick={() => setDialogOpen(name, false)}
            onKeyDown={(e) => {
              // To stop enter event from propagating to the form
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
            disabled={isPending}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            ref={leaveRef}
            onClick={() => {
              setInputValue("");
              confirmCallback();
            }}
            disabled={
              isPending ||
              inputValue.toUpperCase() !== confirmationWord.toUpperCase()
            }
          >
            {isPending ? <Loader /> : "Delete"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
