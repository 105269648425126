import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import logoFull from "/images/logo-full.svg";
import logoSmall from "/images/logo-small.svg";
import { AddAssetDialog } from "../AddAsset/AddAssetDialog";
import { usePlaidIntegration } from "@/hooks/usePlaidIntegration";
import { NavFooter } from "./NavFooter";
import { Menu } from "./Menu";
import { useRef, useState } from "react";
import { KnockFeedProvider, NotificationFeedPopover } from "@knocklabs/react";
import config from "@/lib/config";
import NotificationToaster from "../NavBar/NotificationToaster";
import { UnseenBadge } from "../ui/unseen-badge";
import EndowusLoginDialog from "../AddAsset/Endowus/EndowusLoginDialog";
import { cn } from "@/lib/utils";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import { CurrencySelector } from "./CurrencySelector";
import StashAwayLoginDialog from "../AddAsset/StashAway/StashAwayLoginDialog";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";
import EmeregLoginDialog from "../AddAsset/Emereg/EmeregLoginDialog";
import ZerionDialog from "../AddAsset/Zerion/ZerionDialog";

export function Sidebar() {
  const { setPlaidCustomLinkNameAndResetToken } = usePlaidIntegration();
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef<HTMLButtonElement>(null);

  const [openEndowus, setOpenEndowus] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openStashAway, setOpenStashAway] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openEmereg, setOpenEmereg] = useState<{
    isOpen: boolean;
    customLink: string;
    accountId?: number;
  }>({ isOpen: false, customLink: "dbs" });
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={cn("hidden lg:flex", isCollapsed ? "w-[4.6rem]" : "w-[22rem]")}
    >
      <aside
        className={cn(
          "fixed flex h-full flex-col overflow-y-auto border-r border-gray-200 p-4 z-10",
          isCollapsed ? "w-[4.6rem]" : "w-72",
        )}
      >
        <div
          className={cn(
            "flex flex-row justify-between items-center gap-1 border-b border-gray-200 pb-4",
            isCollapsed && "flex-col",
          )}
        >
          <Link
            to="/"
            className={cn(
              "font-libre text-2xl font-bold",
              isCollapsed && "flex-1 border-b border-gray-200 pb-4",
            )}
          >
            <img
              src={isCollapsed ? logoSmall : logoFull}
              alt="logo"
              className="h-8"
            />
          </Link>

          <div
            className={cn(
              "flex flex-row gap-x-1",
              isCollapsed && "flex-col gap-y-4 mt-4",
            )}
          >
            <KnockFeedProvider
              feedId={config.knockFeedChannelId}
              key={isCollapsed ? "collapsed" : "expanded"}
            >
              <Button
                ref={notifButtonRef}
                onClick={() => setIsVisible(!isVisible)}
                variant="ghost"
                size="sm"
                className={cn(
                  "size-8 rounded-full",
                  !isCollapsed && "bg-beige",
                )}
              >
                <UnseenBadge badgeCountType="unread" />
              </Button>

              <NotificationFeedPopover
                buttonRef={notifButtonRef}
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
                placement={isCollapsed ? "right-end" : "bottom-end"}
              />
              <NotificationToaster />
            </KnockFeedProvider>

            <CurrencySelector className={cn(!isCollapsed && "bg-beige")} />
            <TooltipProvider delayDuration={300}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="size-8 rounded-full"
                  >
                    <div>
                      {isCollapsed ? (
                        <ChevronsRight size={16} />
                      ) : (
                        <ChevronsLeft size={16} />
                      )}
                    </div>
                  </Button>
                </TooltipTrigger>
                {isCollapsed && (
                  <TooltipContent side="right" className="bg-black">
                    <p className="text-white">Expand</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        {!isCollapsed && (
          <>
            <AddAssetDialog
              commandKeyVariant="transparent"
              variant="default"
              size="lg"
              className="my-6 w-full gap-x-2"
              setPlaidCustomLinkNameAndResetToken={
                setPlaidCustomLinkNameAndResetToken
              }
              buttonLabel="Add new asset"
              showIcon={false}
              setOpenEndowus={(isOpen: boolean, accountId?: number) =>
                setOpenEndowus({ isOpen, accountId })
              }
              setOpenStashAway={(isOpen: boolean, accountId?: number) =>
                setOpenStashAway({ isOpen, accountId })
              }
              setOpenEmereg={(
                isOpen: boolean,
                customLink: string,
                accountId?: number,
              ) => setOpenEmereg({ isOpen, customLink, accountId })}
            />
            <ZerionDialog />
            <EndowusLoginDialog
              open={openEndowus.isOpen}
              setOpen={(isOpen: boolean, accountId?: number) =>
                setOpenEndowus({ isOpen, accountId })
              }
              accountId={openEndowus.accountId}
            />
            <StashAwayLoginDialog
              open={openStashAway.isOpen}
              setOpen={(isOpen: boolean, accountId?: number) =>
                setOpenStashAway({ isOpen, accountId })
              }
              accountId={openStashAway.accountId}
            />
            <EmeregLoginDialog
              open={openEmereg.isOpen}
              setOpen={(
                isOpen: boolean,
                customLink: string,
                accountId?: number,
              ) => setOpenEmereg({ isOpen, customLink, accountId })}
              accountId={openEmereg.accountId}
              customLink={openEmereg.customLink}
            />
          </>
        )}

        <Menu isCollapsed={isCollapsed} />

        <NavFooter isCollapsed={isCollapsed} />
      </aside>
    </div>
  );
}
