import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import chroma from "chroma-js";
import {
  COLOUR_PALLETE,
  UNKNOWN_TAG,
  CHART_COLOR_PALLETE_LEFT,
  CHART_COLOR_PALLETE_RIGHT,
} from "./constants";
import { GetAssetsResponse } from "./types";
import _ from "lodash";

const accountTypeEmojis: Record<string, string> = {
  BANK_ACCOUNT: "🏛️",
  COLLECTIBLE: "🏺",
  CREDIT_CARD: "💳",
  CRYPTO: "🪙",
  INSURANCE: "🛡️",
  LOAN: "💰",
  OTHER: "❓",
  PRECIOUS_METAL: "💎",
  PRIVATE_COMPANY: "🏢",
  PUBLIC_MARKET: "📈",
  ALTERNATIVE_INVESTMENT: "🌍",
  MUTUAL_FUND: "🤝🏻",
  BOND: "📑",
  REIT: "🏘️",
  REAL_ESTATE: "🏠",
  RETIREMENT_FUND: "🏝️",
  ROBO_ACCOUNT: "🤖",
};

// map each asset type to its asset class
export const assetClassMap: Record<string, string> = {
  ANGEL_INVESTMENT: "ALTERNATIVE_INVESTMENT",
  HEDGE_FUND: "ALTERNATIVE_INVESTMENT",
  PRIVATE_CREDIT: "ALTERNATIVE_INVESTMENT",
  PRIVATE_EQUITY: "ALTERNATIVE_INVESTMENT",
  FIXED_DEPOSIT: "BANK_ACCOUNT",
  CASH: "BANK_ACCOUNT",
  ART: "COLLECTIBLE",
  WINE: "COLLECTIBLE",
  WATCH: "COLLECTIBLE",
  TOKEN: "CRYPTO",
  NFT: "CRYPTO",
  LIFE_INSURANCE: "INSURANCE",
  GOLD: "PRECIOUS_METAL",
  SILVER: "PRECIOUS_METAL",
  PALLADIUM: "PRECIOUS_METAL",
  PLATINUM: "PRECIOUS_METAL",
  ESOP: "PRIVATE_COMPANY",
  RSU: "PRIVATE_COMPANY",
  SHARE: "PRIVATE_COMPANY",
  ETF: "PUBLIC_MARKET",
  STOCK: "PUBLIC_MARKET",
  MUTUAL_FUND: "PUBLIC_MARKET",
  BOND: "PUBLIC_MARKET",
  REIT: "PUBLIC_MARKET",
  REAL_ESTATE: "REAL_ESTATE",
  SG_CPF_OA: "RETIREMENT_FUND",
  SG_CPF_RA: "RETIREMENT_FUND",
  SG_CPF_SA: "RETIREMENT_FUND",
  SG_CPF_MA: "RETIREMENT_FUND",
  SG_SRS: "RETIREMENT_FUND",
  US_401K: "RETIREMENT_FUND",
  US_IRA: "RETIREMENT_FUND",
  US_529: "RETIREMENT_FUND",
  CREDIT_CARD: "CREDIT_CARD",
  LOAN: "LOAN",
  OTHER: "OTHER",
  ROBO: "ROBO_ACCOUNT",
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Formats asset type to a nicer format (e.g. PRIVATE_CREDIT to Private Credit)
export function formatAssetType(val: string) {
  if (["NFT", "ESOP", "RSU", "ETF", "REIT"].includes(val)) return val;

  if (
    [
      "SG_CPF_OA",
      "SG_CPF_RA",
      "SG_CPF_SA",
      "SG_CPF_MA",
      "SG_SRS",
      "US_401K",
      "US_IRA",
      "US_529",
    ].includes(val)
  ) {
    return val.slice(3).split("_").join(" ");
  }

  return val
    .split("_")
    .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
    .join(" ");
}

// Generate a unique color code based on a string
export function getColourCode(
  str: string,
  colourMap: Record<string, string>,
  newlyAddedTags?: string[],
): string {
  if (colourMap[str]) return colourMap[str];
  const offset = newlyAddedTags?.findIndex((val) => val === str) ?? 0;
  const nextIndex = Object.keys(colourMap).length + offset;
  return COLOUR_PALLETE[nextIndex];
}

// Make numbers human readable and trunctaed to 3 significant figures
export function shortNumber(n: number): string {
  const absN = Math.abs(n);
  const prefix = n < 0 ? "-" : "";
  if (absN < 1) {
    return prefix + absN.toFixed(4).toString();
  } else if (absN < 1_000) {
    if (Number.isInteger(absN)) return prefix + absN.toString();
    return prefix + absN.toFixed(2);
  } else if (absN >= 1_000 && absN < 1_000_000) {
    const front = absN / 1_000;
    if (Number.isInteger(front)) return prefix + front + "K";
    return prefix + front.toFixed(2) + "K";
  } else if (absN >= 1_000_000 && absN < 1_000_000_000) {
    const front = absN / 1_000_000;
    if (Number.isInteger(front)) return prefix + front + "M";
    return prefix + front.toFixed(2) + "M";
  } else if (absN >= 1_000_000_000 && absN < 1_000_000_000_000) {
    const front = absN / 1_000_000_000;
    if (Number.isInteger(front)) return prefix + front + "B";
    return prefix + front.toFixed(2) + "B";
  } else {
    const front = absN / 1_000_000_000_000;
    if (Number.isInteger(front)) return prefix + front + "T";
    return prefix + front.toFixed(2) + "T";
  }
}

// This is to accommodate PK-140 to accommodate for currency specific formatting (such as the requirement for INR).
// We will still keep `shortNumber` function just to continue using it for formatting percentages etc.
export function shortNumberWithCurrency(
  n: number,
  currencyCode: string = "SGD",
): string {
  const absN = Math.abs(n);
  const prefix = n < 0 ? "-" : "";

  if (absN < 1) {
    // Decided alongside Jaz. 4 is better than 5. 4 is better than 2.
    // Assumption: no one really cares if it falls belong 0.000X
    return absN.toFixed(4).toString();
  } else if (absN < 1_000) {
    if (Number.isInteger(absN)) return prefix + absN.toString();
    return prefix + absN.toFixed(2);
  } else if (
    currencyCode === "INR" ||
    currencyCode === "PKR" ||
    currencyCode === "BDT"
  ) {
    // Indian Numbering System
    if (absN >= 1_000 && absN < 100_000) {
      const front = absN / 1_000;
      return (
        prefix +
        (Number.isInteger(front) ? front.toString() : front.toFixed(2)) +
        "K"
      );
    } else if (absN >= 1_00_000 && absN < 1_00_00_000) {
      const front = absN / 1_00_000;
      return (
        prefix +
        (Number.isInteger(front) ? front.toString() : front.toFixed(2)) +
        "L"
      );
    } else {
      const front = absN / 1_00_00_000;
      return (
        prefix +
        (Number.isInteger(front) ? front.toString() : front.toFixed(2)) +
        " cr"
      );
    }
  } else {
    // Default to International System
    return shortNumber(n);
  }
}

export function beautifyAccountType(accountType: string): string {
  const emoji = accountTypeEmojis[accountType] || "";
  const formattedAccountType =
    accountType === "BANK_ACCOUNT"
      ? "Cash & Cash Equivalents"
      : formatAccountType(accountType);
  return `${emoji} ${formattedAccountType}`;
}

export function formatAccountType(accountType: string): string {
  return accountType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function getUniqueTags(data: GetAssetsResponse) {
  const tags = new Set<string>();
  data.forEach((d) => {
    d.assetLiabilities.forEach((a) => {
      if (a.tag) tags.add(a.tag);
      else tags.add(UNKNOWN_TAG);
    });
  });
  return Array.from(tags);
}

export function getUniqueAccountNames(data: GetAssetsResponse) {
  const accountNames = new Set<string>();
  data.forEach((d) => {
    d.name && accountNames.add(d.name);
  });
  return Array.from(accountNames);
}

export function getIntegratedAccounts(data: GetAssetsResponse) {
  return data.filter((d) => d.name && d.type === "INTEGRATED_ACCOUNT");
}

export function truncateText(text: string, length: number) {
  return text.length > length ? text.slice(0, length) + "..." : text;
}

export function generateDivergingColorPalette(
  labels: string[],
): Record<string, string> {
  const colorMap: Record<string, string> = {};
  const colors: string[] = CHART_COLOR_PALLETE_LEFT;
  const colors2: string[] = CHART_COLOR_PALLETE_RIGHT;
  const numColors = labels.length;

  const even = numColors % 2 === 0;
  const numColorsLeft = Math.ceil(numColors / 2) + (even ? 1 : 0);
  const numColorsRight = Math.ceil(numColors / 2) + (even ? 1 : 0);
  const genColors = colors.length !== 1 ? colors : autoColors(colors[0]);
  const genColors2 =
    colors2.length !== 1 ? colors2 : autoColors(colors2[0], true);

  const stepsLeft = chroma
    .scale(genColors)
    .correctLightness()
    .colors(numColorsLeft);
  const stepsRight = chroma
    .scale(genColors2)
    .correctLightness()
    .colors(numColorsRight);
  const stepsResult = stepsLeft
    .slice(0, stepsLeft.length - 1)
    .concat(stepsRight.slice(0));

  labels.forEach((tag, index) => {
    colorMap[tag] = stepsResult[index];
  });

  return colorMap;
}

function autoGradient(color: string, numColors: number) {
  const lab = chroma(color).lab();
  const lRange = 100 * (0.95 - 1 / numColors);
  const lStep = lRange / (numColors - 1);
  const lStart = (100 - lRange) * 0.5;
  const range = _.range(lStart, lStart + numColors * lStep, lStep);
  const offset = 0;

  return range.map((l) => chroma.lab(l + offset, lab[1], lab[2]));
}

function autoColors(color: string, reverse = false) {
  const colors = autoGradient(color, 3).concat(chroma("#f5f5f5"));
  if (reverse) colors.reverse();
  return colors;
}

export function beautifyMonths(months: number) {
  if (months < 24) {
    return `${months} months`;
  }

  // Calculate the years
  const years = Math.floor(months / 12);

  // Calculate the remaining months
  const remainingMonths = months % 12;

  return `${years} years and ${remainingMonths} months`;
}

export function formatQuantity(quantity: number) {
  if (Number.isInteger(quantity)) {
    return quantity.toString();
  } else if (quantity >= 1) {
    return quantity.toFixed(2);
  } else {
    return quantity.toFixed(4);
  }
}
