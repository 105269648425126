import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreVertical, Pencil, Trash2, Unplug } from "lucide-react";
import { PortfolioTableRow } from "@/components/Portfolio/Table/types";
import { AssetLiabilityV2 } from "@/lib/types";
import { useDeleteAssetsLiabilitiesMutation } from "@/hooks/useDeleteAssetsLiabilitiesMutation";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";
import { useDialog } from "@/hooks/useDialog";
import { DeleteConfirmationDialog } from "@/components/common/DeleteConfirmationDialog";

type AssetLiabilityOptionsProps = {
  rowId: string;
  rowOriginal: PortfolioTableRow;
  setEditingRow: (value: AssetLiabilityV2) => void;
  setEditingAccount: (value: {
    id: number;
    rowId: string;
    name: string;
  }) => void;
  handleDisconnect: (accountId: number, accountName: string) => void;
  handleDeleteAssetsLiabilities: (ids: number[]) => void;
  handleDeleteAccount: (accountId: number) => void;
};

export function AssetLiabilityOptions({
  rowId,
  rowOriginal,
  setEditingRow,
  setEditingAccount,
  handleDisconnect,
  handleDeleteAssetsLiabilities,
  handleDeleteAccount,
}: AssetLiabilityOptionsProps) {
  const { setDialogOpen } = useDialog();
  const { showShortcutToast } = useShortcutPrompt();
  const { isPending } = useDeleteAssetsLiabilitiesMutation();

  if (rowOriginal.id === undefined) return null;

  function handleEdit() {
    showShortcutToast({
      shortcutKey: "E",
      template: "Next time, hit {shortcutKey} to edit this instantly",
    });
    if (rowOriginal.rowType === "asset") {
      setEditingRow(rowOriginal);
    }
    if (rowOriginal.rowType !== "asset" && rowOriginal.account) {
      setEditingAccount({
        rowId,
        id: rowOriginal.account.id,
        name: rowOriginal.account.name,
      });
    }
  }

  return (
    <div className="text-right">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <span className="sr-only">Open menu</span>
            <MoreVertical className="h-4 w-4 text-muted-foreground" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="text-gray-600">
          <DropdownMenuItem onClick={handleEdit}>
            <Pencil className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          {rowOriginal.rowType === "section" && (
            <DropdownMenuItem
              onClick={() => {
                setDialogOpen(`deleteAccount-options-${rowOriginal.id}`, true);
              }}
              className="text-destructive"
            >
              <Trash2 className="mr-2 size-4 text-destructive" />
              <div className="flex flex-col text-destructive">
                <span className="mr-2">Delete Account</span>
              </div>
            </DropdownMenuItem>
          )}
          {rowOriginal.account.type === "INTEGRATED_ACCOUNT" ? (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={(event) => {
                  setDialogOpen(
                    `deleteAssetsLiabilities-options-disconnect-${rowOriginal.id}`,
                    true,
                  );
                  event.stopPropagation();
                }}
              >
                <Unplug className="mr-2 h-4 w-4 text-destructive" />
                <div className="flex flex-col text-destructive">
                  <span className="mr-2">Disconnect</span>
                  <span className="text-xs text-gray-400">
                    Deletes all data under the account.
                  </span>
                </div>
              </DropdownMenuItem>
            </>
          ) : rowOriginal.rowType === "asset" ? (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={(event) => {
                  setDialogOpen(
                    `deleteAssetsLiabilities-options-${rowOriginal.id}`,
                    true,
                  );
                  event.stopPropagation();
                }}
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete
              </DropdownMenuItem>
            </>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
      <DeleteConfirmationDialog
        name={`deleteAssetsLiabilities-options-${rowOriginal.id}`}
        confirmCallback={() => {
          handleDeleteAssetsLiabilities([+rowOriginal.id]);
          setDialogOpen(
            `deleteAssetsLiabilities-options-${rowOriginal.id}`,
            false,
          );
        }}
        isPending={isPending}
      />
      <DeleteConfirmationDialog
        name={`deleteAssetsLiabilities-options-disconnect-${rowOriginal.id}`}
        confirmCallback={() => {
          if (rowOriginal.account.id) {
            handleDisconnect(rowOriginal.account.id, rowOriginal.account.name);
            setDialogOpen(
              `deleteAssetsLiabilities-options-disconnect-${rowOriginal.id}`,
              false,
            );
          }
        }}
        isPending={isPending}
      />
      <DeleteConfirmationDialog
        name={`deleteAccount-options-${rowOriginal.id}`}
        confirmCallback={() => {
          if (rowOriginal.account.id) {
            handleDeleteAccount(rowOriginal.account.id);
            setDialogOpen(`deleteAccount-options-${rowOriginal.id}`, false);
          }
        }}
        isPending={isPending}
      />
      <DeleteConfirmationDialog
        name={`deleteAccount-options-${rowOriginal.id}`}
        confirmCallback={() => {
          if (rowOriginal.account.id) {
            handleDeleteAccount(rowOriginal.account.id);
            setDialogOpen(`deleteAccount-options-${rowOriginal.id}`, false);
          }
        }}
        isPending={isPending}
      />
    </div>
  );
}
