import axios from "@/lib/axios";
import config from "@/lib/config";
import {
  GET_PORTFOLIO_ANALYSIS_QUERY_KEY,
  GET_ASSET_ALLOCATION_ANALYSIS_QUERY_KEY,
  GET_ETF_HOLDINGS_ANALYSIS_QUERY_KEY,
} from "@/lib/constants";
import { queryClient } from "@/lib/queryClient";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

export function useRefreshPortfolioAnalysisMutation() {
  const { getToken } = useAuth();
  const { mutate: refreshAnalysis, isPending } = useMutation({
    mutationFn: async () => {
      const token = await getToken();
      const { data } = await axios.post(
        `${config.backendUrl}/analysis/portfolio`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([GET_PORTFOLIO_ANALYSIS_QUERY_KEY], data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { refreshAnalysis, isPending };
}

export function useRefreshAssetAllocationAnalysisMutation(
  age: number,
  liquidityPreference: string,
) {
  const { getToken } = useAuth();
  const { mutate: refreshAnalysis, isPending } = useMutation({
    mutationFn: async () => {
      const token = await getToken();
      const { data } = await axios.post(
        `${config.backendUrl}/analysis/asset-allocation`,
        { age, liquidityPreference },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([GET_ASSET_ALLOCATION_ANALYSIS_QUERY_KEY], data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { refreshAnalysis, isPending };
}

export function useRefreshETFHoldingsAnalysisMutation() {
  const { getToken } = useAuth();
  const { mutate: refreshAnalysis, isPending } = useMutation({
    mutationFn: async () => {
      const token = await getToken();
      const { data } = await axios.post(
        `${config.backendUrl}/analysis/etf-holdings`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([GET_ETF_HOLDINGS_ANALYSIS_QUERY_KEY], data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { refreshAnalysis, isPending };
}
