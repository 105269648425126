import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useColumnVisibility } from "@/hooks/useColumnVisilibility";
import { useCurrency } from "@/hooks/useCurrency";
import { AssetLiabilityV2 } from "@/lib/types";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowDownWideNarrow,
  ArrowUpDown,
  ArrowUpNarrowWide,
  icons,
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { useDialog } from "@/hooks/useDialog";
import { columns } from "./columns";
import { ColumnVisibilityPopup } from "@/components/Portfolio/Table/ColumnVisibilityPopup";
import { DetailsSheet } from "../DetailsSheet";

type PerformanceTableProps = {
  data: AssetLiabilityV2[];
  grouping: "day" | "week" | "month";
  assetTotalValue: number;
  liabilityTotalValue: number;
};

const mandatoryVisibleColumns = ["asset", "totalValue"];

export function PerformanceTable({
  data,
  grouping,
  assetTotalValue,
  liabilityTotalValue,
}: PerformanceTableProps) {
  const [assetLiabilityDetails, setAssetLiabilityDetails] =
    useState<AssetLiabilityV2 | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [lastInteraction, setLastInteraction] = useState<"keyboard" | "mouse">(
    "mouse",
  );
  const { currency } = useCurrency();
  const {
    visibleColumns,
    toggleColumnVisibility,
    setVisibleColumns,
    showAll,
    hideAll,
  } = useColumnVisibility(
    "performanceTable",
    {
      asset: true,
      accountName: false,
      quantity: true,
      profitLossPercentage: true,
      averageCost: true,
      totalCapitalInvested: true,
      profitLoss: true,
      totalValue: true,
    },
    mandatoryVisibleColumns,
  );
  const { setDialogOpen, isAnyDialogOpen } = useDialog();

  const parentRef = useRef<HTMLDivElement>(null);
  const selectedRowRef = useRef<string | null>(null);

  const handleAssetLiabilityDetailsSheetOpen = useCallback(
    (data: AssetLiabilityV2) => {
      setAssetLiabilityDetails(data);
      setDialogOpen("assetLiabilityDetails", true);
    },
    [setAssetLiabilityDetails, setDialogOpen],
  );

  const table = useReactTable({
    data,
    columns: columns({
      currency,
      handleAssetLiabilityDetailsSheetOpen,
      grouping,
    }),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility: visibleColumns,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setVisibleColumns,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 56, // Adjust this based on your row height
    overscan: 15,
    scrollMargin: parentRef.current?.offsetTop ?? 0,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize() + 48;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;

      // only override interaction if there are no other dialogs above
      if (isAnyDialogOpen) {
        return;
      }

      // Make sure that the global shortcuts are not interfering with user inputs
      const isInputFocused =
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable;

      if (isInputFocused) return;

      // We are now in keyboard mode and prioritise kb interactions
      setLastInteraction("keyboard");

      const row = rows.find((row) => row.id === selectedRowRef.current);

      const selectedRow =
        document.querySelector(`tr[data-id="${selectedRowRef.current}"]`) ||
        (parentRef.current?.querySelector("tbody tr") as HTMLTableRowElement);

      if (event.key.toLowerCase() === "j" || event.key === "ArrowDown") {
        selectedRow.setAttribute("aria-selected", "false");

        if (selectedRowRef.current) {
          const nextSibling =
            selectedRow.nextElementSibling as HTMLTableRowElement;
          if (nextSibling) {
            selectedRowRef.current = nextSibling.getAttribute("data-id");
            nextSibling.setAttribute("aria-selected", "true");
          }
        } else {
          // Select first row if none selected
          const firstRow = parentRef.current?.querySelector(
            "tbody tr",
          ) as HTMLTableRowElement;

          if (firstRow) {
            selectedRowRef.current = firstRow.getAttribute("data-id");
            firstRow.setAttribute("aria-selected", "true");
          }
        }
      } else if (event.key.toLowerCase() === "k" || event.key === "ArrowUp") {
        selectedRow.setAttribute("aria-selected", "false");

        if (selectedRowRef.current) {
          const prevSibling =
            selectedRow.previousElementSibling as HTMLTableRowElement;
          if (prevSibling) {
            selectedRowRef.current = prevSibling.getAttribute("data-id");
            prevSibling.setAttribute("aria-selected", "true");
          }
        } else {
          // Select first row if none selected
          const firstRow = parentRef.current?.querySelector(
            "tbody tr",
          ) as HTMLTableRowElement;

          if (firstRow) {
            selectedRowRef.current = firstRow.getAttribute("data-id");
            firstRow.setAttribute("aria-selected", "true");
          }
        }
      } else if (event.key === "Enter") {
        event.preventDefault();
        row && handleAssetLiabilityDetailsSheetOpen(row.original);
      }
    },
    [isAnyDialogOpen, rows, handleAssetLiabilityDetailsSheetOpen],
  );

  const handleMouseMove = useCallback(() => {
    setLastInteraction("mouse");
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    // We use this to listen to mouse events to see whether user is in kb mode or in mouse mode
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleKeyDown, handleMouseMove]);

  return (
    <div>
      <div
        ref={parentRef}
        className="mt-5 overflow-y-hidden rounded-sm border border-gray-200 bg-white"
      >
        <div
          style={{
            height: `${totalSize}px`,
          }}
        >
          <Table className="table-auto">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="h-12 bg-border/50">
                  {headerGroup.headers.map((header) => (
                    <TableHead
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer select-none px-4 py-3"
                    >
                      <span className="flex flex-row items-center gap-x-1 text-right text-gray-600">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                        {header.column.getCanSort() &&
                          {
                            false: <ArrowUpDown size={14} color="darkGray" />,
                            asc: <ArrowUpNarrowWide size={14} />,
                            desc: <ArrowDownWideNarrow size={14} />,
                          }[header.column.getIsSorted() as string]}
                      </span>
                    </TableHead>
                  ))}
                  <TableHead>
                    <ColumnVisibilityPopup
                      visibleColumns={visibleColumns}
                      mandatoryVisibleColumns={mandatoryVisibleColumns}
                      toggleColumnVisibility={toggleColumnVisibility}
                      hideAll={hideAll}
                      showAll={showAll}
                      icons={icons}
                    />
                  </TableHead>
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {virtualRows.map((virtualRow, index) => {
                const row = rows[virtualRow.index];
                return (
                  <TableRow
                    data-id={row.id}
                    key={row.id}
                    className="table-row-hover group cursor-pointer border-b-0 aria-selected:bg-gray-100 [&:not(:last-child)]:shadow-line"
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${
                        virtualRow.start -
                        index * virtualRow.size - // Make sure to use index instead of virtualRow.index
                        rowVirtualizer.options.scrollMargin
                      }px)`,
                    }}
                    onMouseEnter={() => {
                      if (lastInteraction === "mouse") {
                        // Remove Previous Selected Row hover
                        const selectedRow = document.querySelector(
                          `tr[data-id="${selectedRowRef.current}"]`,
                        ) as HTMLTableRowElement;
                        selectedRow &&
                          selectedRow.setAttribute("aria-selected", "false");

                        selectedRowRef.current = row.id;

                        const tr = document.querySelector(
                          `tr[data-id="${row.id}"]`,
                        );
                        tr?.setAttribute("aria-selected", "true");
                      }
                    }}
                    onMouseLeave={() => {
                      // Remove Previous Selected Row hover
                      const selectedRow = document.querySelector(
                        `tr[data-id="${selectedRowRef.current}"]`,
                      ) as HTMLTableRowElement;
                      selectedRow &&
                        selectedRow.setAttribute("aria-selected", "false");

                      selectedRowRef.current = null;

                      const tr = document.querySelector(
                        `tr[data-id="${row.id}"]`,
                      );

                      tr?.setAttribute("aria-selected", "false");
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      if (!cell.getValue()) return undefined;
                      return (
                        <TableCell key={cell.id} className="px-4" align="right">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        {assetLiabilityDetails && (
          <DetailsSheet
            assetLiability={assetLiabilityDetails}
            assetTotalValue={assetTotalValue}
            liabilityTotalValue={liabilityTotalValue}
          />
        )}
      </div>
    </div>
  );
}
