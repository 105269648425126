import { Separator } from "@/components/ui/separator";
import { Profile } from "@/lib/types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { format } from "date-fns";
import { CalendarDays } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";

type ProfileDetailsProps = {
  formData: Profile;
  setFormData: (data: Profile) => void;
};

export function ProfileDetails({ formData, setFormData }: ProfileDetailsProps) {
  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium text-gray-700">
        Personal Information
      </h4>
      <Separator />

      <div>
        <label className="block py-2 text-gray-500">Birthday</label>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-full">
              <span className="font-medium text-gray-700">
                {formData.birthday
                  ? format(formData.birthday, "d MMM yyyy")
                  : "Select date"}
              </span>
              <CalendarDays className="ml-auto h-4 w-4 text-gray-400" />
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" side="top" className="bg-white p-0">
            <Calendar
              mode="single"
              disabled={(date) =>
                date > new Date() || date < new Date("1900-01-01")
              }
              initialFocus
              captionLayout="dropdown"
              selected={formData.birthday}
              onSelect={(date) =>
                date && setFormData({ ...formData, birthday: date })
              }
              fromYear={1900}
              toYear={new Date().getFullYear()}
              classNames={{
                vhidden: "vhidden hidden",
              }}
              defaultMonth={formData.birthday}
              onMonthChange={(date) =>
                date && setFormData({ ...formData, birthday: date })
              }
            />
          </PopoverContent>
        </Popover>
      </div>

      <div>
        <label className="block py-2 text-gray-500">Gender</label>
        <div className="flex flex-1 flex-row items-center gap-x-2">
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left",
              formData.gender === "male" && "bg-offwhite",
            )}
            onClick={() => setFormData({ ...formData, gender: "male" })}
          >
            <img
              src="/images/onboarding/male.svg"
              alt="peek"
              className="mb-3 size-6"
            />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "male" && "font-semibold",
              )}
            >
              Male
            </span>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left",
              formData.gender === "female" && "bg-offwhite",
            )}
            onClick={() => setFormData({ ...formData, gender: "female" })}
          >
            <img
              src="/images/onboarding/female.svg"
              alt="peek"
              className="mb-3 size-6"
            />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "female" && "font-semibold",
              )}
            >
              Female
            </span>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left",
              formData.gender === "preferNotToSay" && "bg-offwhite",
            )}
            onClick={() =>
              setFormData({ ...formData, gender: "preferNotToSay" })
            }
          >
            <img
              src="/images/onboarding/other-gender.svg"
              alt="peek"
              className="mb-3 size-6"
            />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "preferNotToSay" && "font-semibold",
              )}
            >
              Prefer not to say
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
