import { InsightsVariables } from "@/components/PeekHomePage/Insights";
import { useLocalStorage } from "@/hooks/useLocalStorage.ts";

export const useInsightsVariables = () => {
  return useLocalStorage<InsightsVariables>("insightsVariables", {
    inflow: 6000,
    outflow: 5000,
    annualGrowth: 5,
    currentAge: 30,
    retirementAge: 65,
    liquidityPreference: "no",
  });
};
