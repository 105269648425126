import { cn } from "@/lib/utils";
import { MultiStepLoader } from "./multi-step-loader";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const loadingStates = [
    {
      text: "Fetching market data",
    },
    {
      text: "Analyzing stock performance",
    },
    {
      text: "Calculating net worth",
    },
    {
      text: "Reviewing portfolio allocation",
    },
    {
      text: "Tracking crypto prices",
    },
    {
      text: "Evaluating investment risks",
    },
    {
      text: "Projecting future growth",
    },
    {
      text: "Preparing your financial overview",
    },
  ];
  return (
    <div
      className={cn("animate-pulse rounded-md bg-border/20", className)}
      {...props}
    >
      <MultiStepLoader
        loadingStates={loadingStates}
        loading={true}
        duration={1000}
      />
    </div>
  );
}

export { Skeleton };
