import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Badge } from "../ui/badge";
import { Checkbox } from "../ui/checkbox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useKnockClient } from "@knocklabs/react";
import { PreferenceSet } from "@knocklabs/client";
import { ChannelType } from "@knocklabs/types";
import Loader from "../common/Loader";

type WorkflowRows = {
  [category: string]: {
    [workflow: string]: {
      title: string;
    };
  };
};

export function NotificationSettings() {
  // This is how our default preferences look like.
  const defaultPreferenceSet: PreferenceSet = useMemo(
    () => ({
      id: "default",
      categories: {
        reminders: {
          channel_types: { email: true, in_app_feed: true },
        },
        marketing: {
          channel_types: { email: true, in_app_feed: false },
        },
      },
      channel_types: { email: true, in_app_feed: true },
      workflows: {
        "data-update-reminder": {
          channel_types: { email: true, in_app_feed: true },
        },
        "first-week-reminder": {
          channel_types: { email: true, in_app_feed: true },
        },
        "portfolio-digest": {
          channel_types: { email: true, in_app_feed: true },
        },
      },
    }),
    [],
  );

  const channelTypes = {
    in_app_feed: "In-app",
    email: "Email",
  };

  const workflowRows: WorkflowRows = {
    reminders: {
      "data-update-reminder": {
        title: "Update my accounts every month",
      },
      "first-week-reminder": {
        title: "Get a follow up reminder to add all my assets after first week",
      },
    },
    digest: {
      "portfolio-digest": {
        title: "Get a bi-weekly portfolio digest in your inbox",
      },
    },
  };

  const [preferenceSet, setPreferenceSet] = useState<PreferenceSet>();

  const knockClient = useKnockClient();

  useEffect(() => {
    knockClient.user
      .getPreferences({
        preferenceSet: "default",
      })
      .then((existingPreferencesSet) => {
        if (existingPreferencesSet.categories === null) {
          setPreferenceSet({
            ...existingPreferencesSet,
            ...defaultPreferenceSet,
          });
          return;
        }
        setPreferenceSet(existingPreferencesSet);
      });
  }, [knockClient, defaultPreferenceSet]);

  const handlePreferenceCheck = (
    channelType: ChannelType,
    key: string,
    section: "categories" | "workflows",
    checked: boolean,
  ): void => {
    if (!preferenceSet) return;
    const updatedPreferenceSet: PreferenceSet = {
      ...preferenceSet,
    };

    const category = updatedPreferenceSet[section][key];
    if (typeof category !== "boolean" && category) {
      category.channel_types[channelType] = checked;
    }
    setPreferenceSet(updatedPreferenceSet);
    updatePreferences(updatedPreferenceSet);
  };

  const updatePreferences = useCallback(
    async (preferenceSet: PreferenceSet) => {
      setPreferenceSet(preferenceSet);

      await knockClient.user.setPreferences(preferenceSet, {
        preferenceSet: preferenceSet.id,
      });
    },
    [knockClient],
  );

  const getValue = (
    channelType: ChannelType,
    key: string,
    section: "categories" | "workflows",
  ): boolean => {
    if (!preferenceSet) return false;

    const setting = preferenceSet[section][key];
    if (
      typeof setting !== "boolean" &&
      setting &&
      setting.channel_types[channelType] !== undefined
    ) {
      return setting.channel_types[channelType] || false;
    }

    return false;
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-xl font-medium">Notifications</h3>
      <p className="text-gray-500 dark:text-gray-400">
        Customize your Peek notifications to keep updates fresh and exactly
        where you want them.
      </p>
      <div className="my-6 flex-1">
        {Object.keys(workflowRows).map((workflowCategory) =>
          preferenceSet ? (
            <Table key={workflowCategory}>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[300px] text-gray-500">
                    {workflowCategory.charAt(0).toUpperCase() +
                      workflowCategory.slice(1)}
                  </TableHead>
                  {Object.keys(channelTypes).map((channelType) => (
                    <TableHead
                      key={channelType}
                      className="text-center text-gray-500"
                    >
                      {channelTypes[channelType as keyof typeof channelTypes]}
                    </TableHead>
                  ))}
                  <TableHead className="text-center text-gray-500">
                    Whatsapp
                    <br />
                    <Badge
                      variant="orange"
                      className="grow-0.5 h-4 p-0 px-1 text-[9px]"
                    >
                      Soon
                    </Badge>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(workflowRows[workflowCategory]).map((workflow) => (
                  <TableRow key={workflow}>
                    <TableCell className="font-medium">
                      {workflowRows[workflowCategory][workflow].title}
                    </TableCell>
                    {Object.keys(channelTypes).map((channelType) => (
                      <TableCell
                        key={`${workflow}-${channelType}`}
                        className="text-center"
                      >
                        <Checkbox
                          checked={getValue(
                            channelType as ChannelType,
                            workflow,
                            "workflows",
                          )}
                          onCheckedChange={(checked: boolean) =>
                            handlePreferenceCheck(
                              channelType as ChannelType,
                              workflow,
                              "workflows",
                              checked,
                            )
                          }
                        />
                      </TableCell>
                    ))}
                    <TableCell className="text-center">
                      <Checkbox disabled checked />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Loader key={workflowCategory} className="mt-4" />
          ),
        )}
      </div>
    </div>
  );
}
