import { assetSchema, editAssetFormSchema } from "@/components/AddAsset/schema";
import { TableCell, TableRow } from "@/components/ui/table";
import { AssetLiabilityV2 } from "@/lib/types";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCurrency } from "@/hooks/useCurrency";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useUpdateAssetMutation } from "@/hooks/useUpdateAssetMutation";
import { Table } from "@tanstack/react-table";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";
import { CustomReactSelect } from "@/components/common/CustomReactSelect";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";
import { InputActionMeta } from "react-select";
import { PortfolioTableRow } from "./types";
import { ValueColumn } from "@/components/Portfolio/Table/ValueColumn";

const retirementTypes = [
  "SG_CPF_OA",
  "SG_CPF_RA",
  "SG_CPF_SA",
  "SG_CPF_MA",
  "SG_SRS",
  "US_401K",
  "US_IRA",
  "US_529",
];

type InlineEditProps = {
  asset: AssetLiabilityV2;
  cancel: () => void;
  table: Table<PortfolioTableRow>;
};

function getInputValue(asset: AssetLiabilityV2, retirementTypes: string[]) {
  if (asset.fullName) {
    const displayFullName = retirementTypes.includes(asset.type)
      ? asset.fullName.replaceAll("_", " ")
      : asset.fullName;
    const displayName = retirementTypes.includes(asset.type)
      ? asset.name.replaceAll("_", " ")
      : asset.name;
    return `${displayFullName} (${displayName})`;
  } else {
    return retirementTypes.includes(asset.type)
      ? asset.name.replaceAll("_", " ")
      : asset.name;
  }
}

export function InlineEdit({
  asset,
  cancel,
  table,
  ...props
}: InlineEditProps & React.HTMLAttributes<HTMLTableRowElement>) {
  const currencyContext = useCurrency();
  const { updateAsset, isPending } = useUpdateAssetMutation();
  const { showShortcutToast } = useShortcutPrompt();
  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();
  const tagOptions =
    assetLiabilitiesAccountsContext.state === "SUCCESS"
      ? Array.from(
          new Set(
            assetLiabilitiesAccountsContext.data
              .map((assetLiability) => assetLiability.tag)
              .filter((tag): tag is string => tag !== null),
          ),
        ) // Filter out null values and create unique set
      : [];

  const requiresQuantity = !["REAL_ESTATE"].includes(asset.type);

  const tickers = [
    "STOCK",
    "REIT",
    "ETF",
    "BOND",
    "MUTUAL_FUND",
    "TOKEN",
  ].includes(asset.type);

  const requiresUnitValue = ![
    "CASH",
    "FIXED_DEPOSIT",
    "LIFE_INSURANCE",
    "SG_CPF_OA",
    "SG_CPF_RA",
    "SG_CPF_SA",
    "SG_CPF_MA",
    "SG_SRS",
    "US_401K",
    "US_IRA",
    "US_529",
    "LOAN",
    "CREDIT_CARD",
  ].includes(asset.type);

  const requiresName = ![
    "GOLD",
    "SILVER",
    "PALLADIUM",
    "PLATINUM",
    "ESOP",
    "RSU",
    "SHARE",
    "SG_CPF_OA",
    "SG_CPF_RA",
    "SG_CPF_SA",
    "SG_CPF_MA",
    "SG_SRS",
    "US_401K",
    "US_IRA",
    "US_529",
  ].includes(asset.type);

  const form = useForm<z.infer<typeof editAssetFormSchema>>({
    resolver: zodResolver(editAssetFormSchema),
    defaultValues: {
      id: asset.id,
      name: asset.name,
      type: asset.type as z.infer<typeof assetSchema>["type"],
      quantity: asset.snapshots?.day?.slice(-1)[0]?.quantity || 0,
      unitValue: asset.snapshots?.day?.slice(-1)[0]?.original?.unitValue || 0,
      currency: asset.currency,
      tag: asset.tag ?? undefined,
    },
  });

  const onSubmit = useCallback(
    async (values: z.infer<typeof editAssetFormSchema>) => {
      if (isPending) return;

      try {
        const parsedAsset = editAssetFormSchema.safeParse(values);

        if (parsedAsset.success) {
          updateAsset({
            input: [values],
          });
          cancel();
        }
      } catch (error) {
        if (error instanceof z.ZodError) {
          form.setError("root", {
            type: "custom",
            message: error.issues[0].message,
          });
        }
        return;
      }
    },
    [cancel, updateAsset, isPending, form],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;

      // This prevents the form from submitting when the user clicks Enter on any dropdown
      const isInputFocused = target.tagName === "BUTTON";

      if (event.key === "Escape") {
        cancel();
      } else if (event.key === "Enter" && !isInputFocused) {
        form.handleSubmit(onSubmit)();
      }
      event.stopPropagation();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cancel, form, onSubmit]);

  function focusFirstElement() {
    const inputs = ["quantity", "unitValue", "name"].map(
      (id) => document.getElementById(id) as HTMLInputElement,
    );
    const firstInput = inputs.find((input) => input);

    if (firstInput) {
      firstInput.focus();
      firstInput.select();
    }
  }

  // Set focus to first element on mount
  useEffect(() => {
    focusFirstElement();
  }, []);

  const [inputQuantity, inputUnitValue, inputCurrency] = form.watch([
    "quantity",
    "unitValue",
    "currency",
  ]);

  return (
    <TableRow {...props} className="relative z-50 bg-gray-100">
      <Form {...form}>
        <TableCell colSpan={table.getFlatHeaders().length - 1}>
          <div className="absolute left-[-31px] top-[38%] w-[84px] -rotate-90 border-t-2 border-t-darkCyan text-center">
            <span className="text-xs text-darkCyan">EDITING</span>
          </div>
          <div className="ml-8 flex flex-row items-center justify-start gap-x-2">
            {requiresName && !tickers ? (
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <FormLabel className="text-gray-400">Name</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        placeholder="Name"
                        className="rounded-lg px-3 focus-visible:ring-0 focus-visible:ring-offset-0 disabled:opacity-50"
                        {...field}
                        disabled={asset.account.type === "INTEGRATED_ACCOUNT"}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            ) : (
              <FormItem className="w-1/2">
                <FormLabel className="text-gray-400">Name</FormLabel>
                <FormControl>
                  <Input
                    id="name"
                    placeholder="Name"
                    className="rounded-lg px-3 focus-visible:ring-0 focus-visible:ring-offset-0 disabled:bg-gray-200"
                    disabled
                    value={getInputValue(asset, retirementTypes)}
                  />
                </FormControl>
              </FormItem>
            )}

            <FormField
              control={form.control}
              name="tag"
              render={({ field }) => (
                <FormItem className="w-1/4">
                  <FormLabel className="text-gray-400">Tag</FormLabel>
                  <FormControl>
                    <CustomReactSelect
                      options={tagOptions.map((tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      placeholder="Tag"
                      onChange={(newValue) => {
                        const value = newValue as { value: string } | null;
                        if (value) form.setValue("tag", value.value);
                      }}
                      defaultInputValue={
                        field.value === "Uncategorized" ? "" : field.value
                      }
                      onInputChange={(
                        value: string,
                        { action }: InputActionMeta,
                      ) => {
                        if (
                          action !== "input-blur" &&
                          action !== "menu-close"
                        ) {
                          form.setValue("tag", value);
                        }
                      }}
                      noOptionsMessage={() => "No tag found."}
                      closeMenuOnSelect
                      className="ring-0 ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                      autoFocus={asset.account.type === "INTEGRATED_ACCOUNT"}
                      openMenuOnFocus
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            {requiresQuantity && (
              <>
                <FormField
                  control={form.control}
                  name="quantity"
                  render={({ field }) => (
                    <>
                      {!requiresUnitValue && (
                        <span className="mt-6">{asset.currency}</span>
                      )}
                      <FormItem className="w-1/2">
                        <FormLabel className="text-gray-400">
                          {requiresUnitValue ? "Quantity" : "Amount"}
                        </FormLabel>
                        <FormControl>
                          <div className="flex items-center">
                            <Input
                              id="quantity"
                              placeholder={
                                requiresUnitValue ? "Quantity" : "Amount"
                              }
                              className="focus-visible:ring-0 focus-visible:ring-offset-0 disabled:bg-gray-200"
                              type="number"
                              {...field}
                              disabled={
                                asset.account.type === "INTEGRATED_ACCOUNT"
                              }
                            />
                          </div>
                        </FormControl>
                      </FormItem>
                    </>
                  )}
                />
              </>
            )}

            {requiresQuantity && requiresUnitValue && (
              <div className="pt-6">
                <X size={14} className="text-gray-400" />
              </div>
            )}

            {requiresUnitValue && (
              <FormField
                control={form.control}
                name="unitValue"
                render={({ field }) => (
                  <>
                    <span className="mt-6">{asset.currency}</span>
                    <FormItem className="w-1/2 min-w-24">
                      <FormLabel className="text-gray-400">
                        Unit Value
                      </FormLabel>
                      <FormControl>
                        <div className="flex items-center">
                          <Input
                            {...field}
                            className="focus-visible:ring-0 focus-visible:ring-offset-0 disabled:bg-gray-200"
                            type="text"
                            placeholder="Unit Value"
                            disabled={
                              asset.account.type === "INTEGRATED_ACCOUNT"
                            }
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  </>
                )}
              />
            )}
          </div>
        </TableCell>
      </Form>

      <TableCell>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-1 flex-row items-center justify-center gap-1">
            <Separator
              orientation="vertical"
              className="h-5 w-[2px] bg-gray-200"
            />
          </div>
          <ValueColumn
            originalValue={inputQuantity * inputUnitValue}
            type={"asset"}
            originalCurrency={inputCurrency}
            value={
              currencyContext.state === "SUCCESS"
                ? currencyContext.convertValueToSelectedCurrency(
                    inputQuantity * inputUnitValue,
                    inputCurrency,
                    currencyContext.currency,
                  )
                : 0
            }
          />
        </div>
      </TableCell>

      <TableCell>
        <div className="flex flex-row justify-center gap-1">
          <Button
            size="sm"
            variant="darkCyan"
            onClick={() => {
              form.handleSubmit(onSubmit)();
              showShortcutToast({
                shortcutKey: "Enter",
                template: "Just hit {shortcutKey} to save this",
              });
            }}
          >
            <Check className="size-3.5 rounded-lg" />
          </Button>
          <Button
            size="sm"
            onClick={() => {
              cancel();
              showShortcutToast({
                shortcutKey: "Esc",
                template: "You can press {shortcutKey} to cancel",
              });
            }}
            variant="outline"
          >
            <X className="size-3.5 rounded-lg" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}
