import { useState, useEffect } from "react";
import { usePeekUser } from "@/hooks/usePeekUser";
import { ArrowLeft, Clock } from "lucide-react";
import { cn } from "@/lib/utils";
import { Goal, Profile } from "@/lib/types";

// Images
import logoSmall from "/images/logo-small.svg";
import onboardingHello from "/images/onboarding/onboarding-peek-hello.svg";
import onboarding1 from "/images/onboarding/onboarding-peek-1.svg";
import onboarding2 from "/images/onboarding/onboarding-peek-2.svg";
import onboarding3 from "/images/onboarding/onboarding-peek-3.svg";
import onboarding4 from "/images/icon-concierge.svg";

// Steps
import { StepOne } from "./Steps/StepOne";
import { StepTwo } from "./Steps/StepTwo";
import { StepThree } from "./Steps/StepThree";
import { StepFour } from "./Steps/StepFour";
import { StepFive } from "./Steps/StepFive";
import { StepSix } from "./Steps/StepSix";
import { StepSeven } from "./Steps/StepSeven";

import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
import { ProTip } from "../common/ProTip";
import { useAddProfile } from "@/hooks/useAddProfile";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { Currency } from "@/hooks/useCurrency";
import { useOnboardingData, defaultFormData } from "@/hooks/useOnboardingData";

export default function Onboarding() {
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const peekUserContext = usePeekUser();
  const { addProfileAsync } = useAddProfile();

  const [onboarding, setOnboarding] = useOnboardingData();

  useEffect(() => {
    if (
      peekUserContext.state === "SUCCESS" &&
      !peekUserContext.user.customFields.finishedOnboarding
    ) {
      localStorage.setItem("onboardingData", JSON.stringify(onboarding));
    }
  }, [onboarding, peekUserContext]);

  if (
    peekUserContext.state !== "SUCCESS" ||
    peekUserContext.user.customFields.finishedOnboarding
  ) {
    return null;
  }

  const steps = Array.from({ length: 7 }, (_, i) => i + 1);

  const handleNext = (updatedData: Profile, goal?: Goal) => {
    setOnboarding((prev) => {
      const nextStep = prev.currentStep + 1;
      const newState = {
        ...prev,
        currentStep: nextStep === 8 ? prev.currentStep : nextStep,
        data: {
          ...prev.data,
          ...updatedData,
        },
        goal: goal || prev.goal,
      };

      // Check if we should show the final step
      if (nextStep === 8) {
        updateUserProfile(newState.data, newState.goal);
      }

      return newState;
    });
  };

  const handleSkip = () => {
    setOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep + 1,
    }));
  };

  const handleGoBack = () => {
    setOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const handleChangeGoals = () => {
    setOnboarding((prev) => ({
      ...prev,
      currentStep: 6,
    }));
  };

  const updateUserProfile = async (data: Profile, goal: Goal) => {
    setLoading(true);
    try {
      const updatedData = { ...data };
      delete goal.goalOptionIndex;

      await addProfileAsync({
        profile: updatedData,
        ...(goal.name !== "" && { goal }),
      });

      localStorage.removeItem("onboardingData");

      setOnboarding({
        currentStep: 1,
        data: defaultFormData,
        goal: {
          name: "",
          targetNetWorth: 0,
          targetDate: new Date(),
        },
      });
    } catch (error) {
      localStorage.removeItem("onboardingData");
      console.error("Error saving onboarding data:", error);
      toast.error("Error saving onboarding data");
    } finally {
      setLoading(false);
      setShowFinalStep(true);
    }
  };

  const onboardingSteps = [
    {
      logo: onboardingHello,
      title: "Let's build your profile with Peek.",
      description: "Tell us about yourself!",
      proTip: {
        title: "Pro Tip: 💡 Peek is designed for you",
        description:
          "We'll use this information to provide better, more personalized insights tailored to your financial journey.",
      },
    },
    {
      logo: onboarding1,
      title: `Nice to meet you, ${onboarding.data.name}!`,
      description: "What's your family situation?",
      proTip: {
        title:
          "Pro Tip: 💡 Understanding your family situation helps us tailor our insights to your needs.",
      },
    },
    {
      logo: onboarding1,
      title: "Tell us more!",
      description: "What do you do?",
    },
    {
      logo: onboarding2,
      title: "Know where you stand",
      description: "What is your estimated net worth?",
      proTip: {
        title: "Pro Tip: 💡 Stay on track",
        description: (
          <p className="text-center">
            Providing your estimated net worth helps us know when you've
            completed the onboarding process and ensures all your financial
            information is accounted for.
          </p>
        ),
      },
    },
    {
      logo: onboarding2,
      title: "Almost there!",
      description: "Building your financial profile 🚧",
    },
    {
      logo: onboarding3,
      title: "Let's get down to business.",
      description: (
        <>
          Now that we know you better, let's start defining your financial
          goals.
          <br />
          🎯 Choose from popular templates below or create your own custom
          goals.
        </>
      ),
    },
    {
      logo: onboarding4,
      title: "A person with Goals is unstoppable!",
      description: (
        <p className="text-center">
          Proceed to the next step to explore Peek's powerful features.
        </p>
      ),
    },
  ];

  return (
    <Dialog open={!peekUserContext.user.customFields.finishedOnboarding}>
      <DialogContent className="w-[800px] max-w-[90vw] rounded-lg" noCross>
        {showFinalStep ? (
          <div className="flex flex-col items-center">
            <img src={onboarding4} alt="peek-logo" className="size-24" />

            <h1 className="mt-4 font-libre text-4xl font-normal">
              Your profile has been created!
            </h1>

            <p className="my-4 text-center text-base font-normal text-gray-600">
              Just one more thing before we get started...
            </p>

            <Button
              className="uppercase"
              variant="default"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                await peekUserContext.updateUserCustomFields({
                  ...peekUserContext.user.customFields,
                  finishedOnboarding: true,
                });

                queryClient.invalidateQueries({ queryKey: ["peekUser"] });
              }}
            >
              Next
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            {onboarding.currentStep > 1 && (
              <button className="absolute left-5 top-5" onClick={handleGoBack}>
                <ArrowLeft size={18} className="text-black" />
              </button>
            )}
            <div className="mt-1 flex flex-row items-center gap-x-1">
              {steps.map((step) => (
                <div key={step} className="flex flex-col gap-y-1">
                  <img
                    src={logoSmall}
                    alt="peek-logo"
                    className={cn(
                      "size-6 invisible",
                      onboarding.currentStep === step && "visible",
                    )}
                  />
                  <span
                    className={`h-[6px] w-14 rounded-full ${onboarding.currentStep >= step ? "bg-cyan" : "bg-gray-300"}`}
                  />
                </div>
              ))}
            </div>

            <span className="mt-4 flex flex-row items-center gap-x-1 text-sm text-gray-400">
              <Clock size={10} /> Estimated time:{" "}
              {onboarding.currentStep === 5 ? 2 : 1} min
            </span>

            <img
              src={onboardingSteps[onboarding.currentStep - 1].logo}
              alt="peek"
              className="mt-10 size-24"
            />

            <h1 className="mt-4 font-libre text-4xl font-normal">
              {onboardingSteps[onboarding.currentStep - 1].title}
            </h1>

            <div className="my-4 text-center text-base font-normal text-gray-600">
              {onboardingSteps[onboarding.currentStep - 1].description}
            </div>

            {onboardingSteps[onboarding.currentStep - 1].proTip && (
              <ProTip
                title={
                  onboardingSteps[onboarding.currentStep - 1].proTip?.title
                }
                backgroundColor="bg-beige"
              >
                <span className="text-gray-600">
                  {
                    onboardingSteps[onboarding.currentStep - 1].proTip
                      ?.description
                  }
                </span>
              </ProTip>
            )}

            {onboarding.currentStep === 1 && (
              <StepOne
                onNext={handleNext}
                data={{ ...onboarding.data, name: peekUserContext.user.name }}
              />
            )}

            {onboarding.currentStep === 2 && (
              <StepTwo
                onNext={handleNext}
                onSkip={handleSkip}
                data={onboarding.data}
              />
            )}

            {onboarding.currentStep === 3 && (
              <StepThree
                onNext={handleNext}
                onSkip={handleSkip}
                data={onboarding.data}
              />
            )}

            {onboarding.currentStep === 4 && (
              <StepFour onNext={handleNext} data={onboarding.data} />
            )}

            {onboarding.currentStep === 5 && (
              <StepFive
                onNext={handleNext}
                onSkip={handleSkip}
                data={onboarding.data}
              />
            )}

            {onboarding.currentStep === 6 && (
              <StepSix
                goal={onboarding.goal}
                onNext={(goal) => {
                  setOnboarding((prev) => ({
                    ...prev,
                    currentStep: 7,
                    goal,
                  }));
                }}
                onSkip={() =>
                  updateUserProfile(onboarding.data, onboarding.goal)
                }
                isLoading={loading}
              />
            )}

            {onboarding.currentStep === 7 && (
              <StepSeven
                preferredCurrency={
                  onboarding.data.preferredCurrency as Currency
                }
                onNext={(goal) => {
                  handleNext(onboarding.data, goal);
                }}
                onSkip={() =>
                  updateUserProfile(onboarding.data, onboarding.goal)
                }
                onChangeGoals={handleChangeGoals}
                goal={onboarding.goal}
                isLoading={loading}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
