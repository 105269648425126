import { TableCell, TableRow } from "@/components/ui/table";
import { Check, X } from "lucide-react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useRenameAccountMutation } from "@/hooks/useRenameAccountMutation";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";
import { Button } from "@/components/ui/button";
import { Table } from "@tanstack/react-table";
import { PortfolioTableRow } from "@/components/Portfolio/Table/types";

type AccountNameInlineEditProps = {
  cancel: () => void;
  accountName: string;
  accountId: number;
  uniqueAccountNames: string[];
  table: Table<PortfolioTableRow>;
};

export function AccountNameInlineEdit({
  cancel,
  accountName,
  accountId,
  uniqueAccountNames,
  table,
  ...props
}: AccountNameInlineEditProps & React.HTMLAttributes<HTMLTableRowElement>) {
  const { renameAccount, isPending } = useRenameAccountMutation();
  const { showShortcutToast } = useShortcutPrompt();

  const form = useForm<{ accountName: string }>({
    resolver: zodResolver(
      z.object({
        accountName: z
          .string()
          .min(3, "Account name needs to be at least 3 characters"),
      }),
    ),
    defaultValues: {
      accountName,
    },
  });

  const onSubmit = useCallback(
    async (values: { accountName: string }) => {
      if (accountName === values.accountName) {
        cancel();
        return;
      }
      if (isPending) return;

      const parsedAsset = z
        .object({ accountName: z.string().min(3) })
        .refine(
          ({ accountName }) => !uniqueAccountNames.includes(accountName),
          {
            path: ["accountName"],
            message: "Account name already exists",
          },
        )
        .safeParse(values);

      if (!parsedAsset.success) {
        form.setError("accountName", {
          type: "custom",
          message: parsedAsset.error.issues[0].message,
        });
        return;
      }

      renameAccount({
        id: accountId,
        accountName: values.accountName,
      });
      cancel();
    },
    [
      accountName,
      cancel,
      isPending,
      uniqueAccountNames,
      renameAccount,
      accountId,
      form,
    ],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        cancel();
      } else if (event.key === "Enter") {
        form.handleSubmit(onSubmit)();
      }
      event.stopPropagation();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cancel, form, onSubmit]);

  return (
    <TableRow {...props} className="relative  bg-gray-100">
      <Form {...form}>
        <TableCell colSpan={table.getFlatHeaders().length}>
          <div className="absolute left-[-17px] top-[31%] w-[56px] -rotate-90 border-t-2 border-t-darkCyan text-center">
            <span className="text-xs text-darkCyan">EDITING</span>
          </div>

          <FormField
            control={form.control}
            name="accountName"
            render={({ field }) => (
              <FormItem className="ml-8 ">
                <FormControl>
                  <Input
                    autoFocus
                    id="accountName"
                    className="rounded-lg px-3 focus-visible:ring-0 focus-visible:ring-offset-0"
                    {...field}
                    onFocus={(e) => e.target.select()}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {form.formState.errors.accountName && (
            <p className="mt-2 text-sm font-medium text-destructive">
              {form.formState.errors.accountName.message}
            </p>
          )}
        </TableCell>
      </Form>

      <TableCell>
        <div className="flex flex-row justify-center gap-1">
          <Button
            size="sm"
            onClick={() => {
              form.handleSubmit(onSubmit)();
              showShortcutToast({
                shortcutKey: "Enter",
                template: "Just hit {shortcutKey} to save this",
              });
            }}
            variant="darkCyan"
          >
            <Check className="size-3.5 rounded-lg" />
          </Button>
          <Button
            size="sm"
            onClick={() => {
              cancel();
              showShortcutToast({
                shortcutKey: "Esc",
                template: "You can press {shortcutKey} to cancel",
              });
            }}
            variant="outline"
          >
            <X className="size-3.5 rounded-lg" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}
