import { useState } from "react";
import { Pencil, Check, X } from "lucide-react";
import { Input as ShadcnInput } from "@/components/ui/input";
export function Input({
  name,
  value,
  onUpdate,
  renderAs,
  original,
}: {
  name: string;
  value: number;
  onUpdate: (key: string, value: number) => void;
  renderAs: string;
  original?: { currency: string; value: number };
}) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState<number | string>(value);

  return editing ? (
    <div className="flex flex-row items-center justify-start gap-1">
      <ShadcnInput
        type="number"
        value={inputValue}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            onUpdate(name, +inputValue);
            setEditing(false);
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        autoFocus
        className="w-32 rounded-md px-2 outline-none focus:border-none focus:outline-none"
      />
      {original && (
        <span className="text-gray-500">(In {original.currency})</span>
      )}
      <Check
        className="hover:cursor-pointer"
        color="darkCyan"
        onClick={() => {
          onUpdate(name, +inputValue);
          setEditing(false);
        }}
      />
      <X
        className="hover:cursor-pointer"
        color="red"
        onClick={() => setEditing(false)}
      />
    </div>
  ) : (
    <div
      className="inline-block hover:cursor-pointer"
      onClick={() => {
        setEditing(true);
      }}
    >
      <span className="border-b-offset-2 mr-1 border-b-2 border-dashed border-darkCyan text-darkCyan">
        {renderAs}
      </span>
      <Pencil color="darkCyan" size={14} className="inline" />
    </div>
  );
}
