import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { AssetLiabilityV2 } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function useDeleteAccountMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  const queryClient = useQueryClient();

  const { mutate: deleteAccount, isPending } = useMutation({
    mutationFn: (id: number) => callDeleteAccount(id, getToken),
    onMutate: async (deletedId) => {
      await queryClient.cancelQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });

      const previousData = queryClient.getQueryData([
        GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
      ]);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        (old: AssetLiabilityV2[]) =>
          old.filter(
            (assetLiability) => +assetLiability.account.id !== +deletedId,
          ),
      );

      return { previousData };
    },
    onSuccess: async (id) => {
      await queryClient.refetchQueries({
        queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
      });
      posthog.capture("delete_account_success", {
        accountId: id,
      });
    },
    onError: (error, _, context) => {
      toast.error("Failed to delete account");

      console.error(error);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });
    },
  });

  return { deleteAccount, isPending };
}

async function callDeleteAccount(
  id: number,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  await axios.delete(`${config.backendUrl}/user/account/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return id;
}
