import { cn, shortNumberWithCurrency } from "@/lib/utils";
import { useCurrency } from "@/hooks/useCurrency";
import { ArrowLeftRightIcon } from "lucide-react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

export function ValueColumn({
  value,
  type,
  originalCurrency,
  originalValue,
}: {
  value: number;
  type: "asset" | "section" | "subSection";
  originalCurrency: string;
  originalValue: number;
}) {
  const currencyContext = useCurrency();

  if (currencyContext.state !== "SUCCESS") {
    return null;
  }

  const { currency, exchangeRatesBaseUSD } = currencyContext;
  const showOriginalCurrency =
    originalCurrency &&
    currency !== originalCurrency &&
    !(currency === "GBP" && originalCurrency === "GBp");
  const currencyKey = currency !== "USD" ? currency : "SGD";

  // special handling for GBp (pence)
  if (originalCurrency === "GBp") {
    originalCurrency = "GBP";
    originalValue /= 100; // convert GBp (pence) to GBP (pound)
  }

  let exchangeRate = exchangeRatesBaseUSD[currencyKey]; // originalCurrency === "USD"
  if (originalCurrency !== "USD") {
    exchangeRate =
      exchangeRatesBaseUSD[currencyKey] /
      exchangeRatesBaseUSD[originalCurrency];
  }

  const formattedValue = shortNumberWithCurrency(value, currency);

  return (
    <div>
      <div
        className={cn(
          "text-right text-sm",
          type === "section" && "font-medium",
        )}
      >
        {currency} {formattedValue}
      </div>
      {showOriginalCurrency && (
        <div className="flex items-center justify-end">
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger asChild>
                <ArrowLeftRightIcon
                  size={16}
                  className="mr-2 text-gray-400 hover:cursor-pointer"
                />
              </TooltipTrigger>
              <TooltipContent className="bg-black" side="top">
                <p className="text-white">
                  Converted at {originalCurrency} 1.00 = {currencyKey}{" "}
                  {exchangeRate}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div className="text-right text-gray-400">
            <span className="mr-2">{originalCurrency}</span>
            <span>
              {shortNumberWithCurrency(originalValue, originalCurrency)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
