import { Button } from "@/components/ui/button";
import { CardContent } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { RowSelectionState } from "@tanstack/react-table";
import { Delete, LineChart, Table, Trash2 } from "lucide-react";
import { useCallback, useState } from "react";
import { MoveAccountDialog } from "@/components/Portfolio/MoveAccountDialog";
import { DeleteConfirmationDialog } from "@/components/common/DeleteConfirmationDialog";
import { useDeleteAssetsLiabilitiesMutation } from "@/hooks/useDeleteAssetsLiabilitiesMutation";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrency } from "@/hooks/useCurrency";
import peekEmpty from "/images/peek-empty.svg";
import { usePlaidIntegration } from "@/hooks/usePlaidIntegration";
import { AddAssetDialog } from "@/components/AddAsset/AddAssetDialog";
import { PortfolioChart } from "@/components/Portfolio/PortfolioChart";
import { ChartData } from "chart.js";
import EndowusLoginDialog from "@/components/AddAsset/Endowus/EndowusLoginDialog";
import { useDialog } from "@/hooks/useDialog";
import { CommandKey } from "@/components/common/CommandKey";
import StashAwayLoginDialog from "@/components/AddAsset/StashAway/StashAwayLoginDialog";
import EmeregLoginDialog from "@/components/AddAsset/Emereg/EmeregLoginDialog";
import { Legends } from "@/components/Portfolio/Legends";
import { OverviewByList } from "@/components/Portfolio/OverviewByList";
import ZerionDialog from "@/components/AddAsset/Zerion/ZerionDialog";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";
import { PortfolioTable } from "@/components/Portfolio/Table";
import {
  getAccountFilterList,
  getHistoryChartData,
  getSelectedIds,
  getTotalValues,
  groupDataByOverview,
} from "@/lib/portfolio.utils";
import { useColumnVisibility } from "@/hooks/useColumnVisilibility";
import { AccountSyncTaskIntegrator } from "@/lib/types";
import { toast } from "sonner";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";
import config from "@/lib/config";
import { useSnaptrade } from "@/hooks/useSnaptrade";
import EndowusAPIClient from "@/components/AddAsset/Endowus/EndowusAPIClient";
import StashAwayAPIClient from "@/components/AddAsset/StashAway/StashAwayAPIClient";
import EmeregAPIClient from "@/components/AddAsset/Emereg/EmeregAPIClient";
import { useSyncTasks } from "@/hooks/useSyncTasks";

const overviewByList = [
  {
    label: "Asset Class",
    value: "asset",
  },
  {
    label: "Account",
    value: "account",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Tag",
    value: "tag",
  },
];

export function PortfolioPage() {
  const { getToken } = useAuth();
  const snaptrade = useSnaptrade();
  const { addSyncTask } = useSyncTasks();
  const [overviewBy, setOverviewBy] = useState("asset");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [openEndowus, setOpenEndowus] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openStashAway, setOpenStashAway] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openEmereg, setOpenEmereg] = useState<{
    isOpen: boolean;
    customLink: string;
    accountId?: number;
  }>({ isOpen: false, customLink: "dbs" });

  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();
  const { setPlaidCustomLinkNameAndResetToken, setPlaidUpdateModeAccountId } =
    usePlaidIntegration();
  const { deleteAssetsLiabilities, isPending: isDeletePending } =
    useDeleteAssetsLiabilitiesMutation();
  const { currency } = useCurrency();
  const { setDialogOpen } = useDialog();

  const columnVisibility = useColumnVisibility(
    "tableColumnVisibility",
    {
      asset: true,
      tag: true,
      quantity: true,
      unitValue: true,
      percentageHoldings: true,
      value: true,
      smallValue: false,
    },
    ["asset", "value"],
  );

  const handleBackgroundSyncTask = useCallback(
    async (accountId: number, token: string) => {
      try {
        await axios.get(
          `${config.integrationsUrl}/update-account-sync-task/${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } catch (e) {
        throw new Error("Failed to sync");
      }
    },
    [],
  );

  const handleEndowusSync = useCallback(
    async (accountId: number) => {
      const client = new EndowusAPIClient();
      await client.setClerkHeaders(getToken);

      const syncWithAuthToken = async () => {
        try {
          await client.updateEndowusDataForAccount(accountId);
        } catch (error) {
          setOpenEndowus({ isOpen: true, accountId });
          throw new Error("Please re-login to your Endouws account again");
        }
      };

      if (client.hasEndowusAuthToken()) {
        await syncWithAuthToken();
      } else {
        setOpenEndowus({ isOpen: true, accountId });
        throw new Error("Please re-login to your Endouws account again");
      }
    },
    [getToken, setOpenEndowus],
  );

  const handleStashawaySync = useCallback(
    async (accountId: number) => {
      const client = new StashAwayAPIClient();
      await client.setClerkHeaders(getToken);

      const syncWithAuthToken = async () => {
        try {
          await client.updateStashAwayDataForAccount(accountId);
        } catch (error) {
          setOpenStashAway({ isOpen: true, accountId });
          throw new Error("Please re-login to your Stashaway account again");
        }
      };

      if (client.hasStashAwayAuthToken()) {
        await syncWithAuthToken();
      } else {
        setOpenStashAway({ isOpen: true, accountId });
        throw new Error("Please re-login to your Stashaway account again");
      }
    },
    [getToken, setOpenStashAway],
  );

  const handleEmeregSync = useCallback(
    async (accountId: number, link: string) => {
      const client = new EmeregAPIClient();
      await client.setClerkHeaders(getToken);

      try {
        if (link === "dbs-vickers") {
          await client.updateHoldings(accountId);
        } else {
          await client.updateAccounts(accountId);
        }
      } catch (error) {
        setOpenEmereg({ isOpen: true, accountId, customLink: link });
        throw new Error("Please re-login to your Emereg account again");
      }
    },
    [getToken, setOpenEmereg],
  );

  const handleSync = useCallback(
    async (
      accountId: number,
      accountName: string,
      integrator?: AccountSyncTaskIntegrator,
    ) => {
      const token = await getToken();
      if (!token) return;

      let syncPromise: Promise<void>;

      addSyncTask({
        account: {
          id: accountId,
          name: accountName,
        },
        integrator: integrator as AccountSyncTaskIntegrator,
        status: "PENDING",
        updatedAt: new Date().toISOString(),
        id: Date.now(),
      });

      if (integrator === "ENDOWUS") {
        syncPromise = handleEndowusSync(accountId);
      } else if (integrator === "STASHAWAY") {
        syncPromise = handleStashawaySync(accountId);
      } else if (integrator === "EMEREG") {
        const link = accountName.toLowerCase().includes("vickers")
          ? "dbs-vickers"
          : "dbs";
        syncPromise = handleEmeregSync(accountId, link);
      } else {
        syncPromise = handleBackgroundSyncTask(accountId, token);
      }

      toast.promise(syncPromise, {
        loading:
          integrator === "ENDOWUS" ||
          integrator === "STASHAWAY" ||
          integrator === "EMEREG"
            ? `Syncing with ${accountName}...`
            : `Connecting to ${accountName}...`,
        success:
          integrator === "ENDOWUS" ||
          integrator === "STASHAWAY" ||
          integrator === "EMEREG"
            ? `Successfully synced with ${accountName}!`
            : `Successfully connected to ${accountName}! Starting to update your data...`,
        error: `Oops! Please log in again to ${accountName}.`,
      });
    },
    [
      getToken,
      addSyncTask,
      handleEndowusSync,
      handleStashawaySync,
      handleEmeregSync,
      handleBackgroundSyncTask,
    ],
  );

  const handleReloginBasedOnIntegrator = useCallback(
    (accountId: number, integrator: AccountSyncTaskIntegrator) => {
      if (integrator === "PLAID") {
        toast.info("Loading Plaid.. Hang tight!");
        setPlaidUpdateModeAccountId(accountId);
      } else if (integrator === "FLANKS") {
        handleFlanksRelogin(accountId, getToken);
      } else if (integrator === "ENDOWUS") {
        setOpenEndowus({ isOpen: true, accountId });
      } else if (integrator === "STASHAWAY") {
        setOpenStashAway({ isOpen: true, accountId });
      } else if (integrator === "EMEREG") {
        setOpenEmereg({ isOpen: true, customLink: "dbs", accountId });
      } else if (integrator === "SNAPTRADE") {
        toast.info("Relogging into SnapTrade.. Hang tight!");
        if (snaptrade) snaptrade.regenerateRedirectLinkForAccount(accountId);
      }
    },
    [setPlaidUpdateModeAccountId, getToken, snaptrade],
  );

  const handleFlanksRelogin = async (
    accountId: number,
    getToken: (options?: { template: string }) => Promise<string | null>,
  ) => {
    toast.info("Flanks' login screen will appear in a new tab.", {
      action: {
        label: "Open",
        onClick: () => {
          window.open(
            `/flanks-link?bankId=${bankId}&scaToken=${
              scaToken || ""
            }&resetToken=${resetToken || ""}`,
            "_blank",
          );
        },
      },
    });

    const token = await getToken();
    if (!token) return;
    const {
      data: { scaToken, resetToken, bankId },
    } = await axios.get<{
      scaToken: string | null;
      resetToken: string | null;
      bankId: string;
    }>(`${config.integrationsUrl}/flanks/reset-token/${accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.open(
      `/flanks-link?bankId=${bankId}&scaToken=${scaToken || ""}&resetToken=${
        resetToken || ""
      }`,
      "_blank",
    );
  };

  if (assetLiabilitiesAccountsContext.state === "LOADING")
    return <Skeleton className="h-screen w-full" />;

  if (assetLiabilitiesAccountsContext.state === "ERROR")
    throw new Error(assetLiabilitiesAccountsContext.message);

  if (assetLiabilitiesAccountsContext.state !== "SUCCESS") return null;

  const { data } = assetLiabilitiesAccountsContext;

  const filteredData = columnVisibility.visibleColumns.smallValue
    ? data
    : data.filter(
        (assetLiability) =>
          // Use absolute value to filter out small values for assets and liabiliies
          Math.abs(assetLiability.snapshots.day.slice(-1)[0].totalValue) > 1,
      );

  const accountFilterList = getAccountFilterList(filteredData);

  const { assetTotalValue, liabilityTotalValue } = getTotalValues(filteredData);

  const { assets, liabilities } = groupDataByOverview(
    filteredData,
    overviewBy,
    assetTotalValue,
    liabilityTotalValue,
  );

  const historyChartData = getHistoryChartData(filteredData, overviewBy);

  const uniqueAccountNames = [
    ...new Set(
      filteredData.map((assetLiability) => assetLiability.account.name),
    ),
  ];

  const selectedIds = getSelectedIds(rowSelection);

  return (
    <div className="min-h-screen">
      <OverviewByList
        overviewByList={overviewByList}
        overviewBy={overviewBy}
        setOverviewBy={setOverviewBy}
        handleSync={handleSync}
        handleReloginBasedOnIntegrator={handleReloginBasedOnIntegrator}
      />

      <Legends
        data={filteredData}
        overviewBy={overviewBy}
        currency={currency}
        totalAssets={assetTotalValue}
        totalLiabilities={liabilityTotalValue}
        setPlaidCustomLinkNameAndResetToken={
          setPlaidCustomLinkNameAndResetToken
        }
        setOpenEndowus={setOpenEndowus}
        setOpenStashAway={setOpenStashAway}
        setOpenEmereg={setOpenEmereg}
      />

      <Tabs defaultValue="assets" className="py-6 pb-8">
        <div className="flex justify-between">
          <TabsList className="h-auto bg-transparent p-0">
            <TabsTrigger
              value="assets"
              className="text-lg hover:bg-transparent data-[state=active]:bg-transparent data-[state=active]:text-gray-700"
            >
              Assets
            </TabsTrigger>
            <TabsTrigger
              value="liabilities"
              className="text-lg hover:bg-transparent data-[state=active]:bg-transparent data-[state=active]:text-gray-700"
            >
              Liabilities
            </TabsTrigger>
          </TabsList>

          {selectedIds.length > 0 && (
            <div className="flex justify-end gap-x-2">
              <MoveAccountDialog
                selectedIds={selectedIds}
                setSelectedIds={setRowSelection}
                accountOptions={accountFilterList.filter(
                  (account) =>
                    !selectedIds.some((id) => account.assetIds.includes(id)),
                )}
              />
              <Button
                size="sm"
                variant="destructive"
                onClick={() => setDialogOpen("deleteAssetsLiabilities", true)}
                className="gap-1"
              >
                <Trash2 size={14} className="mr-1" />
                Delete all ({selectedIds.length})
                <CommandKey
                  cmdKey="Backspace"
                  variant="white"
                  requireCmdKey={false}
                  callback={() =>
                    setDialogOpen("deleteAssetsLiabilities", true)
                  }
                  icon={<Delete />}
                />
              </Button>
            </div>
          )}
        </div>

        <TabsContent
          value="assets"
          className="mt-6 rounded-md border border-gray-200"
        >
          {assets.length > 0 ? (
            <Tabs defaultValue="table">
              <div className="rounded rounded-b-none border-b bg-border/50 text-sm text-gray-500">
                <div className="flex items-center justify-end space-x-1">
                  <span>View as:</span>
                  <TabsList className="gap-x-1 bg-transparent">
                    <TabsTrigger
                      value="table"
                      className="data-[state=active]:bg-gray-300/50"
                    >
                      <div className="flex flex-row items-center gap-x-1">
                        <Table size={16} />
                        <span>Table</span>
                      </div>
                    </TabsTrigger>
                    <TabsTrigger
                      value="chart"
                      className="data-[state=active]:bg-gray-300/50"
                    >
                      <div className="flex flex-row items-center gap-x-1">
                        <LineChart size={16} />
                        <span>Chart</span>
                      </div>
                    </TabsTrigger>
                  </TabsList>
                </div>
              </div>
              <TabsContent value="table">
                <PortfolioTable
                  data={assets}
                  overviewBy={overviewBy}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  tableType="assets"
                  setOpenEmereg={setOpenEmereg}
                  setOpenEndowus={setOpenEndowus}
                  setOpenStashAway={setOpenStashAway}
                  uniqueAccountNames={uniqueAccountNames}
                  handleDeleteAssetsLiabilities={deleteAssetsLiabilities}
                  handleReloginBasedOnIntegrator={
                    handleReloginBasedOnIntegrator
                  }
                  handleSync={handleSync}
                  assetTotalValue={assetTotalValue}
                  liabilityTotalValue={liabilityTotalValue}
                  columnVisibility={columnVisibility}
                />
              </TabsContent>
              <TabsContent value="chart">
                <PortfolioChart
                  data={
                    historyChartData.assets as ChartData<
                      "bar",
                      number[],
                      string
                    >
                  }
                  type="assets"
                />
              </TabsContent>
            </Tabs>
          ) : (
            <CardContent>
              <div className="flex flex-col items-center justify-center">
                <img src={peekEmpty} />
                <h2>Looks like you haven't added any assets.</h2>
                <p className="text-center text-sm text-muted-foreground">
                  Start tracking your investments, savings, and more to see your
                  wealth grow over time.
                </p>

                <AddAssetDialog
                  enableCmdK={false}
                  variant="default"
                  size="sm"
                  setPlaidCustomLinkNameAndResetToken={
                    setPlaidCustomLinkNameAndResetToken
                  }
                  buttonLabel="Add assets"
                  className="mt-3"
                  setOpenEndowus={(isOpen: boolean, accountId?: number) =>
                    setOpenEndowus({ isOpen, accountId })
                  }
                  setOpenStashAway={(isOpen: boolean, accountId?: number) =>
                    setOpenStashAway({ isOpen, accountId })
                  }
                  setOpenEmereg={(
                    isOpen: boolean,
                    customLink: string,
                    accountId?: number,
                  ) => setOpenEmereg({ isOpen, customLink, accountId })}
                />
              </div>
            </CardContent>
          )}
        </TabsContent>

        <TabsContent
          value="liabilities"
          className="mt-6 rounded-md border border-gray-200"
        >
          {liabilities.length > 0 ? (
            <Tabs defaultValue="table">
              <div className="rounded rounded-b-none border-b bg-border/50 text-sm text-gray-500">
                <div className="flex items-center justify-end space-x-1">
                  <span>View as:</span>
                  <TabsList className="gap-x-1 bg-transparent">
                    <TabsTrigger
                      value="table"
                      className="data-[state=active]:bg-gray-300/50"
                    >
                      <div className="flex flex-row items-center gap-x-1">
                        <Table size={16} />
                        <span>Table</span>
                      </div>
                    </TabsTrigger>
                    <TabsTrigger
                      value="chart"
                      className="data-[state=active]:bg-gray-300/50"
                    >
                      <div className="flex flex-row items-center gap-x-1">
                        <LineChart size={16} />
                        <span>Chart</span>
                      </div>
                    </TabsTrigger>
                  </TabsList>
                </div>
              </div>
              <TabsContent value="table">
                <PortfolioTable
                  data={liabilities}
                  overviewBy={overviewBy}
                  tableType="liabilities"
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  setOpenEmereg={setOpenEmereg}
                  setOpenEndowus={setOpenEndowus}
                  setOpenStashAway={setOpenStashAway}
                  uniqueAccountNames={uniqueAccountNames}
                  handleDeleteAssetsLiabilities={deleteAssetsLiabilities}
                  handleReloginBasedOnIntegrator={
                    handleReloginBasedOnIntegrator
                  }
                  handleSync={handleSync}
                  assetTotalValue={assetTotalValue}
                  liabilityTotalValue={liabilityTotalValue}
                  columnVisibility={columnVisibility}
                />
              </TabsContent>
              <TabsContent value="chart">
                <div className="h-[500px] p-8">
                  <PortfolioChart
                    data={
                      historyChartData.liabilities as ChartData<
                        "bar",
                        number[],
                        string
                      >
                    }
                    type="liabilities"
                  />
                </div>
              </TabsContent>
            </Tabs>
          ) : (
            <CardContent>
              <div className="flex flex-col items-center justify-center">
                <img src={peekEmpty} />
                <h2>Hmmm...Looks like you haven't added any liabilities.</h2>
                <p className="text-center text-sm text-gray-500">
                  Start tracking your debts, loans, and more.
                </p>

                <AddAssetDialog
                  enableCmdK={false}
                  variant="default"
                  size="sm"
                  setPlaidCustomLinkNameAndResetToken={
                    setPlaidCustomLinkNameAndResetToken
                  }
                  buttonLabel="Add liabilities"
                  className="mt-3"
                  setOpenEndowus={(isOpen: boolean, accountId?: number) =>
                    setOpenEndowus({ isOpen, accountId })
                  }
                  setOpenStashAway={(isOpen: boolean, accountId?: number) =>
                    setOpenStashAway({ isOpen, accountId })
                  }
                  setOpenEmereg={(
                    isOpen: boolean,
                    customLink: string,
                    accountId?: number,
                  ) => setOpenEmereg({ isOpen, customLink, accountId })}
                />
              </div>
            </CardContent>
          )}
        </TabsContent>
      </Tabs>

      <ZerionDialog />

      <EndowusLoginDialog
        open={openEndowus.isOpen}
        setOpen={(isOpen: boolean, accountId?: number) =>
          setOpenEndowus({ isOpen, accountId })
        }
        accountId={openEndowus.accountId}
      />

      <StashAwayLoginDialog
        open={openStashAway.isOpen}
        setOpen={(isOpen: boolean, accountId?: number) =>
          setOpenStashAway({ isOpen, accountId })
        }
        accountId={openStashAway.accountId}
      />

      <EmeregLoginDialog
        open={openEmereg.isOpen}
        setOpen={(isOpen: boolean, customLink: string, accountId?: number) =>
          setOpenEmereg({ isOpen, customLink, accountId })
        }
        accountId={openEmereg.accountId}
        customLink={openEmereg.customLink}
      />

      <DeleteConfirmationDialog
        name="deleteAssetsLiabilities"
        confirmCallback={() => {
          setDialogOpen("deleteAssetsLiabilities", false);
          deleteAssetsLiabilities(selectedIds);
          setRowSelection({});
        }}
        isPending={isDeletePending}
      />
    </div>
  );
}
