import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";

export function useDeleteUserMutation() {
  const { getToken, userId } = useAuth();
  const posthog = usePostHog();

  const { mutateAsync: deleteUser, isPending } = useMutation({
    mutationFn: () => callDeleteUser(getToken),
    onSuccess: () => {
      posthog.capture("delete_user_account_success", {
        userId,
      });
    },
  });

  return { deleteUser, isPending };
}

async function callDeleteUser(getToken: () => Promise<string | null>) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  await axios.delete(`${config.backendUrl}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { status: "OK" };
}
