import { NoTrialNoSub } from "@/components/Billing/NoTrialNoSub";
import { SubscriptionEnded } from "@/components/Billing/SubscriptionEnded";
import { TrialEnded } from "@/components/Billing/TrialEnded";
import { TrialEnding } from "@/components/Billing/TrialEnding";
import { ScrollingBanner } from "@/components/NavBar/ScrollingBanner";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePeekUser } from "@/hooks/usePeekUser";
import { useSubscriptions } from "@/hooks/useSubscriptions";
import config from "@/lib/config";
import { useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export function Paywall() {
  const { user } = useUser();
  const peekUserContext = usePeekUser();
  const subscriptionsContext = useSubscriptions();
  const posthog = usePostHog();
  const [showSubscription, setShowSubscription] = useState(false);

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        name: user.fullName,
        email: user.primaryEmailAddress?.emailAddress,
      });

      Sentry.setUser({
        id: user.id,
        name: user.fullName,
        email: user.primaryEmailAddress?.emailAddress,
      });
    }
  }, [posthog, user]);

  if (
    subscriptionsContext.state !== "SUCCESS" ||
    peekUserContext.state !== "SUCCESS"
  ) {
    return null; // show empty page until subscription is fetched
  }

  const {
    signedUpForTrial,
    trialStillValid,
    hasActiveSubscription,
    subscribed,
    trialDaysLeft,
    trialTimeLeftString,
  } = subscriptionsContext;

  return (
    <>
      {trialStillValid && trialDaysLeft <= config.trialEndingBannerDaysLeft && (
        <ScrollingBanner
          texts={[
            `YOUR TRIAL IS ENDING IN ${trialTimeLeftString.toUpperCase()}.`,
            "CLICK HERE TO UPGRADE.",
          ]}
          onClick={() => {
            setShowSubscription(true);
          }}
        />
      )}

      <Dialog open={showSubscription} onOpenChange={setShowSubscription}>
        <DialogContent className="max-w-1/4 max-h-screen w-1/2 overflow-scroll p-0">
          <TrialEnding trialTimeLeftString={trialTimeLeftString} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={
          !signedUpForTrial &&
          !subscribed &&
          peekUserContext.user.customFields.finishedOnboarding
        }
      >
        <DialogContent
          noCross
          className="max-w-1/4 max-h-screen w-1/2 overflow-scroll p-0"
        >
          <NoTrialNoSub />
        </DialogContent>
      </Dialog>
      <Dialog
        open={
          signedUpForTrial &&
          !trialStillValid &&
          !subscribed &&
          peekUserContext.user.customFields.finishedOnboarding
        }
      >
        <DialogContent
          noCross
          className="max-w-1/4 max-h-screen w-1/2 overflow-scroll p-0"
        >
          <TrialEnded />
        </DialogContent>
      </Dialog>
      <Dialog
        open={
          subscribed &&
          !hasActiveSubscription &&
          peekUserContext.user.customFields.finishedOnboarding
        }
      >
        <DialogContent noCross className="max-h-screen overflow-scroll p-0">
          <SubscriptionEnded />
        </DialogContent>
      </Dialog>
    </>
  );
}
