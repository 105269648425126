import { LineChart } from "lucide-react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import CompareSymbolOverview from "@/components/ui/compare-symbol-overview";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { AssetLiabilityV2, SnapshotV2 } from "@/lib/types";
import { useDialog } from "@/hooks/useDialog";

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { tradableAssets } from "@/lib/constants";
import {
  calculateAnnualizedReturns,
  calculateHoldingPeriodInDays,
} from "@/lib/financials";
import { useCurrency } from "@/hooks/useCurrency";
import { RecentActivityTable } from "./RecentActivityTable";
import { MovementTable } from "./MovementTable";
import { Header } from "./Header";
import { Values } from "./Values";
import { PNLTable } from "./PNLTable";
import PNLBarChart from "./PNLBarChart";
import MovementBarChart from "./MovementBarChart";
import RecentActivityBarChart from "./RecentActivityBarChart";

export type DetailsSheetProps = {
  assetLiability: AssetLiabilityV2;
  assetTotalValue: number;
  liabilityTotalValue: number;
};

export type ReturnOption = {
  label: string;
  value: string;
  tooltip: string;
  formula: string;
  calculation: string;
};

export function DetailsSheet({
  assetLiability,
  assetTotalValue,
  liabilityTotalValue,
}: DetailsSheetProps) {
  const { isDialogOpen, setDialogOpen } = useDialog();
  const posthog = usePostHog();
  const currencyContext = useCurrency();
  const currency =
    currencyContext.state === "SUCCESS" ? currencyContext.currency : "USD";

  useEffect(() => {
    if (isDialogOpen("assetLiabilityDetails")) {
      posthog.capture("asset_liabilities_details_sheet_opened");
    }
  }, [isDialogOpen, posthog, assetLiability]);

  const isLiability = ["LOAN", "CREDIT_CARD"].includes(
    assetLiability.assetClass,
  );
  const isTradable = tradableAssets.includes(assetLiability.type);
  const snapshots = assetLiability.snapshots.day;
  const recentActivity = getRecentActivity(snapshots);
  const movement = getMovement(snapshots);
  const pnl = getPNL(snapshots);
  const totalInvested =
    assetLiability.snapshots.day.slice(-1)[0].totalCapitalInvested;
  const averageCost = assetLiability.snapshots.day.slice(-1)[0].averageCost;
  const currentBalance = assetLiability.snapshots.day.slice(-1)[0].totalValue;
  const lastPnl = assetLiability.snapshots.day.slice(-1)[0].pnl;
  const purchasedDates = recentActivity.map(
    (snapshot) => new Date(snapshot.timestamp),
  );
  const holdingPeriodInDays = calculateHoldingPeriodInDays(purchasedDates);
  const simpleReturns = calculateSimpleReturns(totalInvested, currentBalance);
  const annualizedReturns = calculateAnnualizedReturns(
    totalInvested,
    currentBalance,
    holdingPeriodInDays,
  );
  const returnOptions = getReturnOptions(
    totalInvested,
    currentBalance,
    holdingPeriodInDays,
    simpleReturns,
    annualizedReturns,
  );
  const positiveColor = isLiability ? "text-red-6000" : "text-darkCyan";
  const negativeColor = isLiability ? "text-darkCyan" : "text-red-600";
  const positiveTerm = isLiability
    ? "Borrowed"
    : isTradable
      ? "Bought"
      : "Deposited";
  const negativeTerm = isLiability
    ? "Repaid"
    : isTradable
      ? "Sold"
      : "Withdrew";

  return (
    currencyContext.state === "SUCCESS" && (
      <Sheet
        open={isDialogOpen("assetLiabilityDetails")}
        onOpenChange={(open) => setDialogOpen("assetLiabilityDetails", open)}
      >
        <SheetContent className="w-full space-y-4 bg-gray-100 md:max-w-4xl">
          <ScrollArea className="h-full">
            <div className="space-y-4 ">
              <Header assetLiability={assetLiability} />

              <Values
                assetLiability={assetLiability}
                assetTotalValue={assetTotalValue}
                liabilityTotalValue={liabilityTotalValue}
                isTradable={isTradable}
                returnOptions={returnOptions}
                currency={currency}
              />

              <Tabs
                defaultValue="pnl"
                onValueChange={(value) => {
                  posthog.capture(`asset_liabilities_details_sheet_${value}`);
                }}
              >
                <TabsList className="gap-x-2">
                  <TabsList className="gap-x-2">
                    <TabsTrigger value="pnl">Profit/Loss</TabsTrigger>
                  </TabsList>
                  <TabsList className="gap-x-2">
                    <TabsTrigger value="movement">Movement</TabsTrigger>
                  </TabsList>
                  <TabsList className="gap-x-2">
                    <TabsTrigger value="recentActivity">
                      Recent Activity
                    </TabsTrigger>
                  </TabsList>
                  {assetLiability.type === "STOCK" && (
                    <TabsTrigger value="benchmark">Benchmark</TabsTrigger>
                  )}
                </TabsList>

                <TabsContent value="pnl">
                  <Card className="flex flex-col justify-between space-y-4 border-gray-200 p-4 shadow-none">
                    <div className="pt-8">
                      <PNLBarChart
                        snapshots={assetLiability.snapshots.day}
                        currency={currency}
                      />
                    </div>

                    <PNLTable
                      data={pnl}
                      isTradable={isTradable}
                      originalCurrency={assetLiability.currency}
                      currencyContext={currencyContext}
                      lastPnl={lastPnl}
                      positiveColor={positiveColor}
                      negativeColor={negativeColor}
                    />
                  </Card>
                </TabsContent>

                <TabsContent value="movement">
                  <Card className="flex flex-col justify-between space-y-4 border-gray-200 p-4 shadow-none">
                    <div className="pt-8">
                      <MovementBarChart
                        snapshots={assetLiability.snapshots.day}
                        currency={currency}
                      />
                    </div>

                    <MovementTable
                      data={movement}
                      isTradable={isTradable}
                      originalCurrency={assetLiability.currency}
                      currencyContext={currencyContext}
                      currentBalance={currentBalance}
                      positiveColor={positiveColor}
                      negativeColor={negativeColor}
                    />
                  </Card>
                </TabsContent>

                <TabsContent value="recentActivity">
                  <Card className="flex flex-col justify-between space-y-4 border-gray-200 p-4 shadow-none">
                    <div className="pt-8">
                      <RecentActivityBarChart
                        snapshots={assetLiability.snapshots.day}
                        currency={currency}
                      />
                    </div>

                    <RecentActivityTable
                      recentActivity={recentActivity}
                      isTradable={isTradable}
                      totalInvested={totalInvested}
                      averageCost={averageCost}
                      originalCurrency={assetLiability.currency}
                      currencyContext={currencyContext}
                      positiveColor={positiveColor}
                      negativeColor={negativeColor}
                      positiveTerm={positiveTerm}
                      negativeTerm={negativeTerm}
                    />
                  </Card>
                </TabsContent>

                {assetLiability.type === "STOCK" &&
                  assetLiability.assetOrLiability === "ASSET" && (
                    <TabsContent value="benchmark">
                      <BenchmarkCard
                        assetLiability={assetLiability}
                        compareSymbol={{
                          symbol: "AMEX:SPY",
                          lineColor: "#4966AF",
                          lineWidth: 2,
                        }}
                      />
                    </TabsContent>
                  )}
              </Tabs>
            </div>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    )
  );
}

const BenchmarkCard: React.FC<{
  assetLiability: AssetLiabilityV2;
  compareSymbol: {
    symbol: string;
    lineColor: string;
    lineWidth: number;
  };
}> = ({ assetLiability, compareSymbol }) => {
  return (
    <Card className="flex flex-col justify-between space-y-4 border-gray-200 p-4 shadow-none">
      <div className="flex flex-row items-center gap-x-2">
        <LineChart className="h-4 w-4 text-gray-500 " />
        <h3 className="text-gray-500">Benchmarking</h3>
      </div>
      <h4>
        Compare <span className="text-darkCyan">{assetLiability.name}</span>{" "}
        with <span className="text-darkBlue">S&P 500 (SPY)</span>
      </h4>
      <CompareSymbolOverview
        symbols={[
          {
            symbol: assetLiability.name,
            interval: "1D",
            name: assetLiability.fullName as string,
          },
        ]}
        compareSymbol={compareSymbol}
      />
    </Card>
  );
};

function getRecentActivity(snapshots: SnapshotV2[]) {
  return snapshots
    .filter((snapshot) => snapshot.quantityChange !== 0)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
}

function getMovement(snapshots: SnapshotV2[]) {
  return snapshots
    .filter((snapshot) => snapshot.unitValueChangePercentage !== 0)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
}

function getPNL(snapshots: SnapshotV2[]) {
  return snapshots
    .filter((snapshot) => snapshot.pnl !== 0)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
}

function calculateSimpleReturns(totalInvested: number, currentBalance: number) {
  return ((currentBalance - totalInvested) / totalInvested) * 100;
}

function getReturnOptions(
  totalInvested: number,
  currencBalance: number,
  holdingPeriodInDays: number,
  simpleReturns: number,
  annualizedReturns: number,
) {
  const returnOptions = [];
  if (simpleReturns) {
    returnOptions.push({
      label: "Simple Returns",
      value: `${simpleReturns.toFixed(2)}%`,
      tooltip:
        "Simple returns show how much money you've made or lost based on your buying and selling activities over a year.",
      formula:
        "\\(\\frac{\\text{Current Portfolio Balance} - \\text{Total Invested Amount}}{\\text{Total Invested Amount}} \\times 100\\)",
      calculation: `\\(\\frac{${currencBalance.toFixed(
        2,
      )} - ${totalInvested.toFixed(2)}}{${totalInvested.toFixed(
        2,
      )}} \\times 100 = ${simpleReturns.toFixed(2)}\\%\\)`,
    });
  }

  if (annualizedReturns) {
    returnOptions.push({
      label: "Annualized Returns",
      value: `${annualizedReturns.toFixed(2)}%`,
      tooltip:
        "Annualized returns show the average yearly profit or loss from your investments, adjusted for the length of time you held them.",
      formula:
        "\\(\\left(\\left(1 + \\frac{\\text{totalReturn}}{\\text{totalInvested}}\\right)^{\\frac{1}{\\text{holdingPeriodInYears}}} - 1\\right) \\times 100\\)",
      calculation: `\\(\\left(\\left(1 + \\frac{\\text{${currencBalance.toFixed(
        2,
      )}}}{\\text{${totalInvested.toFixed(2)}}}\\right)^{\\frac{1}{\\text{${(
        holdingPeriodInDays / 365
      ).toFixed(2)}}}} - 1\\right) \\times 100 = ${annualizedReturns.toFixed(
        2,
      )}\\%\\)`,
    });
  }
  return returnOptions;
}
