import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { queryClient } from "@/lib/queryClient";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { usePostHog } from "posthog-js/react";
import { AssetLiabilityV2 } from "@/lib/types";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics.ts";

export function useMoveAssetsLiabilitiesMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const { mutate: moveAssetsLiabilities, isPending } = useMutation({
    mutationFn: (data: { assetIds: number[]; accountId: number }) =>
      callMoveAssetsLiabilities(data.accountId, data.assetIds, getToken),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });

      const previousData = queryClient.getQueryData([
        GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
      ]);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        (old: AssetLiabilityV2[]) => {
          const account = old.find(
            (assetLiability) => assetLiability.account.id === data.accountId,
          );

          return old.map((assetLiability) =>
            data.assetIds.includes(assetLiability.id)
              ? {
                  ...assetLiability,
                  accountId: data.accountId,
                  account: {
                    id: data.accountId,
                    name: account?.account.name || "",
                    accountSyncTask: account?.account.accountSyncTask || [],
                  },
                }
              : assetLiability,
          );
        },
      );

      return { previousData };
    },
    onSuccess: async ({ assetIds }) => {
      await queryClient.refetchQueries({
        queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
      });
      posthog.capture("move_assets_liabilities_success", {
        assetIds,
      });
    },
    onError: (error, _, context) => {
      console.error(error);
      toast.error("Failed to move assets.");

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });
    },
  });

  return { moveAssetsLiabilities, isPending };
}

async function callMoveAssetsLiabilities(
  accountId: number,
  assetIds: number[],
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.patch(
    `${config.backendUrl}/account/move-assets-liabilities`,
    { accountId, assetIds },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data, assetIds };
}
