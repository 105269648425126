import { handleMonthly } from "@/components/Billing/utils";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToMonthlyCheckout() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  posthog?.capture("clicked_monthly_subscription");
  handleMonthly(getToken);
  return <></>;
}
