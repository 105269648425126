import * as React from "react";

type ProTipProps = {
  title?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  children?: React.ReactNode;
};

const ProTip = React.forwardRef<HTMLDivElement, ProTipProps>(
  (
    {
      title,
      backgroundColor = "bg-gray-100",
      foregroundColor = "text-gray-600",
      children,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={`flex flex-col items-center rounded-md ${backgroundColor} px-6 py-2 ${foregroundColor} `}
      >
        <div className="flex w-full justify-center text-sm font-semibold">
          <span>{title}</span>
        </div>
        <div className="flex flex-col space-y-2 text-sm">{children}</div>
      </div>
    );
  },
);

export { ProTip };
