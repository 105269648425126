import { UnlockInsights } from "@/components/PeekHomePage/UnlockInsights";
import { UnlockedInsights } from "@/components/PeekHomePage/UnlockedInsights";
import {
  useRefreshETFHoldingsAnalysisMutation,
  useRefreshPortfolioAnalysisMutation,
} from "@/hooks/useRefreshAnalysisMutation";
import { useGetProfileQuery } from "@/hooks/useProfile";
import { PeekUserSuccess } from "@/hooks/usePeekUser";
import Loader from "../common/Loader";

export interface InsightsVariables {
  outflow: number;
  inflow: number;
  annualGrowth: number;
  currentAge: number;
  retirementAge: number;
  liquidityPreference: string;
}

export const Insights = (props: {
  totalNetWorth: number | undefined;
  peekUser: PeekUserSuccess;
}) => {
  const { data: profile, status: profileStatus } = useGetProfileQuery();
  const {
    refreshAnalysis: refreshPortfolioAnalysis,
    isPending: isPendingRefreshPortfolioAnalysis,
  } = useRefreshPortfolioAnalysisMutation();
  const {
    refreshAnalysis: refreshETFHoldingsAnalysis,
    isPending: isPendingRefreshETFHoldingsAnalysis,
  } = useRefreshETFHoldingsAnalysisMutation();

  if (profileStatus !== "success") {
    return (
      <div className="flex flex-col items-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {props.totalNetWorth && (
        <div className="flex flex-col items-center justify-between gap-y-2 lg:flex-row">
          <h3 className="text-lg text-gray-700">Insights</h3>
        </div>
      )}
      {!profile || props.peekUser.user.customFields.unlockedInsights ? (
        <UnlockedInsights
          netWorth={props.totalNetWorth ?? 0}
          isPendingRefreshPortfolioAnalysis={isPendingRefreshPortfolioAnalysis}
          isPendingRefreshETFHoldingsAnalysis={
            isPendingRefreshETFHoldingsAnalysis
          }
          onRefreshPortfolioAnalysis={refreshPortfolioAnalysis}
          onRefreshETFHoldingsAnalysis={refreshETFHoldingsAnalysis}
        />
      ) : (
        <UnlockInsights
          netWorth={props.totalNetWorth ?? 0}
          onUnlockInsights={() => {
            props.peekUser.updateUserCustomFields({
              ...props.peekUser.user.customFields,
              unlockedInsights: true,
            });
            refreshPortfolioAnalysis();
            refreshETFHoldingsAnalysis();
          }}
        />
      )}
    </>
  );
};
