import { useState } from "react";
import { format } from "date-fns";
import { CalendarDays } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Profile } from "@/lib/types";
import peekGenderMale from "/images/onboarding/male.svg";
import peekGenderFemale from "/images/onboarding/female.svg";
import preferNotToSay from "/images/onboarding/other-gender.svg";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  currencies,
  Currency,
  getCurrencySymbol,
  useCurrency,
} from "@/hooks/useCurrency";

type StepOneProps = {
  onNext: (data: Profile) => void;
  data: Profile;
};

export function StepOne({ data, onNext }: StepOneProps) {
  const [formData, setFormData] = useState({
    name: data.name,
    birthday: data.birthday,
    gender: data.gender,
    preferredCurrency: data.preferredCurrency,
  });
  const { setCurrency } = useCurrency();

  const updateFormData = (
    field: keyof typeof formData,
    value: string | Date,
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = () => {
    onNext({
      ...formData,
      birthday: formData.birthday,
      preferredCurrency: formData.preferredCurrency as Currency,
    });
  };

  return (
    <>
      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">👋🏻 Name</label>
        <Input
          placeholder="Enter your name"
          className="flex-1"
          defaultValue={formData.name}
          onChange={(e) => updateFormData("name", e.target.value)}
          autoFocus
        />
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">🎂 Birthday</label>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="flex-1">
              <span className="font-medium text-gray-700">
                {formData.birthday
                  ? format(formData.birthday, "d MMM yyyy")
                  : "Select date"}
              </span>
              <CalendarDays className="ml-auto h-4 w-4 text-gray-400" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            align="start"
            side="top"
            className="w-auto bg-white p-0"
          >
            <Calendar
              mode="single"
              disabled={(date) =>
                date > new Date() || date < new Date("1900-01-01")
              }
              initialFocus
              captionLayout="dropdown"
              selected={formData.birthday}
              onSelect={(date) => date && updateFormData("birthday", date)}
              fromYear={1900}
              toYear={new Date().getFullYear()}
              classNames={{
                vhidden: "vhidden hidden",
              }}
              defaultMonth={formData.birthday}
              onMonthChange={(date) => date && updateFormData("birthday", date)}
            />
          </PopoverContent>
        </Popover>
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">🚻 Gender</label>
        <div className="flex flex-1 flex-row items-center gap-x-2">
          <button
            className={cn(
              "w-full rounded-md border border-gray-200 p-3 text-left",
              formData.gender === "male" && "bg-beige",
            )}
            onClick={() => updateFormData("gender", "male")}
          >
            <img src={peekGenderMale} alt="peek" className="mb-3 size-6" />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "male" && "font-semibold",
              )}
            >
              Male
            </span>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-gray-200 p-3 text-left",
              formData.gender === "female" && "bg-beige",
            )}
            onClick={() => updateFormData("gender", "female")}
          >
            <img src={peekGenderFemale} alt="peek" className="mb-3 size-6" />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "female" && "font-semibold",
              )}
            >
              Female
            </span>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-gray-200 p-3 text-left",
              formData.gender === "preferNotToSay" && "bg-beige",
            )}
            onClick={() => updateFormData("gender", "preferNotToSay")}
          >
            <img src={preferNotToSay} alt="peek" className="mb-3 size-6" />
            <span
              className={cn(
                "font-medium text-gray-600",
                formData.gender === "preferNotToSay" && "font-semibold",
              )}
            >
              Prefer not to say
            </span>
          </button>
        </div>
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">
          💱 Preferred currency
        </label>
        <Select
          onValueChange={(value) => {
            updateFormData("preferredCurrency", value);
            setCurrency(value as Currency);
          }}
          defaultValue={formData.preferredCurrency}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent align="end">
            {currencies.map((c: Currency) => (
              <SelectItem key={c} value={c}>
                <div className="flex gap-x-1">
                  <span>{getCurrencySymbol(c)}</span>
                  {c}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Button
        variant="default"
        className="mt-4 self-end"
        onClick={handleNext}
        disabled={!Object.values(formData).every(Boolean)}
      >
        Next
      </Button>
    </>
  );
}
