import axios from "@/lib/axios";
import config from "@/lib/config";
import {
  GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
  GET_HOLDINGS_QUERY_KEY,
} from "@/lib/constants";
import { queryClient } from "@/lib/queryClient";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics.ts";

const handleFlanks = async (
  code: string,
  getToken: (options?: { template: string }) => Promise<string | null>,
  version: number,
) => {
  const token = await getToken();

  if (!token) return;

  const promise = axios
    .post(
      `${config.integrationsUrl}/flanks/add-account-sync-task`,
      { linkCode: code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(async () => {
      // refetch account info
      if (version === 1) {
        await queryClient.refetchQueries({
          queryKey: [GET_HOLDINGS_QUERY_KEY],
        });
      } else if (version === 2) {
        await Promise.all([
          queryClient.refetchQueries({
            queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
          }),
          queryClient.refetchQueries({
            queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
          }),
        ]);
      }
    });

  toast.promise(promise, {
    loading: `Connecting to your account...`,
    success: `Successfully connected to your account! Starting to import your data...`,
    error: "Oops! Something went wrong. Please retry.",
  });
};

// the code can only be used once, so avoid calling it multiple times
const debouncedFlanks = debounce(handleFlanks, 1000);

export function FlanksNewCredentials() {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  if (!code) {
    return <div>Error connecting to Flanks</div>;
  }
  posthog?.capture(`flanks_connected`);
  debouncedFlanks(code, getToken, 2);
  navigate("/");
}
