import { createContext, useContext } from "react";
import { useGetSubscriptionsQuery } from "./useGetSubscriptionsQuery";
import { differenceInDays, formatDistanceToNow } from "date-fns";

type SubscriptionsLoading = {
  state: "LOADING";
};

type SubscriptionsError = {
  state: "ERROR";
  message?: string;
};

export type SubscriptionsSuccess = {
  state: "SUCCESS";
  trialEndTimestampInMs: number | null;
  activeSubscriptionEndTimestampInMs: number | null;
  signedUpForTrial: boolean;
  trialStillValid: boolean;
  trialDaysLeft: number;
  trialTimeLeftString: string;
  hasActiveSubscription: boolean;
  subscribed: boolean;
  blockUser: boolean;
  cancelAtPeriodEnd: boolean;
};

type SubscriptionsType =
  | SubscriptionsLoading
  | SubscriptionsError
  | SubscriptionsSuccess;

const Subscriptions = createContext<SubscriptionsType>({
  state: "LOADING",
});

export function SubscriptionsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    cancelAtPeriodEnd,
    trialEndTimestamp,
    activeSubscriptionEndTimestamp,
    subscribed,
    isLoading,
    isError,
    error,
  } = useGetSubscriptionsQuery();

  if (isLoading) {
    return (
      <Subscriptions.Provider value={{ state: "LOADING" }}>
        {children}
      </Subscriptions.Provider>
    );
  }

  if (isError) {
    return (
      <Subscriptions.Provider
        value={{
          state: "ERROR",
          message:
            error?.message ??
            "An unexpected error occured. Please try again later.",
        }}
      >
        {children}
      </Subscriptions.Provider>
    );
  }

  const now = Date.now();
  const signedUpForTrial = !!trialEndTimestamp;
  const trialStillValid = signedUpForTrial && now < trialEndTimestamp * 1000;
  const trialDaysLeft = trialStillValid
    ? differenceInDays(trialEndTimestamp * 1000, now)
    : 0;
  const trialTimeLeftString =
    trialStillValid && trialEndTimestamp
      ? formatDistanceToNow(trialEndTimestamp * 1000)
      : "---";
  const hasActiveSubscription = !!activeSubscriptionEndTimestamp;
  const blockUser = !trialStillValid && !hasActiveSubscription;

  return (
    <Subscriptions.Provider
      value={{
        state: "SUCCESS",
        trialEndTimestampInMs: trialEndTimestamp
          ? trialEndTimestamp * 1000
          : null,
        activeSubscriptionEndTimestampInMs: activeSubscriptionEndTimestamp
          ? activeSubscriptionEndTimestamp * 1000
          : null,
        signedUpForTrial,
        trialStillValid,
        trialDaysLeft,
        trialTimeLeftString,
        hasActiveSubscription,
        blockUser,
        subscribed: subscribed || false,
        cancelAtPeriodEnd: cancelAtPeriodEnd || false,
      }}
    >
      {children}
    </Subscriptions.Provider>
  );
}

export function useSubscriptions() {
  return useContext(Subscriptions);
}
