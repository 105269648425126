import { z } from "zod";
import { isPublicMarketOrCrypto } from "@/lib/uploadAssets.util";
import { accountType, assetType } from "@/components/AddAsset/schema";

export const quickPasteSchema = z
  .object({
    accountName: z.string(),
    accountType: accountType,
    assetName: z.string(),
    assetType: assetType,
    fullName: z.string().optional(),
    currency: z.string().min(3, "Currency must have at least 3 characters"),
    quantity: z.coerce.number({
      invalid_type_error: "Quantity must be a number",
    }),
    unitValue: z.coerce.number({
      invalid_type_error: "Unit Value must be a number",
    }),
  })
  // check validation for unitValue other assetType with refine()
  .refine(
    (data) => isPublicMarketOrCrypto(data.assetType) || data.unitValue > 0,
    {
      message: "Unit Value must be greater than 0",
    },
  )
  .transform((data) => {
    return {
      ...data,
      quantity: ["LOAN", "CREDIT_CARD"].includes(data.accountType)
        ? -Math.abs(data.quantity)
        : Math.abs(data.quantity),
    };
  });
