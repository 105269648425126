import { Link, Outlet } from "react-router-dom";
import logoFull from "/images/logo-full.svg";
import logoSmall from "/images/logo-small.svg";
import { Button } from "@/components/ui/button";

// Only Desktop layout support at the moment thus the min-width
export function PublicPubLayout() {
  return (
    <div className="flex h-full flex-col overflow-auto bg-offwhite antialiased">
      <div className="flex-1 shrink-0 rounded-t-2xl bg-background px-4 pb-24 pt-6 sm:px-10">
        <header className="w-full py-2">
          <div className="mx-auto flex w-full min-w-min items-center md:max-w-7xl">
            <Link
              to="https://peek.money"
              className="mr-auto font-libre text-2xl font-bold"
            >
              <img src={logoFull} alt="logo" className="hidden h-8 md:block" />
              <img src={logoSmall} alt="logo" className="h-8 md:hidden" />
            </Link>
            <nav className="hidden flex-1 justify-center gap-x-8 sm:flex">
              <Link to="https://peek.money">Home</Link>
              <Link to="https://peek.money/#pricing">Pricing</Link>
            </nav>
            <Button
              onClick={() => {
                window.open("https://form.typeform.com/to/L8zZgj9r", "_blank");
              }}
            >
              Join Waitlist
            </Button>
          </div>
        </header>
        <main className="mx-auto h-full w-full md:min-w-min md:max-w-7xl">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
