import { FeedMetadata } from "@knocklabs/client";
import { formatBadgeCount, useKnockFeed } from "@knocklabs/react-core";
import React from "react";
import { Badge } from "./badge";
import { Bell } from "lucide-react";

export type BadgeCountType = "unseen" | "unread" | "all";

export type UnseenBadgeProps = {
  badgeCountType?: BadgeCountType;
};

function selectBadgeCount(
  badgeCountType: BadgeCountType,
  metadata: FeedMetadata,
) {
  switch (badgeCountType) {
    case "all":
      return metadata.total_count;
    case "unread":
      return metadata.unread_count;
    case "unseen":
      return metadata.unseen_count;
  }
}

export const UnseenBadge: React.FC<UnseenBadgeProps> = ({
  badgeCountType = "unseen",
}) => {
  const { useFeedStore } = useKnockFeed();
  const badgeCountValue = useFeedStore((state) =>
    selectBadgeCount(badgeCountType, state.metadata),
  );

  return (
    <div className="relative inline-block">
      <Bell className="h-4 w-4" />
      {badgeCountValue !== 0 && (
        <Badge
          variant="destructive"
          className="grow-0.5 absolute right-0 top-0 h-4 -translate-y-1/2 translate-x-1/2 transform p-0 px-1 text-[10px]"
        >
          {formatBadgeCount(badgeCountValue)}
        </Badge>
      )}
    </div>
  );
};
