import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import {
  addAssetFormSchema,
  editAssetFormSchema,
} from "../components/AddAsset/schema";
import { usePostHog } from "posthog-js/react";

// Quick Paste component requires the creation of multiple accounts and the updating of multiple assets.
// We won't add optimistic updates as this feature might be removed in the future.
// This hook is specifically used for the quick paste feature and may be deprecated later.
export function useQuickPasteAssetMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const { mutateAsync: addAssetAsync, isPending: isPendingAdd } = useMutation({
    mutationFn: (input: z.infer<typeof addAssetFormSchema>) =>
      callAddAsset(input, getToken),
    onSuccess: ({ input }) => {
      const changes = input.assetLiabilities.map((asset) => {
        return {
          currency: asset.currency,
          fullname: asset.fullName ?? "",
          name: asset.name,
          type: asset.type,
        };
      });

      posthog.capture("submit_assets_liabilities_form_success", {
        ...input,
        assetLiabilities: changes,
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { mutateAsync: updateAssetAsync, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: (input: z.infer<typeof editAssetFormSchema>[]) =>
        callUpdateAsset(input, getToken),
      onSuccess: ({ input }) => {
        const captureChanges = (
          asset: z.infer<typeof editAssetFormSchema>,
        ) => ({
          currency: asset.currency,
          fullname: asset.fullName ?? "",
          name: asset.name,
          type: asset.type,
        });

        input.forEach((asset) => {
          posthog.capture("submit_assets_liabilities_form_success", {
            ...asset,
            assetLiabilities: captureChanges(asset),
          });
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });

  return {
    addAssetAsync,
    updateAssetAsync,
    isPending: isPendingUpdate || isPendingAdd,
  };
}

async function callAddAsset(
  input: z.infer<typeof addAssetFormSchema>,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  const { data } = await axios.post(
    `${config.backendUrl}/user/account`,
    input,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data, input };
}

async function callUpdateAsset(
  input: z.infer<typeof editAssetFormSchema>[],
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  const response = await axios.patch(
    `${config.backendUrl}/user/assetsliabilities`,
    input,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return {
    data: response.data,
    input,
  };
}
