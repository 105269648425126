import {
  CHART_RADIAL_GOOD,
  CHART_RADIAL_NORMAL,
  CHART_RADIAL_WARNING,
} from "@/lib/constants";
interface RadialProgressProps {
  progress: number;
  size: number;
  text: string;
  strokeWidth: number;
  dashArray: string;
  overlayCircleColor?: string;
}

const RadialProgress: React.FC<RadialProgressProps> = ({
  progress = 0,
  size,
  text,
  strokeWidth,
  dashArray,
  overlayCircleColor = "#fff",
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (circumference * progress) / 100;

  let status: "normal" | "warning" | "good";
  if (progress < 30) {
    status = "warning";
  } else if (progress < 70) {
    status = "normal";
  } else {
    status = "good";
  }

  const getColorBasedOnStatus = (status: "normal" | "warning" | "good") => {
    switch (status) {
      case "warning":
        return {
          circleOneStroke: CHART_RADIAL_WARNING[1],
          circleTwoStroke: CHART_RADIAL_WARNING[0],
        };
      case "good":
        return {
          circleOneStroke: CHART_RADIAL_GOOD[1],
          circleTwoStroke: CHART_RADIAL_GOOD[0],
        };
      default:
        return {
          circleOneStroke: CHART_RADIAL_NORMAL[1],
          circleTwoStroke: CHART_RADIAL_NORMAL[0],
        };
    }
  };

  const { circleOneStroke, circleTwoStroke } = getColorBasedOnStatus(status);

  return (
    <div className="relative flex items-center justify-center">
      <svg className="-rotate-90 transform" width={size} height={size}>
        {/* Background circle */}
        <circle
          stroke={circleOneStroke}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        {/* Progress circle */}
        <circle
          stroke={circleTwoStroke}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="butt"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        {/* Dash circle */}
        <circle
          stroke={overlayCircleColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          strokeLinecap="butt"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-2xl">{text}</span>
      </div>
    </div>
  );
};

export default RadialProgress;
