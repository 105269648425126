import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import {
  ArrowDownWideNarrow,
  ArrowUpDown,
  ArrowUpNarrowWide,
  icons,
} from "lucide-react";
import { AccountSyncTaskIntegrator, AssetLiabilityV2 } from "@/lib/types";
import { columns } from "./columns";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import config from "@/lib/config";
import { queryClient } from "@/lib/queryClient";
import { toast } from "sonner";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { groupDataByOverview, isAccountRow } from "@/lib/portfolio.utils";
import { cn } from "@/lib/utils";
import { PortfolioTableRow } from "./types";
import { debounce } from "lodash";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";
import { useDeleteAccountMutation } from "@/hooks/useDeleteAccountMutation";
import { useColumnVisibility } from "@/hooks/useColumnVisilibility";
import { useDialog } from "@/hooks/useDialog";
import { InlineEdit } from "./InlineEdit";
import { DetailsSheet } from "../../Performance/DetailsSheet";
import { AssetLiabilityOptions } from "./AssetLiabilityOptions";
import { useSyncTasks } from "@/hooks/useSyncTasks";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";
import { ColumnVisibilityPopup } from "@/components/Portfolio/Table/ColumnVisibilityPopup";
import { AccountNameInlineEdit } from "@/components/Portfolio/Table/AccountNameInlineEdit";

type PortfolioTableProps = {
  data: ReturnType<typeof groupDataByOverview>["assets" | "liabilities"];
  tableType: "assets" | "liabilities";
  overviewBy: string;
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
  setOpenEndowus: Dispatch<
    SetStateAction<{ isOpen: boolean; accountId?: number }>
  >;
  setOpenStashAway: Dispatch<
    SetStateAction<{ isOpen: boolean; accountId?: number }>
  >;
  setOpenEmereg: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      customLink: string;
      accountId?: number;
    }>
  >;
  handleDeleteAssetsLiabilities: (ids: number[]) => void;
  handleReloginBasedOnIntegrator: (
    accountId: number,
    integrator: AccountSyncTaskIntegrator,
  ) => void;
  handleSync: (
    accountId: number,
    accountName: string,
    integrator?: AccountSyncTaskIntegrator,
  ) => void;
  uniqueAccountNames: string[];
  assetTotalValue: number;
  liabilityTotalValue: number;
  columnVisibility: ReturnType<typeof useColumnVisibility>;
};

export function PortfolioTable({
  data,
  tableType,
  overviewBy,
  rowSelection,
  setRowSelection,
  uniqueAccountNames,
  handleSync,
  handleDeleteAssetsLiabilities,
  handleReloginBasedOnIntegrator,
  assetTotalValue,
  liabilityTotalValue,
  columnVisibility,
}: PortfolioTableProps) {
  const { getToken } = useAuth();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { syncingAccountIds } = useSyncTasks();
  const [editingRow, setEditingRow] = useState<AssetLiabilityV2 | null>(null);
  const [editingAccount, setEditingAccount] = useState<{
    id: number;
    rowId: string;
    name: string;
  } | null>(null);
  const [lastInteraction, setLastInteraction] = useState<"mouse" | "keyboard">(
    "keyboard",
  );
  const [assetLiabilityDetails, setAssetLiabilityDetails] =
    useState<AssetLiabilityV2 | null>(null);

  const { showShortcutToast } = useShortcutPrompt();
  const { deleteAccount } = useDeleteAccountMutation();
  const { isAnyDialogOpen, setDialogOpen } = useDialog();

  const parentRef = useRef<HTMLDivElement>(null);
  const selectedRowRef = useRef<string | null>(null);

  const {
    visibleColumns,
    setVisibleColumns,
    toggleColumnVisibility,
    showAll,
    hideAll,
  } = columnVisibility;

  const handleAssetLiabilityDetailsSheetOpen = useCallback(
    (data: AssetLiabilityV2) => {
      setAssetLiabilityDetails(data);
      setDialogOpen("assetLiabilityDetails", true);
    },
    [setAssetLiabilityDetails, setDialogOpen],
  );

  const handleDisconnect = useCallback(
    async (accountId: number, accountName: string) => {
      const token = await getToken();
      const promise = axios
        .delete(
          `${config.integrationsUrl}/user/integrated-account/${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async () => {
          await Promise.all([
            queryClient.refetchQueries({
              queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
            }),
            queryClient.refetchQueries({
              queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
            }),
          ]);
        });
      toast.promise(promise, {
        loading: `Disconnecting ${accountName}...`,
        success: `Disconnected ${accountName}`,
        error: `Failed to disconnect ${accountName}. Contact support if this continues.`,
        duration: 3000,
        action: {
          label: "Support",
          onClick: () => {
            const email = "support@peek.money";
            const subject = encodeURIComponent(
              "[Request] Disconnect integration",
            );
            const body = encodeURIComponent(
              `Hello, I would like to disconnect my account: ${accountName}. Please assist me with this request.`,
            );
            window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
          },
        },
      });
    },
    [getToken],
  );

  const table = useReactTable({
    data,
    columns: columns({
      overviewBy,
      handleSync,
      handleRelogin: handleReloginBasedOnIntegrator,
      syncingAccountIds,
      handleAssetLiabilityDetailsSheetOpen,
    }),
    autoResetExpanded: false,
    enableRowSelection: true,
    enableSubRowSelection: (row) =>
      row.original.account.type !== "INTEGRATED_ACCOUNT",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    onRowSelectionChange: setRowSelection,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setVisibleColumns,
    getRowId: (row, index) => `${row.id}|${index}`,
    getSubRows: (row) => {
      if (row.rowType === "section" || row.rowType === "subSection") {
        return row.subRows;
      }
      return [];
    },
    state: {
      expanded,
      sorting,
      rowSelection,
      columnVisibility: visibleColumns,
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 56, // Adjust this based on your row height
    overscan: 15,
    scrollMargin: parentRef.current?.offsetTop ?? 0,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize() + 48;

  const handleExpandRowWithDebounce = debounce((row) => {
    if (row.getCanExpand()) {
      row.getToggleExpandedHandler()();
    }
  }, 300);

  // We have debounce here so that "e" does not get inserted into the first selected input box
  const handleEditMode = debounce((row: Row<PortfolioTableRow>) => {
    // cancel expanding
    handleExpandRowWithDebounce.cancel();

    if (row.original.rowType === "asset") {
      setEditingAccount(null);
      setEditingRow(row.original);
    }

    if (row.original.rowType !== "asset" && isAccountRow(row.id)) {
      setEditingRow(null);
      setEditingAccount({
        rowId: row.id.toString(),
        id: row.original.account.id,
        name: row.original.account.name,
      });
    }
  }, 50);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;

      // only override interaction if there are no other dialogs above
      if (isAnyDialogOpen) {
        return;
      }

      // Make sure that the global shortcuts are not interfering with user inputs
      const isInputFocused =
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable;

      if (isInputFocused) return;

      // We are now in keyboard mode and prioritise kb interactions
      setLastInteraction("keyboard");

      const row = rows.find((row) => row.id === selectedRowRef.current);

      const selectedRow =
        document.querySelector(`tr[data-id="${selectedRowRef.current}"]`) ||
        (parentRef.current?.querySelector("tbody tr") as HTMLTableRowElement);

      if (event.key.toLowerCase() === "j" || event.key === "ArrowDown") {
        selectedRow.setAttribute("aria-selected", "false");

        if (selectedRowRef.current) {
          const nextSibling =
            selectedRow.nextElementSibling as HTMLTableRowElement;
          if (nextSibling) {
            selectedRowRef.current = nextSibling.getAttribute("data-id");
            nextSibling.setAttribute("aria-selected", "true");
          }
        } else {
          // Select first row if none selected
          const firstRow = parentRef.current?.querySelector(
            "tbody tr",
          ) as HTMLTableRowElement;

          if (firstRow) {
            selectedRowRef.current = firstRow.getAttribute("data-id");
            firstRow.setAttribute("aria-selected", "true");
          }
        }
      } else if (event.key.toLowerCase() === "k" || event.key === "ArrowUp") {
        selectedRow.setAttribute("aria-selected", "false");

        if (selectedRowRef.current) {
          const prevSibling =
            selectedRow.previousElementSibling as HTMLTableRowElement;
          if (prevSibling) {
            selectedRowRef.current = prevSibling.getAttribute("data-id");
            prevSibling.setAttribute("aria-selected", "true");
          }
        } else {
          // Select first row if none selected
          const firstRow = parentRef.current?.querySelector(
            "tbody tr",
          ) as HTMLTableRowElement;

          if (firstRow) {
            selectedRowRef.current = firstRow.getAttribute("data-id");
            firstRow.setAttribute("aria-selected", "true");
          }
        }
      } else if (event.key.toLowerCase() === "x") {
        if (row) {
          if (
            row.original.account.type === "INTEGRATED_ACCOUNT" &&
            row.original.rowType === "asset"
          )
            return; // Don't let users select assets that are part of an INTEGRATED_ACCOUNT

          row.toggleSelected();
        }
      } else if (event.key === "Escape") {
        // Deselect row if it is selected
        if (row?.getIsSelected()) {
          row.toggleSelected();
        } else {
          if (row?.getIsExpanded()) row.toggleExpanded();
        }

        const tr = document.querySelector(
          `tr[data-id="${row?.id}"]`,
        ) as HTMLTableRowElement;
        tr?.classList.add("bg-gray-100");
      } else if (event.key === "Enter") {
        if (row) {
          // Only expand if user is not in edit mode
          if (!editingRow && !editingAccount) {
            row.getToggleExpandedHandler()();

            if (row.original.rowType === "asset") {
              event.preventDefault();
              // handleAssetLiabilityDetailsSheetOpen({ row });
            }
          }
        }
      } else if (event.key.toLowerCase() === "e") {
        if (row) {
          handleEditMode(row);
        }
      }
    },
    [
      isAnyDialogOpen,
      rows,
      editingRow,
      editingAccount,
      // handleAssetLiabilityDetailsSheetOpen,
      handleEditMode,
    ],
  );

  const handleMouseMove = useCallback(() => {
    setLastInteraction("mouse");
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    // We use this to listen to mouse events to see whether user is in kb mode or in mouse mode
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleKeyDown, handleMouseMove]);

  useEffect(() => {
    rows.forEach((row) => {
      const allSubRowsSelected =
        row.subRows.length > 0 &&
        row.subRows.every(({ getIsSelected }) => getIsSelected());
      if (allSubRowsSelected) {
        row.toggleSelected(true);
      }
    });
  }, [rows]);

  return (
    <div
      ref={parentRef}
      className="overflow-y-hidden rounded-b-sm border-gray-200 bg-white"
    >
      <div
        style={{
          height: `${totalSize}px`,
        }}
      >
        <Table className="table-auto">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="h-12 bg-border/50">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        header.id === "actions" && "w-12",
                        header.column.getCanSort() &&
                          "cursor-pointer select-none",
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={cn(
                            "flex items-center justify-end",
                            (header.column.id === "asset" ||
                              header.column.id === "unitValue") &&
                              "justify-start",
                          )}
                        >
                          <span className="flex items-center gap-x-1">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.id === "asset" &&
                              `${
                                tableType.charAt(0).toUpperCase() +
                                tableType.slice(1)
                              }`}
                            {header.column.id === "percentageHoldings" &&
                              ` of ${tableType}`}
                          </span>
                          {header.column.id !== "actions" &&
                            ({
                              false: (
                                <ArrowUpDown
                                  size={14}
                                  color="darkGray"
                                  className="ml-2"
                                />
                              ),
                              asc: (
                                <ArrowUpNarrowWide size={14} className="ml-2" />
                              ),
                              desc: (
                                <ArrowDownWideNarrow
                                  size={14}
                                  className="ml-2"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ??
                              null)}
                        </div>
                      )}
                    </TableHead>
                  );
                })}
                <TableHead className="text-right">
                  <ColumnVisibilityPopup
                    visibleColumns={visibleColumns}
                    mandatoryVisibleColumns={["asset", "value"]}
                    toggleColumnVisibility={toggleColumnVisibility}
                    hideAll={hideAll}
                    showAll={showAll}
                    icons={icons}
                  />
                </TableHead>
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {rows?.length ? (
              virtualRows.map((virtualRow, index) => {
                const row = rows[virtualRow.index];

                if (
                  row.original.rowType !== "asset" &&
                  editingAccount?.id === row.original.account.id &&
                  editingAccount?.rowId === row.id.toString()
                ) {
                  return (
                    <AccountNameInlineEdit
                      key={row.id}
                      cancel={() => setEditingAccount(null)}
                      accountName={editingAccount.name}
                      accountId={editingAccount.id}
                      uniqueAccountNames={uniqueAccountNames}
                      table={table}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${
                          virtualRow.start -
                          index * virtualRow.size - // Make sure to use index instead of virtualRow.index
                          rowVirtualizer.options.scrollMargin
                        }px)`,
                      }}
                    />
                  );
                } else if (editingRow?.id === row.original.id) {
                  return (
                    <InlineEdit
                      key={row.original.id}
                      asset={editingRow as AssetLiabilityV2}
                      cancel={() => setEditingRow(null)}
                      table={table}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${
                          virtualRow.start -
                          index * virtualRow.size -
                          rowVirtualizer.options.scrollMargin
                        }px)`,
                      }}
                    />
                  );
                } else {
                  return (
                    <CustomTableRow
                      data-id={row.id}
                      key={`${row.original.id}|${index}`}
                      row={row}
                      syncingIds={syncingAccountIds}
                      setEditingRow={setEditingRow}
                      setEditingAccount={setEditingAccount}
                      handleDisconnect={handleDisconnect}
                      handleDeleteAssetsLiabilities={
                        handleDeleteAssetsLiabilities
                      }
                      handleDeleteAccount={deleteAccount}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${
                          virtualRow.start -
                          index * virtualRow.size -
                          rowVirtualizer.options.scrollMargin
                        }px)`,
                      }}
                      onMouseEnter={() => {
                        if (
                          lastInteraction === "mouse" &&
                          !editingAccount &&
                          !editingRow
                        ) {
                          // Remove Previous Selected Row hover
                          const selectedRow = document.querySelector(
                            `tr[data-id="${selectedRowRef.current}"]`,
                          ) as HTMLTableRowElement;
                          selectedRow &&
                            selectedRow.setAttribute("aria-selected", "false");

                          selectedRowRef.current = row.id;

                          const tr = document.querySelector(
                            `tr[data-id="${row.id}"]`,
                          );
                          tr?.setAttribute("aria-selected", "true");
                        }
                      }}
                      onMouseLeave={() => {
                        // Remove Previous Selected Row hover
                        const selectedRow = document.querySelector(
                          `tr[data-id="${selectedRowRef.current}"]`,
                        ) as HTMLTableRowElement;
                        selectedRow &&
                          selectedRow.setAttribute("aria-selected", "false");

                        selectedRowRef.current = null;

                        const tr = document.querySelector(
                          `tr[data-id="${row.id}"]`,
                        );

                        tr?.setAttribute("aria-selected", "false");
                      }}
                      onClick={(event) => {
                        const target = event.target as HTMLElement;
                        if (target.tagName.toLowerCase() === "button") {
                          // Do not take any action if the click was on a button / checkbox
                          return;
                        }
                        if (isAccountRow(row.id)) {
                          handleExpandRowWithDebounce(row);
                        } else {
                          showShortcutToast({
                            shortcutKey: "Enter",
                            requiresCmdKey: false,
                            template: "You can expand this using {shortcutKey}",
                          });
                          row.getToggleExpandedHandler()();
                        }
                      }}
                      onDoubleClick={() => {
                        showShortcutToast({
                          shortcutKey: "E",
                          requiresCmdKey: false,
                          template:
                            "Next time, try hitting {shortcutKey} to edit",
                        });
                        handleEditMode(row);
                      }}
                    />
                  );
                }
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {assetLiabilityDetails && (
        <DetailsSheet
          assetLiability={assetLiabilityDetails}
          assetTotalValue={assetTotalValue}
          liabilityTotalValue={liabilityTotalValue}
        />
      )}
    </div>
  );
}

function CustomTableRow({
  row,
  syncingIds,
  setEditingRow,
  setEditingAccount,
  handleDisconnect,
  handleDeleteAssetsLiabilities,
  handleDeleteAccount,
  ...props
}: {
  row: Row<PortfolioTableRow>;
  syncingIds: number[];
  setEditingRow: (value: AssetLiabilityV2) => void;
  setEditingAccount: (value: {
    id: number;
    rowId: string;
    name: string;
  }) => void;
  handleDisconnect: (accountId: number, accountName: string) => void;
  handleDeleteAssetsLiabilities: (ids: number[]) => void;
  handleDeleteAccount: (accountId: number) => void;
} & React.HTMLAttributes<HTMLTableRowElement>) {
  const isSyncing =
    row.original.account.id &&
    syncingIds.includes(row.original.account.id) &&
    row.original.account.type !== "INTEGRATED_ACCOUNT";
  const isAsset = row.original.rowType === "asset";

  return (
    <TableRow
      {...props}
      className={cn(
        "group cursor-pointer table-row-hover aria-selected:bg-gray-100 [&:not(:last-child)]:shadow-line border-b-0",
        isSyncing && "opacity-50 pointer-events-none",
        isAsset && "align-top sm:align-middle",
        props.className,
      )}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell
          key={cell.id}
          className={cn(
            cell.column.id === "percentageHoldings" &&
              (isAsset ? "min-w-40 max-w-40 w-40" : "min-w-40"),
          )}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
      <TableCell>
        <AssetLiabilityOptions
          rowId={row.id}
          rowOriginal={row.original}
          setEditingRow={setEditingRow}
          setEditingAccount={setEditingAccount}
          handleDisconnect={handleDisconnect}
          handleDeleteAssetsLiabilities={handleDeleteAssetsLiabilities}
          handleDeleteAccount={handleDeleteAccount}
        />
      </TableCell>
    </TableRow>
  );
}
