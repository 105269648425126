import { CURRENCY_KEY, DEFAULT_CURRENCY } from "@/lib/constants";
import { createContext, useContext, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useQuery } from "@tanstack/react-query";
import axios from "../lib/axios";
import config from "../lib/config";
import { usePostHog } from "posthog-js/react";
import { usePeekUser } from "./usePeekUser";

export type Currency =
  | "SGD"
  | "USD"
  | "INR"
  | "GBP"
  | "JPY"
  | "AUD"
  | "EUR"
  | "SEK"
  | "CHF"
  | "HKD"
  | "TWD"
  | "CAD"
  | "IDR"
  | "MYR"
  | "PHP"
  | "THB"
  | "VND"
  | "KRW";

export const currencies: Currency[] = [
  "SGD",
  "USD",
  "INR",
  "GBP",
  "JPY",
  "AUD",
  "EUR",
  "SEK",
  "CHF",
  "HKD",
  "TWD",
  "CAD",
  "IDR",
  "MYR",
  "PHP",
  "THB",
  "VND",
  "KRW",
];

export function getCurrencySymbol(currency: Currency) {
  const currencySymbols = {
    SGD: "🇸🇬",
    USD: "🇺🇸",
    INR: "🇮🇳",
    GBP: "🇬🇧",
    JPY: "🇯🇵",
    AUD: "🇦🇺",
    EUR: "🇪🇺",
    SEK: "🇸🇪",
    CHF: "🇨🇭",
    HKD: "🇭🇰",
    TWD: "🇹🇼",
    CAD: "🇨🇦",
    IDR: "🇮🇩",
    MYR: "🇲🇾",
    PHP: "🇵🇭",
    THB: "🇹🇭",
    VND: "🇻🇳",
    KRW: "🇰🇷",
  };
  return currencySymbols[currency] || "🇸🇬";
}

export function getLocaleForCurrency(currency: Currency) {
  const currencyToLocaleMap = {
    SGD: "en-SG",
    USD: "en-US",
    INR: "en-IN",
    GBP: "en-GB",
    JPY: "ja-JP",
    AUD: "en-AU",
    EUR: "en-EU",
    SEK: "sv-SE",
    CHF: "de-CH",
    HKD: "zh-HK",
    TWD: "zh-TW",
    CAD: "en-CA",
    IDR: "id-ID",
    MYR: "ms-MY",
    PHP: "en-PH",
    THB: "th-TH",
    VND: "vi-VN",
    KRW: "ko-KR",
  };

  return currencyToLocaleMap[currency] || "en-SG";
}

type ExchangeRatesBaseUSDType = { [key: string]: number };

type CurrencyContextType =
  | {
      state: "SUCCESS";
      currency: Currency;
      setCurrency: (currency: Currency) => void;
      convertValueToSelectedCurrency: (
        value: number,
        from: string,
        to?: string,
      ) => number;
      exchangeRatesBaseUSD: ExchangeRatesBaseUSDType;
    }
  | {
      state: "LOADING";
      currency: Currency;
      setCurrency: (currency: Currency) => void;
    }
  | {
      state: "ERROR";
      currency: Currency;
      setCurrency: (currency: Currency) => void;
      message?: string;
    };

const CurrencyContext = createContext<CurrencyContextType>({
  state: "LOADING",
  currency: DEFAULT_CURRENCY,
  setCurrency: () => void 0,
});

export function useCurrency() {
  return useContext(CurrencyContext);
}

export function CurrencyProvider({ children }: { children: React.ReactNode }) {
  const [currency, setCurrency] = useLocalStorage<Currency>(
    CURRENCY_KEY,
    DEFAULT_CURRENCY,
  );

  const posthog = usePostHog();
  const peekUserContext = usePeekUser();

  useEffect(() => {
    posthog?.capture("changed_currency", {
      currency,
    });
  }, [currency, posthog]);

  // Get all exchange rates with base currency as USD (Can't change this in free plan)
  const {
    data: exchangeRatesBaseUSD,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["exchangeRates"],
    queryFn: fetchExchangeRate,
    // To prevent extra calls, set staleTime to Infinity
    staleTime: Infinity,
    enabled: peekUserContext.state === "SUCCESS",
  });

  if (isLoading)
    return (
      <CurrencyContext.Provider
        value={{
          state: "LOADING",
          currency,
          setCurrency,
        }}
      >
        {children}
      </CurrencyContext.Provider>
    );

  if (isError)
    return (
      <CurrencyContext.Provider
        value={{
          state: "ERROR",
          message: "Failed to fetch exchange rates",
          currency,
          setCurrency,
        }}
      >
        {children}
      </CurrencyContext.Provider>
    );

  // Utility function to convert value from one currency to another, optional to param which defaults to state currency
  function convertValueToSelectedCurrency(
    value: number,
    from: string,
    to = currency as string,
  ) {
    if (from === to) return value;
    let valueInUSD = value; // from === "USD"
    if (from === "GBp") {
      // convert to Pence to Pounds to dividing 100, then divide by USD/GBP rate
      valueInUSD = value / 100 / (exchangeRatesBaseUSD?.["GBP"] ?? 1); // default to 1:1 when currency not found or not loaded
    } else if (from !== "USD") {
      valueInUSD = value / (exchangeRatesBaseUSD?.[from] ?? 1); // default to 1:1 when currency not found or not loaded
    }

    if (to === "GBp") {
      // multiply by 100 to get the Pence rate
      return valueInUSD * 100 * (exchangeRatesBaseUSD?.["GBP"] ?? 1); // default to 1:1 when currency not found or not loaded
    }
    return valueInUSD * (exchangeRatesBaseUSD?.[to] ?? 1); // default to 1:1 when currency not found or not loaded
  }

  return (
    <CurrencyContext.Provider
      value={{
        state: "SUCCESS",
        currency,
        setCurrency,
        convertValueToSelectedCurrency,
        exchangeRatesBaseUSD: exchangeRatesBaseUSD as ExchangeRatesBaseUSDType,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
}

// Get all exchange rates with base currency as USD
async function fetchExchangeRate() {
  const { data } = await axios.get<{
    [key: string]: number;
  }>(`${config.backendUrl}/exchange`);

  return data;
}
