import { useState } from "react";
import { PerformanceTable } from "./Table/PerformanceTable";
import { AssetLiabilityV2 } from "@/lib/types";

type AssetFilterProps = {
  grouping: "day" | "week" | "month";
  data: AssetLiabilityV2[];
  assetTotalValue: number;
  liabilityTotalValue: number;
};

export function AssetFilter({
  grouping,
  data,
  assetTotalValue,
  liabilityTotalValue,
}: AssetFilterProps) {
  const [filter, setFilter] = useState("");

  const filteredData = data.filter(
    (asset) =>
      asset.assetOrLiability === "ASSET" &&
      (asset.name.toLowerCase().includes(filter.toLowerCase()) ||
        asset.fullName?.toLowerCase().includes(filter.toLowerCase())),
  );

  return (
    <div className="mt-5">
      <input
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search for an asset"
        className="w-full rounded-sm border border-gray-100 bg-white px-2 py-1 focus:border-gray-400 focus:outline-none"
      />

      <PerformanceTable
        data={filteredData}
        grouping={grouping}
        assetTotalValue={assetTotalValue}
        liabilityTotalValue={liabilityTotalValue}
      />
    </div>
  );
}
