import { Currency } from "@/hooks/useCurrency";

export interface Goal {
  name: string;
  targetNetWorth: number;
  targetDate: Date;
  goalOptionIndex?: number;
}

export interface Profile {
  // Initial
  name?: string;
  birthday?: Date;
  gender?: string;
  preferredCurrency?: Currency;

  // Family Details
  maritalStatus?: string;
  numberOfDependents?: number;

  // Employment and Savings
  occupation?: string;
  income?: number;
  incomeFrequency?: string;
  approximateSavingsRate?: number;

  // Estimated Net Worth
  estimatedNetWorth?: number;

  // Financial Profile
  financialLiteracy?: string;
  riskTolerance?: string;
}

export type AccountType =
  | "ALTERNATIVE_INVESTMENT"
  | "BANK_ACCOUNT"
  | "COLLECTIBLE"
  | "CRYPTO"
  | "INSURANCE"
  | "OTHER"
  | "PRECIOUS_METAL"
  | "PRIVATE_COMPANY"
  | "PUBLIC_MARKET"
  | "REAL_ESTATE"
  | "RETIREMENT_FUND"
  | "ROBO_ACCOUNT"
  | "CREDIT_CARD"
  | "LOAN"
  | "INTEGRATED_ACCOUNT";

export type Snapshot = {
  id: number;
  timestamp: string;
  quantity: number;
  unitValue: number;
  originalUnitValue: number;
  assetLiabilityId: number;
  dayStamp: string;
  weekStamp: string;
  monthStamp: string;
};

export type AssetLiability = {
  id: number;
  createdAt: string;
  updatedAt: string;
  tag: string | null;
  name: string;
  fullName?: string;
  type: string;
  currency: string;
  accountId: number;
  snapshots: Snapshot[];
  company?: Company;
  subSectionName?: string;
  accountName?: string;
  accountType?: AccountType;
  accountSyncTaskIntegrator?: AccountSyncTaskIntegrator;
  accountSyncTaskStatus?: AccountSyncTaskStatus;
  accountSyncTaskUpdatedAt?: Date;
  isIncludedInNWCalculation: boolean;
  isLiquid: boolean;
};

export type AssetLiabilityV2 = {
  accountId: number;
  assetClass: string;
  assetOrLiability: string;
  company?: Company;
  companyId: string | null;
  createdAt: string;
  currency: string;
  fullName: string | null;
  id: number;
  isIncludedInNWCalculation: boolean;
  isLiquid: boolean;
  name: string;
  securityId: string | null;
  subAccountId: string | null;
  subAccountName: string | null;
  tag: string | null;
  type: string;
  updatedAt: string;
  account: AccountV2;
  snapshots: {
    day: SnapshotV2[];
    week: SnapshotV2[];
    month: SnapshotV2[];
  };
  percentageHolding?: number;
};

export type AccountV2 = {
  id: number;
  name: string;
  type: string;
  accountSyncTask: AccountSyncTask[];
};

export type SnapshotV2 = {
  assetLiabilityId: number;
  averageCost: number;
  capitalFlow: number;
  pnl: number;
  pnlChange: number;
  pnlChangePercentage: number;
  pnlPercentage: number;
  prevPnl: number;
  prevQuantity: number;
  prevTotalValue: number;
  prevUnitValue: number;
  quantity: number;
  quantityChange: number;
  quantityChangePercentage: number;
  timestamp: string;
  totalCapitalInvested: number;
  totalValue: number;
  totalValueChange: number;
  totalValueChangePercentage: number;
  unitValue: number;
  unitValueChange: number;
  unitValueChangePercentage: number;
  original?: {
    pnl: number;
    prevPnl: number;
    unitValue: number;
    unitValueChange: number;
    totalValue: number;
    prevTotalValue: number;
    prevUnitValue: number;
    averageCost: number;
    capitalFlow: number;
    pnlChange: number;
    totalCapitalInvested: number;
    totalValueChange: number;
  };
};

export type Company = {
  name: string;
  fullName: string;
  description: string;
  logo: string;
  website: string;
  sector: string;
  industry: string;
  country: string;
  exchange: string;
  exchangeShortName: string;
  isin: string;
};

export type AccountSyncTaskIntegrator =
  | "PLAID"
  | "FLANKS"
  | "ENDOWUS"
  | "STASHAWAY"
  | "EMEREG";

export type AccountSyncTaskStatus =
  | "PENDING"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "FAILED"
  | "CANCELLED"
  | "EXPIRED"
  | "INTEGRATOR_NOT_READY";

export type AccountSyncTask = {
  integrator: AccountSyncTaskIntegrator;
  status: AccountSyncTaskStatus;
  updatedAt: string;
};

export type KnockSyncTask = AccountSyncTask & {
  id: number;
  account: {
    id: number;
    name: string;
  };
};

export type Account = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: AccountType;
  ownerId: number;
  assetLiabilities: AssetLiability[];
  accountSyncTask: AccountSyncTask[];
};

export type GetAssetsResponse = Account[];

export type CompareWithOption = "week" | "4weeks" | "6months" | "12months";

export type GetHoldingsQueryParams = {
  interval: "day" | "week" | "month";
  count: number;
};

export type PeekUser = {
  id: string;
  email: string;
  name: string;
  stripeCustomerId: string;
  // Custom fields is not enforced on the backend so these fields are purely for client typing
  customFields: {
    ghostBuster?: boolean;
    keyboardMaster1?: boolean;
    keyboardMaster2?: boolean;
    keyboardMaster3?: boolean;
    keyboardMaster4?: boolean;
    finishedOnboarding?: boolean;
    unlockedInsights?: boolean;
    iAmDone?: boolean;
  };
};

export const assetRequiresQuantity = (assetType: string) =>
  ![
    "CASH",
    "FIXED_DEPOSIT",
    "ANGEL_INVESTMENT",
    "HEDGE_FUND",
    "PRIVATE_CREDIT",
    "PRIVATE_EQUITY",
    "LIFE_INSURANCE",
    "REAL_ESTATE",
    "SG_CPF_OA",
    "SG_CPF_RA",
    "SG_CPF_SA",
    "SG_CPF_MA",
    "SG_SRS",
    "US_401K",
    "US_IRA",
    "US_529",
    "ROBO",
  ].includes(assetType);

export type UserAggregatedMetric = {
  date: string;
  totalNetWorth: number;
  liquidTotalNetWorth: number;
  illiquidTotalNetWorth: number;
  investibleCash: number;
  currency: string;
};
