import config from "@/lib/config";
import axios, { AxiosError, AxiosResponse } from "axios";

class EmeregAPIClient {
  private sessionId: string = "";
  private headers: { [key: string]: string } = {};

  async setClerkHeaders(getClerkToken: () => Promise<string | null>) {
    const token = await getClerkToken();
    this.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  async createSession(
    username: string,
    password: string,
  ): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/session`,
        { username, password },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        this.sessionId = response.data.session_id;
        return response;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          throw new Error("Invalid username or password");
        } else {
          console.error(error.message);
          throw new Error(
            "Something went wrong. Please try again for up to 3 times. If the issue persists, please contact support@peek.money.",
          );
        }
      });
  }

  async checkAuth(): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/auth`,
        { sessionId: this.sessionId.toString() },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          throw new Error("You rejected the 2FA request on your iBanking app.");
        } else if (error.response?.status === 504) {
          throw new Error(
            "The 2FA request on your iBanking app expired. Go back to login and try again.",
          );
        } else {
          console.error(error.message);
          throw new Error(
            "Something went wrong during the 2FA step. Go back to login and try again. If you have a DBS Treasures account, please note that it is not supported yet. Register your interest with support@peek.money to be notified when it is supported.",
          );
        }
      });
  }

  async getAccounts(): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/accounts/new`,
        { sessionId: this.sessionId.toString() },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
        throw new Error("Failed to get accounts info from DBS");
      });
  }

  async updateAccounts(accountId: number): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/accounts/update`,
        { sessionId: this.sessionId.toString(), accountId: Number(accountId) },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
        throw new Error("Failed to update accounts info from DBS");
      });
  }

  async getHoldings(): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/holdings/new`,
        { sessionId: this.sessionId.toString() },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
        throw new Error("Failed to get holdings info from DBS Vickers");
      });
  }

  async updateHoldings(accountId: number): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/dbs/holdings/update`,
        { sessionId: this.sessionId.toString(), accountId: Number(accountId) },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
        throw new Error("Failed to update holdings info from DBS Vickers");
      });
  }
}

export default EmeregAPIClient;
