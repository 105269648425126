import {
  ArrowUpRight,
  BellIcon,
  Cable,
  ReceiptText,
  Shield,
  SquareSlash,
  UserIcon,
} from "lucide-react";
import { Dialog, DialogContent } from "../ui/dialog";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { Separator } from "../ui/separator";
import { useDialog } from "@/hooks/useDialog";
import { useEffect } from "react";

const NavLink = ({
  to,
  icon: Icon,
  label,
  isActive,
}: {
  to: string;
  icon: React.ElementType;
  label: string;
  isActive: boolean;
}) => (
  <Link
    className={`text-gray-90 flex items-center gap-3 rounded-md px-3 py-2 text-sm font-medium ${
      isActive ? "bg-beige" : ""
    }`}
    to={to}
  >
    <Icon className="h-5 w-5" />
    {label}
  </Link>
);

export const ExternalLink = ({
  to,
  icon: Icon,
  label,
}: {
  to: string;
  icon: React.ElementType;
  label: string;
}) => (
  <Link
    to={to}
    className="flex items-center gap-3 rounded-md px-3 py-2 text-sm font-medium text-gray-700 transition-colors hover:bg-beige"
    target="_blank"
  >
    <Icon className="h-5 w-5" />
    <span className="flex-1">{label}</span>
    <ArrowUpRight className="h-5 w-5" />
  </Link>
);

export function SettingsLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setDialogOpen } = useDialog();

  useEffect(() => {
    setDialogOpen("settings", true);
  }, [setDialogOpen]);

  return (
    <Dialog
      defaultOpen
      onOpenChange={(open) => {
        setDialogOpen("settings", open);
        if (!open) navigate("/");
      }}
    >
      <DialogContent className="w-[800px] max-w-[90vw] rounded-lg p-0">
        <div className="grid h-[600px] max-h-[90vh] grid-cols-[220px_1fr]">
          <div className="overflow-hidden rounded-l-lg border-r bg-offwhite px-4">
            <div className="flex h-full flex-col">
              <div className="flex h-16 items-center justify-between border-b">
                <h3 className="text-lg font-medium">Settings</h3>
              </div>

              <div className="flex flex-col">
                <nav className="flex-1 space-y-1 py-4">
                  <NavLink
                    to=""
                    icon={UserIcon}
                    label="Profile"
                    isActive={location.pathname === "/preferences"}
                  />
                  <NavLink
                    to="notifications"
                    icon={BellIcon}
                    label="Notifications"
                    isActive={
                      location.pathname === "/settings/" ||
                      location.pathname === "/preferences/notifications"
                    }
                  />
                  <NavLink
                    to="integrations"
                    icon={Cable}
                    label="Integrations"
                    isActive={
                      location.pathname === "/settings/integrations" ||
                      location.pathname === "/preferences/integrations"
                    }
                  />
                </nav>
                <Separator />
                <nav className="flex-1 space-y-1 py-4">
                  <NavLink
                    to="security"
                    icon={Shield}
                    label="Security & Privacy"
                    isActive={
                      location.pathname === "/settings/security" ||
                      location.pathname === "/preferences/security"
                    }
                  />
                  <NavLink
                    to="shortcuts"
                    icon={SquareSlash}
                    label="Shortcuts"
                    isActive={
                      location.pathname === "/settings/shortcuts" ||
                      location.pathname === "/preferences/shortcuts"
                    }
                  />
                  <ExternalLink
                    to="/billing-portal"
                    icon={ReceiptText}
                    label="Billing"
                  />
                </nav>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto p-8">
            <div className="space-y-8">
              <Outlet />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
