import Loader from "@/components/common/Loader";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";

const sha256sum = async (text: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export function AdminPage() {
  const [buttonLabel, setButtonLabel] = useState("Copy to clipboard");
  const [whitelisted, setWhitelisted] = useState(false);
  const [userId, setUserId] = useState("");
  const { getToken } = useAuth();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["peekUser", userId],
    queryFn: async () => {
      const token = await getToken();
      const { data } = await axios.get(
        `${config.backendUrl}/admin/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    enabled: userId.length === 32,
    retry: false,
  });

  const { isSignedIn, userId: loggedInUserId } = useAuth();
  const navigate = useNavigate();

  type AccountSyncIssue = {
    id: number;
    createdAt: string;
    updatedAt: string;
    integrator: string;
    itemId: string;
    accessToken: string;
    accountId: number;
    status: string;
    account: {
      id: number;
      name: string;
      owner: {
        id: string;
        email: string;
        snaptradeUserSecret: string;
      };
    };
  };

  const admins = [
    "1051cee4e43b1a79740ed91ae9260ca267106adb4d5aeeb4cfb3149fd3e947c8",
    "1d27606259264ca77758c269aafea882759bb41c6ea516c18e4d13d977045b56",
    "f85c523cd71400f6e6b037d91ee58cd43508b0f169ba2bc7e39236b435d163f8",
    "20f66e589d43c5899b0b6c04c70e9e4476a37d9eda655495d754dd7171a420e5",
    "d2cf4422884d1a813bfa89ec35803f4a3041a46add289ecd8713d51cb878726b",
    "12b8f9ac118e532c8a8e0a4d3693fea6ec56a100c0d048d4461f6ae33707d8c6",
  ];

  const {
    data: syncIssues,
    isLoading: isSyncIssuesLoading,
    isError: isSyncIssuesError,
  } = useQuery({
    queryKey: ["accountSyncIssues"],
    queryFn: async () => {
      const token = await getToken();
      const { data } = await axios.get(
        `${config.backendUrl}/admin/account-sync-tasks/issues`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    enabled: whitelisted,
  });

  useEffect(() => {
    loggedInUserId &&
      sha256sum(loggedInUserId).then((hashOfLoggedInUserId) => {
        setWhitelisted(admins.includes(hashOfLoggedInUserId));
      });
  }, [loggedInUserId]);

  if (!isSignedIn) {
    navigate("/sign-in");
    return null;
  }

  if (!whitelisted) {
    return <ErrorPage />;
  } else {
    return (
      <div className="flex w-full max-w-3xl flex-col justify-center space-y-3">
        <h2 className="text-xl font-bold">User Lookup</h2>
        <Input
          id="name"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          className="h-8 flex-1 rounded-md px-3"
        />
        {isError && <div>Error fetching user data</div>}
        {isLoading && <Loader />}
        {data && (
          <>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(data, null, 2));
                setButtonLabel("Copied!");
                setTimeout(() => setButtonLabel("Copy to clipboard"), 2000);
              }}
            >
              {buttonLabel}
            </Button>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </>
        )}

        <h2 className="mt-8 text-xl font-bold">Account Sync Issues</h2>
        {isSyncIssuesError && <div>Error fetching sync issues</div>}
        {isSyncIssuesLoading && <Loader />}
        {syncIssues && (
          <div className="max-w-2xl">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>User ID</TableHead>
                  <TableHead>Account ID</TableHead>
                  <TableHead>Account Name</TableHead>
                  <TableHead>Integrator</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Item ID</TableHead>
                  <TableHead>Access Token</TableHead>
                  <TableHead>Snaptrade User Secret</TableHead>
                  <TableHead>Created At</TableHead>
                  <TableHead>Updated At</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {syncIssues.map((issue: AccountSyncIssue) => (
                  <TableRow key={issue.id}>
                    <TableCell>{issue.id}</TableCell>
                    <TableCell>{issue.account.owner.email}</TableCell>
                    <TableCell>{issue.account.owner.id}</TableCell>
                    <TableCell>{issue.account.id}</TableCell>
                    <TableCell>{issue.account.name}</TableCell>
                    <TableCell>{issue.integrator}</TableCell>
                    <TableCell>
                      {(issue.status === "EXPIRED" ||
                        issue.status === "FAILED") && (
                        <Badge variant="destructive">{issue.status}</Badge>
                      )}
                      {issue.status === "CANCELLED" && (
                        <Badge variant="default">{issue.status}</Badge>
                      )}
                      {issue.status === "PENDING" && (
                        <Badge variant="outline">{issue.status}</Badge>
                      )}
                      {(issue.status === "IN_PROGRESS" ||
                        issue.status === "INTEGRATOR_NOT_READY") && (
                        <Badge variant="orange">{issue.status}</Badge>
                      )}
                    </TableCell>
                    <TableCell>{issue.itemId}</TableCell>
                    <TableCell>{issue.accessToken}</TableCell>
                    <TableCell>
                      {issue.account.owner.snaptradeUserSecret}
                    </TableCell>
                    <TableCell>
                      {new Date(issue.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(issue.updatedAt).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {issue.status === "FAILED" && (
                        <Button
                          variant="outline"
                          onClick={async () => {
                            try {
                              await axios.get(
                                `${config.integrationsUrl}/tasks/sync/${issue.account.id}`,
                              );
                            } catch (error) {
                              console.error("Error retrying sync:", error);
                            }
                          }}
                        >
                          Retry
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}
