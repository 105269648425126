import { CornerDownLeft } from "lucide-react";
import { forwardRef, HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

// Styled enter key that is hidden in mobile view
export const EnterKey = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => {
  return (
    <span
      {...props}
      ref={ref}
      className={cn(
        "border border-background ml-2 p-1 rounded-sm aspect-square w-5 h-5 hidden items-center justify-center md:flex",
        className,
      )}
    >
      <CornerDownLeft />
    </span>
  );
});
