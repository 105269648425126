import { ChartData, Chart as ChartJS, ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import { useCurrency } from "@/hooks/useCurrency";
import iconConcierge from "/images/icon-concierge.svg";
import peekpedia from "/images/icon-peekpedia.svg";
import { HelpCircle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { shortNumber } from "@/lib/utils";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../ui/hover-card";

type HistoryChartProps = {
  data: ChartData<"bar", number[], string>;
  type: "assets" | "liabilities";
};

export function PortfolioChart({ data, type }: HistoryChartProps) {
  const { currency } = useCurrency();

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: ChartJS) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;
      if (chart.getActiveElements().length > 0) {
        const xCoor = x.getPixelForValue(
          tooltip?.dataPoints[0]?.dataIndex ?? 0,
        );

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = "black";
        ctx.moveTo(xCoor, top);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  const parseHistoryDataWithoutLiabilities = (
    data: ChartData<"bar", number[], string>,
  ) => {
    // Remove datasets with negative total values
    const filteredDatasets = data.datasets.filter((dataset) => {
      const totalValue = dataset.data.reduce((sum, value) => sum + value, 0);
      if (type === "assets") {
        return totalValue >= 0;
      }
      return totalValue <= 0;
    });

    // Create a new data object with filtered datasets
    const filteredData = {
      ...data,
      datasets: filteredDatasets,
    };

    const totalsByDay = filteredData.datasets[0].data.map((_, index) =>
      filteredData.datasets.reduce(
        (sum, dataset) => sum + Math.abs(dataset.data[index]),
        0,
      ),
    );

    const percentageDatasets = filteredData.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, index) =>
        ((Math.abs(value) / totalsByDay[index]) * 100).toFixed(2),
      ),
    }));

    return {
      ...data,
      datasets: percentageDatasets,
    };
  };

  // TODO: find better way to pass currency variable to chart options.
  const options: ChartOptions = {
    animation: {
      duration: 400, // Duration of the animation in milliseconds
      easing: "easeOutCubic",
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: 8,
      },
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'Manrope', sans-serif",
          size: 18,
        },
        bodyFont: {
          family: "'Manrope', sans-serif",
          size: 14,
          lineHeight: 1.4,
        },
        footerFont: {
          family: "'Manrope', sans-serif",
        },
        callbacks: {
          label: (context) => {
            const value = context.formattedValue;
            return `${
              context.dataset.label
            }: ${value}% (${currency} ${shortNumber(
              data.datasets[context.datasetIndex].data[context.dataIndex],
            )})`;
          },
        },
        cornerRadius: 4,
        caretSize: 0,
        padding: 12,
      },
      legend: {
        position: "bottom" as const,
        align: "center" as const,
        display: true,
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            // Use the default implementation for all datasets
            const labels = ChartJS.defaults.plugins.legend.labels
              .generateLabels(chart)
              .map((label) => {
                if (label.text === "Total") {
                  return {
                    ...label,
                    pointStyle: "circle" as const,
                  };
                }
                return {
                  ...label,
                  pointStyle: "circle" as const,
                };
              });
            return labels;
          },
        },
      },
    },
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Allocation Breakdown in %",
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
        },
        position: "left" as const,
        stacked: true,
        min: 0, // Set the minimum value to 0
        max: 100,
        ticks: {
          callback: function (value) {
            return `${value}%`;
          },
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
          stepSize: 20,
        },
      },
    },
  };

  const parsedData = parseHistoryDataWithoutLiabilities(data);

  const chartData = {
    labels: parsedData.labels,
    datasets: parsedData.datasets,
    amountDataset: data.datasets,
  };

  return (
    <div className="flex flex-col space-y-4 p-8">
      <div className="flex flex-row items-center">
        <h3 className="font-medium text-gray-700">Allocation Chart</h3>

        <HoverCard openDelay={300}>
          <HoverCardTrigger asChild>
            <HelpCircle
              size={16}
              className="ml-2 text-gray-500 hover:cursor-pointer"
            />
          </HoverCardTrigger>
          <HoverCardContent className="w-96">
            <div className="flex flex-col justify-between space-y-4 text-sm">
              <div className="flex flex-row items-center gap-x-2">
                <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                <h4 className="font-semibold">How it works</h4>
              </div>
              <div className="flex flex-col space-y-2">
                <p>
                  Allocation charts show how your assets or liabilities are
                  distributed across different asset classes or accounts.
                </p>
                <p>
                  These charts help you visualize your financial portfolio's
                  composition and balance, allowing you to make informed
                  decisions about diversification and risk management.
                </p>
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      </div>
      <Separator />
      <div className="h-[500px] p-8">
        <Chart
          type="line"
          options={options}
          data={chartData}
          id="chart"
          plugins={[hoverLine]}
        />
      </div>
      <div className="flex items-center space-x-4 rounded-md border p-4">
        <img src={iconConcierge} alt="concierge" className="h-6 w-6" />
        <div className="flex-1 space-y-1">
          <p className="text-sm font-medium leading-none text-gray-700">
            Did you know?
          </p>
          <p className="text-sm text-gray-500">
            You can click on the labels to filter the chart.
          </p>
        </div>
      </div>
    </div>
  );
}
