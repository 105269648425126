import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import crypto from "/images/automaticConnections/crypto.svg";
import { Separator } from "@/components/ui/separator";
import { useDialog } from "@/hooks/useDialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import Loader from "@/components/common/Loader";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { Badge } from "@/components/ui/badge";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export default function ZerionDialog() {
  const { isDialogOpen, setDialogOpen } = useDialog();
  const faq = [
    {
      question: "Which wallets are supported?",
      answer:
        "All crypto wallets on the supported chains will work. This includes wallets like MetaMask, Coinbase Wallet, Trust Wallet, and more.",
    },
    {
      question: "Is Solana supported?",
      answer: "It will be supported in the future.",
    },
    {
      question: "Can Peek track positions in various Defi protocols?",
      answer:
        "Yes, Peek can track positions in various DeFi protocols. This includes yield farming, lending, borrowing, and more.",
    },
    {
      question: "Can Peek touch my money on my crypto wallet?",
      answer:
        "No, Peek only has read-only access to your crypto wallet data. That means we cannot create withdrawal requests or funds transfers on your behalf.",
    },
  ];
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [walletAddress, setWalletAddress] = useState<string>("");

  useEffect(() => {
    // Clear error message whenever there is a loading
    if (loading) {
      setMessage("");
    }
  }, [loading]);

  const importWallet = async (address: string) => {
    const token = await getToken();
    if (!token) throw new Error("User not authenticated");

    setLoading(true);

    try {
      // Create the sync task
      const promise = axios
        .post(
          `${config.integrationsUrl}/zerion/add-account-sync-task`,
          { walletAddress: address },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async () => {
          setDialogOpen("zerion", false);
          setWalletAddress("");

          await Promise.all([
            queryClient.refetchQueries({
              queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
            }),
            queryClient.refetchQueries({
              queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
            }),
          ]);
        });
      toast.promise(promise, {
        loading: `Connecting to ${address}...`,
        success: `Successfully connected to ${address}! Starting to import your data...`,
        error: "Oops! Something went wrong. Please retry.",
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setMessage(`${error.response.data.message}`);
      } else {
        setMessage(`${String(error)}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isDialogOpen("zerion")}
      onOpenChange={(open) => setDialogOpen("zerion", open)}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex flex-col gap-8">
            <img
              src={crypto}
              alt="CryptoWalet"
              className="size-16 rounded-md bg-offwhite p-1"
            />
            Track Your Crypto Wallet
          </DialogTitle>
          <DialogDescription>
            Track your direct crypto holdings and positions across various DeFi
            protocols, including yield farming, lending, and more.
          </DialogDescription>
        </DialogHeader>
        <h2 className="text-sm text-gray-500">Supported Chains</h2>
        <div className="flex flex-wrap gap-1">
          <Badge variant="secondary">Ethereum</Badge>
          <Badge variant="secondary">Polygon</Badge>
          <Badge variant="secondary">Arbitrum</Badge>
          <Badge variant="secondary">Optimism</Badge>
          <Badge variant="secondary">Blast</Badge>
          <Badge variant="secondary">Base</Badge>
          <Badge variant="secondary">Avalanche</Badge>
          <Badge variant="secondary">Binance Smart Chain</Badge>
          <Badge variant="secondary">Fantom</Badge>
          <Badge variant="secondary">Scroll</Badge>
          <Badge variant="secondary">zkSync</Badge>
          <Badge variant="secondary">Zora</Badge>
          <Badge variant="secondary">Solana (Soon)</Badge>
        </div>
        <Input
          placeholder="Enter your crypto wallet address"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
        {message && <div className="text-sm text-red-500">{message}</div>}
        {!loading ? (
          <Button
            className="w-full"
            type="submit"
            onClick={() => importWallet(walletAddress)}
          >
            Import
          </Button>
        ) : (
          <Loader />
        )}
        <Separator />
        <Accordion type="single" collapsible>
          {faq.map((item, index) => (
            <AccordionItem
              key={index}
              value={index.toString()}
              className="text-sm"
            >
              <AccordionTrigger className="text-left text-gray-700">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-500">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
