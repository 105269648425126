import interactiveBrokers from "/images/automaticConnections/interactive-brokers.svg";
import saxo from "/images/automaticConnections/saxo.svg";
import vanguard from "/images/automaticConnections/vanguard.svg";
import citi from "/images/automaticConnections/citi.svg";
import robinhoodGreen from "/images/automaticConnections/robinhood-green.svg";
import wealthfront from "/images/automaticConnections/wealthfront.svg";
import chase from "/images/automaticConnections/chase.svg";
import schwab from "/images/automaticConnections/schwab.svg";
import tdAmeritrade from "/images/automaticConnections/td-ameritrade.svg";
import binance from "/images/automaticConnections/binance.svg";
import coinbase from "/images/automaticConnections/coinbase.svg";
import etoro from "/images/automaticConnections/etoro.svg";
import ig from "/images/automaticConnections/ig.svg";
import endowus from "/images/automaticConnections/endowus.svg";
import stashAway from "/images/automaticConnections/stashaway.svg";
import dbs from "/images/automaticConnections/dbs-bank.svg";
import dbsVickers from "/images/automaticConnections/dbs-vickers.svg";
import crypto from "/images/automaticConnections/crypto.svg";
import zerodha from "/images/automaticConnections/zerodha.svg";
import fidelity from "/images/automaticConnections/fidelity.svg";
import trading212 from "/images/automaticConnections/trading212.svg";
import degiro from "/images/automaticConnections/degiro.svg";
import commsec from "/images/automaticConnections/commsec.svg";
import stake from "/images/automaticConnections/stake.svg";
import others from "/images/logo-small.svg";

import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Badge } from "@/components/ui/badge";

interface AutomaticConnectionItem {
  title: string;
  description?: string;
  icon: string;
  integrator:
    | "PLAID"
    | "FLANKS"
    | "SGFINDEX"
    | "CUSTOM"
    | "EMEREG"
    | "SNAPTRADE"
    | "ZERION";
  customLink: string;
}

const automaticConnectionItems: AutomaticConnectionItem[] = [
  {
    title: "Endowus",
    icon: endowus,
    integrator: "CUSTOM",
    customLink: "endowus",
  },
  {
    title: "StashAway",
    icon: stashAway,
    integrator: "CUSTOM",
    customLink: "stashaway",
  },
  {
    title: "DBS Bank",
    icon: dbs,
    integrator: "EMEREG",
    customLink: "dbs",
  },
  // Disable IBKR for now until we fix the issue with integration
  // {
  //   title: "IBKR - Interactive Brokers Global",
  //   icon: interactiveBrokers,
  //   integrator: "FLANKS",
  //   customLink: "interactivebrokers",
  // },
  {
    title: "Crypto Wallets",
    description:
      "Supported: Ethereum, Polygon, Arbitrum, Optimism, Base, Avalanche, Solana (soon), and more.",
    icon: crypto,
    integrator: "ZERION",
    customLink: "crypto",
  },
  {
    title: "Coinbase",
    icon: coinbase,
    integrator: "SNAPTRADE",
    customLink: "COINBASE",
  },
  {
    title: "Saxo",
    icon: saxo,
    integrator: "FLANKS",
    customLink: "saxobank",
  },
  {
    title: "Charles Schwab",
    icon: schwab,
    integrator: "SNAPTRADE",
    customLink: "SCHWAB",
  },
  {
    title: "Citibank",
    icon: citi,
    integrator: "PLAID",
    customLink: "citi",
  },
  {
    title: "Chase",
    icon: chase,
    integrator: "PLAID",
    customLink: "chase",
  },
  {
    title: "Vanguard",
    icon: vanguard,
    integrator: "SNAPTRADE",
    customLink: "VANGUARD",
  },
  {
    title: "Zerodha",
    icon: zerodha,
    integrator: "SNAPTRADE",
    customLink: "ZERODHA",
  },
  {
    title: "Binance",
    icon: binance,
    integrator: "SNAPTRADE",
    customLink: "BINANCE",
  },
  {
    title: "DBS Vickers",
    icon: dbsVickers,
    integrator: "EMEREG",
    customLink: "dbs-vickers",
  },
  {
    title: "Interactive Brokers (US)",
    icon: interactiveBrokers,
    integrator: "PLAID",
    customLink: "ibkr",
  },
  {
    title: "Robinhood",
    icon: robinhoodGreen,
    integrator: "SNAPTRADE",
    customLink: "ROBINHOOD",
  },
  {
    title: "Wealthfront",
    icon: wealthfront,
    integrator: "PLAID",
    customLink: "wealthfront",
  },
  {
    title: "TD Ameritrade",
    icon: tdAmeritrade,
    integrator: "PLAID",
    customLink: "td_ameritrade",
  },
  {
    title: "TD Ameritrade - Thinkorswim",
    icon: tdAmeritrade,
    integrator: "PLAID",
    customLink: "td_ameritrade",
  },
  {
    title: "eToro",
    icon: etoro,
    integrator: "FLANKS",
    customLink: "etoro",
  },
  {
    title: "IG",
    icon: ig,
    integrator: "FLANKS",
    customLink: "ig",
  },
  {
    title: "Fidelity",
    icon: fidelity,
    integrator: "SNAPTRADE",
    customLink: "FIDELITY",
  },
  {
    title: "Stake Australia",
    icon: stake,
    integrator: "SNAPTRADE",
    customLink: "STAKEAUS",
  },
  {
    title: "CommSec",
    icon: commsec,
    integrator: "SNAPTRADE",
    customLink: "COMMSEC",
  },
  {
    title: "Degiro",
    icon: degiro,
    integrator: "SNAPTRADE",
    customLink: "DEGIRO",
  },
  {
    title: "Trading212",
    icon: trading212,
    integrator: "SNAPTRADE",
    customLink: "TRADING212",
  },
  {
    title: "Others",
    icon: others,
    integrator: "PLAID",
    // this will cause an empty string to be passed to API, which will show the default link
    customLink: "default",
  },
];

const getIntegratorName = (integrator: string) => {
  switch (integrator) {
    case "PLAID":
      return "Plaid";
    case "FLANKS":
      return "Flanks";
    case "SGFINDEX":
      return "SGFinDex";
    case "CUSTOM":
      return "Custom";
    case "EMEREG":
      return "Custom";
    case "SNAPTRADE":
      return "SnapTrade";
    case "ZERION":
      return "Zerion";
  }
};

type AutomaticConnectionsListProps = {
  openPlaid: (plaidCustomLink: string) => void;
  openFlanks: (bankId: string, bankName: string) => void;
  openCustom: (customLink: string) => void;
  openEmereg: (customLink: string) => void;
  openSGFinDex: (orgName: string) => void;
  openZerion: () => void;
  openSnaptrade: (broker: string) => void;
};

// <span className="block border-b pb-2 text-xs text-gray-400">Popular</span>
export function AutomaticConnectionsList({
  openPlaid,
  openFlanks,
  openCustom,
  openSGFinDex,
  openEmereg,
  openZerion,
  openSnaptrade,
}: AutomaticConnectionsListProps) {
  return (
    <CommandList>
      <CommandEmpty>No results found.</CommandEmpty>
      <CommandGroup>
        {automaticConnectionItems.map((item, index) => (
          <CommandItem
            key={index}
            onSelect={() => {
              if (item.integrator === "PLAID") {
                openPlaid(item.customLink);
              } else if (item.integrator === "FLANKS") {
                openFlanks(item.customLink, item.title);
              } else if (item.integrator === "CUSTOM") {
                openCustom(item.customLink);
              } else if (item.integrator === "SGFINDEX") {
                openSGFinDex(item.customLink);
              } else if (item.integrator === "EMEREG") {
                openEmereg(item.customLink);
              } else if (item.integrator === "ZERION") {
                openZerion();
              } else if (item.integrator === "SNAPTRADE") {
                openSnaptrade(item.customLink);
              }
            }}
            className="mb-2 hover:cursor-pointer hover:bg-gray-100"
          >
            <div className="flex w-full items-center gap-x-2 rounded-md px-3">
              <img
                src={item.icon}
                alt={item.title}
                className="size-8 rounded-md bg-offwhite p-1"
              />

              <div className="flex w-full flex-col">
                <span className="text-sm font-medium text-gray-600">
                  {item.title}
                </span>
                {item.description && (
                  <span className="text-sm font-normal text-gray-400">
                    {item.description}
                  </span>
                )}
              </div>
              <Badge variant="outlineGray" className="text-right">
                <span className="text-gray-400">
                  via {getIntegratorName(item.integrator)}
                </span>
              </Badge>
            </div>
          </CommandItem>
        ))}
      </CommandGroup>
    </CommandList>
  );
}
