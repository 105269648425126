import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_PROFILE_QUERY_KEY } from "@/lib/constants";
import { Profile } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { usePeekUser } from "./usePeekUser";

export function useGetProfileQuery() {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();
  const { data, isLoading, isError, error, status } = useQuery({
    queryKey: [GET_PROFILE_QUERY_KEY],
    queryFn: () => getProfile(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });
  return { data, isLoading, isError, error, status };
}

async function getProfile(
  getToken: (options?: { template: string }) => Promise<string | null>,
): Promise<Profile | null> {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<Profile | null>(
    `${config.backendUrl}/profile`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}
