import { useState } from "react";
import { InputRowData } from "./types";
import {
  convertToAccountData,
  findExistingAssetIdWithRow,
  getValidTableData,
  mergeSameAsset,
  transformArray,
} from "@/lib/addAssets.utils";
import { quickPasteSchema } from "./schema";
import { Button } from "../../ui/button";
import { Separator } from "../../ui/separator";
import { InvalidTable } from "./InvalidTable";
import { ValidTable } from "./ValidTable";
import { Progress } from "@/components/ui/progress";
import { toast } from "sonner";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { queryClient } from "@/lib/queryClient";
import {
  excelColumnHeaders,
  getAssetUnitValues,
} from "@/lib/uploadAssets.util";
import Loader from "@/components/common/Loader";
import { Import } from "lucide-react";
import { useQuickPasteAssetMutation } from "@/hooks/useQuickPasteAssetMutation";
import { assetRequiresQuantity } from "@/lib/types";
import { editAssetFormSchema } from "../schema";
import { z } from "zod";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";

export function QuickPaste({ closeDialog }: { closeDialog: () => void }) {
  const [validTableData, setValidTableData] = useState<InputRowData[]>([]);
  const [invalidTableData, setInvalidTableData] = useState<InputRowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetchingPricing, setFetchingPricing] = useState(false);
  const [progress, setProgress] = useState(0);
  const assetLiabilitiesAccounts = useAssetLiabilitiesAccounts();
  const { addAssetAsync, updateAssetAsync } = useQuickPasteAssetMutation();

  async function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
    if (assetLiabilitiesAccounts.state === "SUCCESS") {
      setFetchingPricing(true);

      // Reset the table too to clear up the UI
      setValidTableData([]);
      setInvalidTableData([]);

      const paste = event.clipboardData.getData("text");

      const formatted = paste.split("\n");
      const dataArray: InputRowData[] = formatted
        .filter((row) => row.trim() !== "")
        .map((row) => row.split("\t"))
        .filter(
          (row) =>
            !row.find((column) =>
              excelColumnHeaders.includes(column.toLowerCase()),
            ),
        )
        .map(transformArray);

      const validData: InputRowData[] = [];
      const invalidData: InputRowData[] = [];

      dataArray.forEach(async (data) => {
        const check = quickPasteSchema.safeParse(data);
        if (check.success) {
          validData.push(check.data);
        } else {
          invalidData.push(data);
        }
      });

      // prevent from fetching until all data is validated
      if (invalidData.length === 0) {
        const tableData = await getAssetUnitValues(validData);
        tableData.forEach((row) => {
          const assetId = findExistingAssetIdWithRow(
            row,
            assetLiabilitiesAccounts.data,
          );
          if (assetId) {
            row.id = assetId;
          }
        });
        setValidTableData(tableData);
      }

      setInvalidTableData(invalidData);
      setFetchingPricing(false);
    }
  }

  async function handleUpload() {
    if (assetLiabilitiesAccounts.state === "SUCCESS") {
      setLoading(true);
      const addList = convertToAccountData(
        validTableData.filter((row) => !row.id),
      ).map((account) => {
        return {
          ...account,
          assetLiabilities: mergeSameAsset(account.assetLiabilities),
        };
      });
      const updateList: z.infer<typeof editAssetFormSchema>[] =
        validTableData.reduce(
          (acc, row) => {
            const existingAsset = assetLiabilitiesAccounts.data.find(
              (h_asset) => h_asset.id === row.id,
            );
            if (row.id && existingAsset) {
              acc.push({
                id: row.id,
                name: row.assetName,
                type: row.assetType,
                quantity: assetRequiresQuantity(row.assetType)
                  ? +row.quantity + existingAsset.snapshots.day[0].quantity
                  : +row.quantity,
                unitValue: assetRequiresQuantity(row.assetType)
                  ? +row.unitValue
                  : +row.unitValue + existingAsset.snapshots.day[0].unitValue,
                currency: row.currency,
              });
            }
            return acc;
          },
          [] as z.infer<typeof editAssetFormSchema>[],
        );
      const progressStep =
        100 / (addList.length + updateList.length > 0 ? 1 : 0);

      const promises: Promise<void>[] = addList.map(async (account) => {
        await addAssetAsync(account);
        setProgress((prev) => prev + progressStep);
      });

      if (updateList.length > 0) {
        await updateAssetAsync(updateList);

        setProgress((prev) => prev + progressStep);
      }

      await Promise.all(promises);

      await Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
        }),
      ]);
      setLoading(false);
      setProgress(0);
      setInvalidTableData([]);
      setValidTableData([]);
      toast.success("Data imported successfully");
      closeDialog();
    }
  }

  return (
    <div className="p-4" onPaste={handlePaste}>
      <p className="pb-4 text-center  text-gray-600">
        Please make a copy of our{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1tUHnzolkcse_nqvguLIX_iC6UrRj8-6lgNgV24Gf10I/edit#gid=0"
          target="_blank"
          className="text-orange-500 underline"
        >
          Google Sheets template
        </a>{" "}
        and fill up your portfolio data.
      </p>
      <textarea
        placeholder="Paste your data here once you are done to import your portfolio."
        className="h-20 w-full resize-none rounded-md border border-gray-200 p-2 focus:border-gray-300 focus:outline-none"
        onChange={(e) => {
          if (!e.target.value) {
            setValidTableData([]);
            setInvalidTableData([]);
          }
        }}
      />
      {fetchingPricing ? (
        <div className="flex w-full items-center justify-center gap-x-1">
          {" "}
          <Loader /> Crunching your numbers
        </div>
      ) : loading ? (
        <Progress value={progress} className="my-4 h-2 rounded-md" />
      ) : (
        <Button
          className="my-4 w-full"
          disabled={invalidTableData.length > 0 || validTableData.length === 0}
          onClick={handleUpload}
        >
          <Import className="h-4 w-4" />
          <span className="ml-2">Import</span>
        </Button>
      )}

      {invalidTableData.length > 0 ? (
        <>
          <Separator />
          <span className="rounded-lg bg-rose-100 px-4 py-2 text-sm font-medium text-red-500">
            {invalidTableData.length} invalid{" "}
            {invalidTableData.length > 1 ? "entries" : "entry"}. Please modify
            your data and try again.
          </span>
          <InvalidTable data={invalidTableData} />
        </>
      ) : (
        validTableData.length > 0 &&
        !loading && (
          <>
            <span className="rounded-lg bg-blue-100 px-4 py-2 text-sm font-medium text-blue-500">
              {validTableData.length} valid{" "}
              {validTableData.length > 1 ? "entries" : "entry"}. Click the
              Import button to proceed.
            </span>
            <ValidTable data={getValidTableData(validTableData)} />
          </>
        )
      )}
    </div>
  );
}
