import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { CommandKey } from "../common/CommandKey";
import { ArrowDownIcon, ArrowUpIcon, Delete } from "lucide-react";
import { Switch } from "../ui/switch";
import { useEffect, useState } from "react";

export function ShortcutSettings() {
  const shortcuts = [
    {
      name: "Main Navigation",
      items: [
        {
          name: "Add new assets or liabilities",
          variant: "default",
          cmdKey: ["k"],
          requireCmdKey: true,
        },
        {
          name: "Open insights panel",
          variant: "default",
          cmdKey: ["i"],
          requireCmdKey: true,
        },
        {
          name: "Open settings",
          variant: "default",
          cmdKey: ["."],
          requireCmdKey: true,
        },
        {
          name: "See all shortcuts",
          variant: "default",
          cmdKey: ["?"],
          requireCmdKey: false,
        },
      ],
    },
    {
      name: "Portfolio",
      items: [
        {
          name: "Move up or down",
          variant: "default",
          cmdKey: ["j", "k"],
          icon: [<ArrowUpIcon />, <ArrowDownIcon />],
          requireCmdKey: false,
        },
        {
          name: "Expand list",
          variant: "default",
          cmdKey: ["Enter"],
          requireCmdKey: false,
        },
        {
          name: "Select a row",
          variant: "default",
          cmdKey: ["x"],
          requireCmdKey: false,
        },
        {
          name: "Edit row",
          variant: "default",
          cmdKey: ["e"],
          requireCmdKey: false,
        },
        {
          name: "Delete row",
          variant: "default",
          icon: [<Delete />],
          requireCmdKey: false,
        },
        {
          name: "Cancel selection",
          variant: "default",
          cmdKey: ["esc"],
          requireCmdKey: false,
        },
      ],
    },
  ];

  const [showShortcuts, setShowShortcuts] = useState(false);

  // Set defaults
  useEffect(() => {
    const localShowShortcuts = localStorage.getItem("show_shortcut_prompts");
    if (localShowShortcuts) {
      setShowShortcuts(localShowShortcuts === "true" ? true : false);
    } else {
      localStorage.setItem("show_shortcut_prompts", "true");
      setShowShortcuts(true);
    }
  }, []);

  const handleCheckedChange = (checked: boolean) => {
    setShowShortcuts(checked);
    localStorage.setItem("show_shortcut_prompts", checked.toString());
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-xl font-medium">Keyboard shortcuts</h3>
      <p className="text-gray-500 dark:text-gray-400">
        Quickly navigate through Peek with these handy desktop shortucts.
      </p>
      <div className="my-6 flex-1 space-y-4">
        <Table key="shortcut-settings">
          <TableHeader>
            <TableRow>
              <TableHead>
                <h4>Shortcut</h4>
              </TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <p>Display helpful shortcuts</p>
                <p className="text-xs text-gray-500">
                  Give prompts when shortcuts are not being used.
                </p>
              </TableCell>
              <TableCell className="flex text-right">
                <Switch
                  checked={showShortcuts}
                  onCheckedChange={(checked) => {
                    handleCheckedChange(checked);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {shortcuts.map((shortcut) => (
          <Table key={shortcut.name}>
            <TableHeader>
              <TableRow>
                <TableHead>
                  <h4>{shortcut.name}</h4>
                </TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {shortcut.items.map((item) => (
                <TableRow key={item.name}>
                  <TableCell>
                    <p>{item.name}</p>
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-end gap-1">
                      {item.cmdKey && item.cmdKey.length > 0 && (
                        <div className="flex gap-1">
                          {item.cmdKey.map((key, index) => (
                            <CommandKey
                              key={`${item.name}-${index}`}
                              variant={item.variant as "default" | "white"}
                              cmdKey={key}
                              requireCmdKey={item.requireCmdKey}
                            />
                          ))}
                        </div>
                      )}
                      {item.icon && item.icon.length > 0 && (
                        <div className="flex gap-1">
                          {item.icon.map((icon, index) => (
                            <CommandKey
                              key={`${item.name}-${index}`}
                              variant={item.variant as "default" | "white"}
                              cmdKey=""
                              icon={icon}
                              requireCmdKey={item.requireCmdKey}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </div>
    </div>
  );
}
