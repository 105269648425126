import { createContext, useContext, useMemo } from "react";
import { useCurrency } from "./useCurrency";
import { useGetAssetLiabilitiesAccountsQuery } from "./useGetAssetLiabilitiesAccountsQuery";
import { AccountV2, AssetLiabilityV2, SnapshotV2 } from "@/lib/types";

type AssetLiabilitiesAccountsLoading = { state: "LOADING" };
type AssetLiabilitiesAccountsError = { state: "ERROR"; message?: string };
export type AssetLiabilitiesAccountsSuccess = {
  state: "SUCCESS";
  data: AssetLiabilityV2[];
  accountFilters: { label: string; value: number }[];
  assetClassFilters: { label: string; value: string }[];
  currencyFilters: { label: string; value: string }[];
  tagFilters: { label: string; value: string }[];
  uniqueIntegratedAccounts: AccountV2[];
};
export type AssetLiabilitiesAccountsType =
  | AssetLiabilitiesAccountsLoading
  | AssetLiabilitiesAccountsError
  | AssetLiabilitiesAccountsSuccess;

const AssetLiabilitiesAccounts = createContext<AssetLiabilitiesAccountsType>({
  state: "LOADING",
});

export function AssetLiabilitiesAccountsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const currencyContext = useCurrency();
  const { assetLiabilitiesAccounts, isError, isLoading, error } =
    useGetAssetLiabilitiesAccountsQuery();

  const { accountFilters, assetClassFilters, currencyFilters, tagFilters } =
    useMemo(() => {
      const accountMap = new Map<number, string>();
      const assetClassMap = new Map<string, string>();
      const uniqueCurrencies = new Set<string>();
      const uniqueTags = new Set<string>();

      assetLiabilitiesAccounts.forEach(
        ({ account, assetClass, currency, tag }) => {
          if (!accountMap.has(account.id)) {
            accountMap.set(account.id, account.name);
          }
          if (!assetClassMap.has(assetClass)) {
            assetClassMap.set(assetClass, assetClass);
          }
          uniqueCurrencies.add(currency);
          uniqueTags.add(tag || "Uncategorized");
        },
      );

      const sortByLabel = <T extends { label: string }>(a: T, b: T) =>
        a.label.localeCompare(b.label);

      const accountFilters = Array.from(accountMap.entries())
        .map(([id, name]) => ({ label: name, value: id }))
        .sort(sortByLabel);

      const assetClassFilters = Array.from(assetClassMap.entries())
        .map(([id, name]) => ({
          label: name
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          value: id,
        }))
        .sort(sortByLabel);

      const currencyFilters = Array.from(uniqueCurrencies)
        .map((currency) => ({ label: currency, value: currency }))
        .sort(sortByLabel);

      const tagFilters = Array.from(uniqueTags)
        .map((tag) => ({ label: tag, value: tag }))
        .sort(sortByLabel);

      return { accountFilters, assetClassFilters, currencyFilters, tagFilters };
    }, [assetLiabilitiesAccounts]);

  // convert values to selected currency
  const convertedData = useMemo(() => {
    if (currencyContext.state !== "SUCCESS") {
      return assetLiabilitiesAccounts;
    }

    const convertSnapshotValues = (
      snapshot: SnapshotV2,
      accountCurrency: string,
      originalCurrency: string,
    ) => {
      const convert = (value: number) =>
        currencyContext.convertValueToSelectedCurrency(
          value,
          accountCurrency,
          originalCurrency,
        );

      return {
        ...snapshot,
        original: {
          pnl: snapshot.pnl,
          prevPnl: snapshot.prevPnl,
          unitValue: snapshot.unitValue,
          unitValueChange: snapshot.unitValueChange,
          totalValue: snapshot.totalValue,
          prevTotalValue: snapshot.prevTotalValue,
          prevUnitValue: snapshot.prevUnitValue,
          averageCost: snapshot.averageCost,
          capitalFlow: snapshot.capitalFlow,
          pnlChange: snapshot.pnlChange,
          totalCapitalInvested: snapshot.totalCapitalInvested,
          totalValueChange: snapshot.totalValueChange,
        },
        pnl: convert(snapshot.pnl),
        unitValue: convert(snapshot.unitValue),
        unitValueChange: convert(snapshot.unitValueChange),
        totalValue: convert(snapshot.totalValue),
        prevTotalValue: convert(snapshot.prevTotalValue),
        prevUnitValue: convert(snapshot.prevUnitValue),
        averageCost: convert(snapshot.averageCost),
        capitalFlow: convert(snapshot.capitalFlow),
        pnlChange: convert(snapshot.pnlChange),
        totalCapitalInvested: convert(snapshot.totalCapitalInvested),
        totalValueChange: convert(snapshot.totalValueChange),
      };
    };

    const convertSnapshots = (
      snapshots: SnapshotV2[],
      accountCurrency: string,
      targetCurrency: string,
    ) =>
      snapshots.map((snapshot) =>
        convertSnapshotValues(snapshot, accountCurrency, targetCurrency),
      );

    return assetLiabilitiesAccounts.map((assetLiability) => ({
      ...assetLiability,
      snapshots: {
        day: convertSnapshots(
          assetLiability.snapshots["day"],
          assetLiability.currency,
          currencyContext.currency,
        ),
        week: convertSnapshots(
          assetLiability.snapshots["week"],
          assetLiability.currency,
          currencyContext.currency,
        ),
        month: convertSnapshots(
          assetLiability.snapshots["month"],
          assetLiability.currency,
          currencyContext.currency,
        ),
      },
    }));
  }, [assetLiabilitiesAccounts, currencyContext]);

  const uniqueIntegratedAccounts: AccountV2[] = useMemo(() => {
    const accounts = assetLiabilitiesAccounts
      .filter((d: AssetLiabilityV2) => d.account.type === "INTEGRATED_ACCOUNT")
      .map((d: AssetLiabilityV2) => d.account);

    return Array.from(
      new Map(accounts.map((account) => [account.name, account])).values(),
    );
  }, [assetLiabilitiesAccounts]);

  // export function getIntegratedAccounts(data: ) {
  //   return data.filter((d) => d.name && d.type === "INTEGRATED_ACCOUNT");
  // }

  if (isLoading || currencyContext.state === "LOADING") {
    return (
      <AssetLiabilitiesAccounts.Provider value={{ state: "LOADING" }}>
        {children}
      </AssetLiabilitiesAccounts.Provider>
    );
  }

  if (isError || currencyContext.state === "ERROR") {
    return (
      <AssetLiabilitiesAccounts.Provider
        value={{
          state: "ERROR",
          message:
            error?.message ??
            "An unexpected error occurred. Please try again later.",
        }}
      >
        {children}
      </AssetLiabilitiesAccounts.Provider>
    );
  }

  return (
    <AssetLiabilitiesAccounts.Provider
      value={{
        state: "SUCCESS",
        data: convertedData,
        accountFilters,
        assetClassFilters,
        currencyFilters,
        tagFilters,
        uniqueIntegratedAccounts,
      }}
    >
      {children}
    </AssetLiabilitiesAccounts.Provider>
  );
}

export function useAssetLiabilitiesAccounts() {
  const context = useContext(AssetLiabilitiesAccounts);
  if (context === undefined) {
    throw new Error(
      "useAssetLiabilitiesAccounts must be used within a AssetLiabilitiesAccountsProvider",
    );
  }
  return context;
}
