import { Form } from "@/components/ui/form";
import { useCurrency } from "@/hooks/useCurrency";
import {
  assetNameValidationError,
  findExistingAsset,
  mergeSameAsset,
  getFormValues,
  getParsedAccountType,
  combineWithExistingAsset,
  validateAssetName,
} from "@/lib/addAssets.utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddAssetFirstStep } from "./AddAssetFirstStep";
import AddAssetFooter from "./AddAssetFooter";
import { AddAssetSecondStep } from "./AddAssetSecondStep";
import CancelAddAssetConfirmation from "./CancelAddAssetConfirmation";
import { addAssetFormSchema, assetSchema, editAssetFormSchema } from "./schema";
import { AddAssetFormFields, CustomSearchItems } from "./types";
import { formatAccountType } from "@/lib/utils";
import { noFormatAssetType, saveAssetTypeAsName } from "./searchItems";
import { useAddAssetMutation } from "@/hooks/useAddAssetMutation";
import { useUpdateAssetMutation } from "@/hooks/useUpdateAssetMutation";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";

type AddAssetFormProps = {
  clearCategory: () => void;
  setAccountName: React.Dispatch<React.SetStateAction<string>>;
  accountType: CustomSearchItems;
  closeDialog: () => void;
};

export function AddAssetForm({
  clearCategory,
  setAccountName,
  accountType,
  closeDialog,
}: AddAssetFormProps) {
  // Replace the following with the correct asset type to maintain asset type consistency
  const parsedAccountType = getParsedAccountType(accountType);

  const form = useForm<z.infer<typeof addAssetFormSchema>>({
    resolver: zodResolver(addAssetFormSchema),
    defaultValues: {
      name: "",
      type: parsedAccountType,
      assetLiabilities: [],
    },
  });

  const posthog = usePostHog();
  const currencyContext = useCurrency();

  const { addAsset, isPending: addPending } = useAddAssetMutation();
  const { updateAsset, isPending: updatePending } = useUpdateAssetMutation();
  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();

  const [step, setStep] = useState<1 | 2>(1);

  const formValues = useMemo(() => getFormValues(accountType), [accountType]);

  // Used to store the input values for the second step
  const [fields, setFields] = useState<AddAssetFormFields>({
    ...formValues.defaultValues,
    currency: currencyContext.currency,
  });

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const { isDirty, isValid } = form.formState;

  function handleConfirmationCancel() {
    // Set focus to the first input input
    setConfirmationDialogOpen(false);
    const input = document.querySelector("input") as HTMLInputElement;
    input?.focus();
  }

  // To set focus back to the first input after closing the confirmation dialog
  useEffect(() => {
    const assetForm = document.getElementById("asset-form");
    const firstInput = assetForm?.querySelector("input") as HTMLInputElement;
    const firstCombobox = document.getElementById(
      "combobox",
    ) as HTMLButtonElement;
    if (!confirmationDialogOpen) {
      if (firstInput) firstInput.focus();
      else {
        firstCombobox?.focus();
      }
    }
  }, [confirmationDialogOpen]);

  // To clear submission error
  useEffect(() => {
    if (isValid) setSubmissionError(null);
  }, [isValid]);

  async function onSubmit(values: z.infer<typeof addAssetFormSchema>) {
    if (assetLiabilitiesAccountsContext.state === "SUCCESS") {
      const newList: z.infer<typeof assetSchema>[] = [];
      const updateList: z.infer<typeof editAssetFormSchema>[] = [];

      mergeSameAsset(
        values.assetLiabilities.filter((assetLiability) => {
          if (
            assetLiability.type === "CASH" ||
            assetLiability.type === "FIXED_DEPOSIT"
          ) {
            return assetLiability.unitValue > 0;
          }
          return true;
        }),
      ).forEach((asset: z.infer<typeof assetSchema>) => {
        if (asset.type === "CASH" || asset.type === "FIXED_DEPOSIT") {
          asset.name = asset.name || formatAccountType(asset.type);
        }

        if (saveAssetTypeAsName.includes(asset.type)) {
          asset.name = noFormatAssetType.includes(asset.type)
            ? asset.type
            : formatAccountType(asset.type);
        }

        // Check if Account already existed and has Asset with same currency and name
        const { existingAsset } = findExistingAsset(
          asset,
          values.name,
          assetLiabilitiesAccountsContext.data,
        );

        if (existingAsset) {
          updateList.push({
            id: existingAsset.id,
            ...combineWithExistingAsset(existingAsset, asset),
          });
        } else {
          newList.push(asset);
        }
      });

      if (newList.length > 0) {
        addAsset({
          name: values.name,
          type: values.type,
          assetLiabilities: newList,
        });
      }

      if (updateList.length > 0) {
        updateAsset({
          input: updateList,
        });
      }

      closeDialog();
    }
  }

  function saveCallback() {
    // Check if the currently inputted fields are valid, if they are append them to the assetLiabilities array
    // this allows users to add a single asset without having to "Add" first before "Save"
    const parsedAsset = assetSchema
      .refine(validateAssetName, assetNameValidationError)
      .safeParse(fields);

    if (parsedAsset.success) {
      form.setValue("assetLiabilities", [
        parsedAsset.data,
        ...form.watch("assetLiabilities"),
      ]);
    }
    form.handleSubmit(onSubmit, (err) => {
      err.assetLiabilities?.message &&
        setSubmissionError(err.assetLiabilities.message);
    })();
  }

  function nextCallback() {
    form.trigger("name").then((isValid) => {
      if (isValid) {
        posthog?.capture("set_account_name_assets_liabilities_form");
        setAccountName(form.getValues("name"));
        setStep(2);
      }
    });
  }

  if (assetLiabilitiesAccountsContext.state !== "SUCCESS") {
    return null;
  }

  const accountOptions = assetLiabilitiesAccountsContext.accountFilters.map(
    (x) => ({ value: x.label, label: x.label }),
  );

  return (
    <>
      <Form {...form}>
        <div
          className="space-y-4 p-4"
          onKeyDownCapture={(e) => {
            if (e.key === "Enter" && (e.ctrlKey || e.metaKey) && step === 1) {
              nextCallback();
              e.stopPropagation();
              e.preventDefault();
            } else if (
              e.key === "Enter" &&
              (e.ctrlKey || e.metaKey) &&
              step === 2
            ) {
              saveCallback();
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        >
          {step === 1 && (
            <AddAssetFirstStep
              form={form}
              clearCategory={clearCategory}
              setConfirmationDialogOpen={setConfirmationDialogOpen}
              options={accountOptions}
              accountType={accountType}
              nextStep={nextCallback}
            />
          )}
          {step === 2 && (
            <AddAssetSecondStep
              form={form}
              previousStep={() => setStep(1)}
              formValues={formValues}
              tagOptions={assetLiabilitiesAccountsContext.tagFilters.map(
                (x) => x.label,
              )}
              clearSubmissionError={() => setSubmissionError(null)}
              fields={fields}
              setFields={setFields}
              accountType={accountType}
            />
          )}
          {submissionError && (
            <p className="text-sm font-medium text-destructive">
              {submissionError}
            </p>
          )}
          <AddAssetFooter
            step={step}
            isDirty={isDirty}
            setConfirmationDialogOpen={setConfirmationDialogOpen}
            closeDialog={closeDialog}
            nextCallback={nextCallback}
            saveCallback={saveCallback}
            isPending={addPending || updatePending}
          />
        </div>
      </Form>
      <CancelAddAssetConfirmation
        confirmationDialogOpen={confirmationDialogOpen}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        closeDialog={closeDialog}
        handleConfirmationCancel={handleConfirmationCancel}
      />
    </>
  );
}
