import {
  Megaphone,
  Scroll,
  Map,
  Shield,
  LogOut,
  CircleArrowUp,
  MessageCircleQuestion,
} from "lucide-react";
import { Button } from "../ui/button";
import { usePostHog } from "posthog-js/react";
import { useAuth } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";
import { useSubscriptions } from "@/hooks/useSubscriptions";
import { Card, CardContent } from "../ui/card";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Upgrade } from "../Billing/Upgrade";

const footerLinks = [
  {
    label: "Help Center",
    href: "https://help.peek.money",
    icon: MessageCircleQuestion,
  },
  {
    label: "Feedback",
    href: "mailto:support@peek.money",
    icon: Megaphone,
  },
  {
    label: "Changelog",
    href: "https://bluejay.notion.site/Change-Log-515aad10cc02411b8ab629a7ea618053",
    icon: Scroll,
  },
  {
    label: "Roadmap",
    href: "https://bluejay.notion.site/4fbcd123f61848d6ae69c5cbd28bcd95?v=ad7acecaf6a047dbb3a6c14ef0983d55",
    icon: Map,
  },
  {
    label: "Security",
    href: "/preferences/security",
    icon: Shield,
    external: false,
  },
];

export function NavFooter({ isCollapsed = false }: { isCollapsed?: boolean }) {
  const { signOut } = useAuth();
  const posthog = usePostHog();
  const subscriptionsContext = useSubscriptions();

  const handleSignOut = () => {
    localStorage.removeItem("onboardingData");
    posthog.capture("user_signed_out");
    posthog.reset();
    signOut();
  };

  const canShowUpgrade = () => {
    return (
      subscriptionsContext.state === "SUCCESS" &&
      !subscriptionsContext.subscribed &&
      subscriptionsContext.signedUpForTrial &&
      subscriptionsContext.trialStillValid
    );
  };

  return (
    <>
      {canShowUpgrade() && (
        <>
          {!isCollapsed ? (
            <Card className="shadow-md">
              <CardContent>
                <div className="flex flex-col gap-y-2">
                  <h6 className="text-sm font-semibold">Enjoying Peek?</h6>
                  <p className="text-xs text-gray-600">
                    Upgrade now to keep using the features you love, without
                    interruption.
                  </p>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="animate-gradient items-center justify-center gap-1 whitespace-nowrap rounded-md bg-gradient-to-r from-[#FF7CD3] to-[#68A4FF] bg-[length:400%] px-3 text-sm font-medium text-white ring-offset-background transition-colors hover:bg-black/20 hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                      >
                        Upgrade Now
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="max-w-1/4 max-h-screen w-1/2 overflow-scroll p-0">
                      <Upgrade />
                    </DialogContent>
                  </Dialog>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="animate-gradient items-center justify-center gap-1 whitespace-nowrap rounded-md bg-gradient-to-r from-[#FF7CD3] to-[#68A4FF] bg-[length:400%] px-3 text-sm font-medium text-white ring-offset-background transition-colors hover:bg-black/20 hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                >
                  <CircleArrowUp size={14} />
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-1/4 max-h-screen w-1/2 overflow-scroll p-0">
                <Upgrade />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
      <div className="mt-2 flex flex-col gap-y-2">
        {footerLinks.map((footerLink, index) => (
          <TooltipProvider delayDuration={300} key={index}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  to={footerLink.href}
                  target={footerLink.external ? "_blank" : undefined}
                >
                  <Button
                    variant="ghost"
                    size="sm"
                    className="flex w-full flex-row justify-start gap-x-1 text-base text-gray-400"
                  >
                    <footerLink.icon size={16} />
                    {!isCollapsed && footerLink.label}
                  </Button>
                </Link>
              </TooltipTrigger>
              {isCollapsed && (
                <TooltipContent
                  side="right"
                  className="bg-black"
                  hideWhenDetached={true}
                >
                  <p className="text-white">{footerLink.label}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        ))}
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="w-full items-center justify-start gap-x-1 text-base text-gray-400"
                onClick={handleSignOut}
              >
                <LogOut size={14} />
                {!isCollapsed && "Logout"}
              </Button>
            </TooltipTrigger>
            {isCollapsed && (
              <TooltipContent
                side="right"
                className="bg-black"
                hideWhenDetached={true}
              >
                <p className="text-white">Logout</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>
    </>
  );
}
