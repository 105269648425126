import { Tag } from "@/components/common/Tag";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Progress } from "@/components/ui/progress";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  AccountSyncTaskIntegrator,
  AccountSyncTaskStatus,
  AssetLiabilityV2,
} from "@/lib/types";
import {
  beautifyAccountType,
  cn,
  formatQuantity,
  shortNumber,
} from "@/lib/utils";
import { ColumnDef, Row } from "@tanstack/react-table";
import { formatDistance } from "date-fns";
import {
  AlertTriangle,
  ChevronDown,
  ChevronRight,
  PanelRightOpen,
  RefreshCcw,
  TagIcon,
} from "lucide-react";
import { PortfolioTableRow } from "./types";
import { isAccountRow } from "@/lib/portfolio.utils";
import { ValueColumn } from "@/components/Portfolio/Table/ValueColumn";

const retirementTypes = [
  "SG_CPF_OA",
  "SG_CPF_RA",
  "SG_CPF_SA",
  "SG_CPF_MA",
  "SG_SRS",
  "US_401K",
  "US_IRA",
  "US_529",
];

const hideQuantityAndValue = [
  "CASH",
  "FIXED_DEPOSIT",
  "ANGEL_INVESTMENT",
  "HEDGE_FUND",
  "PRIVATE_CREDIT",
  "PRIVATE_EQUITY",
  "LIFE_INSURANCE",
  "REAL_ESTATE",
  "ROBO",
  ...retirementTypes,
];

type ColumnProps = {
  overviewBy: string;
  handleAssetLiabilityDetailsSheetOpen: (data: AssetLiabilityV2) => void;
  handleSync: (
    accountId: number,
    accountName: string,
    integrator?: AccountSyncTaskIntegrator,
  ) => void;
  handleRelogin: (
    accountId: number,
    integrator: AccountSyncTaskIntegrator,
  ) => void;
  syncingAccountIds: number[];
};

export const columns = ({
  overviewBy,
  handleSync,
  handleRelogin,
  syncingAccountIds,
  handleAssetLiabilityDetailsSheetOpen,
}: ColumnProps): ColumnDef<PortfolioTableRow>[] => [
  {
    accessorKey: "name",
    id: "asset",
    enableHiding: false,
    sortingFn: (rowA, rowB) => {
      const dataA = rowA.original;
      const dataB = rowB.original;

      if (dataA.rowType === "asset" && dataB.rowType === "asset") {
        const nameA = dataA.fullName
          ? `${dataA.fullName} (${dataA.name})`
          : dataA.name;

        const nameB = dataB.fullName
          ? `${dataB.fullName} (${dataB.name})`
          : dataB.name;

        return nameA > nameB ? 1 : -1;
      }

      return dataA.name > dataB.name ? 1 : -1;
    },
    header: ({ table }) => (
      <div className="flex items-center gap-x-1">
        <Button
          variant="ghost"
          size="icon"
          {...{
            onClick: table.getToggleAllRowsExpandedHandler(),
          }}
        >
          {table.getIsAllRowsExpanded() ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronRight className="h-4 w-4" />
          )}
        </Button>
      </div>
    ),
    cell: ({ row }) => {
      const rowData = row.original;

      let isIntegratedAccountGroup = false;
      let label = "";

      if (rowData.rowType === "section" || rowData.rowType === "subSection") {
        const isSection = rowData.rowType === "section";

        isIntegratedAccountGroup = row.subRows.some((sr) =>
          isSection
            ? sr.subRows.some(
                (ssr) => ssr.original.account.type === "INTEGRATED_ACCOUNT",
              )
            : sr.original.account.type === "INTEGRATED_ACCOUNT",
        );

        const totalSubRows = isSection
          ? row.subRows.reduce((acc, sr) => acc + sr.subRows.length, 0)
          : row.subRows.length;

        const showName = isSection
          ? overviewBy === "asset"
            ? beautifyAccountType(rowData.name)
            : rowData.name
          : overviewBy === "account"
            ? beautifyAccountType(rowData.name)
            : rowData.name;

        label = `${showName}${
          row.subRows.length && !row.getIsExpanded() ? ` (${totalSubRows})` : ""
        }`;
      }

      const lastUpdatedAt = rowData.account.accountSyncTask[0]?.updatedAt || "";
      let accountSyncTaskIntegrator: AccountSyncTaskIntegrator | undefined;
      let accountSyncTaskStatus: AccountSyncTaskStatus | undefined;

      if (rowData.account.type === "INTEGRATED_ACCOUNT") {
        accountSyncTaskIntegrator =
          rowData.account.accountSyncTask[0]?.integrator;
        accountSyncTaskStatus = rowData.account.accountSyncTask[0]?.status;
      }

      return (
        <>
          <div
            className={cn(
              "flex items-center gap-x-1",
              (rowData.rowType === "section" ||
                rowData.rowType === "subSection") &&
                "font-medium",
              rowData.rowType === "subSection" && "pl-7",
            )}
          >
            {row.getCanExpand() ? (
              <div className="p-2">
                {row.getIsExpanded() ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronRight className="h-4 w-4" />
                )}
              </div>
            ) : row.depth === 0 ? (
              // indent empty accounts without Chevron
              <div className="h-8 w-8" />
            ) : null}
            {!isIntegratedAccountGroup &&
              rowData.account.type !== "INTEGRATED_ACCOUNT" &&
              (rowData.rowType === "section"
                ? row.subRows.length > 0
                : rowData.rowType === "subSection"
                  ? row.subRows.length > 0
                  : true) && (
                <Checkbox
                  className={cn(rowData.rowType === "asset" && "ml-20")}
                  {...{
                    checked: ["section", "subSection"].includes(rowData.rowType)
                      ? row.getIsAllSubRowsSelected()
                      : row.getIsSelected(),
                    onCheckedChange: (checked) => {
                      row.getToggleSelectedHandler()(checked);
                    },
                  }}
                />
              )}

            {rowData.rowType === "asset" ? (
              <div
                className={cn(
                  "pl-2 w-full",
                  rowData.account.type === "INTEGRATED_ACCOUNT" && "ml-14",
                )}
              >
                <div className="flex flex-row items-center gap-x-1">
                  <img
                    src={
                      rowData.company?.logo ||
                      `https://ui-avatars.com/api/?background=F4EEE3&color=4B5563&name=${
                        rowData.fullName || rowData.name
                      }`
                    }
                    className="mr-2 h-8 w-8 rounded-full bg-beige p-1"
                  />
                  <div className="w-full">
                    {rowData.fullName ? (
                      <>
                        {retirementTypes.includes(rowData.type)
                          ? rowData.fullName.replaceAll("_", " ")
                          : rowData.fullName}
                        <span className="ml-1 font-normal">
                          (
                          {retirementTypes.includes(rowData.type)
                            ? rowData.name.replaceAll("_", " ")
                            : rowData.name}
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        {retirementTypes.includes(rowData.type)
                          ? rowData.name.replaceAll("_", " ")
                          : rowData.name}
                      </>
                    )}
                  </div>
                  <Button
                    className="invisible h-6 rounded-sm bg-gray-100 px-2 py-0 text-gray-500 shadow hover:text-white group-aria-selected:visible"
                    size="sm"
                    onClick={() =>
                      handleAssetLiabilityDetailsSheetOpen(rowData)
                    }
                  >
                    <PanelRightOpen size={12} className="mr-1" />
                    Open
                  </Button>
                </div>
              </div>
            ) : (
              <span className="flex flex-row items-center gap-x-1">
                {label}
                {isAccountRow(row.id) &&
                  rowData.account.type === "INTEGRATED_ACCOUNT" && (
                    <>
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            {accountSyncTaskStatus === "EXPIRED" ? (
                              <Button
                                className="h-6 rounded-sm bg-red-100 px-2 py-0 text-red-500 shadow hover:bg-red-500 hover:text-white"
                                size="sm"
                                onClick={() =>
                                  handleRelogin(
                                    rowData.account.id as number,
                                    accountSyncTaskIntegrator as AccountSyncTaskIntegrator,
                                  )
                                }
                              >
                                <AlertTriangle size={12} className="mr-1" />
                                Re-login to sync
                              </Button>
                            ) : accountSyncTaskStatus === "FAILED" ? (
                              <Button
                                className="h-6 rounded-sm bg-orange-100 px-2 py-0 text-orange-500 shadow hover:bg-orange-500 hover:text-white"
                                size="sm"
                                onClick={() =>
                                  handleSync(
                                    rowData.account.id as number,
                                    rowData.name,
                                    accountSyncTaskIntegrator,
                                  )
                                }
                              >
                                <RefreshCcw size={12} className="mr-1" />
                                Retry
                              </Button>
                            ) : accountSyncTaskStatus ===
                              "INTEGRATOR_NOT_READY" ? (
                              <Button
                                className="h-6 rounded-sm bg-blue-100 px-2 py-0 text-blue-500 shadow hover:bg-blue-500 hover:text-white"
                                size="sm"
                                onClick={() =>
                                  handleSync(
                                    rowData.account.id as number,
                                    rowData.name,
                                    accountSyncTaskIntegrator,
                                  )
                                }
                              >
                                <AlertTriangle size={12} className="mr-1" />
                                Syncing in progress
                              </Button>
                            ) : (
                              <Button
                                className="h-6 rounded-sm bg-gray-100 px-2 py-0 text-gray-500 shadow hover:text-white"
                                size="sm"
                                onClick={() =>
                                  handleSync(
                                    rowData.account.id as number,
                                    rowData.name,
                                    accountSyncTaskIntegrator,
                                  )
                                }
                                disabled={syncingAccountIds.includes(
                                  rowData.account.id,
                                )}
                              >
                                <RefreshCcw size={12} className="mr-1" />
                                {syncingAccountIds.includes(rowData.account.id)
                                  ? "Syncing"
                                  : "Sync"}
                              </Button>
                            )}
                          </TooltipTrigger>
                          {lastUpdatedAt && (
                            <TooltipContent className="rounded-md bg-white text-sm text-gray-400">
                              Last synced{" "}
                              {formatDistance(
                                new Date(lastUpdatedAt),
                                new Date(),
                                {
                                  addSuffix: true,
                                },
                              )}
                            </TooltipContent>
                          )}
                        </Tooltip>
                      </TooltipProvider>
                    </>
                  )}
              </span>
            )}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "tag",
    header: () => <div className="hidden text-right sm:block">Tag</div>,
    cell: ({ row }) => {
      const rowData = row.original;
      if (rowData.rowType === "asset") {
        return (
          <div className="hidden justify-end sm:flex">
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    {!rowData.tag ? (
                      <div className="flex items-center justify-center rounded-sm bg-gray-200 px-2 py-1">
                        <TagIcon size={12} />
                      </div>
                    ) : (
                      <Tag isTag={true} label={rowData.tag ?? ""} />
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent className="bg-black text-white">
                  Double click to edit
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      }
    },
  },
  {
    accessorKey: "quantity",
    header: () => <div className="text-right">Quantity</div>,
    sortingFn: (rowA, rowB) => {
      if (
        rowA.original.rowType === "asset" &&
        rowB.original.rowType === "asset"
      ) {
        const dataA = rowA.original;
        const dataB = rowB.original;
        return dataA.snapshots.day.slice(-1)[0].quantity >
          dataB.snapshots.day.slice(-1)[0].quantity
          ? 1
          : -1;
      }
      return 0;
    },
    cell: ({ row }) => {
      if (
        row.original.rowType === "asset" &&
        !hideQuantityAndValue.includes(row.original.type)
      ) {
        const data = row.original;
        return (
          <div className="text-right">
            <span>
              {formatQuantity(data.snapshots.day.slice(-1)[0]?.quantity || 0)}
            </span>
            <span className="ml-4">x</span>
          </div>
        );
      }
    },
  },
  {
    accessorFn: (row) =>
      row?.snapshots?.day?.slice(-1)?.[0]?.original?.unitValue || 0,
    id: "unitValue",
    header: () => <div className="text-left">Unit Value</div>,
    sortingFn: (rowA, rowB) => {
      const getUnitValue = (row: Row<PortfolioTableRow>) =>
        row?.original?.snapshots?.day?.slice(-1)?.[0]?.original?.unitValue ?? 0;

      const unitValueA = getUnitValue(rowA);
      const unitValueB = getUnitValue(rowB);

      if (
        rowA.original.rowType === "asset" &&
        rowB.original.rowType === "asset"
      ) {
        return unitValueA > unitValueB ? 1 : -1;
      }

      return unitValueA - unitValueB;
    },
    cell: ({ row }) => {
      if (
        row.original.rowType === "asset" &&
        !hideQuantityAndValue.includes(row.original.type)
      ) {
        const data = row.original;
        return (
          <div className="text-left">
            <span className="mr-2">{data.currency}</span>
            <span>
              {shortNumber(
                data?.snapshots?.day?.slice(-1)?.[0]?.original?.unitValue || 0,
              )}
            </span>
          </div>
        );
      }
    },
  },
  {
    accessorKey: "percentageHoldings",
    header: () => <div className="text-right">%</div>,
    sortingFn: (rowA, rowB) => {
      const percentageHoldingsA = rowA.original.percentageHolding ?? 0;
      const percentageHoldingsB = rowB.original.percentageHolding ?? 0;
      return percentageHoldingsA - percentageHoldingsB;
    },
    cell: ({ row }) => {
      const data = row.original;
      return (
        <div className="flex flex-row items-center gap-x-2">
          <Progress value={data.percentageHolding} />
          <div
            className={cn(
              "text-right",
              data.rowType === "section" && "font-semibold",
            )}
          >
            {data.percentageHolding?.toFixed(2)}%
          </div>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row?.snapshots?.day?.slice(-1)?.[0]?.unitValue || 0,
    id: "value",
    enableHiding: false,
    header: () => <div className="text-right">Value</div>,
    sortingFn: (rowA, rowB) => {
      const getTotalValue = (row: Row<PortfolioTableRow>) => {
        if (row.original.rowType !== "asset") {
          return row.original.totalValue;
        }
        const snapshot = row.original.snapshots?.day?.slice(-1)?.[0] || {};
        return (snapshot.quantity ?? 0) * (snapshot.unitValue ?? 0);
      };

      const totalValueA = getTotalValue(rowA);
      const totalValueB = getTotalValue(rowB);

      return totalValueA - totalValueB;
    },
    cell: ({ row }) => {
      const data = row.original;
      const quantity = data.snapshots?.day?.slice(-1)?.[0]?.quantity ?? 0;
      const totalValue =
        data.rowType !== "asset"
          ? data.totalValue
          : quantity * (data.snapshots?.day?.slice(-1)?.[0]?.unitValue ?? 0);
      const originalTotalValue =
        data.rowType !== "asset"
          ? data.originalTotalValue
          : quantity *
            (data.snapshots?.day?.slice(-1)?.[0]?.original?.unitValue ?? 0);

      return (
        <ValueColumn
          value={totalValue}
          type={data.rowType as "section" | "subSection"}
          originalCurrency={data.currency || ""}
          originalValue={originalTotalValue}
        />
      );
    },
  },
];
