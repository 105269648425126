import { AssetLiabilityV2 } from "@/lib/types";

export function Header({
  assetLiability,
}: {
  assetLiability: AssetLiabilityV2;
}) {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <img
        src={
          assetLiability.company?.logo ||
          `https://ui-avatars.com/api/?background=F4EEE3&color=4B5563&name=${
            assetLiability.name
          }`
        }
        className="mr-2 h-10 w-10 rounded-full bg-beige p-1"
      />
      <div className="flex w-full flex-col">
        <h2 className="text-left font-medium">
          {assetLiability.fullName
            ? assetLiability.fullName
            : assetLiability.account.name}
        </h2>
        <div className="flex flex-row items-center gap-x-2 text-sm font-normal text-gray-400">
          <span>{assetLiability.name}</span>
          {assetLiability.company?.isin && (
            <>
              <span>•</span>
              <span>{assetLiability.company?.isin}</span>
            </>
          )}
          {assetLiability.company?.exchangeShortName && (
            <>
              <span>•</span>
              <span>{assetLiability.company?.exchangeShortName}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
