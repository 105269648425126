import { riskToleranceLevels } from "@/components/Onboarding/constants";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Separator } from "@/components/ui/separator";
import { Profile } from "@/lib/types";
import { cn } from "@/lib/utils";
import { HelpCircle } from "lucide-react";
import peekpedia from "/images/icon-peekpedia.svg";

type FinancialProfileProps = {
  formData: Profile;
  setFormData: (data: Profile) => void;
};

export function FinancialProfile({
  formData,
  setFormData,
}: FinancialProfileProps) {
  const riskToleranceStepValue = formData.riskTolerance
    ? riskToleranceLevels.findIndex(
        (level) => level.value === formData.riskTolerance,
      ) + 1
    : 0;

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium text-gray-700">Financial Profile</h4>
      <Separator />

      <div>
        <label className="block py-2 text-gray-500">Financial Literacy</label>

        <div className="flex flex-1 flex-row items-center gap-x-2">
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left flex items-center justify-between",
              formData.financialLiteracy === "beginner" && "bg-offwhite",
            )}
            onClick={() =>
              setFormData({ ...formData, financialLiteracy: "beginner" })
            }
          >
            <span
              className={cn(
                "font-medium text-gray-600 text-sm",
                formData.financialLiteracy === "beginner" && "font-semibold",
              )}
            >
              Beginner
            </span>
            <HoverCard openDelay={300}>
              <HoverCardTrigger asChild>
                <HelpCircle
                  size={16}
                  className="ml-2 text-gray-500 hover:cursor-pointer"
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-96">
                <div className="flex flex-col justify-between space-y-4 text-sm">
                  <div className="flex flex-row items-center gap-x-2">
                    <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                    <h4 className="font-semibold">What it means</h4>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <p>
                      A beginner in financial literacy typically refers to
                      someone who:
                    </p>
                    <ul className="list-disc pl-4">
                      <li>
                        Has limited experience with personal finance concepts
                      </li>
                      <li>
                        May be highly accomplished in their career but new to
                        managing personal wealth
                      </li>
                      <li>Is starting to learn about investing</li>
                      <li>
                        Might not yet fully understand concepts like compound
                        interest or diversification
                      </li>
                      <li>
                        Is beginning to explore different financial products and
                        services
                      </li>
                      <li>
                        May need guidance on tax strategies and retirement
                        planning
                      </li>
                    </ul>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left flex items-center justify-between",
              formData.financialLiteracy === "intermediate" && "bg-offwhite",
            )}
            onClick={() =>
              setFormData({ ...formData, financialLiteracy: "intermediate" })
            }
          >
            <span
              className={cn(
                "font-medium text-gray-600 text-sm",
                formData.financialLiteracy === "intermediate" &&
                  "font-semibold",
              )}
            >
              Intermediate
            </span>
            <HoverCard openDelay={300}>
              <HoverCardTrigger asChild>
                <HelpCircle
                  size={16}
                  className="ml-2 text-gray-500 hover:cursor-pointer"
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-96">
                <div className="flex flex-col justify-between space-y-4 text-sm">
                  <div className="flex flex-row items-center gap-x-2">
                    <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                    <h4 className="font-semibold">What it means</h4>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <p>
                      An intermediate in financial literacy typically refers to
                      someone who:
                    </p>
                    <ul className="list-disc pl-4">
                      <li>
                        Has a good grasp of personal finance and budgeting
                      </li>
                      <li>
                        Understands basic investment concepts and strategies
                      </li>
                      <li>
                        Is familiar with various financial products like stocks,
                        bonds, and mutual funds
                      </li>
                      <li>
                        Can manage debt effectively and understands the
                        importance of credit scores
                      </li>
                      <li>
                        Has some knowledge of tax implications on investments
                        and income
                      </li>
                      <li>
                        Is aware of retirement planning basics but may need
                        guidance on advanced strategies
                      </li>
                      <li>
                        May be looking to optimize their investment portfolio
                        and explore more complex financial instruments
                      </li>
                    </ul>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          </button>
          <button
            className={cn(
              "w-full rounded-md border border-beige p-3 text-left flex items-center justify-between",
              formData.financialLiteracy === "advanced" && "bg-offwhite",
            )}
            onClick={() =>
              setFormData({ ...formData, financialLiteracy: "advanced" })
            }
          >
            <span
              className={cn(
                "font-medium text-gray-600 text-sm",
                formData.financialLiteracy === "sophisticated" &&
                  "font-semibold",
              )}
            >
              Sophisticated
            </span>
            <HoverCard openDelay={300}>
              <HoverCardTrigger asChild>
                <HelpCircle
                  size={16}
                  className="ml-2 text-gray-500 hover:cursor-pointer"
                />
              </HoverCardTrigger>
              <HoverCardContent className="w-96">
                <div className="flex flex-col justify-between space-y-4 text-sm">
                  <div className="flex flex-row items-center gap-x-2">
                    <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                    <h4 className="font-semibold">What it means</h4>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <p>
                      A sophisticated investor typically refers to someone who:
                    </p>
                    <ul className="list-disc pl-4">
                      <li>
                        Has extensive knowledge and experience in financial
                        markets and complex investment strategies
                      </li>
                      <li>
                        Understands and actively manages a diverse portfolio of
                        assets across various sectors and asset classes
                      </li>
                      <li>
                        Is well-versed in advanced investment techniques such as
                        derivatives, hedging, and arbitrage
                      </li>
                      <li>
                        Thoroughly comprehends risk management principles and
                        applies them to optimize their portfolio
                      </li>
                      <li>
                        Is familiar with complex financial products like
                        structured products, private equity, and alternative
                        investments
                      </li>
                      <li>
                        Employs advanced tax optimization strategies and has a
                        comprehensive approach to retirement and estate planning
                      </li>
                      <li>
                        Stays informed about global economic trends and their
                        potential impact on investments
                      </li>
                      <li>
                        May be involved in high-level financial decision-making
                        or have professional experience in finance
                      </li>
                    </ul>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          </button>
        </div>
      </div>

      <div className="mt-4 flex w-full flex-row items-center gap-x-4">
        <label className="text-gray-500">Risk Tolerance</label>
        <input
          type="range"
          step={1}
          min={0}
          max={5}
          value={riskToleranceStepValue}
          onChange={(e) =>
            setFormData({
              ...formData,
              riskTolerance:
                +e.target.value === 0
                  ? ""
                  : riskToleranceLevels[parseInt(e.target.value) - 1].value,
            })
          }
          className="h-2 flex-1 cursor-pointer appearance-none rounded-lg bg-gray-200 [&::-webkit-slider-thumb]:size-6 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-[url('/images/onboarding/onboarding-peek-3.svg')] [&::-webkit-slider-thumb]:bg-contain [&::-webkit-slider-thumb]:bg-center [&::-webkit-slider-thumb]:bg-no-repeat"
          style={{
            background: `linear-gradient(to right, #E7CD80 0%, #E7CD80 ${riskToleranceStepValue * 20}%, #E5E7EB ${riskToleranceStepValue * 20}%, #E5E7EB 100%)`,
          }}
        />
      </div>

      {riskToleranceStepValue > 0 && (
        <div className="mt-6 w-full rounded-md border border-beige bg-offwhite px-4 py-3 shadow-md">
          <span className="text-sm font-bold text-gray-500">
            {riskToleranceLevels[riskToleranceStepValue - 1].name}:
          </span>
          <ul className="ml-4 mt-2 space-y-1">
            {riskToleranceLevels[riskToleranceStepValue - 1].list.map(
              (item, index) => (
                <li
                  key={index}
                  className="list-disc break-words text-sm font-medium text-gray-500"
                >
                  {item}
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
