import React, { useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TimeScale,
  ChartDataset,
  LineController,
} from "chart.js";
import { shortNumber } from "@/lib/utils";
import { draw } from "patternomaly";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "@/components/ui/button";
import iconConcierge from "/images/icon-concierge.svg";
import { Fullscreen } from "lucide-react";
import "chartjs-adapter-date-fns";
import { SnapshotV2 } from "@/lib/types";
import { format, parse } from "date-fns";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCurrency } from "@/hooks/useCurrency";

interface Props {
  snapshots: SnapshotV2[];
  startDate: Date;
  endDate: Date;
  grouping: "day" | "week" | "month";
}

ChartJS.register(
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
  BarController,
  zoomPlugin,
  TimeScale,
  LineController,
);

export const PerformanceChart: React.FC<Props> = ({
  snapshots,
  startDate,
  endDate,
  grouping,
}) => {
  const [chartSelection, setChartSelection] = useState({
    barChart: "pnl",
    lineCharts: ["totalCapitalInvested", "totalValue"],
  });
  const { currency } = useCurrency();
  const chartRef = React.useRef<ChartJS<"bar" | "line">>(null);

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: ChartJS) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;

      if (chart.getActiveElements().length > 0) {
        const xCoor = x.getPixelForValue(
          tooltip?.dataPoints[0]?.dataIndex ?? 0,
        );

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = "rgb(0,0,0,0.8)";
        ctx.moveTo(xCoor, top);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  const filteredSnapshots = snapshots.filter((snapshot) => {
    const snapshotDate = new Date(snapshot.timestamp);
    return snapshotDate >= startDate && snapshotDate <= endDate;
  });

  const parseSnapshotData = (snapshots: SnapshotV2[]) => {
    if (snapshots.length < 1) {
      return {
        labels: [],
        cumulativePnl: [],
        pnlChange: [],
        totalValueChange: [],
        totalValues: [],
        capitalFlows: [],
        totalCapitalInvested: [],
      };
    }

    const labels: Date[] = [];
    const cumulativePnl: number[] = [];
    const pnlChange: number[] = [];
    const totalValueChange: number[] = [];
    const totalValues: number[] = [];
    const capitalFlows: number[] = [];
    const totalCapitalInvested: number[] = [];

    snapshots.forEach((snapshot) => {
      labels.push(new Date(snapshot.timestamp));
      cumulativePnl.push(snapshot.pnl);
      pnlChange.push(snapshot.pnlChange);
      totalValues.push(snapshot.totalValue);
      totalValueChange.push(snapshot.totalValueChange);
      capitalFlows.push(snapshot.capitalFlow);
      totalCapitalInvested.push(snapshot.totalCapitalInvested);
    });

    return {
      labels,
      cumulativePnl,
      pnlChange,
      totalValues,
      capitalFlows,
      totalValueChange,
      totalCapitalInvested,
    };
  };

  // Parse snapshot data
  const snapshotData = parseSnapshotData(filteredSnapshots);

  const datasets: ChartDataset<"bar" | "line", number[]>[] = [];

  const createBarDataset = (
    label: string,
    data: number[],
    color: string,
  ): ChartDataset<"bar", number[]> => ({
    label,
    data,
    backgroundColor: data.map((value) =>
      value < 0
        ? draw("diagonal", "rgb(220, 38, 38, 0.0)", "#dc2626", 5)
        : draw("diagonal", "rgb(220, 38, 38, 0.0)", color, 5),
    ),
    borderColor: data.map((value) => (value < 0 ? "#dc2626" : color)),
    borderWidth: 1,
    barPercentage: 0.8,
    borderRadius: 4,
    type: "bar",
    yAxisID: "y2",
  });

  if (chartSelection.barChart === "totalValueChange") {
    datasets.push(
      createBarDataset(
        "Total Value Movement",
        snapshotData.totalValueChange,
        "#41989D",
      ),
    );
  } else if (chartSelection.barChart === "pnl") {
    datasets.push(
      createBarDataset("Profit/Loss", snapshotData.cumulativePnl, "#41989D"),
    );
  } else if (chartSelection.barChart === "capitalFlow") {
    datasets.push(
      createBarDataset("Capital Flow", snapshotData.capitalFlows, "#41989D"),
    );
  }

  const createLineDataset = (
    label: string,
    data: number[],
    color: string,
  ): ChartDataset<"line", number[]> => ({
    label,
    data,
    borderColor: color,
    type: "line" as const, // Explicitly type as "line"
    yAxisID: "y",
    tension: 0.5,
    pointBackgroundColor: color,
    pointRadius: 0,
    pointHitRadius: 15,
    borderWidth: 2,
  });

  if (chartSelection.lineCharts.includes("totalValue")) {
    datasets.push(
      createLineDataset("Total Value", snapshotData.totalValues, "#4966AF"),
    );
  }

  if (chartSelection.lineCharts.includes("totalCapitalInvested")) {
    datasets.push(
      createLineDataset(
        "Total Capital Invested",
        snapshotData.totalCapitalInvested,
        "#41989D",
      ),
    );
  }

  const data: ChartData<"bar" | "line", number[], string> = {
    labels: snapshotData.labels.map((date) => date.toISOString()),
    datasets,
  };
  const options: ChartOptions<"bar" | "line"> = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    transitions: {
      zoom: {
        animation: {
          duration: 400,
          easing: "easeOutQuad",
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: grouping,
        },
        min: snapshotData.labels[0]?.toISOString() || endDate.toISOString(),
        max: endDate.toISOString(),
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Total",
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
        },
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val, index) {
            const label = val === 0 ? 0 : shortNumber(val as number);
            // Hide every 2nd tick label
            return index % 2 === 0 ? label : "";
          },
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y2: {
        title: {
          display: true,
          text: (() => {
            switch (chartSelection.barChart) {
              case "totalValueChange":
                return "Total Value Movement";
              case "pnl":
                return "Profit/Loss";
              case "capitalFlow":
                return "Capital Flow";
              default:
                return chartSelection.barChart;
            }
          })(),
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
          drawOnChartArea: false,
        },
        position: "right",
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          mode: "x",
          drag: {
            enabled: true,
            backgroundColor: "#F4EEE380",
          },
          pinch: {
            enabled: true,
          },
        },
      },
      tooltip: {
        titleFont: {
          family: "'Manrope', sans-serif",
          size: 18,
        },
        bodyFont: {
          family: "'Manrope', sans-serif",
          size: 14,
          lineHeight: 1.4,
        },
        callbacks: {
          title: (context) => {
            const date = parse(
              context[0].label,
              "MMM d, yyyy, h:mm:ss a",
              new Date(),
            );
            return format(date, "MMM d, yyyy"); // Format date to "May 1, 2024"
          },
          label: (context) => {
            return `${context.dataset.label}: ${currency} ${shortNumber(
              context.raw as number,
            )}`;
          },
        },
        caretSize: 0,
        padding: 12,
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
      chartRef.current.options.scales!.x!.min = undefined;
      chartRef.current.update();
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-end space-x-2">
        <Select
          value={chartSelection.barChart}
          onValueChange={(value) => {
            setChartSelection((prev) => ({
              ...prev,
              barChart: value,
            }));
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select an option" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="totalValueChange">
                Total Value Movement
              </SelectItem>
              <SelectItem value="pnl">Profit/Loss</SelectItem>
              <SelectItem value="capitalFlow">Capital Flow</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">Cumulative</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuCheckboxItem
              checked={chartSelection.lineCharts.includes(
                "totalCapitalInvested",
              )}
              onCheckedChange={(value) => {
                setChartSelection((prev) => ({
                  ...prev,
                  lineCharts: value
                    ? [...prev.lineCharts, "totalCapitalInvested"]
                    : prev.lineCharts.filter(
                        (chart) => chart !== "totalCapitalInvested",
                      ),
                }));
              }}
            >
              Total Capital Invested
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={chartSelection.lineCharts.includes("totalValue")}
              onCheckedChange={(value) => {
                setChartSelection((prev) => ({
                  ...prev,
                  lineCharts: value
                    ? [...prev.lineCharts, "totalValue"]
                    : prev.lineCharts.filter((chart) => chart !== "totalValue"),
                }));
              }}
            >
              Total Value
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="h-[400px]">
        <Chart
          ref={chartRef}
          type="bar"
          options={options}
          data={data}
          plugins={[hoverLine]}
        />
      </div>
      {snapshotData.labels.length > 7 && (
        <div className="flex items-center space-x-4 rounded-md border p-4">
          <img src={iconConcierge} alt="concierge" className="h-6 w-6" />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none text-gray-700">
              Did you know?
            </p>
            <p className="text-sm text-gray-500">
              You can drag on the chart to zoom in
            </p>
          </div>
          <Button
            variant="outline"
            onClick={handleResetZoom}
            className="gap-x-2"
          >
            <Fullscreen size={16} />
            Reset Zoom
          </Button>
        </div>
      )}
    </div>
  );
};
