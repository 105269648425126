import goal1 from "/images/onboarding/goals/goal-1.svg";
import goal2 from "/images/onboarding/goals/goal-2.svg";
import goal3 from "/images/onboarding/goals/goal-3.svg";
import goal4 from "/images/onboarding/goals/goal-4.svg";
import goal5 from "/images/onboarding/goals/goal-5.svg";
import goal6 from "/images/onboarding/goals/goal-6.svg";
import ownGoal from "/images/onboarding/goals/own-goal.svg";

export const goalOptions = [
  {
    title: "I want to achieve financial independence to retire early.",
    image: goal1,
    duration: "10 - 20 yrs",
  },
  {
    title:
      "I want to save for my own or my children's college tuition and expenses.",
    image: goal2,
    duration: "15 - 20 yrs",
  },
  {
    title: "I want to save for a down payment and purchase a house.",
    image: goal3,
    duration: "5 - 10 yrs",
  },
  {
    title: "I want to plan for a family",
    image: goal4,
    duration: "5 - 10 yrs",
  },
  {
    title: "I want to save for a wedding",
    image: goal5,
    duration: "1 - 2 yrs",
  },
  {
    title: "I want to build an emergency fund to cover unexpected expenses.",
    image: goal6,
    duration: "1 - 2 yrs",
  },
  {
    title: "Create your own financial goal.",
    image: ownGoal,
    duration: "Flexible",
  },
];

export const riskToleranceLevels = [
  {
    name: "Conservative",
    value: "conservative",
    list: [
      "Prefers low-risk investments with minimal fluctuations.",
      "Focuses on preserving capital and earning steady, though possibly lower, returns.",
      "Suitable for those with a short investment horizon or low risk tolerance.",
    ],
  },
  {
    name: "Moderately Conservative",
    value: "moderately_conservative",
    list: [
      "Willing to take on some risk for slightly higher returns.",
      "Prefers a balanced approach with a mix of low-risk and moderate-risk investments.",
      "Suitable for those with a moderate risk tolerance and a medium investment horizon.",
    ],
  },
  {
    name: "Moderate",
    value: "moderate",
    list: [
      "Comfortable with taking on moderate risk to achieve higher returns.",
      "Balances between growth and stability, investing in a mix of stocks and bonds.",
      "Suitable for those with a balanced risk tolerance and a medium to long investment horizon.",
    ],
  },
  {
    name: "Moderately Aggressive",
    value: "moderately_aggressive",
    list: [
      "Willing to accept higher risk for the potential of higher returns.",
      "Focuses more on growth investments, such as stocks, with some allocation to bonds.",
      "Suitable for those with a higher risk tolerance and a long investment horizon.",
    ],
  },
  {
    name: "Aggressive",
    value: "aggressive",
    list: [
      "Seeks higher returns through aggressive growth investments.",
      "Emphasizes growth investments, such as stocks, with minimal allocation to bonds.",
      "Suitable for those with a high risk tolerance and a long investment horizon.",
    ],
  },
];
