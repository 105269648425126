import { useEffect, useRef, memo } from "react";

interface MainSymbol {
  name?: string;
  symbol: string;
  interval: string;
}

interface CompareSymbol {
  symbol: string;
  lineColor: string;
  lineWidth: number;
}

interface TradingViewWidgetProps {
  symbols: MainSymbol[];
  compareSymbol?: CompareSymbol;
}

function CompareSymbolOverview({
  symbols,
  compareSymbol,
}: TradingViewWidgetProps) {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "symbols": ${JSON.stringify(
          symbols.map((s) => [s.name, `${s.symbol}|${s.interval}`]),
        )},
        "chartOnly": true,
        "width": "100%",
        "height": "400",
        "locale": "en",
        "colorTheme": "light",
        "autosize": true,
        "showVolume": true,
        "showMA": false,
        "hideDateRanges": false,
        "hideMarketStatus": true,
        "hideSymbolLogo": true,
        "scalePosition": "right",
        "scaleMode": "Percentage",
        "fontFamily": "Manrope, sans-serif",
        "fontSize": "14",
        "fontColor": "rgb(156 163 175)",
        "noTimeScale": false,
        "valuesTracking": "2",
        "changeMode": "price-only",
        "chartType": "line",
        "lineColor": "#41989D",
        "maLineColor": "#2962FF",
        "maLineWidth": 1,
        "maLength": 9,
        "lineWidth": 2,
        "lineType": 1,
        "backgroundColor": "#fff",
        "gridLineColor": "rgba(42, 46, 57, 0.20)",
        "compareSymbol": ${
          compareSymbol ? JSON.stringify(compareSymbol) : "null"
        },
        "dateRanges": [
          "1w|15",
          "1m|60",
          "3m|60",
          "12m|1D",
          "60m|1W",
          "all|1M"
        ],
        "dateFormat": "dd/MM/yyyy",
        "timeHoursFormat": "12-hours"
      }
    `;

    container.current?.replaceWith(script);
  }, [symbols, compareSymbol]);

  return <div className="tradingview-widget-container" ref={container}></div>;
}
export default memo(CompareSymbolOverview);
