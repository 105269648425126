function generateConfig() {
  const {
    VITE_BACKEND_BASE_URL,
    VITE_INTEGRATIONS_BASE_URL,
    VITE_CLERK_PUBLISHABLE_KEY,
    VITE_TRIAL_ENDING_BANNER_DAYS_LEFT,
    VITE_PUBLIC_POSTHOG_KEY,
    VITE_PUBLIC_POSTHOG_HOST,
    VITE_CHATWOOT_WEBSITE_TOKEN,
    VITE_CHATWOOT_USER_IDENTITY_VERIFICATION,
    VITE_ENABLE_ALL_FEATURE_FLAGS,
    VITE_FLANKS_LINK,
    VITE_VERSION_SWITCH_ENABLE,
    VITE_KNOCK_PUBLIC_API_KEY,
    VITE_KNOCK_FEED_CHANNEL_ID,
    VITE_WISP_BLOG_ID,
    VITE_SENTRY_DSN,
    VITE_SENTRY_ENVIRONMENT,
  } = import.meta.env;
  if (!VITE_BACKEND_BASE_URL) {
    throw Error("VITE_BACKEND_BASE_URL is not defined");
  }
  if (!VITE_INTEGRATIONS_BASE_URL) {
    throw Error("VITE_INTEGRATIONS_BASE_URL is not defined");
  }
  if (!VITE_CLERK_PUBLISHABLE_KEY) {
    throw Error("VITE_CLERK_PUBLISHABLE_KEY is not defined");
  }
  if (!VITE_TRIAL_ENDING_BANNER_DAYS_LEFT) {
    throw Error("VITE_TRIAL_ENDING_BANNER_DAYS_LEFT is not defined");
  }
  if (!VITE_PUBLIC_POSTHOG_KEY) {
    throw Error("VITE_PUBLIC_POSTHOG_KEY is not defined");
  }
  if (!VITE_PUBLIC_POSTHOG_HOST) {
    throw Error("VITE_PUBLIC_POSTHOG_HOST is not defined");
  }
  if (!VITE_CHATWOOT_WEBSITE_TOKEN) {
    throw Error("VITE_CHATWOOT_WEBSITE_TOKEN is not defined");
  }
  if (!VITE_CHATWOOT_USER_IDENTITY_VERIFICATION) {
    throw Error("VITE_CHATWOOT_USER_IDENTITY_VERIFICATION is not defined");
  }
  if (!VITE_FLANKS_LINK) {
    throw Error("VITE_FLANKS_LINK is not defined");
  }
  if (!VITE_KNOCK_PUBLIC_API_KEY) {
    throw Error("VITE_KNOCK_PUBLIC_API_KEY is not defined");
  }

  if (!VITE_KNOCK_FEED_CHANNEL_ID) {
    throw Error("VITE_KNOCK_FEED_CHANNEL_ID is not defined");
  }
  if (!VITE_WISP_BLOG_ID) {
    throw Error("VITE_WISP_BLOG_ID is not defined");
  }
  if (!VITE_SENTRY_DSN) {
    throw Error("VITE_SENTRY_DSN is not defined");
  }
  if (!VITE_SENTRY_ENVIRONMENT) {
    throw Error("VITE_SENTRY_ENVIRONMENT is not defined");
  }
  if (
    !["local", "development", "production"].includes(VITE_SENTRY_ENVIRONMENT)
  ) {
    throw Error("Invalid VITE_SENTRY_ENVIRONMENT value");
  }

  return {
    backendUrl: VITE_BACKEND_BASE_URL,
    integrationsUrl: VITE_INTEGRATIONS_BASE_URL,
    clerkPublishableKey: VITE_CLERK_PUBLISHABLE_KEY,
    trialEndingBannerDaysLeft: parseInt(VITE_TRIAL_ENDING_BANNER_DAYS_LEFT),
    publicPosthogKey: VITE_PUBLIC_POSTHOG_KEY,
    publicPosthogHost: VITE_PUBLIC_POSTHOG_HOST,
    chatwootWebsiteToken: VITE_CHATWOOT_WEBSITE_TOKEN,
    chatwootUserIdentityVerification: VITE_CHATWOOT_USER_IDENTITY_VERIFICATION,
    enableAllFeatureFlags: VITE_ENABLE_ALL_FEATURE_FLAGS === "true",
    flanksLink: VITE_FLANKS_LINK,
    versionSwitchEnabled: VITE_VERSION_SWITCH_ENABLE === "true",
    publicKnockApiKey: VITE_KNOCK_PUBLIC_API_KEY,
    knockFeedChannelId: VITE_KNOCK_FEED_CHANNEL_ID,
    wispBlogId: VITE_WISP_BLOG_ID,
    sentryDsn: VITE_SENTRY_DSN,
    sentryEnvironment: VITE_SENTRY_ENVIRONMENT,
  };
}

const config = generateConfig();

export default config;
