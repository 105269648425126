import { PerformanceChart } from "@/components/Performance/PerformanceChart";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrency } from "@/hooks/useCurrency";
import { AssetFilter } from "@/components/Performance/AssetFilter";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import {
  calculateAnnualizedReturns,
  calculateSimpleReturns,
  getHoldingPeriodInDays,
  getSortedSnapshorts,
  getTotals,
} from "@/lib/financials";
import { Filters } from "@/components/Performance/Filters";
import { InfoCards } from "@/components/Performance/InfoCards";

const today = new Date();
const oneYearAgo = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1),
);

export function PerformancePage() {
  const [filters, setFilters] = useState<{
    assetClass: string[];
    account: number[];
    currency: string[];
    tag: string[];
  }>({
    account: [],
    assetClass: [],
    currency: [],
    tag: [],
  });
  const [date, setDate] = useState<DateRange | undefined>({
    from: oneYearAgo,
    to: today,
  });
  const [grouping, setGrouping] = useState<"day" | "week" | "month">("day");
  const { currency } = useCurrency();
  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();

  if (assetLiabilitiesAccountsContext.state === "LOADING")
    return <Skeleton className="h-screen w-full" />;

  if (assetLiabilitiesAccountsContext.state === "ERROR")
    throw new Error(assetLiabilitiesAccountsContext.message);

  if (assetLiabilitiesAccountsContext.state !== "SUCCESS") return null;

  const {
    data,
    accountFilters,
    assetClassFilters,
    currencyFilters,
    tagFilters,
  } = assetLiabilitiesAccountsContext;

  const filteredData = data.filter(
    (assetLiability) =>
      !filters.account.includes(assetLiability.account.id) &&
      !filters.assetClass.includes(assetLiability.assetClass) &&
      !filters.currency.includes(assetLiability.currency) &&
      !filters.tag.includes(assetLiability.tag || "Uncategorized"),
  );

  const { assetTotalValue, liabilityTotalValue, totalPL, currentBalance } =
    getTotals(filteredData, date?.from, date?.to);

  const totalInvested = currentBalance - totalPL;

  const holdingPeriodInDays = getHoldingPeriodInDays(filteredData);

  const simpleReturns = calculateSimpleReturns(currentBalance, totalInvested);
  const annualizedReturns = calculateAnnualizedReturns(
    totalInvested,
    currentBalance,
    holdingPeriodInDays,
  );

  const deposits = getSortedSnapshorts(filteredData, "month").reduce(
    (acc, snapshot) =>
      acc + (snapshot.capitalFlow > 0 ? snapshot.capitalFlow : 0),
    0,
  );

  const withdrawals = Math.abs(totalInvested - deposits);

  return (
    <>
      <Filters
        filters={filters}
        setFilters={setFilters}
        accountFilters={accountFilters}
        assetClassFilters={assetClassFilters}
        currencyFilters={currencyFilters}
        tagFilters={tagFilters}
        date={date}
        setDate={setDate}
        grouping={grouping}
        setGrouping={setGrouping}
      />

      <InfoCards
        currency={currency}
        totalPL={totalPL}
        totalInvested={totalInvested}
        currentBalance={currentBalance}
        simpleReturns={simpleReturns}
        annualizedReturns={annualizedReturns}
        holdingPeriodInDays={holdingPeriodInDays}
        deposits={deposits}
        withdrawals={withdrawals}
      />

      <div className="mt-5 rounded-sm border border-gray-200 bg-white p-5">
        <PerformanceChart
          snapshots={
            grouping === "week"
              ? getSortedSnapshorts(filteredData, "week")
              : grouping === "month"
                ? getSortedSnapshorts(filteredData, "month")
                : getSortedSnapshorts(filteredData, "day")
          }
          startDate={date?.from ?? oneYearAgo}
          endDate={date?.to ?? today}
          grouping={grouping}
        />
      </div>

      <AssetFilter
        grouping={grouping}
        data={filteredData}
        assetTotalValue={assetTotalValue}
        liabilityTotalValue={liabilityTotalValue}
      />
    </>
  );
}
