import axios from "@/lib/axios";
import config from "@/lib/config";
import { Profile } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient.ts";
import { GET_PROFILE_QUERY_KEY } from "@/lib/constants.ts";

export function useUpdateProfileMutation() {
  const { getToken } = useAuth();
  const { mutate: updateProfile, isPending } = useMutation({
    mutationFn: async (updatedFields: Profile) => {
      const token = await getToken();
      const { data } = await axios.patch(
        `${config.backendUrl}/profile`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_PROFILE_QUERY_KEY],
      });
      toast.success("Profile data updated successfully!");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update profile data!");
    },
  });

  return { updateProfile, isPending };
}
