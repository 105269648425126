import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Profile } from "@/lib/types";
import { HelpCircle } from "lucide-react";
import { Input } from "@/components/ui/input";
import { NumberInputIcon } from "@/components/ui/number-input-icon";
import { Separator } from "@/components/ui/separator";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";
import peekpedia from "/images/icon-peekpedia.svg";

type EmploymentSavingsProps = {
  currency: Currency;
  formData: Profile;
  setFormData: (data: Profile) => void;
};

export function EmploymentSavings({
  currency,
  formData,
  setFormData,
}: EmploymentSavingsProps) {
  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium text-gray-700">
        Employment & Savings
      </h4>
      <Separator />

      <div>
        <label className="block py-2 text-gray-500">Occupation</label>
        <Input
          placeholder="Enter your occupation"
          className="flex-1"
          defaultValue={formData.occupation}
          onChange={(e) =>
            setFormData({ ...formData, occupation: e.target.value })
          }
        />
      </div>

      <div>
        <label className="block py-2 text-gray-500">Income</label>
        <div className="flex flex-row items-center gap-x-2">
          <NumberInputIcon
            locale={getLocaleForCurrency(currency)}
            placeholder="0"
            wrapperClassName="flex-1"
            value={formData.income?.toString() || "0"}
            onChange={(e) =>
              setFormData({ ...formData, income: +e.target.value })
            }
            startAdornment={<span className="text-gray-600">{currency}</span>}
            className="text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          />
          <Select
            onValueChange={(value) =>
              setFormData({ ...formData, incomeFrequency: value })
            }
            defaultValue={formData.incomeFrequency}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="annually">Annually</SelectItem>
              <SelectItem value="monthly">Monthly</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div>
        <div className="flex flex-row items-center">
          <label className="block py-2 text-gray-500">
            Approximate Savings Rate
          </label>
          <HoverCard openDelay={300}>
            <HoverCardTrigger asChild>
              <HelpCircle
                size={16}
                className="ml-2 text-gray-500 hover:cursor-pointer"
              />
            </HoverCardTrigger>
            <HoverCardContent className="w-96">
              <div className="flex flex-col justify-between space-y-4 text-sm">
                <div className="flex flex-row items-center gap-x-2">
                  <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                  <h4 className="font-semibold">How it works</h4>
                </div>
                <div className="flex flex-col space-y-2">
                  <p>
                    Savings rate represents the percentage of your income that
                    you save.
                  </p>
                  <p>To calculate your savings rate:</p>
                  <ol className="list-decimal pl-4">
                    <li>Determine your total income after taxes.</li>
                    <li>
                      Calculate the total amount you save, including
                      contributions to savings accounts, retirement funds, and
                      other investments.
                    </li>
                    <li>Divide your total savings by your post-tax income.</li>
                    <li>Multiply by 100 to get the percentage.</li>
                  </ol>
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>
        <NumberInputIcon
          locale={getLocaleForCurrency(currency)}
          placeholder="0"
          wrapperClassName="flex-1"
          value={formData.approximateSavingsRate?.toString() || "0"}
          onChange={(e) =>
            setFormData({
              ...formData,
              approximateSavingsRate: +e.target.value,
            })
          }
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          endAdornment={<span className="text-gray-600">%</span>}
        />
      </div>
    </div>
  );
}
