import config from "@/lib/config";
import { useQuery } from "@tanstack/react-query";
import { buildWispClient } from "@wisp-cms/client";

export const wisp = buildWispClient({
  blogId: config.wispBlogId,
});

export const useGetPosts = (
  props: Parameters<(typeof wisp)["getPosts"]>[0],
) => {
  return useQuery({
    queryKey: ["getPosts", props],
    queryFn: async () => {
      return wisp.getPosts(props);
    },
    enabled: true,
  });
};

export const useGetPost = (slug?: string) => {
  return useQuery({
    queryKey: ["getPost", slug],
    queryFn: async () => {
      return wisp.getPost(slug!);
    },
    enabled: !!slug,
  });
};

export const useGetRelatedPosts = (
  props: Parameters<(typeof wisp)["getRelatedPosts"]>[0],
) => {
  return useQuery({
    queryKey: ["getRelatedPosts", props],
    queryFn: async () => {
      return wisp.getRelatedPosts(props);
    },
    enabled: true,
  });
};

export const useGetTags = (props: Parameters<(typeof wisp)["getTags"]>[0]) => {
  return useQuery({
    queryKey: ["getTags", props],
    queryFn: async () => {
      return wisp.getTags(props);
    },
    enabled: true,
  });
};
