import { handleTrial } from "@/components/Billing/utils";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToTrialCheckout() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  posthog?.capture("clicked_trial");
  handleTrial(getToken);
  return <></>;
}
