import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@/components/ui/table";
import { InputRowData } from "./types";

export function InvalidTable({ data }: { data: InputRowData[] }) {
  return (
    <div className="my-4 max-h-40 overflow-y-scroll rounded-md border border-gray-200">
      <Table>
        <TableHeader>
          <TableRow className="bg-border/50">
            <TableHead>Account Name</TableHead>
            <TableHead>Account Type</TableHead>
            <TableHead>Asset Name</TableHead>
            <TableHead>Asset Type</TableHead>
            <TableHead>Full Name</TableHead>
            <TableHead>Currency</TableHead>
            <TableHead>Quantity</TableHead>
            <TableHead>Unit Value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row: InputRowData, index: number) => (
            <TableRow key={index}>
              <TableCell>{row.accountName}</TableCell>
              <TableCell>{row.accountType}</TableCell>
              <TableCell>{row.assetName}</TableCell>
              <TableCell>{row.assetType}</TableCell>
              <TableCell>{row.fullName}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.unitValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
