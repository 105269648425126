import { CurrencyProvider } from "@/hooks/useCurrency";
import { SubscriptionsProvider } from "@/hooks/useSubscriptions";
import { queryClient } from "@/lib/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";

// Only Desktop layout support at the moment thus the min-width
export function BillingLayout() {
  return (
    <QueryClientProvider client={queryClient}>
      <SubscriptionsProvider>
        <CurrencyProvider>
          <div className="flex flex-col overflow-auto bg-offwhite">
            <main className="w-full flex-1 shrink-0 rounded-t-2xl bg-background px-10 pb-10 pt-6">
              <div className="mx-auto min-w-min max-w-6xl">
                <Outlet />
              </div>
            </main>
          </div>
        </CurrencyProvider>
      </SubscriptionsProvider>
    </QueryClientProvider>
  );
}
