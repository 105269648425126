import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Section, SubSection, TableRowData } from "./types";
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { columns } from "./columns";
import { useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

export function AssetLiabilityTable({ data }: { data: TableRowData[] }) {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) =>
      row.type === "section" || row.type === "subSection"
        ? (row.subRows as Section[] | SubSection[])
        : [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { rows } = table.getRowModel();
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48, // Adjust this based on your row height
    overscan: 30,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize() + 48;

  return (
    <div ref={parentRef} className="max-h-80 overflow-auto">
      <div
        style={{
          height: `${totalSize}px`,
        }}
      >
        <Table className="h-full table-auto overflow-scroll">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-border/50">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {virtualRows.map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              return (
                <TableRow
                  key={row.id}
                  className={`cursor-pointer border-b-0 hover:bg-gray-100 [&:not(:last-child)]:shadow-line`}
                  style={{
                    height: `${virtualRow.size}px`,
                    width: "100%",
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                  }}
                  {...(row.getCanExpand() && {
                    onClick: (event) => {
                      const target = event.target as HTMLElement;

                      if (target.tagName.toLowerCase() === "button") {
                        // Do not take any action if the click was on a button / checkbox
                        return;
                      }
                      row.getToggleExpandedHandler()();
                    },
                  })}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
