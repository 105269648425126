import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Profile } from "@/lib/types";

type StepTwoProps = {
  data: Profile;
  onNext: (data: Profile) => void;
  onSkip: () => void;
};

export function StepTwo({ data, onNext, onSkip }: StepTwoProps) {
  const [formData, setFormData] = useState({
    maritalStatus: data.maritalStatus,
    numberOfDependents: data.numberOfDependents,
  });

  const updateFormData = (
    field: keyof typeof formData,
    value: string | number,
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">
          💍 Marital status
        </label>
        <Select
          wrapperClass="flex-1 w-full"
          onValueChange={(value) => updateFormData("maritalStatus", value)}
          defaultValue={data.maritalStatus}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="single">Single</SelectItem>
            <SelectItem value="married">Married</SelectItem>
            <SelectItem value="preferNotToSay">Prefer not to say</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">
          👶🏻 Number of dependents
        </label>
        <Select
          wrapperClass="flex-1 w-full"
          onValueChange={(value) =>
            updateFormData("numberOfDependents", +value)
          }
          defaultValue={data.numberOfDependents?.toString() || "0"}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">0</SelectItem>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">2</SelectItem>
            <SelectItem value="3">3</SelectItem>
            <SelectItem value="4">4</SelectItem>
            <SelectItem value="5">5+</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="mt-4 flex w-full flex-row justify-between">
        <Button variant="link" onClick={onSkip} className="px-0 text-gray-400">
          Skip family details
        </Button>

        <Button
          variant="default"
          onClick={() => onNext(formData)}
          disabled={!formData.maritalStatus}
        >
          Next
        </Button>
      </div>
    </>
  );
}
