import { AssetLiabilityV2, SnapshotV2 } from "./types";

export const calculateHoldingPeriodInDays = (purchaseDates: Date[]): number => {
  const currentDate = new Date();
  const earliestPurchaseDate = new Date(
    Math.min(...purchaseDates.map((date) => date.getTime())),
  );

  const timeDiff = currentDate.getTime() - earliestPurchaseDate.getTime();
  const holdingPeriodInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return holdingPeriodInDays;
};

export const calculateAnnualizedReturns = (
  totalInvested: number,
  currentBalance: number,
  holdingPeriodInDays: number,
): number => {
  const totalReturn = currentBalance - totalInvested;
  const holdingPeriodInYears = holdingPeriodInDays / 365;

  const annualizedReturn =
    Math.pow(1 + totalReturn / totalInvested, 1 / holdingPeriodInYears) - 1;

  return annualizedReturn * 100;
};

export const calculateSimpleReturns = (
  currentBalance: number,
  totalInvested: number,
) => ((currentBalance - totalInvested) / totalInvested) * 100;

export const getTotals = (
  data: AssetLiabilityV2[],
  startDate?: Date,
  endDate?: Date,
) => {
  return data.reduce(
    (acc, account) => {
      const filteredSnapshots = account.snapshots["day"].filter((snapshot) => {
        return (
          (!startDate || new Date(snapshot.timestamp) >= startDate) &&
          (!endDate || new Date(snapshot.timestamp) <= endDate)
        );
      });

      const lastSnapshot = filteredSnapshots.slice(-1)[0];
      if (lastSnapshot) {
        if (account.assetOrLiability === "ASSET") {
          acc.assetTotalValue += lastSnapshot.totalValue;
        } else if (account.assetOrLiability === "LIABILITY") {
          acc.liabilityTotalValue += lastSnapshot.totalValue;
        }
        acc.totalPL += lastSnapshot.pnl;
        acc.currentBalance += lastSnapshot.totalValue;
      }
      return acc;
    },
    {
      assetTotalValue: 0,
      liabilityTotalValue: 0,
      totalPL: 0,
      currentBalance: 0,
    },
  );
};

export const getHoldingPeriodInDays = (data: AssetLiabilityV2[]) => {
  const recentActivity = data.filter((assetLiability) =>
    assetLiability.snapshots["day"].some(
      (snapshot) => snapshot.quantityChange !== 0,
    ),
  );

  const purchaseDates = recentActivity.map(
    (assetLiability) => new Date(assetLiability.snapshots["day"][0].timestamp),
  );

  const currentDate = new Date();
  const earliestPurchaseDate = new Date(
    Math.min(...purchaseDates.map((date) => date.getTime())),
  );

  const timeDiff = currentDate.getTime() - earliestPurchaseDate.getTime();
  const holdingPeriodInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return holdingPeriodInDays;
};

export const getSortedSnapshorts = (
  data: AssetLiabilityV2[],
  type: "day" | "week" | "month",
) => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), 0, 1);
  const combinedSnapshots = new Map<string, SnapshotV2>();

  data.forEach((assetLiability: AssetLiabilityV2) => {
    const snapshots = assetLiability.snapshots[type];

    snapshots?.forEach((snapshot) => {
      const snapshotDate = new Date(snapshot.timestamp);
      if (snapshotDate >= startDate && snapshotDate <= today) {
        const dateKey = snapshot.timestamp;
        if (!combinedSnapshots.has(dateKey)) {
          combinedSnapshots.set(dateKey, {
            ...snapshot,
          });
        } else {
          const existingSnapshot = combinedSnapshots.get(dateKey)!;
          // there's only one snapshot per asset per day|week|month, so we can just add up the values across all assets
          existingSnapshot.pnl += snapshot.pnl;
          existingSnapshot.capitalFlow += snapshot.capitalFlow;
          existingSnapshot.totalValue += snapshot.totalValue;
          existingSnapshot.totalValueChange += snapshot.totalValueChange;
          existingSnapshot.totalCapitalInvested +=
            snapshot.totalCapitalInvested;
        }
      }
    });
  });

  const result = Array.from(combinedSnapshots.values()).sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  );

  return result;
};
