import * as React from "react";
import { cn } from "@/lib/utils";

export interface NumberInputIconProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  locale: string;
  value?: string; // Accept value as a prop
  wrapperClassName?: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

function parseLocaleNumber(stringNumber: string, locale: string): number {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replaceAll(thousandSeparator, "")
      .replace(decimalSeparator, "."),
  );
}

const allowedPartials = ["", "-", ".", "-."];
const NumberInputIcon = React.forwardRef<
  HTMLInputElement,
  NumberInputIconProps
>(
  (
    {
      wrapperClassName,
      className,
      startAdornment,
      endAdornment,
      onChange,
      value: propValue = "",
      ...props
    },
    ref,
  ) => {
    const formatter = React.useMemo(
      () => new Intl.NumberFormat(props.locale, { maximumFractionDigits: 20 }),
      [props.locale],
    );

    const formatValue = React.useCallback(
      (inputValue: string, locale: string) => {
        // special handling for initial number inputs that are valid, but not a number yet
        if (allowedPartials.includes(inputValue)) return inputValue;

        const startsWithNegativeSign = inputValue.startsWith("-");
        if (startsWithNegativeSign) inputValue = inputValue.slice(1); // remove negative sign

        const decimalSeparator = Intl.NumberFormat(locale)
          .format(1.1)
          .replace(/\p{Number}/gu, "");
        const endsWithDecimalSeparator = inputValue.endsWith(decimalSeparator);

        const cleanedValue = parseLocaleNumber(inputValue, locale);
        if (!isNaN(Number(cleanedValue))) {
          let formattedValue = formatter.format(cleanedValue);

          if (startsWithNegativeSign) {
            formattedValue = "-" + formattedValue; // put back negative sign
          }
          // If the original input ends with a decimal separator, append it.
          if (endsWithDecimalSeparator) {
            formattedValue += ".";
          }

          return formattedValue;
        }
        return ""; // clear the input if it is not a number
      },
      [formatter],
    );

    const [value, setValue] = React.useState(() =>
      formatValue(propValue, props.locale),
    );

    React.useEffect(() => {
      const formattedPropValue = formatValue(propValue, props.locale);
      if (formattedPropValue !== value) {
        setValue(formattedPropValue);
      }
    }, [propValue, props.locale, formatValue, value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;

      const formattedValue = formatValue(rawValue, props.locale);
      setValue(formattedValue);

      // Create a synthetic event to pass the numeric value to the parent's onChange
      // only pass the value back if it is a valid number, instead of "-."
      // only pass the value back if it is different from the propValue
      const parsedPropValue = parseLocaleNumber(propValue, props.locale);
      const parsedRawValue = parseLocaleNumber(rawValue, props.locale);

      if (
        onChange &&
        parsedPropValue !== parsedRawValue &&
        !isNaN(parsedRawValue)
      ) {
        const modifiedEvent = {
          ...event,
          target: {
            ...event.target,
            value: parsedRawValue.toString(), // Pass the unformatted value to the parent
          },
        };
        onChange(modifiedEvent as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const hasAdornment = Boolean(startAdornment) || Boolean(endAdornment);

    return (
      <>
        {hasAdornment ? (
          <div
            className={cn(
              "flex h-10 items-center justify-center gap-2 rounded-md border border-input bg-transparent px-3 ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50",
              wrapperClassName,
            )}
            data-disabled={props.disabled}
          >
            {startAdornment && (
              <div className={cn("text-muted-foreground")}>
                {startAdornment}
              </div>
            )}
            <input
              type="text" // Use text type to allow formatted input
              value={value}
              onChange={handleChange}
              className={cn(
                "flex h-full w-full rounded-md bg-transparent py-2 text-sm file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground shadow-none outline-none border-none focus-visible:outline-none focus-visible:border-none focus-visible:shadow-none",
                className,
              )}
              ref={ref}
              {...props}
            />
            {endAdornment && (
              <div className={cn("text-muted-foreground")}>{endAdornment}</div>
            )}
          </div>
        ) : (
          <input
            type="text" // Use text type to allow formatted input
            value={value}
            onChange={handleChange}
            className={cn(
              "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              className,
            )}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);

NumberInputIcon.displayName = "NumberInputIcon";

export { NumberInputIcon };
