import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { editAssetFormSchema } from "../components/AddAsset/schema";
import { usePostHog } from "posthog-js/react";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { AssetLiabilityV2 } from "@/lib/types";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function useUpdateAssetMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const handleUpdateAsset = async (data: {
    input: z.infer<typeof editAssetFormSchema>[];
  }) => {
    const token = await getToken();
    if (!token) throw new Error("User not authenticated");

    const response = await axios.patch(
      `${config.backendUrl}/user/assetsliabilities`,
      data.input,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return {
      data: response.data,
      input: data.input,
    };
  };

  const { mutate: updateAsset, isPending } = useMutation({
    mutationFn: handleUpdateAsset,
    onMutate: async (updateData) => {
      const now = new Date();

      await queryClient.cancelQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });

      const previousData = queryClient.getQueryData([
        GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
      ]);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        (prevData: AssetLiabilityV2[]) => {
          return prevData.map((assetLiability) => {
            const updatedAsset = updateData.input.find(
              (input) => input.id === assetLiability.id,
            );

            if (updatedAsset) {
              const tempSnapshot = {
                assetLiabilityId: assetLiability.id,
                timestamp: now.toISOString(),
                totalValue: updatedAsset.quantity * updatedAsset.unitValue,
                prevTotalValue: 0,
                quantity: updatedAsset.quantity,
                prevQuantity: 0,
                unitValue: updatedAsset.unitValue,
                prevUnitValue: 0,
                pnl: 0,
                prevPnl: 0,
                averageCost: updatedAsset.unitValue,
                capitalFlow: updatedAsset.quantity * updatedAsset.unitValue,
                totalCapitalInvested:
                  updatedAsset.quantity * updatedAsset.unitValue,
                totalValueChange:
                  updatedAsset.quantity * updatedAsset.unitValue,
                totalValueChangePercentage: 0,
                unitValueChange: updatedAsset.unitValue,
                unitValueChangePercentage: 0,
                quantityChange: updatedAsset.quantity,
                quantityChangePercentage: 0,
                pnlChange: 0,
                pnlChangePercentage: 0,
                pnlPercentage: 0,
                original: {
                  pnl: 0,
                  prevPnl: 0,
                  unitValue: 1,
                  unitValueChange: 1,
                  totalValue: updatedAsset.quantity * updatedAsset.unitValue,
                  prevTotalValue: 0,
                  prevUnitValue: 0,
                  averageCost: 1,
                  capitalFlow: updatedAsset.quantity * updatedAsset.unitValue,
                  pnlChange: 0,
                  totalCapitalInvested:
                    updatedAsset.quantity * updatedAsset.unitValue,
                },
              };

              return {
                ...assetLiability,
                currency: updatedAsset.currency,
                name: updatedAsset.name,
                tag: updatedAsset.tag,
                snapshots: {
                  day: [...assetLiability.snapshots.day, tempSnapshot],
                  week: [...assetLiability.snapshots.week, tempSnapshot],
                  month: [...assetLiability.snapshots.month, tempSnapshot],
                },
              };
            }

            return assetLiability;
          });
        },
      );

      return { previousData };
    },
    onSuccess: async ({ input }) => {
      await queryClient.refetchQueries({
        queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
      });
      const captureChanges = (asset: z.infer<typeof editAssetFormSchema>) => ({
        currency: asset.currency,
        fullname: asset.fullName ?? "",
        name: asset.name,
        type: asset.type,
      });

      input.forEach((asset) => {
        posthog.capture("submit_assets_liabilities_form_success", {
          ...asset,
          assetLiabilities: captureChanges(asset),
        });
      });
    },
    onError: (error, _, context) => {
      console.error(error);

      toast.error("Failed to update asset/liability");

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });
    },
  });

  return { updateAsset, isPending };
}
