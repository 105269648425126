import config from "@/lib/config";
import axios from "axios";

export async function handleTrial(getToken: () => Promise<string | null>) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data: checkoutSessionUrl } = await axios.get(
    `${config.backendUrl}/billing/trial`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  window.location.href = checkoutSessionUrl;
}

export async function handleAnnual(getToken: () => Promise<string | null>) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data: checkoutSessionUrl } = await axios.get(
    `${config.backendUrl}/billing/annual`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  window.location.href = checkoutSessionUrl;
}

export async function handleMonthly(getToken: () => Promise<string | null>) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data: checkoutSessionUrl } = await axios.get(
    `${config.backendUrl}/billing/monthly`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  window.location.href = checkoutSessionUrl;
}

export async function handlePortal(getToken: () => Promise<string | null>) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data: portalSessionUrl } = await axios.get(
    `${config.backendUrl}/billing/portal`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  window.location.href = portalSessionUrl;
}
