import React, { useMemo, useState } from "react";
import { Card } from "@/components/ui/card";
import { DisplayValue } from "./display-value";
import { ChevronDown, HelpCircle } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Button } from "./button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover-card";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import peekpedia from "/images/icon-peekpedia.svg";
import { cn } from "@/lib/utils";
interface InfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
  currency?: string;
  context?: string | number | React.ReactNode;
  highlightNumbers?: boolean;
  options: {
    label: string;
    value: string | number;
    tooltip?: string;
    calculation?: string;
    formula?: string;
  }[];
}

export default function InfoCard({
  currency = "",
  highlightNumbers = false,
  context,
  options,
  ...props
}: InfoCardProps) {
  const [selectedOption, setSelectedOption] = useState(options[0].label);

  const selectedOptionData = useMemo(() => {
    return (
      options.find((option) => option.label === selectedOption) || options[0]
    );
  }, [selectedOption, options]);

  const { value, tooltip, formula, calculation } = selectedOptionData;

  return (
    <Card
      {...props}
      className={cn(
        "border-grey-200 flex w-full flex-col justify-between space-y-2 p-4 shadow-none sm:col-span-2 md:col-span-1",
        props.className,
      )}
    >
      <div className="space-y-2">
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center">
            <h4 className="font-normal text-gray-500">{selectedOption}</h4>

            {tooltip && (
              <HoverCard openDelay={300}>
                <HoverCardTrigger asChild>
                  <HelpCircle
                    size={16}
                    className="ml-2 text-gray-500 hover:cursor-pointer"
                  />
                </HoverCardTrigger>
                <HoverCardContent className="w-96">
                  <div className="flex flex-col justify-between space-y-4 text-sm">
                    <div className="flex flex-row items-center gap-x-2">
                      <img
                        src={peekpedia}
                        alt="Peekpedia"
                        className="h-8 w-8"
                      />
                      <h4 className="font-semibold">How it works</h4>
                    </div>
                    <p>{tooltip}</p>
                    {formula && (
                      <>
                        <h4 className="mb-4 font-semibold">Formula</h4>
                        <p>
                          <Latex>{`{${formula}}`}</Latex>
                        </p>
                      </>
                    )}
                    {calculation && (
                      <>
                        <h4 className="mb-4 font-semibold">Calculation</h4>
                        <p>
                          <Latex>{`{${calculation}}`}</Latex>
                        </p>
                      </>
                    )}
                  </div>
                </HoverCardContent>
              </HoverCard>
            )}

            {options.length > 1 && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="ml-1 h-auto px-1 py-0">
                    <ChevronDown size={16} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuRadioGroup
                    value={selectedOption}
                    onValueChange={setSelectedOption}
                  >
                    {options.map((option) => (
                      <DropdownMenuRadioItem
                        key={option.label}
                        value={option.label}
                      >
                        {option.label}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>

          {context && (
            <div className="flex-1 text-right text-gray-400">{context}</div>
          )}
        </div>

        <DisplayValue
          currency={currency}
          value={value}
          size="large"
          highlightNumbers={highlightNumbers}
        />
      </div>
    </Card>
  );
}
