import { useState } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Clock } from "lucide-react";
import { Goal } from "@/lib/types";
import { goalOptions } from "../constants";

type StepSixProps = {
  goal: Goal;
  onNext: (goal: Goal) => void;
  onSkip: () => void;
  isLoading: boolean;
};

export function StepSix({ goal, onNext, onSkip, isLoading }: StepSixProps) {
  const [goalOptionIndex, setGoalOptionIndex] = useState(
    goal?.goalOptionIndex ?? 0,
  );

  return (
    <>
      <ScrollArea className="h-72 w-full">
        <div className="grid grid-cols-3 gap-2">
          {goalOptions.map((option, index) => (
            <div
              className={cn(
                "flex flex-col justify-between rounded-md p-4 hover:bg-beige cursor-pointer border border-gray-200",
                goalOptionIndex === index && "bg-beige",
              )}
              key={index}
              onClick={() => setGoalOptionIndex(index)}
            >
              <img src={option.image} alt={option.title} className="size-9" />
              <p className="flex-1 py-4">{option.title}</p>
              <div className="inline-flex w-fit flex-row items-center gap-1 rounded bg-gray-100 px-2 py-1 text-xs">
                <Clock size={12} /> {option.duration}
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>

      <div className="mt-4 flex w-full flex-row justify-between">
        <Button
          variant="link"
          onClick={onSkip}
          className="px-0 text-gray-400"
          disabled={isLoading}
        >
          Skip goal setting
        </Button>

        <Button
          variant="default"
          onClick={() => {
            const goalData = goalOptions[goalOptionIndex];
            onNext({
              name: goalData.title,
              targetNetWorth: 0,
              targetDate: new Date(),
              goalOptionIndex,
            });
          }}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </>
  );
}
