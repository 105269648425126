import React, { createContext, useContext, useRef, useCallback } from "react";
import { toast } from "sonner";

type ShortcutPromptOptions = {
  shortcutKey?: string;
  shortcutIcon?: React.ReactNode;
  requiresCmdKey?: boolean;
  template: string;
};

type ShortcutPromptContextType = {
  showShortcutToast: (options: ShortcutPromptOptions) => void;
};

const ShortcutPromptContext = createContext<
  ShortcutPromptContextType | undefined
>(undefined);

export const ShortcutPromptProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const lastToastContentRef = useRef<string | null>(null);

  const showShortcutToast = useCallback((options: ShortcutPromptOptions) => {
    const showShortcuts =
      localStorage.getItem("show_shortcut_prompts") === "true";
    if (!showShortcuts) return;

    const { shortcutKey, template, shortcutIcon } = options;
    const parts = template.split("{shortcutKey}");
    const toastContent = `${parts[0]}${
      options.requiresCmdKey
        ? navigator.userAgent.includes("Mac")
          ? "⌘"
          : "Ctrl"
        : ""
    }${shortcutIcon || shortcutKey}${parts[1]}`;

    if (lastToastContentRef.current === toastContent) {
      return;
    }

    lastToastContentRef.current = toastContent;

    toast.info(
      <div>
        {parts[0]}
        {options.requiresCmdKey && (
          <span className="mx-0.5 aspect-square h-5 w-5 items-center justify-center rounded-sm border-none bg-white px-1 py-1 text-[12px] text-darkBlue">
            {navigator.userAgent.includes("Mac") ? "⌘" : "Ctrl"}
          </span>
        )}
        <span className="mx-0.5 aspect-square h-5 w-5 items-center justify-center rounded-sm border-none bg-white px-1 py-1 text-[12px] text-darkBlue">
          {shortcutIcon || shortcutKey}
        </span>
        {parts[1]}
      </div>,
      {
        position: "bottom-center",
        closeButton: false,
        duration: 3000,
        classNames: {
          info: "!bg-darkBlue !text-white !border-none !p-2.5",
        },
      },
    );
  }, []);

  return (
    <ShortcutPromptContext.Provider value={{ showShortcutToast }}>
      {children}
    </ShortcutPromptContext.Provider>
  );
};

export const useShortcutPrompt = () => {
  const context = useContext(ShortcutPromptContext);
  if (context === undefined) {
    throw new Error(
      "useShortcutPrompt must be used within a ShortcutPromptProvider",
    );
  }
  return context;
};
