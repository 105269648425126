import { CommandKey } from "@/components/common/CommandKey";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useMoveAssetsLiabilitiesMutation } from "@/hooks/useMoveAssetsLiabilities";
import { RowSelectionState } from "@tanstack/react-table";
import { FolderInput } from "lucide-react";
import { useState } from "react";
import { useDialog } from "@/hooks/useDialog";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";

type MoveAccountDialogProps = {
  selectedIds: number[];
  setSelectedIds: (value: React.SetStateAction<RowSelectionState>) => void;
  accountOptions: { value: number; label: string }[];
};

export function MoveAccountDialog({
  selectedIds,
  setSelectedIds,
  accountOptions,
}: MoveAccountDialogProps) {
  const { isDialogOpen, setDialogOpen } = useDialog();
  const [accountId, setAccountId] = useState<number | null>(null);
  const { showShortcutToast } = useShortcutPrompt();

  const { moveAssetsLiabilities, isPending } =
    useMoveAssetsLiabilitiesMutation();

  function handleMoveAssetsLiabilities() {
    moveAssetsLiabilities({ accountId: accountId!, assetIds: selectedIds });
    setSelectedIds({});
    setAccountId(null);
    setDialogOpen("moveAccount", false);
  }

  return (
    <Dialog
      open={isDialogOpen("moveAccount")}
      onOpenChange={(open) => setDialogOpen("moveAccount", open)}
    >
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          showShortcutToast({
            shortcutKey: "M",
            template: "You can also just hit {shortcutKey} to move this",
          });
          setDialogOpen("moveAccount", true);
        }}
        className="gap-1"
      >
        <FolderInput size={14} className="mr-1" />
        Move ({selectedIds.length})
        <CommandKey
          cmdKey="m"
          requireCmdKey={false}
          callback={() => setDialogOpen("moveAccount", true)}
        />
      </Button>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Move Account</DialogTitle>
          <DialogDescription>
            Move selected assets/liabilities to another account.
          </DialogDescription>
        </DialogHeader>
        <div>
          <Select
            defaultValue={accountId?.toString()}
            onValueChange={(value) => setAccountId(+value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select account" />
            </SelectTrigger>
            <SelectContent>
              {accountOptions.map((option) => (
                <SelectItem value={option.value.toString()} key={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <DialogFooter>
          <Button
            onClick={handleMoveAssetsLiabilities}
            disabled={accountId === null || isPending}
          >
            Move
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
