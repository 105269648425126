import { X } from "lucide-react";
import { ElementRef, useEffect, useRef, useState, useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { InputActionMeta } from "react-select";
import { ZodError, z } from "zod";
import debounce from "lodash/debounce";
import {
  Currency,
  currencies,
  getLocaleForCurrency,
  useCurrency,
} from "../../hooks/useCurrency";
import {
  assetNameValidationError,
  getFormValues,
  validateAssetName,
} from "../../lib/addAssets.utils";
import {
  getCryptoPrice,
  getStockPrice,
  searchCrypto,
  searchStock,
} from "../../lib/stocks.utils";
import { cn, formatAssetType } from "../../lib/utils";
import Loader from "../common/Loader";
import { StockReactSelect } from "../common/StockReactSelect";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import AddAssetButton from "./AddAssetButton";
import { addAssetFormSchema, assetSchema } from "./schema";
import { searchItems } from "./searchItems";
import { AddAssetFormFields, CustomSearchItems } from "./types";
import { debounceDelay } from "@/lib/constants";
import { NumberInput } from "../ui/number-input";

type SelectedOptionType = {
  label?: string | null;
  value?: string;
  createNew?: boolean;
};

type AddAssetSecondStepProps = {
  form: UseFormReturn<z.infer<typeof addAssetFormSchema>>;
  previousStep: () => void;
  formValues: ReturnType<typeof getFormValues>;
  tagOptions: string[];
  clearSubmissionError: () => void;
  fields: AddAssetFormFields;
  setFields: React.Dispatch<React.SetStateAction<AddAssetFormFields>>;
  accountType: CustomSearchItems;
};

export function AddAssetSecondStep({
  form,
  previousStep,
  formValues,
  clearSubmissionError,
  fields,
  setFields,
  accountType,
}: AddAssetSecondStepProps) {
  const {
    defaultValues,
    assetTypes,
    requiresName,
    requiresQuantity,
    requiresUnitValue,
    requiresType,
    usesExternalApiForPrice,
  } = formValues;

  const nameRef = useRef<ElementRef<typeof StockReactSelect>>(null);

  function focusFirstElement() {
    if (nameRef.current) {
      nameRef.current?.focus();
    } else {
      const inputs = ["name", "quantity", "unitValue"].map(
        (id) => document.getElementById(id) as HTMLInputElement,
      );
      const firstInput = inputs.find((input) => input);
      if (firstInput) firstInput.focus();
    }
  }

  // Set focus to first element on mount
  useEffect(() => {
    focusFirstElement();
  }, []);

  const currencyContext = useCurrency();

  const [errors, setErrors] = useState<string | null>(null);

  const [isPriceLoading, setIsPriceLoading] = useState(false);

  const [inputValue, setInputValue] = useState<string>();
  const [selectedOption, setSelectedOption] =
    useState<SelectedOptionType | null>();

  const [disableUnitValueInput, setDisableUnitValueInput] = useState(
    usesExternalApiForPrice,
  );

  function remove(index: number) {
    const assetsCopy = form.watch("assetLiabilities");
    assetsCopy.splice(index, 1);
    form.setValue("assetLiabilities", assetsCopy);
  }

  function handleChange(
    key: keyof z.infer<typeof addAssetFormSchema>["assetLiabilities"][0],
    value: unknown,
  ) {
    setFields((prev) => ({ ...prev, [key]: value }));
  }

  function handleAdd() {
    assetSchema
      .refine(validateAssetName, assetNameValidationError)
      .parseAsync(fields)
      .then((fields) => {
        setErrors(null);
        clearSubmissionError();
        setFields({ ...defaultValues, currency: currencyContext.currency });
        form.setValue("assetLiabilities", [
          ...form.watch("assetLiabilities"),
          fields,
        ]);
        focusFirstElement();
        setInputValue("");
        setSelectedOption(null);
      })
      .catch((err: ZodError) => {
        const error = err.errors?.[0].message;
        if (error) {
          if (usesExternalApiForPrice) {
            setErrors(error.replace("Name", "Ticker"));
          } else {
            setErrors(error);
          }
        }
      });
  }

  const usesStockApi =
    assetTypes[0] === "STOCK" ||
    assetTypes[0] === "ETF" ||
    assetTypes[0] === "REIT" ||
    assetTypes[0] === "BOND" ||
    assetTypes[0] === "MUTUAL_FUND";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetch = useCallback(
    debounce((inputValue, callback) => {
      if (usesStockApi) {
        searchStock(inputValue).then((results) => {
          callback([
            ...results.map((val) => ({
              label: `${val.name}`,
              value: val.symbol,
              currency: val.currency,
              stockExchange: val.stockExchange,
              exchangeShortName: val.exchangeShortName,
            })),
            {
              value: `${inputValue}`,
              label: `${inputValue}`,
              createNew: `Create custom "${inputValue}"`,
            },
          ]);
        });
      } else {
        searchCrypto(inputValue).then((results) => {
          callback([
            ...results.map((val) => ({
              label: `${val.name}`,
              value: val.symbol,
            })),
            {
              value: `${inputValue}`,
              label: `${inputValue}`,
              createNew: `Create custom "${inputValue}"`,
            },
          ]);
        });
      }
    }, debounceDelay),
    [debounce],
  );

  return (
    <div
      className="relative space-y-4"
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          previousStep();
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div
        className={cn(
          "flex flex-col gap-2",
          requiresName && requiresType && requiresQuantity
            ? "md:flex-row"
            : "sm:flex-row",
        )}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter" && e.shiftKey) {
            !isPriceLoading && handleAdd();
            e.preventDefault();
          }
        }}
      >
        {requiresName &&
          (usesExternalApiForPrice ? (
            <StockReactSelect
              ref={nameRef}
              onInputChange={(value: string, { action }: InputActionMeta) => {
                if (action === "input-change" && value.length === 0) {
                  setSelectedOption(null);

                  // Clear of the field
                  handleChange("name", null);
                  handleChange("fullName", null);
                }

                if (action !== "input-blur" && action !== "menu-close") {
                  handleChange("name", value);
                  handleChange("fullName", value);
                  setInputValue(value);
                }
              }}
              onFocus={(e) => {
                if (selectedOption && selectedOption.label) {
                  setInputValue(selectedOption.label);
                } else if (e.target.value) {
                  handleChange("name", e.target.value);
                  handleChange("fullName", e.target.value);
                  setInputValue(e.target.value);
                }
              }}
              onChange={(value) => {
                const typedValue = value as SelectedOptionType;
                handleChange("name", typedValue.value);

                if (typedValue.createNew) {
                  setDisableUnitValueInput(false);
                } else {
                  setDisableUnitValueInput(true);
                  handleChange("fullName", typedValue.label);

                  getStockOrCryptoPriceAndSetUnitValue(
                    typedValue.value as string,
                    (price) => handleChange("unitValue", price),
                    setIsPriceLoading,
                    (currency) => handleChange("currency", currency),
                    usesStockApi,
                  );
                }
                setSelectedOption(typedValue);

                const next = document.getElementById(
                  "quantity",
                ) as HTMLButtonElement;
                next?.focus();
              }}
              inputValue={inputValue}
              value={selectedOption}
              loadOptions={debounceFetch}
              cacheOptions={false}
              className="w-flex-1 w-full sm:min-w-[14px]"
              placeholder={`Search for a ${formatAssetType(
                fields.type ?? "stock",
              ).toLowerCase()}*`}
              noOptionsMessage={() => null}
            />
          ) : (
            <Input
              id="name"
              value={fields.name}
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder={
                searchItems[accountType]?.assetNameInputPlaceholder || "Name"
              }
              className="w-flex-1 h-8 rounded-md px-3"
            />
          ))}
        {requiresType && (
          <Select
            onValueChange={(val) => handleChange("type", val)}
            value={fields.type}
          >
            <SelectTrigger id="first" size="sm" className="w-full">
              <SelectValue placeholder="Type" />
            </SelectTrigger>
            <SelectContent>
              {assetTypes.map((val) => (
                <SelectItem key={val} value={val}>
                  {formatAssetType(val)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        {requiresQuantity && !requiresUnitValue && (
          <Select
            onValueChange={(val) => handleChange("currency", val)}
            value={fields.currency}
          >
            <SelectTrigger id="currency" size="sm" className="w-full">
              <SelectValue placeholder="Currency" />
            </SelectTrigger>
            <SelectContent className="max-h-[45vh] overflow-y-scroll">
              {currencies.map((val) => (
                <SelectItem key={val} value={val}>
                  {val}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        {requiresQuantity && (
          <Input
            id="quantity"
            value={fields.quantity}
            onChange={(e) => handleChange("quantity", e.target.value)}
            placeholder={requiresUnitValue ? "Quantity" : "Amount"}
            className={cn(
              "h-8 w-full rounded-md px-3",
              requiresName &&
                (requiresType && requiresQuantity
                  ? "md:max-w-24"
                  : "sm:max-w-24"),
            )}
          />
        )}
        {requiresUnitValue && !disableUnitValueInput && (
          <Select
            onValueChange={(val) => handleChange("currency", val)}
            value={fields.currency}
          >
            <SelectTrigger id="currency" size="sm" className="w-full">
              <SelectValue placeholder="Currency" />
            </SelectTrigger>
            <SelectContent className="max-h-[45vh] overflow-y-scroll">
              {currencies.map((val) => (
                <SelectItem key={val} value={val}>
                  {val}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        {requiresUnitValue && (
          <div className={cn("relative", !usesExternalApiForPrice && "flex-1")}>
            {disableUnitValueInput ? (
              <div
                className={cn(
                  "sm:min-w-24 flex h-8 w-full items-center whitespace-nowrap rounded-md border px-3 text-sm",
                  !fields.unitValue && "text-muted-foreground",
                )}
              >
                {isPriceLoading ? (
                  ""
                ) : fields.unitValue ? (
                  <span>
                    {fields.currency} {fields.unitValue}
                  </span>
                ) : (
                  <span>Unit Value</span>
                )}
              </div>
            ) : (
              <NumberInput
                id="unitValue"
                value={fields.unitValue?.toString()}
                onChange={(e) =>
                  handleChange("unitValue", Number(e.target.value))
                }
                placeholder={!isPriceLoading ? "Unit Value" : ""}
                locale={getLocaleForCurrency(fields.currency)}
                className={cn(
                  "h-8 sm:min-w-24 rounded-md px-3 disabled:opacity-100",
                  isPriceLoading && "text-transparent",
                )}
              />
            )}
            {isPriceLoading && (
              <div className="absolute left-1/2 top-0 -translate-x-1/2 translate-y-1/2">
                <Loader />
              </div>
            )}
          </div>
        )}

        <AddAssetButton handleAdd={handleAdd} disabled={isPriceLoading} />
      </div>
      {errors && (
        <p className="text-sm font-medium text-destructive">{errors}</p>
      )}
      {form.watch("assetLiabilities").length > 0 && (
        <div className="max-h-80 overflow-scroll rounded-md">
          <Table>
            <TableHeader>
              <TableRow className="bg-border/50">
                {requiresName && (
                  <>
                    <TableHead>
                      {usesExternalApiForPrice ? "Ticker" : "Name"}
                    </TableHead>
                  </>
                )}
                {!requiresName && (
                  <TableHead className="hidden sm:block">Type</TableHead>
                )}
                {requiresQuantity && (
                  <TableHead>
                    {requiresUnitValue ? "Quantity" : "Amount"}
                  </TableHead>
                )}
                {requiresUnitValue && <TableHead>Value</TableHead>}
                <TableHead className="w-6"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {form.watch("assetLiabilities").map((field, index) => (
                <TableRow key={index}>
                  {requiresName && (
                    <TableCell>
                      <span className="mr-2 font-semibold">{field.name}</span>
                      <span>{field.fullName}</span>
                    </TableCell>
                  )}
                  {!requiresName && (
                    <TableCell className="hidden sm:table-cell">
                      <span className="mr-2">
                        {formatAssetType(field.type)}
                      </span>
                    </TableCell>
                  )}
                  {requiresQuantity && !requiresUnitValue && (
                    <TableCell className="whitespace-nowrap">
                      {field.currency}{" "}
                      {Intl.NumberFormat(
                        getLocaleForCurrency(field.currency as Currency),
                        { maximumFractionDigits: 20 },
                      ).format(field.quantity)}
                    </TableCell>
                  )}
                  {requiresQuantity && requiresUnitValue && (
                    <TableCell>{field.quantity}</TableCell>
                  )}
                  {requiresUnitValue && (
                    <TableCell className="whitespace-nowrap">
                      {field.currency}{" "}
                      {Intl.NumberFormat(
                        getLocaleForCurrency(field.currency as Currency),
                        { maximumFractionDigits: 20 },
                      ).format(field.unitValue)}
                    </TableCell>
                  )}
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}

async function getStockOrCryptoPriceAndSetUnitValue(
  ticker: string,
  setValue: (value: number) => void,
  setPriceLoading: (loading: boolean) => void,
  setCurrency: (currency: Currency) => void,
  isStock: boolean,
) {
  setPriceLoading(true);
  try {
    const { currency, price } = isStock
      ? await getStockPrice(ticker)
      : await getCryptoPrice(ticker);
    setCurrency(currency as Currency);
    setValue(price);
  } catch (e) {
    console.error(e);
  }
  setPriceLoading(false);
}
