import {
  Currency,
  currencies,
  getCurrencySymbol,
  useCurrency,
} from "@/hooks/useCurrency";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { usePostHog } from "posthog-js/react";
import { cn } from "@/lib/utils";

export function CurrencySelector({ className }: { className?: string }) {
  const { currency, setCurrency } = useCurrency();
  const posthog = usePostHog();

  return (
    <Select
      value={currency}
      onValueChange={(value) => {
        posthog.capture("currency_changed", { currency: value });
        setCurrency(value as Currency);
      }}
    >
      <SelectTrigger
        variant="ghost"
        className={cn(
          "flex size-8 items-center justify-center rounded-full",
          className,
        )}
        showIcon={false}
      >
        <div>{getCurrencySymbol(currency)}</div>
      </SelectTrigger>

      <SelectContent align="end">
        {currencies.map((c) => (
          <SelectItem key={c} value={c}>
            <div className="flex gap-x-1">
              <span>{getCurrencySymbol(c)}</span>
              {c}
            </div>
          </SelectItem>
        ))}

        <hr className="my-2" />

        <Button
          size="sm"
          formTarget="_blank"
          variant="outlineOrange"
          onClick={() => window.open("mailto:support@peek.money", "_blank")}
        >
          <span>Request Currency</span>
        </Button>
      </SelectContent>
    </Select>
  );
}
