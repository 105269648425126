import { useEffect } from "react";
import { cn } from "../../lib/utils";
import { useSubscriptions } from "@/hooks/useSubscriptions";
import { useDialog } from "@/hooks/useDialog";

// Only to show in desktop view and must be OS agnostic
// Runs a callback when a specific command key is pressed
// Will display the icon if icon is provided
export function CommandKey({
  cmdKey,
  icon,
  callback,
  variant = "default",
  requireCmdKey = true,
}: {
  cmdKey: string;
  icon?: React.ReactNode;
  callback?: () => void;
  variant?: "default" | "white" | "transparent";
  requireCmdKey?: boolean;
}) {
  const subscriptionContext = useSubscriptions();

  const { isAnyDialogOpen } = useDialog();

  useEffect(() => {
    // effectively disables cmd+k when user doesn't have a valid subscription
    if (
      subscriptionContext.state === "SUCCESS" &&
      subscriptionContext.blockUser
    )
      return;

    const down = (e: KeyboardEvent) => {
      if (
        e.key === cmdKey &&
        (!requireCmdKey || e.metaKey || e.ctrlKey) &&
        !isAnyDialogOpen
      ) {
        e.preventDefault();
        callback && callback();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [subscriptionContext, callback, cmdKey, requireCmdKey, isAnyDialogOpen]);

  return (
    <div className="hidden md:flex md:gap-x-1">
      {requireCmdKey && (
        <div
          className={cn(
            "relative border border-gray-300 text-[10px] p-1 rounded-sm h-5 flex items-center justify-center",
            variant === "white" && "border-none bg-gray-50/20",
            variant === "transparent" && "border-transparent bg-white/20",
          )}
        >
          {navigator.userAgent.includes("Mac") ? "⌘" : "Ctrl"}
        </div>
      )}
      <div
        className={cn(
          "relative border border-gray-300 uppercase text-[10px] p-1 rounded-sm min-w-5 h-5 flex items-center justify-center",
          variant === "white" && "border-none bg-gray-50/20",
          variant === "transparent" && "border-transparent bg-white/20",
          icon && "aspect-square",
        )}
      >
        {icon ? icon : cmdKey}
      </div>
    </div>
  );
}
