import React from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";
import App from "./App.tsx";
import "./index.css";
import config from "@/lib/config";

const options = {
  api_host: config.publicPosthogHost,
  autocapture: false,
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider apiKey={config.publicPosthogKey} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
