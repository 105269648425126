import { cn } from "@/lib/utils";
import Loader from "./Loader";

export default function LoaderWithText({ className }: { className?: string }) {
  return (
    <div className={cn("flex items-center gap-x-2", className)}>
      <Loader />
      <div className="h-[calc(theme(fontSize.base)*theme(lineHeight.tight))] overflow-hidden md:h-[calc(theme(fontSize.base)*theme(lineHeight.tight))]">
        <ul className="block animate-text-slide-5 text-left leading-tight [&_li]:block">
          <li>Crunching numbers with AI...</li>
          <li>Letting the AI do the heavy lifting...</li>
          <li>Consulting the AI analyst...</li>
          <li>Peeking into your portfolio</li>
          <li>Judging you hard (just kidding)</li>
          <li aria-hidden="true">Crunching numbers with AI...</li>
        </ul>
      </div>
    </div>
  );
}
