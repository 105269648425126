import { Card, CardContent, CardFooter } from "@/components/ui/card";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

export const BlogPostPreviewCard = ({
  title,
  description,
  image,
  slug,
  className,
}: {
  className?: string;
  title: string;
  description: string | null;
  image: string | null;
  slug: string;
}) => {
  return (
    <Card key={slug} className={cn("flex h-full flex-col bg-beige", className)}>
      <CardContent className="flex-grow space-y-4">
        <div className="flex flex-col items-start gap-x-2">
          {image && (
            <img
              src={image}
              alt={title}
              className="mb-2 h-40 w-full rounded-lg object-cover"
            />
          )}
          <h3 className="font-medium">{title}</h3>
        </div>
        <p className="line-clamp-4 text-gray-500">{description}</p>
      </CardContent>
      <CardFooter>
        <Link to={`/post/${slug}`}>
          <Button size="sm" variant="secondary" className="w-full">
            Read
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};
