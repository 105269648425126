import { Card, CardContent, CardFooter } from "@/components/ui/card";
import iconConcierge from "/images/icon-concierge.svg";
import iconFire from "/images/icon-fire.svg";
import iconInsurance from "/images/icon-life-insurance.svg";
import iconPeektube from "/images/icon-peek-tube.svg";
import iconPeekpedia from "/images/icon-peekpedia.svg";
import iconPlayground from "/images/icon-playground.svg";
import iconRebalancing from "/images/icon-rebalancing.svg";
import iconRentVsBuy from "/images/icon-rent-vs-buy.svg";

import Loader from "@/components/common/Loader";
import { BlogPostsPagination } from "@/components/Pub/BlogPostPagination";
import { BlogPostPreviewCard } from "@/components/Pub/BlogPostPreviewCard";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useGetPosts } from "@/hooks/useWisp";
import { SquareArrowUpRight } from "lucide-react";
import { useState } from "react";

const playgroundItems = [
  {
    title: "Rent VS Buy Calculator",
    description:
      "Compare the financial implications of renting a home versus buying one",
    details: `
    This rent vs. buy calculator is a tool that helps individuals figure out the true opportunity cost between renting or buying properties. 
    <br/><br/>
    - Users input details like home price, monthly rent, stay duration, mortgage rate, down payment, and mortgage term.<br/>
    - When they press “calculate,” the calculator will tell them whether buying or renting is more cost-effective
    <br/><br/>
    The calculator includes default assumptions for figures such as investment return (for the opportunity cost calculator), home price growth, and more. However, users can adjust these as needed.
    `,
    icon: iconRentVsBuy,
    link: "https://peekrentorbuy.streamlit.app",
    youTubeId: "dIumrX6c2bA",
  },
  {
    title: "Fire Calculator",
    description:
      "See how much you need to save and invest to achieve financial independence and retire early.",
    details: `
    Use this tool to estimate how much you need to save to achieve financial independence and retire early. Simply input your annual expenses, current savings, expected annual return on investments, annual income, and savings rate.
    <br/><br/>
    There are many other FIRE calculators out there, but what makes this one a bit different is it gives you:
    <br/><br/>
    a) More granular options for specific asset classes
    <br/>
    b) FREE simulation of potential outcomes (using Monte Carlo method)
    <br/>
    c) Better lens for the "Barbell FIRE" method
    <br/><br/>
    What is Barbell FIRE?
    <br/><br/>
    Barbell FIRE is a school of thought within FIRE that balances low-risk, stable investments with high-risk, high-reward investments. The idea is to divide your portfolio into two main parts: stable assets (ETFs, bonds, etc.) and growth assets (e.g., individual tech stocks, crypto, angel investing). In the best case top 10-20% scenarios, you might be able to reach your goal sooner. In the bottom 10% of the scenarios, your stable assets still ground your portfolio.
    `,
    icon: iconFire,
    link: "https://peekfire.streamlit.app/",
    youTubeId: "",
  },

  {
    title: "Life Insurance Calculator",
    description:
      "See the amount of life insurance coverage you need to protect you and your loved ones financially.",
    details: `
    The Life Insurance Calculator, a free tool developed by Sherry from the Peek team (peek.money), assists users in assessing their life insurance coverage needs. It allows comparison of death coverage with critical illness or other health insurance coverage like hospitalization. This tool was born from a personal need to decipher complex life insurance information online.
    <br/><br/>
Built with simplicity in mind, the calculator aims to be a useful resource for others with similar questions. While it uses directional assumptions for convenience, users are encouraged to input precise policy numbers for accurate results tailored to their individual circumstances.
<br/><br/>
To use the calculator, users need to input their current age, savings rate %, current savings, annual income, and gender. Additionally, they must input their current financial obligations. The calculator then identifies any existing coverage gaps and helps the user determine whether it is more beneficial from an expected value perspective to purchase life insurance coverage now or later.
    `,
    icon: iconInsurance,
    link: "https://lifeinsurance.streamlit.app/",
    youTubeId: "",
  },
  {
    title: "Portfolio Rebalancing Calculator",
    description:
      "Optimize your investment portfolio with our Rebalancing Simulator. Input your financial details for tailored allocation suggestions based on liquid assets and market data.",
    details: `
    The Portfolio Rebalancing Simulator is a tool aimed to assist users in optimizing their investment portfolios. By inputting basic information such as net worth, risk appetite, and age, the tool offers allocation suggestions. The current portfolio should focus on liquid or public assets with prices sourced from Yahoo! Finance.
    <br/><br/>
    The simulator provides vital portfolio health metrics like annualized return, standard deviation, and Sharpe ratio, calculated from 2015 onwards. With this information, users can receive AI-generated suggestions to either de-risk or bolster growth in their portfolio. Users can also add new items with varying weights to visualize the impact on their portfolio's overall health.
    <br/><br/>
    This tool offers a dynamic approach to portfolio rebalancing, empowering users to make informed decisions for their financial goals. Please note, past performance does not guarantee future results and should only be used as an indicator. The information provided is strictly for educational and informational purposes and should not be taken as financial advice.
    <br/><br/>
    Steps
    <br/><br/>
    1. Find the tickers for your holdings
    <br/>
    2. Add in your current portfolio and % holding, in order to see your “initial portfolio review”
    <br/>
    3. Tap “AI Rebalancing Suggestions” for suggestions around new allocations
    <br/>
    4. Add in the new allocations for your rebalanced portfolio
    <br/>
    5. Check “Rebalanced Portfolio Review” to see the new portfolio numbers and the differences between the initial and rebalanced portfolios
    `,
    icon: iconRebalancing,
    link: "https://peekrebalancing.streamlit.app/",
    youTubeId: "pRM8cgq1DAM",
  },
];

const peektubeItems = [
  {
    title: "How I deal with 'finertia' with my million dollar portfolio",
    link: "4MY_AOp_gVc",
  },
  {
    title: "Intro to Peek's channel",
    link: "JxTZSlR9UcQ",
  },
  {
    title:
      "How I hit my first million in my early 30s - growing wealth early in your career",
    link: "KkXuquI4Ik4",
  },
  {
    title: "Meet Peek!",
    link: "ifM2CvU4",
  },
];

export const PeekPediaSection = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const [page, setPage] = useState(1);
  const { data } = useGetPosts({
    limit: 9,
    page,
  });

  const onSelectPage = (page: number) => {
    setPage(page);
  };

  return (
    <Card>
      <CardContent className="space-y-4">
        <div className="flex flex-row items-center gap-x-2">
          <img src={iconPeekpedia} alt="PeekPedia" className="h-8 w-8" />
          <h2 className="font-medium">PeekPedia</h2>
        </div>
        <p className="text-gray-500">
          Access a wealth of knowledge in PeekPedia with articles on various
          personal finance topics to expand your understanding.
        </p>
        {!data && (
          <div className="p-6">
            <Loader className="m-auto" />
          </div>
        )}
        {data && (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {(isLoggedIn ? data.posts : data.posts.slice(0, 3)).map((item) => (
              <BlogPostPreviewCard
                key={item.slug}
                title={item.title}
                description={item.description}
                image={item.image}
                slug={item.slug}
              />
            ))}
          </div>
        )}
        {data && isLoggedIn && (
          <BlogPostsPagination
            pagination={data.pagination}
            onSelectPage={onSelectPage}
          />
        )}
      </CardContent>
    </Card>
  );
};

export function PubPage({ isLoggedIn = true }: { isLoggedIn?: boolean }) {
  return (
    <div className="mt-8 space-y-4">
      <h1 className="text-3xl">Peek Pub</h1>
      <p className="text-gray-500">
        Peek Pub, a creation of the Peek team, is an open-source,
        community-driven project aimed at promoting general knowledge in the
        realm of personal finance. The mission of Peek is to enable every
        individual to feel empowered to make their own financial decisions,
        provided they have access to the right knowledge and tools. All
        resources within Peek Pub are freely accessible and the Peek team,
        together with the community, commits to an ongoing contribution of
        educational resources to assist you on your path towards building
        wealth.
      </p>

      <Card>
        <CardContent className="space-y-4">
          <div className="flex flex-row items-center gap-x-2">
            <img src={iconPlayground} alt="Playground" className="h-8 w-8" />
            <h2 className="font-medium">Playground</h2>
          </div>
          <p className="text-gray-500">
            Explore our tools and calculators to navigate your financial future.
          </p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {(isLoggedIn ? playgroundItems : playgroundItems.slice(0, 3)).map(
              (item) => (
                <Card
                  key={item.title}
                  className="flex h-full flex-col bg-beige"
                >
                  <CardContent className="flex-grow space-y-4">
                    <div className="flex flex-row items-center gap-x-2">
                      <img
                        src={item.icon}
                        alt={item.title}
                        className="h-8 w-8"
                      />
                      <h3 className="font-medium">{item.title}</h3>
                    </div>
                    <p className="text-gray-500">{item.description}</p>
                  </CardContent>
                  <CardFooter>
                    <Sheet>
                      <SheetTrigger asChild>
                        {item.link ? (
                          <Button
                            size="sm"
                            variant="secondary"
                            className="w-full"
                          >
                            Open
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="secondary"
                            className="w-full"
                            disabled
                          >
                            Coming Soon
                          </Button>
                        )}
                      </SheetTrigger>
                      <SheetContent className="w-full space-y-4 bg-gray-100 sm:max-w-2xl">
                        <SheetHeader>
                          <SheetTitle>{item.title}</SheetTitle>
                        </SheetHeader>
                        <ScrollArea className="h-full">
                          <p
                            dangerouslySetInnerHTML={{ __html: item.details }}
                            className="mb-4"
                          />
                          {item.youTubeId && (
                            <iframe
                              width="100%"
                              height="400px"
                              src={`https://www.youtube.com/embed/${item.youTubeId}`}
                              title="YouTube video player"
                              allow="autoplay; picture-in-picture; web-share"
                            />
                          )}

                          {item.link && (
                            <Button
                              className="mb-8 mt-4 w-full"
                              onClick={() => window.open(item.link, "_blank")}
                            >
                              Open Calculator
                            </Button>
                          )}
                        </ScrollArea>
                      </SheetContent>
                    </Sheet>
                  </CardFooter>
                </Card>
              ),
            )}
            <div className="flex items-center space-x-4 rounded-md border p-4">
              <div className="flex-col space-y-4">
                <div className="flex flex-row items-center space-x-4">
                  <img
                    src={iconConcierge}
                    alt="concierge"
                    className="h-6 w-6"
                  />
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none text-gray-700">
                      Did you know?
                    </p>
                    <p className="text-sm text-gray-500">
                      Customize these tools to your liking. Check out the source
                      code on our GitHub.
                    </p>
                  </div>
                </div>
                <Button
                  variant="outline"
                  onClick={() =>
                    window.open(
                      "https://github.com/sherryyjiang/peek",
                      "_blank",
                    )
                  }
                  className="ml-10 gap-x-2"
                >
                  <SquareArrowUpRight size={16} />
                  Open Github
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {!isLoggedIn && (
        <Card>
          <CardContent className="py-16">
            <div className="flex flex-col items-center justify-center space-y-4">
              <img src={iconConcierge} className="h-16 w-16" />
              <h2>Hmmm...Looks like you don't have an account yet.</h2>
              <p className="text-center text-sm text-muted-foreground">
                Beta users enjoy personalized calculations and exclusive tools!
                Join our waitlist to unlock these features and more.
              </p>
              <Button
                onClick={() => {
                  window.open(
                    "https://form.typeform.com/to/L8zZgj9r",
                    "_blank",
                  );
                }}
              >
                Join Waitlist
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
      <PeekPediaSection isLoggedIn={isLoggedIn} />

      <Card>
        <CardContent className="space-y-4">
          <div className="flex flex-row items-center gap-x-2">
            <img src={iconPeektube} alt="PeekTube" className="h-8 w-8" />
            <h2 className="font-medium">PeekTube</h2>
          </div>
          <p className="text-gray-500">
            Watch our YouTube videos for insights, tips, and real-life stories
            to support your financial journey.
          </p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {(isLoggedIn ? peektubeItems : peektubeItems.slice(0, 3)).map(
              (item) => (
                <Card
                  key={item.title}
                  className="flex h-full flex-col bg-beige"
                >
                  <CardContent className="flex-grow space-y-4">
                    <iframe
                      width="100%"
                      height="200px"
                      src={`https://www.youtube.com/embed/${item.link}`}
                      title="YouTube video player"
                      allow="autoplay; picture-in-picture; web-share"
                    />
                    <div className="flex flex-row items-center gap-x-2">
                      <h3 className="font-medium">{item.title}</h3>
                    </div>
                  </CardContent>
                </Card>
              ),
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
