import LoaderWithText from "@/components/common/LoaderWithText";
import AnalysisDetails from "./AnalysisDetails";
import { Button } from "@/components/ui/button";
import { Input } from "./Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAnalysis } from "@/hooks/useAnalysis";
import { useRefreshAssetAllocationAnalysisMutation } from "@/hooks/useRefreshAnalysisMutation";
import { useInsightsVariables } from "@/hooks/useInsightsVariables";
import { InsightsVariables } from "./Insights";
import { useGetProfileQuery } from "@/hooks/useProfile.tsx";
import { differenceInYears } from "date-fns";
import { useEffect } from "react";
import { RotateCw } from "lucide-react";
import { Accordion } from "@radix-ui/react-accordion";
import DOMPurify from "dompurify";
import ETFHoldingsRow from "./ETFHoldingsRow";
import { FinancialGoal } from "@/components/PeekHomePage/FinancialGoal";

export const UnlockedInsights = (props: {
  isPendingRefreshPortfolioAnalysis: boolean;
  isPendingRefreshETFHoldingsAnalysis: boolean;
  onRefreshPortfolioAnalysis: () => void;
  onRefreshETFHoldingsAnalysis: () => void;
  netWorth: number;
}) => {
  const [insightsVariables, setInsightsVariables] = useInsightsVariables();

  const { data: profile } = useGetProfileQuery();
  const handleUpdate = (newInsightsVariables: Partial<InsightsVariables>) => {
    setInsightsVariables((prevInsightsVariables: InsightsVariables) => ({
      ...prevInsightsVariables,
      ...newInsightsVariables,
    }));
  };

  const currentAge = profile?.birthday
    ? differenceInYears(new Date(), profile.birthday)
    : insightsVariables.currentAge ?? 30;
  const {
    refreshAnalysis: refreshAssetAllocationAnalysis,
    isPending: isPendingRefreshAssetAllocationAnalysis,
  } = useRefreshAssetAllocationAnalysisMutation(
    insightsVariables.currentAge,
    insightsVariables.liquidityPreference,
  );

  const analysis = useAnalysis();

  useEffect(() => {
    // TODO this smells the bug =)
    if (currentAge && currentAge !== insightsVariables.currentAge) {
      setInsightsVariables((prevInsightsVariables: InsightsVariables) => ({
        ...prevInsightsVariables,
        currentAge,
      }));
    }
  }, [currentAge, insightsVariables.currentAge, setInsightsVariables]);

  return (
    <div className="flex flex-col gap-4">
      <FinancialGoal netWorth={props.netWorth} />
      <div className="rounded-sm border border-gray-200 bg-white p-5">
        {analysis.state === "LOADING" ||
        props.isPendingRefreshPortfolioAnalysis ? (
          <LoaderWithText />
        ) : analysis.state === "SUCCESS" &&
          analysis.concentrationRiskData.length > 0 ? (
          analysis.concentrationRiskData.map((analysis, index) => (
            <AnalysisDetails
              key={index}
              analysis={analysis}
              onRefresh={props.onRefreshPortfolioAnalysis}
            />
          ))
        ) : (
          <div className="flex flex-col items-center gap-4">
            <p>
              Measure how your investments are spread across; scoring from 1 to
              10 based on diversification to assess risk.
            </p>

            <Button
              className="flex w-auto items-center gap-1"
              onClick={() => props.onRefreshPortfolioAnalysis()}
            >
              <span>Assess my portfolio</span>
            </Button>
          </div>
        )}
      </div>
      <div className="rounded-sm border border-gray-200 bg-white p-5">
        {analysis.state === "LOADING" ||
        props.isPendingRefreshETFHoldingsAnalysis ? (
          <LoaderWithText />
        ) : analysis.state === "SUCCESS" &&
          analysis.ETFHoldingsData.length > 0 ? (
          analysis.ETFHoldingsData.map((analysis, index) => (
            <div className="flex flex-col gap-y-3">
              <div className="items-center-justify-between flex w-full flex-row">
                <div className="flex flex-col gap-y-3">
                  <h3
                    className="text-s font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(analysis.title),
                    }}
                  />
                  <p
                    className="text-s font-normal text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(analysis.description),
                    }}
                  />
                </div>
                <div className="flex-1">
                  <Button
                    variant="ghost"
                    className="ml-auto flex h-fit self-start p-0 text-muted-foreground hover:bg-transparent"
                    onClick={props.onRefreshETFHoldingsAnalysis}
                  >
                    Refresh
                    <RotateCw className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
              <Accordion type="single" collapsible className="w-full">
                {analysis.overlaps?.map((ETFHolding, overlapIndex) => (
                  <ETFHoldingsRow
                    key={overlapIndex}
                    accordionValue={overlapIndex.toString()}
                    ETFHolding={ETFHolding}
                  />
                ))}
              </Accordion>

              <AnalysisDetails key={index} analysis={analysis} />
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center gap-4">
            <p>Detect stock and ETF overlaps in your portfolio.</p>

            <Button
              className="flex w-auto items-center gap-1"
              onClick={() => props.onRefreshETFHoldingsAnalysis()}
            >
              <span>Analyse overlapping assets</span>
            </Button>
          </div>
        )}
      </div>
      <div className="rounded-sm border border-gray-200 bg-white p-5">
        {analysis.state === "LOADING" ||
        isPendingRefreshAssetAllocationAnalysis ? (
          <LoaderWithText />
        ) : analysis.state === "SUCCESS" &&
          analysis.assetAllocationData.length > 0 ? (
          analysis.assetAllocationData.map((analysis, index) => (
            <AnalysisDetails
              key={index}
              analysis={analysis}
              onRefresh={refreshAssetAllocationAnalysis}
            />
          ))
        ) : (
          <div className="flex flex-col items-center gap-4">
            <p>
              Analyze and rebalance your portfolio based on age, and upcoming
              liquidity needs.
            </p>
            <div className="flex flex-col space-y-4">
              {!profile && (
                <div className="flex w-full flex-col gap-y-1 self-start">
                  <p>Your age</p>
                  <Input
                    name="currentAge"
                    value={currentAge}
                    onUpdate={(_key, value) => {
                      handleUpdate({ currentAge: value });
                    }}
                    renderAs={`${insightsVariables.currentAge}`}
                  />
                </div>
              )}
              <div className="flex w-full flex-col gap-y-1 self-start">
                <p>Do you expect any big spending in the next 3 years?</p>
                <Select
                  value={insightsVariables.liquidityPreference}
                  onValueChange={(value) => {
                    handleUpdate({ liquidityPreference: value });
                  }}
                >
                  <SelectTrigger variant="secondaryGrey" className="w-fit">
                    <SelectValue placeholder="Yes / No" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="no">No</SelectItem>
                    <SelectItem value="yes">Yes</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <Button
              className="flex w-auto items-center gap-1"
              onClick={() => refreshAssetAllocationAnalysis()}
            >
              <span>Analyse my portfolio composition</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
