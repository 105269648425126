import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

export function SecuritySettings() {
  const faq = [
    {
      question: "Is my financial data secure with Peek?",
      answer:
        "Your financial data is encrypted at-rest and in-transit. This means that your data is protected from unauthorized access. Even if the database files were to be stolen, the thieves wouldn't be able to decrypt the contents without the decryption keys. In addition, the decryption keys are secured by Amazon Web Service’s Key Management Service, which makes use of industry standard hardware security modules. In addition, data transfers between your browser and our servers are also encrypted with secured HTTPS connections, so your data will still be secured even if it was intercepted by a hacker.",
    },
    {
      question:
        "Does Peek sell or share my data with other financial institutions?",
      answer:
        "No, we do not sell or share your data to other financial institutions. That is simply not our business model.",
    },
    {
      question:
        "Can Peek transfer money out of my bank or brokerage accounts directly?",
      answer:
        "No, Peek does not have any write access to your bank or brokerage accounts.",
    },
    {
      question: "Can I request to delete my data from Peek?",
      answer:
        "Yes, you can request to delete your data from Peek at any time. Simply contact our customer support team at support@peek.money, and we will guide you through the process of permanently deleting your account and all associated data.",
    },
    {
      question:
        "What happens to my data if Peek is acquired or goes out of business?",
      answer:
        "In the event that Peek is acquired or goes out of business, we will ensure that your data is protected and treated in accordance with our privacy policy. We will notify you of any changes to our business and provide you with the opportunity to delete your data if desired.",
    },
  ];

  return (
    <div className="flex flex-col">
      <h3 className="text-xl font-medium">Security & Privacy</h3>
      <p className="text-gray-500 dark:text-gray-400">
        Protecting your data & privacy is important to us. Here are some
        questions that our users have asked us.
      </p>
      <div className="my-6 flex-1 space-y-4">
        <Accordion type="single" collapsible>
          {faq.map((item, index) => (
            <AccordionItem key={index} value={index.toString()}>
              <AccordionTrigger className="text-left text-gray-700">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-500">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
