import { useGetRelatedPosts } from "@/hooks/useWisp";
import { BlogPostPreviewCard } from "./BlogPostPreviewCard";

export const RelatedBlogPost = ({ slug }: { slug: string }) => {
  const { data: relatedPost } = useGetRelatedPosts({ slug, limit: 4 });
  if (!relatedPost) return null;

  return (
    <div className="mt-8">
      <h2 className="mb-4 text-2xl font-bold">Related Posts</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {relatedPost.posts.map((post) => (
          <BlogPostPreviewCard
            key={post.slug}
            title={post.title}
            description={post.description}
            image={post.image}
            slug={post.slug}
          />
        ))}
      </div>
    </div>
  );
};
