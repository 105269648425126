import { CornerDownLeft } from "lucide-react";
import { CommandKey } from "../common/CommandKey";
import { Button } from "../ui/button";
import Loader from "../common/Loader";

type AddAssetFooterProps = {
  step: number;
  isDirty: boolean;
  setConfirmationDialogOpen: (open: boolean) => void;
  closeDialog: () => void;
  nextCallback: () => void;
  saveCallback: () => void;
  isPending: boolean;
};

export default function AddAssetFooter({
  step,
  isDirty,
  setConfirmationDialogOpen,
  closeDialog,
  nextCallback,
  saveCallback,
  isPending,
}: AddAssetFooterProps) {
  return (
    <div className="flex justify-end gap-x-2">
      <Button
        variant="outlineOrange"
        type="button"
        id="cancel"
        onClick={() => {
          if (isDirty) {
            setConfirmationDialogOpen(true);
          } else {
            closeDialog();
          }
        }}
      >
        Leave
      </Button>
      {step === 1 ? (
        <Button type="button" className="gap-x-2" onClick={nextCallback}>
          Next{" "}
          <CommandKey
            icon={<CornerDownLeft />}
            cmdKey="Enter"
            variant="white"
            callback={nextCallback}
          />
        </Button>
      ) : (
        <Button
          type="button"
          className="min-w-24 gap-x-2"
          onClick={saveCallback}
          disabled={isPending}
        >
          {isPending ? (
            <Loader />
          ) : (
            <>
              Save
              <CommandKey
                icon={<CornerDownLeft />}
                cmdKey="Enter"
                variant="white"
              />
            </>
          )}
        </Button>
      )}
    </div>
  );
}
