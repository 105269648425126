import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from "react";

interface DialogContextType {
  setDialogOpen: (id: string, isOpen: boolean) => void;
  isAnyDialogOpen: boolean;
  isDialogOpen: (id: string) => boolean;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogOpen, setDialogOpenState] = useState<{ [key: string]: boolean }>(
    {},
  );

  const isDialogOpen = useCallback(
    (id: string) => {
      return dialogOpen[id];
    },
    [dialogOpen],
  );

  const setDialogOpen = useCallback((id: string, isOpen: boolean) => {
    setDialogOpenState((prevDialogOpen) => ({
      ...prevDialogOpen,
      [id]: isOpen,
    }));
  }, []);

  const isAnyDialogOpen = useMemo(() => {
    return Object.values(dialogOpen).some((isOpen) => isOpen);
  }, [dialogOpen]);

  const contextValue = useMemo(
    () => ({
      isDialogOpen,
      setDialogOpen,
      isAnyDialogOpen,
    }),
    [isDialogOpen, setDialogOpen, isAnyDialogOpen],
  );

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
