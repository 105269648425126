import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Profile } from "@/lib/types";
import { NumberInputIcon } from "@/components/ui/number-input-icon";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { HelpCircle } from "lucide-react";
import peekpedia from "/images/icon-peekpedia.svg";

type StepThreeProps = {
  data: Profile;
  onNext: (data: Profile) => void;
  onSkip: () => void;
};

export function StepThree({ data, onNext, onSkip }: StepThreeProps) {
  const [formData, setFormData] = useState({
    occupation: data.occupation,
    income: data.income,
    incomeFrequency: data.incomeFrequency,
    approximateSavingsRate: data.approximateSavingsRate,
  });

  const updateFormData = (
    field: keyof typeof formData,
    value: string | number,
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">🧑🏻‍💻 Occupation</label>
        <Input
          placeholder="Enter your occupation"
          className="flex-1"
          defaultValue={formData.occupation}
          onChange={(e) => updateFormData("occupation", e.target.value)}
          autoFocus
        />
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-2">
        <label className="w-1/4 font-medium text-gray-600">💰 Income</label>
        <NumberInputIcon
          locale={getLocaleForCurrency(data.preferredCurrency as Currency)}
          placeholder="0"
          wrapperClassName="flex-1"
          value={formData.income?.toString() || "0"}
          onChange={(e) => updateFormData("income", +e.target.value)}
          startAdornment={
            <span className="text-gray-600">{data.preferredCurrency}</span>
          }
          className="text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
        <Select
          onValueChange={(value) =>
            setFormData((prev) => ({ ...prev, incomeFrequency: value }))
          }
          defaultValue={data.incomeFrequency}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="annually">Annually</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="flex w-1/4 flex-row items-center font-medium text-gray-600">
          💵 Approximate Savings Rate
          <HoverCard openDelay={300}>
            <HoverCardTrigger asChild>
              <HelpCircle
                size={16}
                className="ml-2 text-gray-500 hover:cursor-pointer"
              />
            </HoverCardTrigger>
            <HoverCardContent className="w-96">
              <div className="flex flex-col justify-between space-y-4 text-sm">
                <div className="flex flex-row items-center gap-x-2">
                  <img src={peekpedia} alt="Peekpedia" className="h-8 w-8" />
                  <h4 className="font-semibold">How it works</h4>
                </div>
                <div className="flex flex-col space-y-2">
                  <p>
                    Savings rate represents the percentage of your income that
                    you save.
                  </p>
                  <p>To calculate your savings rate:</p>
                  <ol className="list-decimal pl-4">
                    <li>Determine your total income after taxes.</li>
                    <li>
                      Calculate the total amount you save, including
                      contributions to savings accounts, retirement funds, and
                      other investments.
                    </li>
                    <li>Divide your total savings by your post-tax income.</li>
                    <li>Multiply by 100 to get the percentage.</li>
                  </ol>
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        </label>
        <NumberInputIcon
          locale={getLocaleForCurrency(data.preferredCurrency as Currency)}
          placeholder="0"
          wrapperClassName="flex-1"
          value={formData.approximateSavingsRate?.toString() || "0"}
          onChange={(e) =>
            updateFormData("approximateSavingsRate", +e.target.value)
          }
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          endAdornment={<span className="text-gray-600">%</span>}
        />
      </div>

      <div className="mt-4 flex w-full flex-row justify-between">
        <Button variant="link" onClick={onSkip} className="px-0 text-gray-400">
          Skip employment and savings
        </Button>

        <Button
          variant="default"
          onClick={() => onNext(formData)}
          disabled={!Object.values(formData).every(Boolean)}
        >
          Next
        </Button>
      </div>
    </>
  );
}
