import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCurrency } from "@/hooks/useCurrency";
import { SnapshotV2 } from "@/lib/types";
import { cn, formatQuantity, shortNumber } from "@/lib/utils";
import { ArrowDown, ArrowUp, CandlestickChart, MoveRight } from "lucide-react";
import { useState } from "react";

type MovementTableProps = {
  data: SnapshotV2[];
  isTradable: boolean;
  originalCurrency: string;
  currencyContext: Extract<
    ReturnType<typeof useCurrency>,
    { state: "SUCCESS" }
  >;
  currentBalance: number;
  positiveColor: string;
  negativeColor: string;
};

export function MovementTable({
  data,
  isTradable,
  originalCurrency,
  currencyContext,
  currentBalance,
  positiveColor,
  negativeColor,
}: MovementTableProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const { currency } = currencyContext;

  const itemsPerPage = 5;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handlePageChange = (page: number) => setCurrentPage(page);

  return (
    <div className="rounded-lg border border-gray-200 p-4">
      <Header />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead className="text-center">Activity</TableHead>
            {isTradable && (
              <>
                <TableHead className="text-right">Quantity</TableHead>
                <TableHead className="text-right">Unit Value</TableHead>
              </>
            )}
            <TableHead className="text-right">Total Value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedData.map((snapshot, index) => (
            <TableRow key={index} className="h-[48px] border-b border-gray-200">
              <TableCell className="text-gray-500">
                {new Date(snapshot.timestamp).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </TableCell>
              <ActivityCell
                snapshot={snapshot}
                originalCurrency={originalCurrency}
                positiveColor={positiveColor}
                negativeColor={negativeColor}
              />
              {isTradable && (
                <>
                  <TableCell className="space-x-2 text-right">
                    <div className="flex flex-row items-center justify-end gap-x-1">
                      <span>{formatQuantity(snapshot.quantity)}</span>
                    </div>
                  </TableCell>
                  <TableCell className="space-x-2 text-right">
                    <div className="flex flex-row items-center justify-center gap-x-1">
                      <span>
                        {originalCurrency}{" "}
                        {shortNumber(snapshot.original?.unitValue || 0)}
                      </span>
                    </div>
                  </TableCell>
                </>
              )}
              <TableCell className="text-right font-medium text-darkCyan">
                <PercentageMovementValue
                  percentageMovement={snapshot.unitValueChangePercentage}
                  currency={currency}
                  originalCurrency={originalCurrency}
                  originalValue={snapshot.original?.totalValue || 0}
                  value={snapshot.totalValue}
                  dailyMovement={snapshot.original?.totalValueChange || 0}
                  positiveColor={positiveColor}
                  negativeColor={negativeColor}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {isTradable && (
          <TableFooter>
            <TableRow>
              <TableCell className="text-right" colSpan={4}>
                Total Value
              </TableCell>
              <TableCell className="text-right">
                <TooltipProvider delayDuration={200}>
                  <Tooltip>
                    <TooltipTrigger>
                      <span
                        className={cn(
                          "text-gray-700",
                          currentBalance > 0 && positiveColor,
                          currentBalance < 0 && negativeColor,
                        )}
                      >
                        {originalCurrency}{" "}
                        {shortNumber(
                          currencyContext.convertValueToSelectedCurrency(
                            currentBalance,
                            currency,
                            originalCurrency,
                          ),
                        )}
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-black">
                      <p className="text-white">
                        {currency} {shortNumber(currentBalance)}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}

const Header = () => (
  <div className="mb-4 flex flex-row items-center gap-x-2">
    <CandlestickChart className="h-4 w-4 text-gray-500 " />
    <h3 className="text-gray-700">Movement</h3>
  </div>
);

const ActivityCell = ({
  snapshot,
  originalCurrency,
  positiveColor,
  negativeColor,
}: {
  snapshot: SnapshotV2;
  originalCurrency: string;
  positiveColor: string;
  negativeColor: string;
}) => (
  <TableCell className="space-x-2 text-center">
    <div className="flex flex-row items-center justify-start gap-x-1">
      {snapshot.prevUnitValue !== snapshot.unitValue ? (
        <>
          {snapshot.unitValue > snapshot.prevUnitValue && (
            <ArrowUp size={12} className={positiveColor} />
          )}
          {snapshot.unitValue < snapshot.prevUnitValue && (
            <ArrowDown size={12} className={negativeColor} />
          )}
          <span>
            {originalCurrency}{" "}
            {shortNumber(snapshot.original?.prevUnitValue || 0)}
          </span>
          <MoveRight size={12} className="text-grey-700" />
          <span>
            {originalCurrency} {shortNumber(snapshot.original?.unitValue || 0)}
          </span>
        </>
      ) : (
        <span className="w-full text-center">-</span>
      )}
    </div>
  </TableCell>
);

const Pagination = ({
  currentPage,
  totalPages,
  handlePageChange,
}: {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
}) => (
  <div className="mt-4 flex items-center justify-end">
    <Button
      size="sm"
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      variant="outline"
    >
      Previous
    </Button>
    <span className="mx-2 text-sm text-gray-500">
      {currentPage} / {totalPages}
    </span>
    <Button
      size="sm"
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      variant="outline"
    >
      Next
    </Button>
  </div>
);

type PercentageMovementValueProps = {
  originalCurrency: string;
  originalValue: number;
  currency: string;
  value: number;
  percentageMovement: number;
  dailyMovement: number;
  positiveColor: string;
  negativeColor: string;
};

const PercentageMovementValue: React.FC<PercentageMovementValueProps> = ({
  originalCurrency,
  originalValue,
  currency,
  value,
  percentageMovement,
  dailyMovement,
  positiveColor,
  negativeColor,
}) => {
  const isNegative = parseFloat(percentageMovement.toFixed(2)) < 0;
  const isPositive = parseFloat(percentageMovement.toFixed(2)) > 0;
  const noChange = parseFloat(percentageMovement.toFixed(2)) === 0;

  const variant = isPositive ? "outlineGreen" : "outline";
  const textColor = isPositive
    ? positiveColor
    : isNegative
      ? negativeColor
      : "text-gray-700";

  return (
    <div className="flex flex-col items-end justify-end gap-x-1">
      <span className={textColor}>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger>
              {originalCurrency} {shortNumber(originalValue)}
            </TooltipTrigger>
            <TooltipContent className="bg-black">
              <p className="text-white">
                {currency} {shortNumber(value)}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </span>
      {!noChange && (
        <Badge variant={variant} className="gap-x-1 border-none p-0">
          {isPositive && <ArrowUp size={12} className={textColor} />}
          {isNegative && <ArrowDown size={12} className={textColor} />}
          <span className={textColor}>{shortNumber(percentageMovement)}%</span>
          <>
            <span className="text-gray-500">|</span>
            <span className={textColor}>
              {originalCurrency} {shortNumber(dailyMovement)}
            </span>
          </>
        </Badge>
      )}
    </div>
  );
};
