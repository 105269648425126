import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { searchItems } from "./searchItems";
import { CustomSearchItems } from "./types";
import { AutomaticConnectionsList } from "./AutomaticConnectionsList";
import { QuickPaste } from "./QuickPaste";

const importTypes: { label: string; type: string }[] = [
  { type: "automatic", label: "Automatic Connection" },
  { type: "manual", label: "Manual Import" },
  { type: "excel", label: "Quick Paste" },
];

export default function AddAssetCommand({
  setAccountType,
  openPlaid,
  openFlanks,
  openCustom,
  openEmereg,
  openSGFinDex,
  openZerion,
  openSnaptrade,
  closeDialog,
}: {
  setAccountType: (category: CustomSearchItems) => void;
  openPlaid: (plaidCustomLink: string) => void;
  openFlanks: (bankId: string, bankName: string) => void;
  openSnaptrade: (broker: string) => void;
  openCustom: (customLink: string) => void;
  openEmereg: (customLink: string) => void;
  openSGFinDex: (orgName: string) => void;
  openZerion: () => void;
  closeDialog: () => void;
}) {
  const ref = React.useRef<HTMLInputElement>(null);
  const [importType, setImportType] = useState("automatic");

  // Set focus on id commandInput on mount and when importType changes
  useEffect(() => {
    ref?.current?.focus();
  }, [importType]);

  return (
    <Command
      filter={(value: string, search: string) => {
        if (value.toLowerCase().includes(search.toLowerCase())) return 1;
        return 0;
      }}
    >
      {(importType === "automatic" || importType === "manual") && (
        <CommandInput
          ref={ref}
          autoFocus
          placeholder="What would you like to add?"
        />
      )}
      <div className="flex items-center justify-center gap-x-4 py-4">
        {importTypes.map((item, index) => {
          return (
            <button
              key={index + 1}
              onClick={() => setImportType(item.type)}
              className={cn(
                "rounded-sm py-1 px-2 hover:bg-gray-100 text-sm text-gray-400",
                importType === item.type && "bg-gray-100 text-gray-600",
              )}
            >
              {item.label}
            </button>
          );
        })}
      </div>

      {importType === "automatic" && (
        <AutomaticConnectionsList
          openPlaid={openPlaid}
          openFlanks={openFlanks}
          openSnaptrade={openSnaptrade}
          openCustom={openCustom}
          openSGFinDex={openSGFinDex}
          openEmereg={openEmereg}
          openZerion={openZerion}
        />
      )}

      {importType === "manual" && (
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup>
            {Object.entries(searchItems).map(([key, item]) => (
              <SearchItem
                key={key}
                item={item}
                itemKey={key as CustomSearchItems}
                setCategory={setAccountType}
              />
            ))}
          </CommandGroup>
        </CommandList>
      )}

      {importType === "excel" && <QuickPaste closeDialog={closeDialog} />}
    </Command>
  );
}

function SearchItem(props: {
  itemKey: CustomSearchItems;
  item: (typeof searchItems)[CustomSearchItems];
  setCategory: (category: CustomSearchItems) => void;
}) {
  function handleClick() {
    props.setCategory(props.itemKey);
  }

  return (
    <CommandItem
      className="relative flex items-center gap-4 aria-selected:bg-border/50"
      onSelect={handleClick}
      value={props.item.title + ":" + props.item.description}
    >
      <div
        className={cn(
          "rounded-md p-2",
          props.item.iconBackgroundColor,
          "iconColor" in props.item ? props.item.iconColor : "",
        )}
      >
        <props.item.icon className="h-4 w-4" />
      </div>
      <div className="flex flex-col justify-center">
        <span className="text font-medium">{props.item.title}</span>
        {"description" in props.item && (
          <span className="text-muted-foreground">
            {props.item.description}
          </span>
        )}
      </div>
    </CommandItem>
  );
}
