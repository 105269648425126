import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_SUBSCRIPTIONS_QUERY_KEY } from "@/lib/constants";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { usePeekUser } from "./usePeekUser";

export function useGetSubscriptionsQuery() {
  const { getToken, userId } = useAuth();
  const peekUserContext = usePeekUser();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [GET_SUBSCRIPTIONS_QUERY_KEY, userId],
    queryFn: () => getSubscriptions(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });
  return { ...data, isLoading, isError, error };
}

type GetSubscriptionsResponse = {
  activeSubscriptionEndTimestamp: number | null;
  subscribed: boolean;
  trialEndTimestamp: number | null;
  cancelAtPeriodEnd: boolean;
};

async function getSubscriptions(
  getToken: (options?: { template: string }) => Promise<string | null>,
): Promise<GetSubscriptionsResponse> {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<GetSubscriptionsResponse>(
    `${config.backendUrl}/billing/subscription`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
}
