import React from "react";
import { cn } from "@/lib/utils";
export function ScrollingBanner({
  texts,
  onClick,
}: {
  texts: string[];
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const repeatedTexts = Array.from({ length: 10 }, (_, i) =>
    texts.map((text, j) => (
      <span className="pl-36" key={i * texts.length + j}>
        {text}
      </span>
    )),
  );
  return (
    <div
      id="marqueeContainer"
      className={cn(
        "bg-customOrange text-white overflow-hidden whitespace-nowrap",
        onClick ? "cursor-pointer" : "",
      )}
      onClick={onClick}
    >
      <div className="animate-marquee">{repeatedTexts}</div>
    </div>
  );
}
