import axios from "./axios";
import config from "./config";

export async function searchStock(search?: string) {
  if (!search) return [];

  try {
    const escaped = encodeURIComponent(search);

    const { data } = await axios.get<
      {
        name: string;
        symbol: string;
        currency: string;
        stockExchange: string;
        exchangeShortName: string;
      }[]
    >(`${config.backendUrl}/stock/search`, {
      params: {
        search: escaped,
      },
    });
    return data;
  } catch (e) {
    return [];
  }
}

export async function searchCrypto(search?: string) {
  if (!search) return [];

  try {
    const escaped = encodeURIComponent(search);
    const { data } = await axios.get<{ name: string; symbol: string }[]>(
      `${config.backendUrl}/crypto/search`,
      {
        params: {
          search: escaped,
        },
      },
    );
    return data;
  } catch (e) {
    return [];
  }
}

export async function getStockPrice(ticker: string) {
  const escaped = encodeURIComponent(ticker);
  const { data } = await axios.get<{ currency: string; price: number }>(
    `${config.backendUrl}/stock`,
    {
      params: {
        ticker: escaped,
      },
    },
  );
  return data;
}

export async function getCryptoPrice(ticker: string) {
  const { data } = await axios.get<{ currency: string; price: number }>(
    `${config.backendUrl}/crypto`,
    {
      params: {
        ticker,
      },
    },
  );
  return data;
}
