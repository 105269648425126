import axiosDefault, { AxiosError } from "axios";

const axios = axiosDefault.create();

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const error = err as Error | AxiosError;
    if (axiosDefault.isAxiosError(error)) {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else if (error.request) {
        return Promise.reject(error.request);
      } else {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);

export default axios;
