import { Analysis } from "@/hooks/useAnalysis";
import { Checkbox } from "@/components/ui/checkbox";
import { Card, CardContent } from "@/components/ui/card";
import { BarChartHorizontal, Info, RotateCw, Sparkle } from "lucide-react";
import RadialProgress from "@/components/ui/radial-progress-bar";
import DOMPurify from "dompurify";
import AllocationChart from "./AllocationChart";
import { Button } from "@/components/ui/button.tsx";

type AnalysisDetailsProps = {
  analysis: Analysis;
  onRefresh?: () => void;
};

export default function AnalysisDetails({
  analysis,
  onRefresh,
}: AnalysisDetailsProps) {
  const isAssetAllocationAnalysis = analysis.actual_allocation !== undefined;
  const isETFOverlapAnalysis = analysis.overlaps !== undefined;

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row items-center gap-x-4">
        {analysis.score > 0 && (
          <RadialProgress
            progress={analysis.score * 10}
            text={analysis.score.toString()}
            size={80}
            strokeWidth={10}
            dashArray="3" // This creates dashes in the stroke
          />
        )}
        {!isETFOverlapAnalysis && (
          <div className="flex flex-col gap-y-2">
            <h3
              className="font-normal"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(analysis.title),
              }}
            />
            <p
              className="font-normal text-gray-500"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(analysis.description),
              }}
            />
          </div>
        )}
        {onRefresh && (
          <Button
            variant="ghost"
            className="ml-auto flex h-fit self-start p-0 text-muted-foreground hover:bg-transparent"
            onClick={onRefresh}
          >
            Refresh
            <RotateCw className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      {isAssetAllocationAnalysis && (
        <>
          <hr className="border-gray-100" />
          <div>
            <div className="flex flex-row items-center gap-x-1 text-gray-400 ">
              <BarChartHorizontal className="h-4 w-4 "></BarChartHorizontal>
              <h4 className="text-xs font-semibold">
                Your Portfolio Composition
              </h4>
            </div>
            <div className="grid h-[400px] grid-cols-2 gap-2">
              <AllocationChart
                targetAllocation={analysis.target_allocation || []}
                actualAllocation={analysis.actual_allocation || []}
              />
            </div>
          </div>
        </>
      )}
      <hr className="border-gray-100" />
      <div className="flex flex-col gap-y-3">
        <div className="flex flex-row items-center gap-x-3 text-gray-400 ">
          <Info className="h-4 w-4 "></Info>
          <h4 className="text-xs font-semibold">Why?</h4>
        </div>
        <div className="grid grid-cols-2 gap-2">
          {analysis.insights?.map((insight, recIndex) => (
            <Card key={recIndex} className="border-none bg-beige">
              <CardContent className="space-y-4">
                <h3
                  className="font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(insight.title),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(insight.description),
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      <hr className="border-gray-100" />
      <div className="space-y-4">
        <div className="flex w-fit flex-row items-center gap-x-1 rounded-full bg-gradient-to-r from-[#FF7CD3] to-[#68A4FF] p-2 text-white">
          <Sparkle className="h-4 w-4"></Sparkle>
          <h4 className="text-xs font-semibold">Suggestions</h4>
        </div>
        <ul className="space-y-2">
          {analysis.recommendation?.map((recommendation, recIndex) => (
            <li
              key={recIndex}
              className="flex items-baseline gap-x-2 rounded-lg bg-gray-100 p-4"
            >
              <Checkbox />
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(recommendation),
                }}
              ></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
