import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";
import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useContext,
} from "react";
import { SnapTradeReact } from "snaptrade-react";
import { useDialog } from "./useDialog";
import { useWindowMessage } from "snaptrade-react/hooks/useWindowMessage";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import posthog from "posthog-js";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics.ts";

interface SnaptradeContextType {
  snaptradeRedirectLink: string;
  setSnaptradeRedirectLink: (link: string) => void;
  generateRedirectLink: (broker: string) => Promise<void>;
  regenerateRedirectLinkForAccount: (accountId: number) => Promise<void>;
}

const SnaptradeContext = createContext<SnaptradeContextType | undefined>(
  undefined,
);

export const SnaptradeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getToken } = useAuth();
  const { isDialogOpen, setDialogOpen } = useDialog();
  const [snaptradeBroker, setSnaptradeBroker] = useState<string>("");

  const [snaptradeRedirectLink, setSnaptradeRedirectLink] =
    useState<string>("");

  const generateRedirectLink = useCallback(
    async (broker: string) => {
      try {
        setSnaptradeBroker(broker);
        posthog?.capture(`clicked_snaptrade`, {
          broker,
        });
        const token = await getToken();

        await axios.post(
          `${config.integrationsUrl}/snaptrade/create-user`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const createRedirectLinkResponse = await axios.post(
          `${config.integrationsUrl}/snaptrade/create-redirect-link`,
          { broker },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setSnaptradeRedirectLink(createRedirectLinkResponse.data.redirectURI);
        setDialogOpen("snaptrade", true);
      } catch (error) {
        console.error("Error generating redirect link:", error);
      }
    },
    [getToken, setDialogOpen, setSnaptradeBroker],
  );

  const regenerateRedirectLinkForAccount = useCallback(
    async (accountId: number) => {
      try {
        posthog?.capture(`relogin_snaptrade`, {
          accountId,
        });

        const token = await getToken();
        const regenerateRedirectLinkResponse = await axios.post(
          `${config.integrationsUrl}/snaptrade/regenerate-redirect-link`,
          { accountId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setSnaptradeRedirectLink(
          regenerateRedirectLinkResponse.data.redirectURI,
        );
        setDialogOpen("snaptrade", true);
      } catch (error) {
        console.error("Error generating redirect link:", error);
      }
    },
    [getToken, setDialogOpen],
  );

  const onSuccess = async () => {
    const token = await getToken();

    if (!token) return;

    setDialogOpen("snaptrade", false);
    posthog?.capture(`snaptrade_connected`, {
      broker: snaptradeBroker,
    });

    const promise = axios
      .post(
        `${config.integrationsUrl}/snaptrade/add-account-sync-task`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        await Promise.all([
          queryClient.refetchQueries({
            queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
          }),
          queryClient.refetchQueries({
            queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
          }),
        ]);
      });
    toast.promise(promise, {
      loading: `Connecting to your account...`,
      success: `Successfully connected to your account! Starting to import your data...`,
      error: "Oops! Something went wrong. Please retry.",
    });
  };

  const onError = () => {
    toast.error("Error connecting to SnapTrade. Please try again later");
  };

  const close = () => {
    setDialogOpen("snaptrade", false);
  };

  useWindowMessage({
    handleSuccess: onSuccess,
    handleError: onError,
    close,
  });

  return (
    <SnaptradeContext.Provider
      value={{
        snaptradeRedirectLink,
        setSnaptradeRedirectLink,
        generateRedirectLink,
        regenerateRedirectLinkForAccount,
      }}
    >
      <SnapTradeReact
        loginLink={snaptradeRedirectLink}
        isOpen={isDialogOpen("snaptrade")}
        close={() => setDialogOpen("snaptrade", false)}
      />
      {children}
    </SnaptradeContext.Provider>
  );
};

export function useSnaptrade() {
  return useContext(SnaptradeContext);
}
