import React from "react";
import { Chart } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TimeScale,
} from "chart.js";
import { shortNumber } from "@/lib/utils";
import { draw } from "patternomaly";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "@/components/ui/button";
import iconConcierge from "/images/icon-concierge.svg";
import { Fullscreen } from "lucide-react";
import "chartjs-adapter-date-fns";
import { format, parse } from "date-fns";
import { SnapshotV2 } from "@/lib/types";

interface Props {
  snapshots: SnapshotV2[];
  currency: string;
}

ChartJS.register(
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
  BarController,
  zoomPlugin,
  TimeScale,
);

const PNLBarChart: React.FC<Props> = ({ snapshots, currency }) => {
  const chartRef = React.useRef<ChartJS<"bar" | "line">>(null);

  const hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: ChartJS) {
      const {
        ctx,
        tooltip,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;

      if (chart.getActiveElements().length > 0) {
        const xCoor = x.getPixelForValue(
          tooltip?.dataPoints[0]?.dataIndex ?? 0,
        );

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = "rgb(0,0,0,0.8)";
        ctx.moveTo(xCoor, top);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    },
  };

  const getMaxDate = (snapshots: SnapshotV2[]) =>
    snapshots.reduce((acc, snapshot) => {
      const date = new Date(snapshot.timestamp);
      return date > acc ? date : acc;
    }, new Date());

  const getMinDate = (maxDate: Date) =>
    new Date(maxDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  const parseSnapshotData = (snapshots: SnapshotV2[]) => {
    const labels: Date[] = [];
    const y1Data: number[] = [];
    const y2Data: number[] = [];
    const y3Data: number[] = [];

    const firstDay = new Date(snapshots[0].timestamp);
    const previousDay = new Date(firstDay);
    previousDay.setDate(firstDay.getDate() - 1);

    labels.push(previousDay);
    y1Data.push(0);
    y2Data.push(0);
    y3Data.push(0);

    let lastSnapshot = snapshots[0];
    const dateMap = new Map<string, { y1: number; y2: number; y3: number }>();

    snapshots.forEach((snapshot) => {
      const currentDay = new Date(snapshot.timestamp);
      const lastDay = new Date(lastSnapshot.timestamp);

      while (lastDay < currentDay) {
        lastDay.setDate(lastDay.getDate() + 1);
        if (lastDay < currentDay && !dateMap.has(lastDay.toDateString())) {
          dateMap.set(lastDay.toDateString(), {
            y1: 0,
            y2: 0,
            y3: 0,
          });
        }
      }

      if (!dateMap.has(currentDay.toDateString())) {
        dateMap.set(currentDay.toDateString(), {
          y1: snapshot.totalValue,
          y2: snapshot.pnl,
          y3: snapshot.totalCapitalInvested,
        });
      }
      lastSnapshot = snapshot;
    });

    dateMap.forEach((value, key) => {
      labels.push(new Date(key));
      y1Data.push(value.y1);
      y2Data.push(value.y2);
      y3Data.push(value.y3);
    });

    return { labels, y1Data, y2Data, y3Data };
  };

  const snapshotData = parseSnapshotData(snapshots);
  const maxDate = getMaxDate(snapshots);
  const minDate = getMinDate(maxDate);

  const data: ChartData<"bar" | "line", number[], string> = {
    labels: snapshotData.labels.map((date) => date.toISOString()),
    datasets: [
      {
        label: "Profit/Loss",
        data: snapshotData.y2Data,
        backgroundColor: snapshotData.y2Data.map((value) =>
          value < 0
            ? draw("diagonal", "rgb(220, 38, 38, 0.0)", "#dc2626", 5)
            : draw("diagonal", "rgb(220, 38, 38, 0.0)", "#41989D", 5),
        ),
        borderColor: snapshotData.y2Data.map((value) =>
          value < 0 ? "#dc2626" : "#41989D",
        ),
        borderWidth: 1,
        barPercentage: 0.8,
        borderRadius: 4,
        type: "bar",
        yAxisID: "y2",
      },
      {
        label: "Total Value",
        data: snapshotData.y1Data,
        borderColor: "#4966AF",
        type: "line",
        yAxisID: "y",
        tension: 0.5,
        pointBackgroundColor: "#4966AF",
        pointRadius: 0,
        pointHitRadius: 15,
        borderWidth: 2,
      },
      {
        label: "Total Capital Invested",
        data: snapshotData.y3Data,
        borderColor: "#41989D",
        type: "line",
        yAxisID: "y",
        tension: 0.5,
        pointBackgroundColor: "#41989D",
        pointRadius: 0,
        pointHitRadius: 15,
        borderWidth: 2,
      },
    ],
  };

  const options: ChartOptions<"bar" | "line"> = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    transitions: {
      zoom: {
        animation: {
          duration: 400,
          easing: "easeOutQuad",
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        min: minDate.toISOString(),
        max: maxDate.toISOString(),
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Total",
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
        },
        ticks: {
          callback: function (val, index) {
            const label = val === 0 ? 0 : shortNumber(val as number);
            return index % 2 === 0 ? label : "";
          },
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
      y2: {
        title: {
          display: true,
          text: "Profit/Loss",
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: "#F4EEE7",
          drawOnChartArea: false,
        },
        position: "right",
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          mode: "x",
          drag: {
            enabled: true,
            backgroundColor: "#F4EEE380",
          },
          pinch: {
            enabled: true,
          },
        },
      },
      tooltip: {
        titleFont: {
          family: "'Manrope', sans-serif",
          size: 18,
        },
        bodyFont: {
          family: "'Manrope', sans-serif",
          size: 14,
          lineHeight: 1.4,
        },
        callbacks: {
          title: (context) => {
            const date = parse(
              context[0].label,
              "MMM d, yyyy, h:mm:ss a",
              new Date(),
            );
            return format(date, "MMM d, yyyy");
          },
          label: (context) => {
            return `${context.dataset.label}: ${currency} ${shortNumber(
              context.raw as number,
            )}`;
          },
        },
        caretSize: 0,
        padding: 12,
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
      chartRef.current.options.scales!.x!.min = undefined;
      chartRef.current.update();
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="h-[400px]">
        <Chart
          ref={chartRef}
          type="bar"
          options={options}
          data={data}
          plugins={[hoverLine]}
        />
      </div>
      {snapshotData.labels.length > 7 && (
        <div className="flex items-center space-x-4 rounded-md border p-4">
          <img src={iconConcierge} alt="concierge" className="h-6 w-6" />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none text-gray-700">
              Did you know?
            </p>
            <p className="text-sm text-gray-500">
              You can drag on the chart to zoom in
            </p>
          </div>
          <Button
            variant="outline"
            onClick={handleResetZoom}
            className="gap-x-2"
          >
            <Fullscreen size={16} />
            Reset Zoom
          </Button>
        </div>
      )}
    </div>
  );
};

export default PNLBarChart;
