import axios from "@/lib/axios";
import config from "@/lib/config";
import { Goal, Profile } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { queryClient } from "@/lib/queryClient";
import { GET_PROFILE_QUERY_KEY } from "@/lib/constants.ts";

export function useAddProfile() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const { mutateAsync: addProfileAsync, isPending } = useMutation({
    mutationFn: ({ profile, goal }: { profile: Profile; goal?: Goal }) =>
      callAddProfile(profile, goal, getToken),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PROFILE_QUERY_KEY],
      });
      // do we need to capture userId here?
      posthog.capture("add_onboarding_data_success", {
        data,
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { addProfileAsync, isPending };
}

async function callAddProfile(
  profile: Profile,
  goal: Goal | undefined,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.post(
    `${config.backendUrl}/profile`,
    { profile, ...(goal && { goal }) },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data };
}
