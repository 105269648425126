import { Button } from "@/components/ui/button";
import {
  beautifyAccountType,
  cn,
  formatQuantity,
  shortNumber,
} from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { ChevronDown, ChevronRight } from "lucide-react";
import { TableRowData } from "./types";

const columnHelper = createColumnHelper<TableRowData>();

const hideQuantity = [
  "CASH",
  "FIXED_DEPOSIT",
  "ANGEL_INVESTMENT",
  "HEDGE_FUND",
  "PRIVATE_CREDIT",
  "PRIVATE_EQUITY",
  "LIFE_INSURANCE",
  "REAL_ESTATE",
  "SG_CPF_OA",
  "SG_CPF_RA",
  "SG_CPF_SA",
  "SG_CPF_MA",
  "SG_SRS",
  "US_401K",
  "US_IRA",
  "US_529",
  "ROBO",
];

export const columns = [
  columnHelper.accessor("asset", {
    header: ({ table }) => (
      <div className="flex items-center gap-x-1">
        <Button
          variant="ghost"
          size="icon"
          {...{
            onClick: table.getToggleAllRowsExpandedHandler(),
          }}
        >
          {table.getIsAllRowsExpanded() ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronRight className="h-4 w-4" />
          )}
        </Button>
        Asset / Liability
      </div>
    ),
    cell: ({ row }) => {
      const rowData = row.original;

      let label = "";

      if (rowData.type === "section") {
        label = `${beautifyAccountType(rowData.asset)} ${
          row.subRows.length && !row.getIsExpanded()
            ? `(${row.subRows.reduce((acc, sr) => acc + sr.subRows.length, 0)})`
            : ""
        }`;
      }

      if (rowData.type === "subSection") {
        label = `${rowData.asset} ${
          row.subRows.length && !row.getIsExpanded()
            ? `(${row.subRows.length})`
            : ""
        }`;
      }

      return (
        <div
          className={cn(
            "flex items-center gap-x-1",
            (rowData.type === "section" || rowData.type === "subSection") &&
              "font-medium",
            rowData.type === "subSection" && "pl-7",
          )}
        >
          {row.getCanExpand() ? (
            <div className="p-2">
              {row.getIsExpanded() ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronRight className="h-4 w-4" />
              )}
            </div>
          ) : null}
          {rowData.type === "asset" ? (
            <div className="pl-16">
              {label}
              <span className="block">
                {rowData.fullName ? (
                  <>
                    {rowData.fullName}
                    <span className="ml-1 font-normal">({rowData.asset})</span>
                    {rowData.update && (
                      <span className="ml-1 rounded-sm bg-darkCyan px-2 text-sm text-white">
                        Update
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {rowData.asset}{" "}
                    {rowData.update && (
                      <span className="ml-1 rounded-sm bg-darkCyan px-2 text-sm text-white">
                        Update
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
          ) : (
            <span className="block">{label}</span>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor((row) => row.type === "asset" && row.quantity, {
    id: "quantity",
    header: () => <div className="flex justify-end">Quantity</div>,
    cell: ({ row }) => {
      if (
        row.original.type === "asset" &&
        !hideQuantity.includes(row.original.assetType)
      )
        return (
          <div className="text-right">
            <span>{formatQuantity(+row.original.quantity)}</span>
            <span className="ml-4">x</span>
          </div>
        );
    },
  }),
  columnHelper.accessor("unitValue", {
    header: () => <span className="text-left">Unit Value</span>,
    cell: ({ row }) => {
      if (row.original.type === "asset")
        return (
          <div className="text-left">
            <span className="mr-2">{row.original.currency}</span>
            <span>{shortNumber(+row.original.unitValue)}</span>
          </div>
        );
    },
  }),
];
