import config from "@/lib/config";
import { useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToFlanks() {
  const { user } = useUser();
  const posthog = usePostHog();

  // only redirect to flanks when user.id available
  if (!user) {
    return <></>;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const bankId = searchParams.get("bankId");
  const bankName = searchParams.get("bankName");
  const scaToken = searchParams.get("scaToken");
  const resetToken = searchParams.get("resetToken");
  posthog?.capture(`clicked_flanks_${bankId}`);
  window.location.href = `${config.flanksLink}&bank=${bankId}&bankName=${bankName}&language=en&external_id=${user.id}${scaToken ? "&sca_token=" + scaToken : ""}${resetToken ? "&reset_token=" + resetToken : ""}`;
  return <></>;
}
