import { MenuIcon } from "lucide-react";
import { Button } from "../ui/button";
import { Sheet, SheetContent, SheetHeader } from "../ui/sheet";
import { Link } from "react-router-dom";
import logoFull from "/images/logo-full.svg";
import logoSmall from "/images/logo-small.svg";
import { NavFooter } from "./NavFooter";
import { Menu } from "./Menu";
import { useRef, useState } from "react";
import { KnockFeedProvider, NotificationFeedPopover } from "@knocklabs/react";
import config from "@/lib/config";
import { UnseenBadge } from "../ui/unseen-badge";
import NotificationToaster from "../NavBar/NotificationToaster";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import {
  Currency,
  currencies,
  getCurrencySymbol,
  useCurrency,
} from "@/hooks/useCurrency";
import { usePostHog } from "posthog-js/react";
import { usePlaidIntegration } from "@/hooks/usePlaidIntegration";
import { AddAssetDialog } from "../AddAsset/AddAssetDialog";
import ZerionDialog from "../AddAsset/Zerion/ZerionDialog";
import EndowusLoginDialog from "../AddAsset/Endowus/EndowusLoginDialog";
import StashAwayLoginDialog from "../AddAsset/StashAway/StashAwayLoginDialog";
import EmeregLoginDialog from "../AddAsset/Emereg/EmeregLoginDialog";

export function Navbar() {
  const [sheetOpen, setSheetOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [openEndowus, setOpenEndowus] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openStashAway, setOpenStashAway] = useState<{
    isOpen: boolean;
    accountId?: number;
  }>({ isOpen: false });
  const [openEmereg, setOpenEmereg] = useState<{
    isOpen: boolean;
    customLink: string;
    accountId?: number;
  }>({ isOpen: false, customLink: "dbs" });

  const notifButtonRef = useRef<HTMLButtonElement>(null);

  const { setPlaidCustomLinkNameAndResetToken } = usePlaidIntegration();
  const posthog = usePostHog();
  const { currency, setCurrency } = useCurrency();

  return (
    <header className="fixed top-0 z-10 flex w-full flex-row items-center justify-between bg-gray-50 px-10 py-2 backdrop-blur supports-[backdrop-filter]:bg-gray-50 dark:shadow-secondary lg:hidden">
      <Link to="/" className="font-libre text-2xl font-bold">
        <img src={logoFull} alt="logo" className="hidden h-8 md:block" />
        <img src={logoSmall} alt="logo" className="h-8 md:hidden" />
      </Link>

      <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
        <div className="flex gap-x-3">
          <Select
            value={currency}
            onValueChange={(value) => {
              posthog.capture("currency_changed", { currency: value });
              setCurrency(value as Currency);
            }}
          >
            <SelectTrigger
              size="sm"
              variant="ghost"
              className="rounded-full bg-black/5"
            >
              <div className="flex gap-x-1">
                <span>{getCurrencySymbol(currency)}</span>
                {currency}
              </div>
            </SelectTrigger>
            <SelectContent align="end">
              {currencies.map((c: Currency) => (
                <SelectItem key={c} value={c}>
                  <div className="flex gap-x-1">
                    <span>{getCurrencySymbol(c)}</span>
                    {c}
                  </div>
                </SelectItem>
              ))}
              <hr className="my-2" />
              <Button
                size="sm"
                formTarget="_blank"
                variant="outlineOrange"
                onClick={() =>
                  window.open("mailto:support@peek.money", "_blank")
                }
              >
                <span>Request Currency</span>
              </Button>
            </SelectContent>
          </Select>

          <KnockFeedProvider feedId={config.knockFeedChannelId}>
            <>
              <Button
                ref={notifButtonRef}
                onClick={() => setIsVisible(!isVisible)}
                variant="ghost"
                size="sm"
                className="space-x-1"
              >
                <UnseenBadge badgeCountType="unread" />
              </Button>

              <NotificationFeedPopover
                buttonRef={notifButtonRef}
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
              />
              <NotificationToaster />
            </>
          </KnockFeedProvider>
          <Button
            className="h-8"
            variant="outline"
            size="icon"
            onClick={() => setSheetOpen(true)}
          >
            <MenuIcon size={20} />
          </Button>
        </div>
        <SheetContent className="flex h-full flex-col px-3" side="left">
          <SheetHeader>
            <Button
              className="flex items-center justify-center pb-2 pt-1"
              variant="link"
              asChild
            >
              <Link to="/" className="font-libre text-2xl font-bold">
                <img src={logoFull} alt="logo" className="h-8" />
              </Link>
            </Button>
          </SheetHeader>

          <AddAssetDialog
            commandKeyVariant="transparent"
            variant="default"
            size="lg"
            className="w-full"
            setPlaidCustomLinkNameAndResetToken={
              setPlaidCustomLinkNameAndResetToken
            }
            buttonLabel="Add new asset"
            showIcon={false}
            setOpenEndowus={(isOpen: boolean, accountId?: number) =>
              setOpenEndowus({ isOpen, accountId })
            }
            setOpenStashAway={(isOpen: boolean, accountId?: number) =>
              setOpenStashAway({ isOpen, accountId })
            }
            setOpenEmereg={(
              isOpen: boolean,
              customLink: string,
              accountId?: number,
            ) => setOpenEmereg({ isOpen, customLink, accountId })}
          />

          <Menu close={() => setSheetOpen(false)} />

          <div className="flex grow flex-col justify-end gap-y-2">
            <NavFooter />
          </div>
        </SheetContent>
      </Sheet>

      <>
        <ZerionDialog />
        <EndowusLoginDialog
          open={openEndowus.isOpen}
          setOpen={(isOpen: boolean, accountId?: number) =>
            setOpenEndowus({ isOpen, accountId })
          }
          accountId={openEndowus.accountId}
        />
        <StashAwayLoginDialog
          open={openStashAway.isOpen}
          setOpen={(isOpen: boolean, accountId?: number) =>
            setOpenStashAway({ isOpen, accountId })
          }
          accountId={openStashAway.accountId}
        />
        <EmeregLoginDialog
          open={openEmereg.isOpen}
          setOpen={(isOpen: boolean, customLink: string, accountId?: number) =>
            setOpenEmereg({ isOpen, customLink, accountId })
          }
          accountId={openEmereg.accountId}
          customLink={openEmereg.customLink}
        />
      </>
    </header>
  );
}
