import unlock from "/images/unlock.svg";
import unlockedInsights from "/images/unlocked-insights.svg";
import iconScout from "/images/icon-scout.svg";
import { Currency, useCurrency } from "@/hooks/useCurrency";
import { DisplayValue } from "@/components/ui/display-value";
import { Progress } from "@/components/ui/progress";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useGetProfileQuery } from "@/hooks/useProfile";
import { useUpdateProfileMutation } from "@/hooks/useUpdateProfileMutation";
import Loader from "../common/Loader";
import { PlusIcon } from "lucide-react";
import peekCoach from "/images/coach.svg";
import { Input } from "./Input";

export const ProgressLayout = (props: {
  currency: Currency;
  netWorth: number | undefined;
  percentage: number;
}) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch rounded-lg border border-gray-200 p-4">
      <div className="text-sm">You have imported...</div>
      <div>
        <DisplayValue
          compact={true}
          currency={props.currency}
          value={props.netWorth ? props.netWorth : 0}
          size="large"
        />
      </div>
      <Progress
        value={props.percentage}
        className="h-2 rounded-md"
        progressBarColor="bg-cyan"
      />
      <span className="text-sm text-gray-500">
        {Math.floor(props.percentage)}% of your net worth into Peek
      </span>
    </div>
  );
};

export const UnlockInsights = ({
  netWorth,
  onUnlockInsights,
}: {
  netWorth: number;
  onUnlockInsights: () => void;
}) => {
  const currencyContext = useCurrency();
  const { data: profile } = useGetProfileQuery();
  const { updateProfile, isPending } = useUpdateProfileMutation();
  if (currencyContext.state !== "SUCCESS" || !profile) return null;

  const rawEstimatedWorth = profile.estimatedNetWorth ?? 0;
  const rawPreferredCurrency = profile.preferredCurrency ?? "USD";
  const convertedEstimatedWorth = Math.ceil(
    currencyContext.convertValueToSelectedCurrency(
      rawEstimatedWorth,
      rawPreferredCurrency,
      currencyContext.currency,
    ),
  );
  const convertedNetWorth = Math.ceil(
    currencyContext.convertValueToSelectedCurrency(
      netWorth,
      rawPreferredCurrency,
      currencyContext.currency,
    ),
  );

  const percentage =
    convertedEstimatedWorth === 0
      ? 0
      : Math.min((100 * convertedNetWorth) / convertedEstimatedWorth, 100);

  if (percentage < 80) {
    return (
      <div className="flex flex-col items-center gap-4 rounded-sm border border-gray-200 bg-white p-5">
        {netWorth > 0 ? (
          <div className="flex flex-col items-center gap-2">
            <img src={unlock} alt="Unlock" className="h-8 w-8" />
            <h3 className="font-semibold text-gray-700">Unlock AI Insights</h3>
            <p className="text-gray-700">
              Get personalized insights by adding 80% of your net worth in
              assets to Peek.
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <img src={peekCoach} alt="Unlock" className="h-16 w-16" />
            <h3 className="font-semibold text-gray-700">
              Start importing your assets into Peek
            </h3>
            <p className="text-gray-700">
              Once you have imported 80% of your net worth into Peek, you'll
              unlock personalized insights.
            </p>
          </div>
        )}
        <div className="flex items-center gap-2 self-stretch rounded-lg border border-offwhite bg-beige p-4 text-sm font-semibold">
          <img src={iconScout} alt="Scout" className="h-6 w-6" />I have an
          estimated net worth of{" "}
          {isPending ? (
            <Loader />
          ) : (
            <Input
              // use the key so that the component value field updates correctly when the currency change
              key={`${currencyContext.currency}-${rawEstimatedWorth}`}
              name="inflow"
              value={convertedEstimatedWorth}
              onUpdate={(_key, value) => {
                const convertedNewValue =
                  currencyContext.convertValueToSelectedCurrency(
                    value,
                    currencyContext.currency,
                    rawPreferredCurrency,
                  );

                updateProfile({
                  ...profile,
                  income: Number(profile.income),
                  approximateSavingsRate: Number(
                    profile.approximateSavingsRate,
                  ),
                  estimatedNetWorth: convertedNewValue,
                });
              }}
              renderAs={`${currencyContext.currency} ${convertedEstimatedWorth.toLocaleString()}`}
            />
          )}
        </div>

        <ProgressLayout
          currency={currencyContext.currency}
          netWorth={convertedNetWorth}
          percentage={percentage}
        />
        <Link to="/portfolio">
          <Button variant="default" className="flex items-center gap-1">
            <PlusIcon className="h-4 w-4" />
            <span>
              {percentage < 10 ? "Start adding your assets" : "Add more assets"}
            </span>
          </Button>
        </Link>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center gap-4 rounded-sm border border-gray-200 bg-white p-5">
      <div className="flex flex-col items-center gap-2">
        <img src={unlockedInsights} alt="You made it" className="h-20 w-20" />
        <div>
          Your insights panel is now unlocked. Enjoy personalized financial
          insights and recommendations tailored just for you.
        </div>
      </div>
      <ProgressLayout
        currency={currencyContext.currency}
        netWorth={convertedNetWorth}
        percentage={percentage}
      />

      <Button
        variant="default"
        onClick={() => {
          onUnlockInsights();
        }}
      >
        Show me Insights
      </Button>
    </div>
  );
};
