import { handleTrial } from "@/components/Billing/utils";
import { useSubscriptions } from "@/hooks/useSubscriptions";
import { SignUp, useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { SignUpPageLayout } from "./SignUpPageLayout";
import { TestimonialCarousel } from "@/components/ui/testimonial-carousel";

export function SignUpTrialPage() {
  const { isSignedIn, getToken } = useAuth();
  const subscriptionContext = useSubscriptions();
  const navigate = useNavigate();

  if (!isSignedIn) {
    return (
      <SignUpPageLayout>
        <div className="relative flex items-center justify-center rounded-lg rounded-r-none bg-white">
          <SignUp
            redirectUrl={"/sign-up-trial"}
            signInUrl="/sign-in"
            appearance={{
              elements: {
                card: {
                  boxShadow: "none",
                },
                footerActionLink: {
                  color: "#4966AF",
                },
              },
            }}
          />
        </div>
        <TestimonialCarousel />
      </SignUpPageLayout>
    );
  }

  if (subscriptionContext.state === "SUCCESS") {
    if (subscriptionContext.activeSubscriptionEndTimestampInMs) {
      navigate("/");
    } else {
      handleTrial(getToken);
    }
  }
}
