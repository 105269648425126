import InfoCard from "@/components/ui/info-card";
import { shortNumber } from "@/lib/utils";

type InfoCardsProps = {
  currency: string;
  totalPL: number;
  totalInvested: number;
  currentBalance: number;
  simpleReturns: number;
  annualizedReturns: number;
  holdingPeriodInDays: number;
  deposits: number;
  withdrawals: number;
};

export function InfoCards({
  currency,
  totalPL,
  totalInvested,
  currentBalance,
  simpleReturns,
  annualizedReturns,
  holdingPeriodInDays,
  deposits,
  withdrawals,
}: InfoCardsProps) {
  return (
    <div className="grid grid-cols-1 items-stretch gap-x-2 gap-y-2 md:grid-cols-1 lg:grid-cols-3">
      <InfoCard
        options={[
          {
            label: "Profit/Loss",
            value: totalPL,
            tooltip:
              "Total profit and loss is the sum of all the gains and losses from your investments over time.",
            formula:
              "\\(\\text{Total Value} - \\text{Total Capital Invested}\\)",
            calculation: `\\(${currentBalance.toFixed(
              2,
            )} - ${totalInvested.toFixed(2)} = ${totalPL.toFixed(2)}\\)`,
          },
        ]}
        className="bg-beige"
        currency={currency}
      />

      <InfoCard
        options={[
          {
            label: "Total Capital Invested",
            value: totalInvested,
            tooltip:
              "Total capital invested is the sum of all the money you've put into your investments, including any additional contributions you've made.",
            formula: "\\(\\text{Total Deposits} - \\text{Total Withdrawals}\\)",
            calculation: `\\(${deposits.toFixed(2)} - ${withdrawals.toFixed(2)} = ${totalInvested.toFixed(
              2,
            )}\\)`,
          },
          {
            label: "Total Value",
            value: currentBalance,
            tooltip:
              "Total value is the sum of your total capital invested and total profit and loss, representing your overall net worth.",
            formula:
              "\\({\\text{Total Capital Invested}} + \\text{Total Profit/Loss}\\)",
            calculation: `\\(${totalInvested.toFixed(
              2,
            )} + ${totalPL.toFixed(2)} = ${currentBalance.toFixed(2)}\\)`,
          },
        ]}
        currency={currency}
      />

      <InfoCard
        options={[
          {
            label: "Simple Returns",
            value: `${simpleReturns ? simpleReturns.toFixed(2) : 0}%`,
            tooltip:
              "Simple returns show how much money you've made or lost based on your buying and selling activities over a year.",
            formula:
              "\\(\\frac{\\text{Current Portfolio Balance} - \\text{Total Invested Amount}}{\\text{Total Invested Amount}} \\times 100\\)",
            calculation: `\\(\\frac{${currentBalance.toFixed(
              2,
            )} - ${totalInvested.toFixed(2)}}{${totalInvested.toFixed(
              2,
            )}} \\times 100 = ${simpleReturns.toFixed(2)}\\%\\)`,
          },
          {
            label: "Annualized Returns",
            value: `${annualizedReturns ? annualizedReturns.toFixed(2) : 0}%`,
            tooltip:
              "Annualized returns show the average yearly profit or loss from your investments, adjusted for the length of time you held them.",
            formula:
              "\\(\\left(\\left(1 + \\frac{\\text{totalReturn}}{\\text{totalInvested}}\\right)^{\\frac{1}{\\text{holdingPeriodInYears}}} - 1\\right) \\times 100\\)",
            calculation: `\\(\\left(\\left(1 + \\frac{\\text{${totalPL.toFixed(
              2,
            )}}}{\\text{${totalInvested.toFixed(
              2,
            )}}}\\right)^{\\frac{1}{\\text{${(
              holdingPeriodInDays / 365
            ).toFixed(
              2,
            )}}}} - 1\\right) \\times 100 = ${annualizedReturns.toFixed(
              2,
            )}\\%\\)`,
          },
        ]}
        context={`${currency} ${shortNumber(totalPL)}`}
        highlightNumbers={true}
      />
    </div>
  );
}
