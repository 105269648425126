import React from "react";

type SignUpPageLayoutProps = {
  children: React.ReactNode;
};

export const SignUpPageLayout: React.FC<SignUpPageLayoutProps> = ({
  children,
}) => {
  return (
    <div className="flex items-center justify-center bg-gray-50 p-10 lg:h-screen">
      <div className="flex max-w-full flex-col items-center justify-center rounded-lg border border-gray-100 bg-beige shadow-lg lg:flex-row">
        {children}
      </div>
    </div>
  );
};
