import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

export const BlogPostsPagination = ({
  pagination,
  numSiblingPages = 2,
  className,
  onSelectPage,
}: {
  className?: string;
  numSiblingPages?: number;
  pagination: {
    page: number;
    limit: number | "all";
    totalPages: number;
    nextPage: number | null;
    prevPage: number | null;
  };
  onSelectPage: (page: number) => void;
}) => {
  const { nextPage, prevPage } = pagination;
  return (
    <Pagination className={className}>
      <PaginationContent>
        {prevPage && (
          <PaginationItem className="cursor-pointer">
            <PaginationPrevious onClick={() => onSelectPage(prevPage)} />
          </PaginationItem>
        )}
        {pagination.page > 3 && (
          <>
            <PaginationItem className="cursor-pointer">
              <PaginationLink onClick={() => onSelectPage(1)}>1</PaginationLink>
            </PaginationItem>
            <PaginationEllipsis />
          </>
        )}
        {Array.from({ length: pagination.totalPages }, (_, index) => index + 1)
          .filter(
            (pageNumber) =>
              Math.abs(pagination.page - pageNumber) <= numSiblingPages,
          )
          .map((pageNumber) => (
            <PaginationItem key={pageNumber} className="cursor-pointer">
              <PaginationLink
                onClick={() => onSelectPage(pageNumber)}
                isActive={pageNumber === pagination.page}
              >
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          ))}
        {pagination.page < pagination.totalPages - 2 && (
          <>
            <PaginationEllipsis />
            <PaginationItem className="cursor-pointer">
              <PaginationLink
                onClick={() => onSelectPage(pagination.totalPages)}
              >
                {pagination.totalPages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}
        {nextPage && (
          <PaginationItem className="cursor-pointer">
            <PaginationNext onClick={() => onSelectPage(nextPage)} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};
