import { createColumnHelper } from "@tanstack/react-table";
import { AssetLiabilityV2 } from "@/lib/types";
import { Button } from "@/components/ui/button";
import { ArrowDown, ArrowUp, PanelRightOpen } from "lucide-react";
import { cn, shortNumber } from "@/lib/utils";
import { formatQuantity } from "@/lib/utils";

const positiveColor = "text-darkCyan";
const negativeColor = "text-red-500";

type ColumnProps = {
  currency: string;
  handleAssetLiabilityDetailsSheetOpen: (data: AssetLiabilityV2) => void;
  grouping: "day" | "week" | "month";
};

const columnHelper = createColumnHelper<AssetLiabilityV2>();

export const columns = ({
  currency,
  handleAssetLiabilityDetailsSheetOpen,
  grouping,
}: ColumnProps) => [
  columnHelper.accessor("name", {
    id: "asset",
    header: "Asset",
    sortingFn: (rowA, rowB) => {
      const dataA = rowA.original;
      const dataB = rowB.original;

      if (dataA.type === "asset" && dataB.type === "asset") {
        const nameA = dataA.fullName
          ? `${dataA.fullName} (${dataA.name})`
          : dataA.name;

        const nameB = dataB.fullName
          ? `${dataB.fullName} (${dataB.name})`
          : dataB.name;

        return nameA > nameB ? 1 : -1;
      }

      return rowA.original.name > rowB.original.name ? 1 : -1;
    },
    cell: ({ row }) => {
      const rowData = row.original;

      return (
        <div className="flex flex-row items-center gap-x-1">
          <img
            src={
              rowData.company?.logo ||
              `https://ui-avatars.com/api/?background=F4EEE3&color=4B5563&name=${
                rowData.name
              }`
            }
            className="mr-2 h-8 w-8 rounded-full bg-beige p-1"
          />
          <span className="block w-full truncate text-left">
            {rowData.fullName ? (
              <>
                {rowData.fullName}
                <span className="ml-1 font-normal">({rowData.name})</span>
              </>
            ) : (
              <>{rowData.name}</>
            )}
          </span>
          <Button
            className="invisible h-6 rounded-sm bg-gray-100 px-2 py-0 text-gray-500 shadow hover:text-white group-aria-selected:visible"
            size="sm"
            onClick={() => handleAssetLiabilityDetailsSheetOpen(row.original)}
          >
            <PanelRightOpen size={12} className="mr-1" />
            Open
          </Button>
        </div>
      );
    },
  }),
  columnHelper.accessor((row) => row.account.name, {
    id: "accountName",
    header: () => <span className="w-full text-right">Account</span>,
    cell: ({ row }) => (
      <div className="truncate text-right">
        <span>{row.original.account.name}</span>
      </div>
    ),
  }),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.quantity ?? 0,
    {
      id: "quantity",
      header: () => <span className="w-full text-right">Quantity</span>,
      cell: ({ row }) => (
        <div className="text-right">
          <span>
            {formatQuantity(
              +row.original.snapshots[grouping].slice(-1)[0]?.quantity,
            )}
          </span>
          {Math.abs(
            row.original.snapshots[grouping].slice(-1)[0]
              ?.quantityChangePercentage,
          ) > 0.1 && (
            <div
              className={cn(
                "flex flex-row justify-end items-center text-sm text-gray-500",
                +row.original.snapshots[grouping].slice(-1)[0]
                  ?.quantityChangePercentage === 0
                  ? "text-gray-700"
                  : +row.original.snapshots[grouping].slice(-1)[0]
                        ?.quantityChangePercentage > 0
                    ? positiveColor
                    : negativeColor,
              )}
            >
              {row.original.snapshots[grouping].slice(-1)[0]
                ?.quantityChangePercentage > 0 ? (
                <ArrowUp size={12} className={positiveColor} />
              ) : (
                <ArrowDown size={12} className={negativeColor} />
              )}
              {row.original.snapshots[grouping]
                .slice(-1)[0]
                ?.quantityChangePercentage.toFixed(2)}
              %
            </div>
          )}
        </div>
      ),
    },
  ),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.averageCost ?? 0,
    {
      id: "averageCost",
      header: () => <span className="w-full text-right">Average Cost</span>,
      cell: ({ row }) => (
        <span>
          {currency}{" "}
          {shortNumber(
            +row.original.snapshots[grouping].slice(-1)[0]?.averageCost,
          )}
        </span>
      ),
    },
  ),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.totalCapitalInvested ?? 0,
    {
      id: "totalCapitalInvested",
      header: () => (
        <span className="w-full text-right">Total Capital Invested</span>
      ),
      cell: ({ row }) => (
        <span>
          {currency}{" "}
          {shortNumber(
            +row.original.snapshots[grouping].slice(-1)[0]
              ?.totalCapitalInvested,
          )}
        </span>
      ),
    },
  ),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.pnl ?? 0,
    {
      id: "profitLoss",
      header: () => <span className="w-full text-right">Profit/Loss</span>,
      cell: ({ row }) => (
        <div className="text-right">
          <span>
            {currency}{" "}
            {shortNumber(
              row.original.snapshots[grouping].slice(-1)[0]?.pnl || 0,
            )}
          </span>
          {Math.abs(
            row.original.snapshots[grouping].slice(-1)[0]?.pnlChangePercentage,
          ) > 0.1 && (
            <div
              className={cn(
                "flex flex-row justify-end items-center text-sm text-gray-500",
                +row.original.snapshots[grouping].slice(-1)[0]
                  ?.pnlChangePercentage === 0
                  ? "text-gray-700"
                  : +row.original.snapshots[grouping].slice(-1)[0]
                        ?.pnlChangePercentage > 0
                    ? positiveColor
                    : negativeColor,
              )}
            >
              {row.original.snapshots[grouping].slice(-1)[0]
                ?.pnlChangePercentage > 0 ? (
                <ArrowUp size={12} className={positiveColor} />
              ) : (
                <ArrowDown size={12} className={negativeColor} />
              )}
              {row.original.snapshots[grouping]
                .slice(-1)[0]
                ?.pnlChangePercentage.toFixed(2)}
              %
            </div>
          )}
        </div>
      ),
    },
  ),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.pnlPercentage ?? 0,
    {
      id: "profitLossPercentage",
      header: () => <span className="w-full text-right">Profit/Loss %</span>,
      sortingFn: (rowA, rowB) => {
        return (
          rowA.original.snapshots[grouping].slice(-1)[0]?.pnlPercentage -
          rowB.original.snapshots[grouping].slice(-1)[0]?.pnlPercentage
        );
      },
      cell: ({ row }) => (
        <span
          className={cn(
            +row.original.snapshots[grouping].slice(-1)[0]?.pnlPercentage === 0
              ? "text-gray-700"
              : +row.original.snapshots[grouping].slice(-1)[0]?.pnlPercentage >
                  0
                ? positiveColor
                : negativeColor,
          )}
        >
          {
            +row.original.snapshots[grouping]
              .slice(-1)[0]
              ?.pnlPercentage.toFixed(2)
          }
          %
        </span>
      ),
    },
  ),
  columnHelper.accessor(
    (row) => row.snapshots[grouping].slice(-1)[0]?.totalValue ?? 0,
    {
      id: "totalValue",
      header: () => <span className="w-full text-right">Total Value</span>,
      cell: ({ row }) => (
        <span>
          {currency}{" "}
          {shortNumber(
            +row.original.snapshots[grouping].slice(-1)[0]?.totalValue,
          )}
        </span>
      ),
    },
  ),
];
