import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Profile } from "@/lib/types";
import { NumberInputIcon } from "@/components/ui/number-input-icon";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";

type StepFourProps = {
  data: Profile;
  onNext: (data: Profile) => void;
};

export function StepFour({ data, onNext }: StepFourProps) {
  const [estimatedNetWorth, setEstimatedNetWorth] = useState(
    Number(data.estimatedNetWorth) || 0,
  );

  return (
    <>
      <div className="mt-6 flex w-full flex-row items-center gap-x-4">
        <label className="w-1/4 font-medium text-gray-600">
          💰 Estimated Net Worth
        </label>
        <NumberInputIcon
          locale={getLocaleForCurrency(data.preferredCurrency as Currency)}
          placeholder="0"
          value={estimatedNetWorth.toString()}
          onChange={(e) => setEstimatedNetWorth(+e.target.value)}
          autoFocus
          className="text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          wrapperClassName="flex-1"
          startAdornment={
            <span className="text-gray-600">{data.preferredCurrency}</span>
          }
        />
      </div>

      <Button
        variant="default"
        onClick={() => onNext({ estimatedNetWorth })}
        disabled={!estimatedNetWorth}
        className="mt-4 self-end"
      >
        Next
      </Button>
    </>
  );
}
