import { Sidebar } from "@/components/Sidebar";
import { Navbar } from "@/components/Sidebar/NavBar";
import { AnalysisProvider } from "@/hooks/useAnalysis";
import { CurrencyProvider } from "@/hooks/useCurrency";
import { DialogProvider } from "@/hooks/useDialog";
import { PeekUserProvider } from "@/hooks/usePeekUser";
import { PlaidProvider } from "@/hooks/usePlaid";
import { SubscriptionsProvider } from "@/hooks/useSubscriptions";
import { ShortcutPromptProvider } from "@/hooks/useShortcutPrompt";
import config from "@/lib/config";
import { useAuth, useUser } from "@clerk/clerk-react";
import { KnockProvider } from "@knocklabs/react";
import "@knocklabs/react/dist/index.css";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AssetLiabilitiesAccountsProvider } from "@/hooks/useAssetLiabilitiesAccounts";
import Onboarding from "@/components/Onboarding";
import { SnaptradeProvider } from "@/hooks/useSnaptrade";
import { Paywall } from "./Paywall";
import { SyncTasksProvider } from "@/hooks/useSyncTasks";
// Only Desktop layout support at the moment thus the min-width
export function DashboardLayout() {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;

      // Make sure that the global shortcuts are not interfering with user inputs
      const isInputFocused =
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable;

      if (isInputFocused) return;

      if (e.key === "?") {
        e.preventDefault();
        navigate("/settings/shortcuts");
      } else if (e.key === "." && (e.metaKey || e.ctrlKey)) {
        navigate("/settings/");
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [navigate]);

  if (!isSignedIn) {
    navigate("/sign-in");
    return null;
  }

  return (
    <PeekUserProvider>
      <SubscriptionsProvider>
        <DialogProvider>
          <ShortcutPromptProvider>
            <CurrencyProvider>
              <AssetLiabilitiesAccountsProvider>
                <PlaidProvider>
                  <SnaptradeProvider>
                    <AnalysisProvider>
                      {user && (
                        <KnockProvider
                          apiKey={config.publicKnockApiKey}
                          userId={user.id}
                        >
                          <SyncTasksProvider>
                            <div className="flex h-full overflow-auto bg-gray-50 antialiased">
                              <Onboarding />
                              <Navbar />
                              <Sidebar />
                              <main className="mx-auto min-h-screen w-full overflow-x-hidden px-10 py-6 md:max-w-7xl lg:max-w-[1600px]">
                                <Paywall />

                                <Outlet />
                              </main>
                            </div>
                          </SyncTasksProvider>
                        </KnockProvider>
                      )}
                    </AnalysisProvider>
                  </SnaptradeProvider>
                </PlaidProvider>
              </AssetLiabilitiesAccountsProvider>
            </CurrencyProvider>
          </ShortcutPromptProvider>
        </DialogProvider>
      </SubscriptionsProvider>
    </PeekUserProvider>
  );
}
