import axios from "@/lib/axios";
import config from "@/lib/config";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { AssetLiabilityV2 } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function useRenameAccountMutation() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  const queryClient = useQueryClient();

  const { mutate: renameAccount, isPending } = useMutation({
    mutationFn: (data: { id: number; accountName: string }) =>
      callRenameAccount(data.id, data.accountName, getToken),
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });

      const previousData = queryClient.getQueryData([
        GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
      ]);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        (old: AssetLiabilityV2[]) =>
          old.map((assetLiability) =>
            +assetLiability.account.id === +updatedData.id
              ? {
                  ...assetLiability,
                  account: {
                    ...assetLiability.account,
                    name: updatedData.accountName,
                  },
                }
              : assetLiability,
          ),
      );

      return { previousData };
    },
    onSuccess: async ({ id }) => {
      await queryClient.refetchQueries({
        queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
      });
      posthog.capture("submit_rename_account_form_success", {
        accountId: id,
      });
    },
    onError: (error, _, context) => {
      toast.error("Failed to update account name");

      console.error(error);

      queryClient.setQueryData(
        [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        context?.previousData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      });
    },
  });

  return { renameAccount, isPending };
}

async function callRenameAccount(
  id: number,
  accountName: string,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  await axios.patch(
    `${config.backendUrl}/user/account/${id}`,
    {
      accountName,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { id };
}
