import {
  BarChartBig,
  Beer,
  Home,
  PieChart,
  Settings,
  SquareKanban,
  Trophy,
} from "lucide-react";

export const routes = [
  {
    href: "/",
    title: "Peek",
    icon: Home,
  },
  {
    href: "/portfolio",
    title: "Portfolio",
    icon: PieChart,
  },
  {
    href: "/performance",
    title: "Performance",
    icon: BarChartBig,
  },
  {
    href: "/plan",
    title: "Plan",
    icon: SquareKanban,
    disabled: true,
  },
  {
    href: "/progress",
    title: "Progress",
    icon: Trophy,
    disabled: true,
  },
  {
    href: "/pub",
    title: "Pub",
    icon: Beer,
  },
  {
    href: "/preferences",
    title: "Preferences",
    icon: Settings,
  },
];
