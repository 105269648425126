import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCurrency } from "@/hooks/useCurrency";
import { SnapshotV2 } from "@/lib/types";
import { shortNumber } from "@/lib/utils";
import { Activity, ArrowDown, ArrowUp } from "lucide-react";
import { useState } from "react";

type RecentActivityTableProps = {
  recentActivity: SnapshotV2[];
  isTradable: boolean;
  totalInvested: number;
  averageCost: number;
  originalCurrency: string;
  currencyContext: Extract<
    ReturnType<typeof useCurrency>,
    { state: "SUCCESS" }
  >;
  positiveColor: string;
  negativeColor: string;
  positiveTerm: string;
  negativeTerm: string;
};

export function RecentActivityTable({
  recentActivity,
  isTradable,
  totalInvested,
  averageCost,
  originalCurrency,
  currencyContext,
  positiveColor,
  negativeColor,
  positiveTerm,
  negativeTerm,
}: RecentActivityTableProps) {
  const { currency } = currencyContext;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const paginatedRecentActivity = recentActivity.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(recentActivity.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="rounded-lg border border-gray-200 p-4">
      <div className="mb-4 flex flex-row items-center gap-x-2">
        <Activity className="h-4 w-4 text-gray-500 " />
        <h3 className="text-gray-700">Recent Activity</h3>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead className="text-left">Activity</TableHead>
            {isTradable && (
              <>
                <TableHead className="text-right">Capital Flow</TableHead>
                <TableHead className="text-right">
                  Total Capital Invested
                </TableHead>
              </>
            )}
            <TableHead className="text-right">Total Value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedRecentActivity.map((snapshot, index) => (
            <TableRow key={index} className="h-[48px] border-b border-gray-200">
              <TableCell className="text-gray-500">
                {new Date(snapshot.timestamp).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </TableCell>

              <TableCell className="space-x-2 text-center">
                <div className="flex flex-row items-center justify-start gap-x-1">
                  {snapshot.quantityChange !== 0 && (
                    <>
                      {snapshot.quantityChange > 0 ? (
                        <ArrowUp size={12} className={positiveColor} />
                      ) : (
                        <ArrowDown size={12} className={negativeColor} />
                      )}
                      <span>
                        {snapshot.quantityChange > 0
                          ? positiveTerm
                          : negativeTerm}
                      </span>
                      {isTradable && (
                        <>
                          <span
                            className={
                              snapshot.quantityChange > 0
                                ? positiveColor
                                : negativeColor
                            }
                          >
                            {shortNumber(snapshot.quantityChange)}
                          </span>
                          <span
                            className={
                              snapshot.quantityChange > 0
                                ? positiveColor
                                : negativeColor
                            }
                          >
                            x
                          </span>
                        </>
                      )}
                      <span
                        className={
                          snapshot.quantityChange > 0
                            ? positiveColor
                            : negativeColor
                        }
                      >
                        {originalCurrency}{" "}
                        {shortNumber(snapshot.original?.unitValue || 0)}
                      </span>
                    </>
                  )}
                </div>
              </TableCell>
              {isTradable && (
                <>
                  <TableCell className="space-x-2 text-right">
                    <div className="flex flex-row items-center justify-end gap-x-1">
                      <span>
                        {originalCurrency}{" "}
                        {shortNumber(snapshot.original?.capitalFlow || 0)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="space-x-2 text-right">
                    <div className="flex flex-row items-center justify-center gap-x-1">
                      <span>
                        {originalCurrency}{" "}
                        {snapshot.original
                          ? shortNumber(snapshot.original.totalCapitalInvested)
                          : "0"}
                      </span>
                    </div>
                  </TableCell>
                </>
              )}
              <TableCell className="text-right font-medium">
                <TooltipProvider delayDuration={300}>
                  <Tooltip>
                    <TooltipTrigger>
                      {originalCurrency}{" "}
                      {shortNumber(snapshot.original?.totalValue || 0)}
                    </TooltipTrigger>
                    <TooltipContent className="bg-black">
                      <p className="text-white">
                        {currency} {shortNumber(snapshot.totalValue)}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {isTradable && (
          <TableFooter>
            <TableRow>
              <TableCell className="text-right" colSpan={4}>
                Total Capital Invested
              </TableCell>
              <TableCell className="text-right">
                <TooltipProvider delayDuration={300}>
                  <Tooltip>
                    <TooltipTrigger>
                      {originalCurrency}{" "}
                      {shortNumber(
                        currencyContext.convertValueToSelectedCurrency(
                          totalInvested,
                          currency,
                          originalCurrency,
                        ),
                      )}
                    </TooltipTrigger>
                    <TooltipContent className="bg-black">
                      <p className="text-white">
                        {currency} {shortNumber(totalInvested)}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-right" colSpan={4}>
                Average Cost
              </TableCell>
              <TableCell className="text-right">
                <TooltipProvider delayDuration={300}>
                  <Tooltip>
                    <TooltipTrigger>
                      {originalCurrency}{" "}
                      {shortNumber(
                        currencyContext.convertValueToSelectedCurrency(
                          averageCost,
                          currency,
                          originalCurrency,
                        ),
                      )}
                    </TooltipTrigger>
                    <TooltipContent className="bg-black">
                      <p className="text-white">
                        {currency} {shortNumber(averageCost)}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      <div className="mt-4 flex items-center justify-end">
        <Button
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          variant="outline"
        >
          Previous
        </Button>
        <span className="mx-2 text-sm text-gray-500">
          {currentPage} / {totalPages}
        </span>
        <Button
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          variant="outline"
        >
          Next
        </Button>
      </div>
    </div>
  );
}
