import axios from "@/lib/axios";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";

export function useUpdateCalculationSettings() {
  const { getToken } = useAuth();
  const posthog = usePostHog();

  const { mutateAsync: updateNetWorthCalculation, isPending: netWorthPending } =
    useMutation({
      mutationFn: (data: { includeIds: number[]; excludeIds: number[] }) =>
        callUpdateNetWorthCalculationSettings(
          data.includeIds,
          data.excludeIds,
          getToken,
        ),
      onSuccess: () => {
        posthog.capture("update_net_worth_calculation_setting_success");
      },
    });

  const { mutate: updateLiquiditySettings, isPending: liquidityPending } =
    useMutation({
      mutationFn: (data: { liquidIds: number[]; illiquidIds: number[] }) =>
        callUpdateLiquiditySettings(data.liquidIds, data.illiquidIds, getToken),
      onSuccess: () => {
        posthog.capture("update_liquidity_setting_success");
      },
    });

  return {
    updateNetWorthCalculation,
    netWorthPending,
    updateLiquiditySettings,
    liquidityPending,
  };
}

async function callUpdateNetWorthCalculationSettings(
  includeIds: number[],
  excludeIds: number[],
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  const { data } = await axios.patch(
    `${config.backendUrl}/setting/nw-calculation`,
    { includeIds, excludeIds },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data };
}

async function callUpdateLiquiditySettings(
  liquidIds: number[],
  illiquidIds: number[],
  getToken: () => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");

  const { data } = await axios.patch(
    `${config.backendUrl}/setting/liquid`,
    { liquidIds, illiquidIds },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return { data };
}
