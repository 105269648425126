import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "../lib/axios";
import config from "../lib/config";
import { useAuth } from "@clerk/clerk-react";
import { PeekUser } from "@/lib/types";

type PeekUserLoading = {
  state: "LOADING";
};

type PeekUserError = {
  state: "ERROR";
  message?: string;
};

export type PeekUserSuccess = {
  state: "SUCCESS";
  user: PeekUser;
  updateUserCustomFields: (customFields: PeekUser["customFields"]) => void;
  updatePending: boolean;
};

type PeekUserType = PeekUserLoading | PeekUserError | PeekUserSuccess;

const PeekUserContext = createContext<PeekUserType>({
  state: "LOADING",
});

export function PeekUserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<PeekUser | null>(null);

  const { getToken } = useAuth();

  const {
    data: fetchedUser,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["peekUser"],
    queryFn: async () => {
      const token = await getToken();
      const { data } = await axios.get<PeekUser>(`${config.backendUrl}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    enabled: true,
  });

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [fetchedUser]);

  const { mutate: updateUserCustomFields, isPending: updatePending } =
    useMutation({
      mutationFn: async (customFields: PeekUser["customFields"]) => {
        const token = await getToken();
        const { data } = await axios.patch(
          `${config.backendUrl}/user`,
          { customFields },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        return { data, customFields };
      },
      onError: (error) => {
        console.error("Error updating user custom fields", error);
      },
      onSuccess: (data) => {
        const modifiedData = user
          ? {
              ...user,
              customFields: { ...user.customFields, ...data.customFields },
            }
          : null;

        setUser(modifiedData);
      },
    });

  if (isLoading) {
    return (
      <PeekUserContext.Provider value={{ state: "LOADING" }}>
        {children}
      </PeekUserContext.Provider>
    );
  }

  if (isError) {
    return (
      <PeekUserContext.Provider value={{ state: "ERROR" }}>
        {children}
      </PeekUserContext.Provider>
    );
  }

  return (
    <PeekUserContext.Provider
      value={{
        state: "SUCCESS",
        user: user ?? {
          id: "",
          email: "",
          name: "",
          stripeCustomerId: "",
          customFields: {},
        },
        updateUserCustomFields,
        updatePending,
      }}
    >
      {children}
    </PeekUserContext.Provider>
  );
}

export function usePeekUser() {
  return useContext(PeekUserContext);
}
