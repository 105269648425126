import { z } from "zod";

export const accountType = z.union([
  z.literal("ALTERNATIVE_INVESTMENT"),
  z.literal("BANK_ACCOUNT"),
  z.literal("COLLECTIBLE"),
  z.literal("CRYPTO"),
  z.literal("INSURANCE"),
  z.literal("OTHER"),
  z.literal("PRECIOUS_METAL"),
  z.literal("PRIVATE_COMPANY"),
  z.literal("PUBLIC_MARKET"),
  z.literal("REAL_ESTATE"),
  z.literal("RETIREMENT_FUND"),
  z.literal("ROBO_ACCOUNT"),
  z.literal("CREDIT_CARD"),
  z.literal("LOAN"),
]);

export const assetType = z.union([
  z.literal("ANGEL_INVESTMENT"),
  z.literal("HEDGE_FUND"),
  z.literal("PRIVATE_CREDIT"),
  z.literal("PRIVATE_EQUITY"),
  z.literal("FIXED_DEPOSIT"),
  z.literal("CASH"),
  z.literal("ART"),
  z.literal("WINE"),
  z.literal("WATCH"),
  z.literal("TOKEN"),
  z.literal("NFT"),
  z.literal("LIFE_INSURANCE"),
  z.literal("GOLD"),
  z.literal("SILVER"),
  z.literal("PALLADIUM"),
  z.literal("PLATINUM"),
  z.literal("ESOP"),
  z.literal("RSU"),
  z.literal("SHARE"),
  z.literal("ETF"),
  z.literal("STOCK"),
  z.literal("MUTUAL_FUND"),
  z.literal("BOND"),
  z.literal("REIT"),
  z.literal("REAL_ESTATE"),
  z.literal("SG_CPF_OA"),
  z.literal("SG_CPF_RA"),
  z.literal("SG_CPF_MA"),
  z.literal("SG_CPF_SA"),
  z.literal("SG_SRS"),
  z.literal("US_401K"),
  z.literal("US_IRA"),
  z.literal("US_529"),
  z.literal("CREDIT_CARD"),
  z.literal("LOAN"),
  z.literal("OTHER"),
  z.literal("ROBO"),
]);

export const assetSchema = z.object({
  name: z.string().optional(),
  fullName: z.string().optional(), // Used for stocks/crypto with tickers
  type: assetType,
  quantity: z.coerce.number({
    invalid_type_error: "Quantity must be a number",
  }),
  unitValue: z.coerce.number({
    invalid_type_error: "Value must be a number",
  }),
  currency: z.string().min(3, "Currency must have at least 3 characters"),
  tag: z.string().min(1, "Tag must have at least 1 character").optional(),
});

export const addAssetFormSchema = z.object({
  name: z.string().min(3),
  type: accountType,
  assetLiabilities: z
    .array(
      assetSchema.transform((data) => {
        return {
          ...data,
          // If type is LOAN or CREDIT_CARD, unitValue should be negative
          quantity: ["LOAN", "CREDIT_CARD"].includes(data.type)
            ? -Math.abs(data.quantity)
            : Math.abs(data.quantity),
        };
      }),
    )
    .min(1, "At least 1 asset must be added"),
});

export const editAssetFormSchema = assetSchema
  .extend({
    id: z.number(),
  })
  .transform((data) => {
    return {
      ...data,
      // If type is LOAN or CREDIT_CARD, unitValue should be negative
      quantity: ["LOAN", "CREDIT_CARD"].includes(data.type)
        ? -Math.abs(data.quantity)
        : Math.abs(data.quantity),
    };
  });
