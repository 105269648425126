import Loader from "@/components/common/Loader";
import { BlogPostContent } from "@/components/Pub/BlogPostContent";
import { RelatedBlogPost } from "@/components/Pub/RelatedBlogPost";
import { useGetPost } from "@/hooks/useWisp";
import { Link, useParams } from "react-router-dom";

export const PubPostPage = () => {
  const { slug: slugFromParam } = useParams<"slug">();
  const { data: postData } = useGetPost(slugFromParam);

  if (!postData) {
    return (
      <div className="my-20">
        <Loader className="m-auto" />
      </div>
    );
  }

  if (!postData.post) {
    return (
      <div className="mx-auto mt-8 max-w-4xl">
        <small>
          <Link to="/pub">&lt; Back to Pub</Link>
        </small>
        <h1 className="text-3xl">Post Not Found</h1>
      </div>
    );
  }

  const { title, content, slug } = postData.post;
  return (
    <div className="mx-auto my-8 max-w-4xl">
      <small>
        <Link to="/pub">&lt; Back to Pub</Link>
      </small>
      <h1 className="my-6 text-3xl">{title}</h1>
      <BlogPostContent content={content} />
      <RelatedBlogPost slug={slug} />
    </div>
  );
};
