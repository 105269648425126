import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import Loader from "@/components/common/Loader";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { useAuth } from "@clerk/clerk-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import { usePostHog } from "posthog-js/react";

import dbs from "/images/automaticConnections/dbs-bank.svg";
import dbsVickers from "/images/automaticConnections/dbs-vickers.svg";
import EmeregAPIClient from "./EmeregAPIClient";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { PasswordInput } from "@/components/ui/input-password";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

const schema = z.object({
  email: z.string().min(1, "Username is required"),
  password: z.string().min(1, "PIN is required"),
});

type FormData = z.infer<typeof schema>;

export default function EmeregLoginDialog({
  open,
  setOpen,
  customLink,
  accountId,
}: {
  open: boolean;
  setOpen: (open: boolean, customLink: string) => void;
  customLink: string;
  accountId?: number;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const [showOtp, setShowOtp] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState<string>("");
  const [authVerified, setAuthVerified] = useState(false);

  const client = useMemo(() => new EmeregAPIClient(), []);
  const { getToken } = useAuth();

  const posthog = usePostHog();

  useEffect(() => {
    // Clear error message whenever there is a loading
    if (loading) {
      setMessage("");
    }
  }, [loading]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setAuthVerified(false);

    try {
      await client.setClerkHeaders(getToken); // set the headers with clerk's auth token
      await client.createSession(data.email, data.password);
      setShowOtp(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setShowLogin(true);
      setMessage(error.message);
    }
  };

  const handleVerifyAuth = async () => {
    setLoading(true);
    try {
      await client.setClerkHeaders(getToken); // set the headers with clerk's auth token
      await client.checkAuth();
      setLoading(false);
      setAuthVerified(true);
    } catch (error: any) {
      setLoading(false);
      setMessage(error.message);
    }
  };

  const handleImport = async () => {
    setLoading(true);
    try {
      await client.setClerkHeaders(getToken); // set the headers with clerk's auth token
      let status, data;
      if (customLink === "dbs-vickers") {
        const holdingsResponse = accountId
          ? await client.updateHoldings(accountId)
          : await client.getHoldings();
        ({ status, data } = holdingsResponse);
      } else {
        const accountsResponse = accountId
          ? await client.updateAccounts(accountId)
          : await client.getAccounts();
        ({ status, data } = accountsResponse);
      }

      if (status === 200) {
        posthog?.capture(accountId ? "endowus_updated" : "emereg_imported", {
          [accountId ? "accountId" : "accounts"]: accountId || [data.id],
        });
      }

      await Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
        }),
      ]);
      toast.success("Data imported successfully from DBS");
      setLoading(false);
      setShowOtp(false);
      setOpen(false, customLink);
    } catch (error) {
      setLoading(false);
      setShowOtp(false);
      setShowLogin(true);
      setMessage(`${error}`);
    }
  };

  const faq = [
    {
      question: "How frequently will my data be synced?",
      answer:
        "Your data is synced on-demand for maximum security. We prioritize your privacy by not storing your credentials, which means automatic background syncing isn't possible.",
    },
    {
      question: "Are my credentials saved?",
      answer:
        "No, your credentials are not saved on Peek's servers. Your temporary DBS access key is kept in your browser.",
    },
    {
      question: "Can Peek touch my money?",
      answer:
        "No, Peek only has read-only access to your data. That means we cannot create withdrawal requests or funds transfers on your behalf.",
    },
    {
      question: "Is the data on Peek encrypted?",
      answer:
        "Your financial data is encrypted at-rest and in-transit. This means that your data is protected from unauthorized access. Even if the database files were to be stolen, the thieves wouldn't be able to decrypt the contents without the decryption keys. In addition, the decryption keys are secured by Amazon Web Service’s Key Management Service, which makes use of industry standard hardware security modules. In addition, data transfers between your browser and our servers are also encrypted with secured HTTPS connections, so your data will still be secured even if it was intercepted by a hacker.",
    },
  ];

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open, customLink)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex flex-col gap-8">
            {customLink === "dbs" ? (
              <>
                <img
                  src={dbs}
                  alt="DBS"
                  className="size-16 rounded-md bg-offwhite p-1"
                />
                Login to DBS
              </>
            ) : (
              <>
                <img
                  src={dbsVickers}
                  alt="DBS Vickers"
                  className="size-16 rounded-md bg-offwhite p-1"
                />
                Login to DBS Vickers
              </>
            )}
          </DialogTitle>
          <DialogDescription>
            Enter your credentials to access your account.
          </DialogDescription>
        </DialogHeader>
        {!showOtp && showLogin ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex flex-col gap-2">
                <Label htmlFor="email">Username</Label>
                <Input
                  autoComplete="off"
                  className="col-span-3"
                  id="email"
                  placeholder="Enter your email or username"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="col-span-4 text-sm text-red-500">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <Label htmlFor="password">PIN</Label>
                <PasswordInput
                  data-1p-ignore
                  autoComplete="off"
                  className="col-span-3"
                  id="password"
                  placeholder="Enter your PIN"
                  {...register("password")}
                />
                {errors.password && (
                  <span className="col-span-4 text-sm text-red-500">
                    {errors.password.message}
                  </span>
                )}
              </div>
              {message && (
                <span className="col-span-4 text-sm text-red-500">
                  {message}
                </span>
              )}
              {!loading ? (
                <Button className="w-full" type="submit">
                  Login
                </Button>
              ) : (
                <div className="flex flex-row items-center gap-x-2 text-sm text-gray-500">
                  <Loader />
                  This may take up to 30 seconds.
                </div>
              )}
            </div>
          </form>
        ) : (
          <div className="flex flex-col gap-2">
            {!authVerified ? (
              <Alert>
                <AlertTitle>Check your DBS mobile application.</AlertTitle>
                <AlertDescription>
                  Once you have authorized the transaction, click the button
                  below.
                </AlertDescription>
              </Alert>
            ) : (
              <Alert>
                <AlertTitle>Successfully authenticated on DBS.</AlertTitle>
                <AlertDescription>
                  Click the button below to import your data.
                </AlertDescription>
              </Alert>
            )}

            {message && (
              <span className="col-span-4 text-sm text-red-500">{message}</span>
            )}

            {loading ? (
              <div className="flex flex-row items-center gap-x-2 text-sm text-gray-500">
                <Loader />
                This may take up to 30 seconds.
              </div>
            ) : (
              <div className="flex flex-col space-y-1">
                <Button
                  type="submit"
                  onClick={() =>
                    authVerified ? handleImport() : handleVerifyAuth()
                  }
                >
                  {authVerified ? "Import" : "Verify"}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    setAuthVerified(false);
                    setLoading(false);
                    setShowOtp(false);
                    setShowLogin(true);
                    setMessage("");
                  }}
                >
                  Back to Login
                </Button>
              </div>
            )}
          </div>
        )}
        <Separator />
        <Accordion type="single" collapsible>
          {faq.map((item, index) => (
            <AccordionItem
              key={index}
              value={index.toString()}
              className="text-sm"
            >
              <AccordionTrigger className="text-left text-gray-700">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-500">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        <p className="text-xs italic text-gray-500">
          Disclaimer: This integration is a custom solution and is not
          officially endorsed by DBS Bank.
        </p>
      </DialogContent>
    </Dialog>
  );
}
