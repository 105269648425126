import { useState, useEffect, useCallback } from "react";

export const useColumnVisibility = (
  key: string,
  initialColumns: Record<string, boolean>,
  mandatoryVisibleColumns: string[],
) => {
  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
    () => {
      const savedVisibility = localStorage.getItem(key);
      if (!savedVisibility) {
        return initialColumns;
      }

      const parsedVisibility = JSON.parse(savedVisibility);
      const filteredVisibility = Object.keys(parsedVisibility).reduce(
        (acc, key) => {
          if (key in initialColumns) {
            acc[key] = parsedVisibility[key];
          }
          return acc;
        },
        {} as Record<string, boolean>,
      );

      return { ...initialColumns, ...filteredVisibility };
    },
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(visibleColumns));
  }, [key, visibleColumns]);

  const toggleColumnVisibility = useCallback((columnId: string) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  }, []);

  const showAll = useCallback(() => {
    setVisibleColumns((prev) =>
      Object.keys(prev).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      ),
    );
  }, []);

  const hideAll = useCallback(() => {
    setVisibleColumns((prev) =>
      Object.keys(prev).reduce(
        (acc, key) => {
          acc[key] = mandatoryVisibleColumns.includes(key);
          return acc;
        },
        {} as Record<string, boolean>,
      ),
    );
  }, [mandatoryVisibleColumns]);

  return {
    visibleColumns,
    toggleColumnVisibility,
    setVisibleColumns,
    showAll,
    hideAll,
  };
};
