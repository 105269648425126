import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "@/lib/axios";
import config from "@/lib/config";
import { queryClient } from "@/lib/queryClient";
import { GET_GOALS } from "@/lib/constants";
import { usePeekUser } from "./usePeekUser";

export interface Goal {
  id: number;
  createdAt: string;
  updatedAt: string;
  userId: string;
  name: string;
  targetDate: string;
  targetNetWorth: string;
}

export interface UpdateGoalInput {
  id: number;
  createdAt: string;
  updatedAt: string;
  userId: string;
  name: string;
  targetDate: Date | string;
  targetNetWorth: number;
}

export const useGetGoals = () => {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();
  return useQuery({
    queryKey: GET_GOALS,
    queryFn: async () => {
      const token = await getToken();
      if (!token) throw new Error("User not authenticated");
      const { data } = await axios.get<{ goals: Goal[] }>(
        `${config.backendUrl}/user/goals`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data.goals;
    },
    enabled: peekUserContext.state === "SUCCESS",
  });
};

export const useUpdateGoal = () => {
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: async (goal: UpdateGoalInput) => {
      const token = await getToken();
      if (!token) throw new Error("User not authenticated");
      const { data } = await axios.patch<{ goal: Goal }>(
        `${config.backendUrl}/user/goals/${goal.id}`,
        goal,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data.goal;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: GET_GOALS });
    },
  });
};
