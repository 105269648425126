import { Separator } from "../ui/separator";
import { Switch } from "../ui/switch";
import peekMoneyBag from "/images/icon-peek-money-bag.svg";

type PlansProps = {
  yearly: boolean;
  setYearly: React.Dispatch<React.SetStateAction<boolean>>;
};

export function Plans({ yearly, setYearly }: PlansProps) {
  return (
    <>
      <div className="flex w-full flex-col gap-y-4 rounded-lg bg-white p-6 shadow-sm">
        <h4 className="text-lg font-medium">How much does Peek cost?</h4>

        <div className="space-y-8 rounded bg-beige p-8 text-center">
          <div className="flex w-full justify-center space-x-2">
            <span className="font-libre">Monthly</span>
            <Switch checked={yearly} onCheckedChange={setYearly} />
            <div className="flex flex-col gap-x-1 md:flex-row">
              <span className="font-libre">Yearly</span>
              <span className="rounded-full bg-black px-3 text-sm text-white">
                2 months off
              </span>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center space-y-2 font-libre text-3xl">
            <div className="text-4xl">
              {yearly ? (
                <>
                  <span className="mr-2 text-gray-400 line-through">
                    USD 200
                  </span>
                  <span className="mr-2">USD 100/Year</span>
                </>
              ) : (
                <>
                  <span className="mr-2 text-gray-400 line-through">
                    USD 20
                  </span>
                  <span className="mr-2">USD 10/Month</span>
                </>
              )}
            </div>
            <div>
              <img
                src={peekMoneyBag}
                alt="Eyes"
                className="hidden h-8 md:block"
              />
            </div>
          </div>
          <div>
            <span className="rounded bg-sage p-2 text-sm">
              Use PEEK50 to get a 50% off at checkout
            </span>
          </div>
        </div>
        <Separator className="bg-customOrange" />
        <ul className="ml-4 list-disc">
          <li>Connect to over 100+ financial institutions across the world</li>
          <li>Sync automatically with your financial institutions</li>
          <li>Track your net worth in real-time</li>
          <li>Calculate profit & loss, average cost, cash flows, and more</li>
          <li>Asset-specific performance trends</li>
          <li>Key risk factor analysis</li>
          <li>Personal finance nudges</li>
          <li>Target savings projection</li>
          <li>On-demand financial analyst</li>
        </ul>
      </div>
    </>
  );
}
