import React from "react";
import { Bar } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Allocation } from "@/hooks/useAnalysis";

interface Props {
  targetAllocation: Allocation[];
  actualAllocation: Allocation[];
}

ChartJS.register(
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  BarController,
  Filler,
);

const AllocationChart: React.FC<Props> = ({
  targetAllocation,
  actualAllocation,
}) => {
  // Function to map allocation data to chart data format
  const parseAllocationData = (allocations: Allocation[]) => {
    const labels: string[] = [];
    const data: number[] = [];
    allocations.forEach((allocation) => {
      labels.push(allocation.assetClass);
      data.push(parseFloat(allocation.percentageOfNetWorth.replace("%", "")));
    });
    return { labels, data };
  };

  // Parse both target and actual allocations
  const targetData = parseAllocationData(targetAllocation);
  const actualDataMap = new Map<string, number>();
  actualAllocation.forEach((a) => {
    actualDataMap.set(
      a.assetClass,
      parseFloat(a.percentageOfNetWorth.replace("%", "")),
    );
  });
  const actualData: number[] = targetData.labels.map(
    (label) => actualDataMap.get(label) || 0,
  );

  const data: ChartData<"bar", number[], string> = {
    labels: targetData.labels,
    datasets: [
      {
        label: "Target Allocation",
        data: targetData.data,
        backgroundColor: "#41989D",
      },
      {
        label: "Your Allocation",
        data: actualData,
        backgroundColor: "#4966AF",
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 30,
      },
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'Manrope', sans-serif",
          size: 18,
        },
        bodyFont: {
          family: "'Manrope', sans-serif",
          size: 14,
          lineHeight: 1.4,
        },
        footerFont: {
          family: "'Manrope', sans-serif",
        },
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.raw}%`;
          },
        },
        cornerRadius: 4,
        caretSize: 0,
        padding: 12,
      },
      legend: {
        position: "bottom" as const,
        labels: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
          useBorderRadius: true,
          borderRadius: 8,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 12,
          },
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            family: "'Manrope', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return <Bar options={options} data={data} id="allocation-bar" />;
};

export default AllocationChart;
