// Local Storage Keys
export const COMPARE_WITH_KEY = "compareWith";
export const CURRENCY_KEY = "currency";
export const OVERVIEW_TAB_KEY = "overviewTab";
export const MY_ACCOUNTS_TAB_KEY = "myAccountsTab";

// For null asset tags
export const UNKNOWN_TAG = "Uncategorized";

// Query Keys
export const GET_GOALS = ["user", "goals"];
export const GET_HOLDINGS_QUERY_KEY = "getHoldings";
export const GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY =
  "getAssetLiabilitiesAccounts";
export const GET_ASSET_LIABILITY_QUERY_KEY = "getAssetLiability";
export const GET_SUBSCRIPTIONS_QUERY_KEY = "getSubscriptions";
export const GET_PORTFOLIO_ANALYSIS_QUERY_KEY = "getPortfolioAnalysis";
export const GET_ASSET_ALLOCATION_ANALYSIS_QUERY_KEY =
  "getAssetAllocationAnalysis";
export const GET_ETF_HOLDINGS_ANALYSIS_QUERY_KEY = "getETFHoldingsAnalysis";
export const GET_PROFILE_QUERY_KEY = "getProfile";
export const GET_SYNC_TASKS_QUERY_KEY = "getSyncTasks";
export const DEFAULT_CURRENCY = "SGD";

export const DEFAULT_TAGS = [
  "Risky",
  "Illiquid",
  "Rainy Day",
  "Ready to Invest",
];

// Colours are currently assigned using index
export const COLOUR_PALLETE = [
  "#FFEFBF",
  "#FFD7CB",
  "#B7E8EF",
  "#FFD75F",
  "#BAD499",
  "#FE875C",
  "#B877B4",
  "#ECB258",
  "#75C9CE",
  "#EBE66F",
  "#B3BC8E",
  "#FF9C9C",
  "#D1AB7A",
  "#E8712E",
  "#874A84",
  "#41989D",
  "#8F985D",
  "#C5A01A",
  "#896737",
  "#ECBBA3",
  "#F2C7F3",
  "#CAEAEA",
  "#FDE568",
  "#CAF4C6",
  "#D4D4F3",
  "#FFE3B3",
  "#BB5F35",
  "#581E57",
  "#006A6F",
  "#B9A91A",
  "#4E581A",
  "#903510",
  "#6D5023",
];

export const CHART_COLOR_PALLETE_LEFT = ["#fe875c", "#e4c76e", "#fbf7f1"];

export const CHART_COLOR_PALLETE_RIGHT = ["#bad4d3", "#41989d", "#4966af"];

export const CHART_RADIAL_WARNING = ["#fe875c", "#f9d1bf"];
export const CHART_RADIAL_NORMAL = ["#e7cd80", "#f4ebcd"];
export const CHART_RADIAL_GOOD = ["#6ca4a8", "#c3dadd"];

export const debounceDelay = 1000;
export const doublePaymentDebounceDelay = 500;

interface ColumnConfig {
  label: string;
  icon: string;
}

const createColumnConfig = (label: string, icon: string): ColumnConfig => ({
  label,
  icon,
});

export const showHideColumns: Record<string, ColumnConfig> = {
  asset: createColumnConfig("Assets", "List"),
  accountName: createColumnConfig("Account", "CircleUser"),
  tag: createColumnConfig("Tag", "Tag"),
  averageCost: createColumnConfig("Average Cost", "Banknote"),
  quantity: createColumnConfig("Quantity", "LayoutGrid"),
  unit: createColumnConfig("Unit Value", "Banknote"),
  unitValue: createColumnConfig("Unit Value", "Banknote"),
  percentageHoldings: createColumnConfig(
    "% of (assets/liabilities)",
    "CandlestickChart",
  ),
  value: createColumnConfig("Value", "Banknote"),
  smallValue: createColumnConfig("Small Values", "CircleSlash"),
  emptyAccount: createColumnConfig("Empty Accounts", "CircleSlash"),
  totalValue: createColumnConfig("Total Value", "Banknote"),
  profitLoss: createColumnConfig("Profit/Loss", "CandlestickChart"),
  profitLossPercentage: createColumnConfig("Profit/Loss %", "CirclePercent"),
  totalCapitalInvested: createColumnConfig(
    "Total Capital Invested",
    "Banknote",
  ),
};

export const publicMakets = ["ETF", "STOCK", "MUTUAL_FUND", "BOND", "REIT"];

export const crypto = ["TOKEN", "NFT"];

export const tradableAssets = [
  ...publicMakets,
  ...crypto,
  "ART",
  "WINE",
  "WATCH",
  "GOLD",
  "SILVER",
  "PALLADIUM",
  "PLATINUM",
  "ESOP",
  "RSU",
  "SHARE",
  "REAL_ESTATE",
];
export const mandatoryVisibleColumns = ["asset", "value"];

export const assetLiabilityMap: Record<string, string> = {
  // Assets
  ANGEL_INVESTMENT: "ASSET",
  HEDGE_FUND: "ASSET",
  PRIVATE_CREDIT: "ASSET",
  PRIVATE_EQUITY: "ASSET",
  FIXED_DEPOSIT: "ASSET",
  CASH: "ASSET",
  ART: "ASSET",
  WINE: "ASSET",
  WATCH: "ASSET",
  TOKEN: "ASSET",
  NFT: "ASSET",
  LIFE_INSURANCE: "ASSET",
  GOLD: "ASSET",
  SILVER: "ASSET",
  PALLADIUM: "ASSET",
  PLATINUM: "ASSET",
  ESOP: "ASSET",
  RSU: "ASSET",
  SHARE: "ASSET",
  ETF: "ASSET",
  STOCK: "ASSET",
  MUTUAL_FUND: "ASSET",
  BOND: "ASSET",
  REIT: "ASSET",
  REAL_ESTATE: "ASSET",
  SG_CPF_OA: "ASSET",
  SG_CPF_RA: "ASSET",
  SG_CPF_SA: "ASSET",
  SG_CPF_MA: "ASSET",
  SG_SRS: "ASSET",
  US_401K: "ASSET",
  US_IRA: "ASSET",
  US_529: "ASSET",
  ROBO: "ASSET",
  OTHER: "ASSET",

  // Liabilities
  CREDIT_CARD: "LIABILITY",
  LOAN: "LIABILITY",
};
