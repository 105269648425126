import { PortfolioTableRow } from "@/components/Portfolio/Table/types";
import { AssetLiabilityV2 } from "./types";
import {
  beautifyAccountType,
  generateDivergingColorPalette,
  getColourCode,
} from "./utils";
import { format } from "date-fns";
import { RowSelectionState } from "@tanstack/react-table";

export enum RowIdPrefix {
  Account = "a",
  Other = "o",
}

export function getGroupedLegendData(
  data: AssetLiabilityV2[],
  overview: string,
) {
  const groupData = (data: AssetLiabilityV2[], type: string) => {
    return data.reduce(
      (acc, item) => {
        if (item.assetOrLiability !== type) return acc;
        const lastSnapshot = item.snapshots["day"].slice(-1)[0];
        if (lastSnapshot) {
          let groupByKey =
            item.assetClass === "BANK_ACCOUNT"
              ? "Cash & Cash Equivalents"
              : item.assetClass
                  .toLowerCase()
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");

          switch (overview) {
            case "account":
              groupByKey = item.account.name;
              break;
            case "currency":
              groupByKey = item.currency;
              break;
            case "tag":
              groupByKey = item.tag || "Uncategorized";
              break;
          }
          acc[groupByKey] = (acc[groupByKey] || 0) + lastSnapshot.totalValue;
        }
        return acc;
      },
      {} as Record<string, number>,
    );
  };

  const groupedAssetData = groupData(data, "ASSET");
  const groupedLiabilityData = groupData(data, "LIABILITY");

  const sortedKeys = [
    ...Object.keys(groupedAssetData),
    ...Object.keys(groupedLiabilityData),
  ].sort(
    (a, b) =>
      (groupedAssetData[b] || groupedLiabilityData[b]) -
      (groupedAssetData[a] || groupedLiabilityData[a]),
  );

  const colorMaps = generateDivergingColorPalette(sortedKeys);

  const createLegends = (groupedData: Record<string, number>) => {
    return Object.entries(groupedData)
      .map(([label, value]) => ({
        label,
        value,
        colorCode: colorMaps[label],
      }))
      .sort((a, b) => b.value - a.value);
  };

  const groupedAssetLegends = createLegends(groupedAssetData);
  const groupedLiabilityLegends = createLegends(groupedLiabilityData);

  return { groupedAssetLegends, groupedLiabilityLegends };
}

// rowId format is for following benefits:
// 1. check if row is an account row for AccountRenameInlineEdit and Sync button.
// 2. remove from rowSelection to get selected asset/liability ids.
// 3. rowSelection must have unique rowId otherwise checkbox will have issues
export function groupDataByOverview(
  data: AssetLiabilityV2[],
  overview: string,
  assetTotalValue: number,
  liabilityTotalValue: number,
): { assets: PortfolioTableRow[]; liabilities: PortfolioTableRow[] } {
  const groupMapping = {
    assets: new Map(),
    liabilities: new Map(),
  };

  const getGroupKey = (assetLiability: AssetLiabilityV2) => {
    switch (overview) {
      case "account":
        return assetLiability.account.name;
      case "currency":
        return assetLiability.currency;
      case "tag":
        return assetLiability.tag || "Uncategorized";
      default:
        return assetLiability.assetClass;
    }
  };

  const getSubGroupKey = (assetLiability: AssetLiabilityV2) => {
    switch (overview) {
      case "account":
        return assetLiability.assetClass;
      case "currency":
      case "tag":
        return assetLiability.account.name;
      default:
        return assetLiability.account.id;
    }
  };

  const getSubGroupName = (assetLiability: AssetLiabilityV2) => {
    if (overview === "account") {
      return assetLiability.assetClass;
    } else {
      return assetLiability.account.name;
    }
  };

  data.forEach((assetLiability) => {
    const groupKey = getGroupKey(assetLiability);
    const groupType =
      assetLiability.assetOrLiability.toLowerCase() === "asset"
        ? "assets"
        : "liabilities";

    if (!groupMapping[groupType].has(groupKey)) {
      groupMapping[groupType].set(groupKey, {
        id: `${overview === "account" ? RowIdPrefix.Account : RowIdPrefix.Other}|${assetLiability.account.id}|${assetLiability.assetClass}|section|${groupKey}`,
        name: groupKey,
        rowType: "section",
        subRows: [],
        account: assetLiability.account,
      });
    }

    const sectionGroup = groupMapping[groupType].get(groupKey);
    const subGroupKey = getSubGroupKey(assetLiability);
    const subGroupRowIdPrefix =
      overview === "account" ? RowIdPrefix.Other : RowIdPrefix.Account;
    const subSectionId = `${subGroupRowIdPrefix}|${assetLiability.account.id}|${assetLiability.assetClass}|subSection|${subGroupKey}`;

    let subSectionGroup = sectionGroup.subRows.find(
      (subRow: { id: string; name: string }) => subRow.id === subSectionId,
    );

    if (!subSectionGroup) {
      const subGroupName = getSubGroupName(assetLiability);
      subSectionGroup = {
        id: subSectionId,
        name: subGroupName,
        rowType: "subSection",
        subRows: [],
        account: assetLiability.account,
      };
      sectionGroup.subRows.push(subSectionGroup);
    }
    subSectionGroup.subRows.push({ ...assetLiability, rowType: "asset" });
  });

  const assets = Array.from(groupMapping.assets.values());
  const liabilities = Array.from(groupMapping.liabilities.values());

  const newAssets = calculatePercentageHolding(assets, assetTotalValue);

  const newLiabilities = calculatePercentageHolding(
    liabilities,
    liabilityTotalValue,
  );

  return {
    assets: newAssets as PortfolioTableRow[],
    liabilities: newLiabilities as PortfolioTableRow[],
  };
}

function calculatePercentageHolding(
  sections: {
    id: number | undefined;
    name: string;
    rowType: "section";
    subRows: {
      id: number | undefined;
      name: string;
      rowType: "subSection";
      subRows: AssetLiabilityV2[];
    }[];
  }[],
  totalValue: number,
) {
  return sections.map((section) => {
    const newSubRows = section.subRows.map((subSection) => {
      const newSubRows = subSection.subRows.map((row) => {
        return {
          ...row,
          percentageHolding:
            ((row.snapshots.day.slice(-1)[0]?.totalValue || 0) / totalValue) *
            100,
        };
      });

      let subSectionTotalValue = 0;
      let subSectionOriginalTotalValue = 0;
      let percentageHolding = 0;

      newSubRows.forEach((row) => {
        subSectionTotalValue += row.snapshots.day.slice(-1)[0]?.totalValue || 0;
        subSectionOriginalTotalValue +=
          row.snapshots.day.slice(-1)[0]?.original?.totalValue || 0;
        percentageHolding += row.percentageHolding;
      });

      return {
        ...subSection,
        percentageHolding,
        totalValue: subSectionTotalValue,
        originalTotalValue: subSectionOriginalTotalValue,
        subRows: [...newSubRows],
      };
    });

    let sectionTotalValue = 0;
    let sectionOriginalTotalValue = 0;
    let percentageHolding = 0;

    newSubRows.forEach((row) => {
      sectionTotalValue += row.totalValue;
      sectionOriginalTotalValue += row.originalTotalValue || 0;
      percentageHolding += row.percentageHolding;
    });

    return {
      ...section,
      percentageHolding,
      totalValue: sectionTotalValue,
      originalTotalValue: sectionOriginalTotalValue,
      subRows: [...newSubRows],
    };
  });
}

export function getHistoryChartData(
  data: AssetLiabilityV2[],
  overviewBy: string,
) {
  const endDate = new Date();
  const tags = new Set<string>();
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);

  const groupedByTimestampAndTag = data.reduce(
    (
      acc,
      { snapshots, account, currency, tag, assetClass, assetOrLiability },
    ) => {
      let attribute: string;

      switch (overviewBy) {
        case "account":
          attribute = account.name;
          break;
        case "currency":
          attribute = currency;
          break;
        case "tag":
          attribute = tag || "Uncategorized";
          break;
        default:
          attribute = beautifyAccountType(assetClass);
          break;
      }

      snapshots.month.forEach(({ timestamp, quantity, unitValue }) => {
        const snapshotDate = new Date(timestamp);

        if (snapshotDate >= startDate && snapshotDate <= endDate) {
          const key = `${snapshotDate.getFullYear()}-${snapshotDate.getMonth() + 1}`;
          const value = quantity * unitValue;

          tags.add(attribute);

          if (!acc[assetOrLiability]) {
            acc[assetOrLiability] = {};
          }

          if (!acc[assetOrLiability][key]) {
            acc[assetOrLiability][key] = { total: 0 };
          }
          if (!acc[assetOrLiability][key][attribute]) {
            acc[assetOrLiability][key][attribute] = 0;
          }
          acc[assetOrLiability][key][attribute] += value;
          acc[assetOrLiability][key].total += value;
        }
      });
      return acc;
    },
    {} as {
      [assetOrLiability: string]: {
        [timestamp: string]: { total: number; [tag: string]: number };
      };
    },
  );

  const processGroupedData = (groupedData: {
    [timestamp: string]: { total: number; [tag: string]: number };
  }) => {
    const timestamps = Object.keys(groupedData).sort();

    const values = timestamps.map((timestamp) => groupedData[timestamp]);

    const labels = timestamps.map((timestamp) =>
      format(
        new Date(
          parseInt(timestamp.split("-")[0]),
          parseInt(timestamp.split("-")[1]) - 1,
        ),
        "MMM yyyy",
      ),
    );

    const sortedTags = Array.from(tags)
      .map((tag) => ({
        tag,
        totalValue: values.reduce((acc, value) => acc + (value[tag] || 0), 0),
      }))
      .sort((a, b) => b.totalValue - a.totalValue)
      .map((item) => item.tag);

    const colorMap = generateDivergingColorPalette(sortedTags);

    return {
      labels,
      datasets: sortedTags.map((tag) => ({
        label: tag,
        data: values.map((value) => value[tag] || 0),
        totalValue: values.reduce((acc, value) => acc + (value[tag] || 0), 0),
        borderColor: "transparent",
        backgroundColor: getColourCode(tag, colorMap),
        fill: true,
        yAxisID: "y",
        pointRadius: 0,
        pointHitRadius: 20,
        pointStyle: false,
        tension: 0.2,
      })),
    };
  };

  const assets = processGroupedData(groupedByTimestampAndTag["ASSET"] || {});
  const liabilities = processGroupedData(
    groupedByTimestampAndTag["LIABILITY"] || {},
  );

  return {
    assets: {
      ...assets,
      datasets: assets.datasets.filter((dataset) => dataset.totalValue !== 0),
    },
    liabilities: {
      ...liabilities,
      datasets: liabilities.datasets.filter(
        (dataset) => dataset.totalValue !== 0,
      ),
    },
  };
}

export function getAccountFilterList(data: AssetLiabilityV2[]): {
  label: string;
  value: number;
  assetIds: number[];
}[] {
  const accountMap = new Map<number, { name: string; assetIds: number[] }>();

  data.forEach(({ account, id }) => {
    if (!accountMap.has(account.id)) {
      accountMap.set(account.id, { name: account.name, assetIds: [id] });
    } else {
      accountMap.get(account.id)!.assetIds.push(id);
    }
  });

  return Array.from(accountMap, ([id, { name, assetIds }]) => ({
    label: name,
    value: id,
    assetIds,
  })).sort((a, b) => a.label.localeCompare(b.label));
}

export function getTotalValues(data: AssetLiabilityV2[]) {
  let assetTotalValue = 0;
  let liabilityTotalValue = 0;

  for (const item of data) {
    const totalValue = item.snapshots.month.slice(-1)[0]?.totalValue ?? 0;
    if (item.assetOrLiability === "ASSET") {
      assetTotalValue += totalValue;
    } else if (item.assetOrLiability === "LIABILITY") {
      liabilityTotalValue += totalValue;
    }
  }

  return { assetTotalValue, liabilityTotalValue };
}

// Extract selected asset/liability IDs for moving accounts and deleting
// rowSelection is an object containing all selected row IDs
// Row IDs are formatted as follows:
// - For sections subsections: "a|6|PUBLIC_MARKET|subSection|6|{index}" or "o|6|PUBLIC_MARKET|section|PUBLIC_MARKET|{index}"
// - For assets/liabilities: "{assetLiability.id}|{index}"
// We filter for valid numeric IDs (asset/liability IDs) and ignore section/subsection IDs
export function getSelectedIds(rowSelection: RowSelectionState) {
  return Object.keys(rowSelection).reduce((acc: number[], item) => {
    const id = parseInt(item.split("|")[0]);
    if (!isNaN(id)) {
      acc.push(id);
    }
    return acc;
  }, []);
}

/**
 * Determines if a given row ID represents an account row.
 *
 * @param rowId - The row ID to check. row format will be like this "a|6|PUBLIC_MARKET|subSection|6|{index}" "o|6|PUBLIC_MARKET|section|PUBLIC_MARKET|{index}"
 * a = "Account", o = "Other" (Tag, Currency, Asset Class)
 * @returns True if the row ID represents an account, false otherwise.
 */
export function isAccountRow(rowId: string): boolean {
  return rowId.split("|")[0] === RowIdPrefix.Account;
}
