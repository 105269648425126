import * as React from "react";
import { cn } from "@/lib/utils";

export interface NumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  locale: string;
  value?: string; // Accept value as a prop
}

function parseLocaleNumber(stringNumber: string, locale: string): number {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replaceAll(thousandSeparator, "")
      .replace(decimalSeparator, "."),
  );
}

const allowedPartials = ["", "-", ".", "-."];
const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ className, onChange, value: propValue = "", ...props }, ref) => {
    const [value, setValue] = React.useState(propValue);

    React.useEffect(() => {
      // update value if different from propValue
      const parsedPropValue = parseLocaleNumber(propValue, props.locale);
      const parsedValue = parseLocaleNumber(value, props.locale);

      if (parsedPropValue !== parsedValue && !allowedPartials.includes(value)) {
        setValue(propValue);
      }
    }, [propValue, props.locale, value]);

    const formatter = React.useMemo(
      () => new Intl.NumberFormat(props.locale, { maximumFractionDigits: 20 }),
      [props.locale],
    );

    const formatValue = React.useCallback(
      (inputValue: string) => {
        // special handling for initial number inputs that are valid, but not a number yet
        if (allowedPartials.includes(inputValue)) return inputValue;

        const startsWithNegativeSign = inputValue.startsWith("-");
        if (startsWithNegativeSign) inputValue = inputValue.slice(1); // remove negative sign

        const decimalSeparator = Intl.NumberFormat(props.locale)
          .format(1.1)
          .replace(/\p{Number}/gu, "");
        const endsWithDecimalSeparator = inputValue.endsWith(decimalSeparator);

        const cleanedValue = parseLocaleNumber(inputValue, props.locale);
        if (!isNaN(Number(cleanedValue))) {
          let formattedValue = formatter.format(cleanedValue);

          if (startsWithNegativeSign) {
            formattedValue = "-" + formattedValue; // put back negative sign
          }
          // If the original input ends with a decimal separator, append it.
          if (endsWithDecimalSeparator) {
            formattedValue += ".";
          }

          return formattedValue;
        }
        return ""; // clear the input if it is not a number
      },
      [formatter, props.locale],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;

      const formattedValue = formatValue(rawValue);
      setValue(formattedValue);

      // Create a synthetic event to pass the numeric value to the parent's onChange
      // only pass the value back if it is a valid number, instead of "-."
      // only pass the value back if it is different from the propValue
      const parsedPropValue = parseLocaleNumber(propValue, props.locale);
      const parsedRawValue = parseLocaleNumber(rawValue, props.locale);

      if (
        onChange &&
        parsedPropValue !== parsedRawValue &&
        !isNaN(parsedRawValue)
      ) {
        const modifiedEvent = {
          ...event,
          target: {
            ...event.target,
            value: parsedRawValue.toString(), // Pass the unformatted value to the parent
          },
        };
        onChange(modifiedEvent as React.ChangeEvent<HTMLInputElement>);
      }
    };

    return (
      <input
        type="text" // Use text type to allow formatted input
        value={value}
        onChange={handleChange}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

NumberInput.displayName = "NumberInput";

export { NumberInput };
