import { CommandDialog } from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { PlusIcon } from "lucide-react";
import { ComponentProps, useState } from "react";
import { CommandKey } from "../common/CommandKey";
import { Button, ButtonProps, buttonVariants } from "../ui/button";
import AddAssetCommand from "./AddAssetCommand";
import { AddAssetForm } from "./AddAssetForm";
import { searchItems } from "./searchItems";
import { CustomSearchItems } from "./types";
import { toast } from "sonner";
import { useDialog } from "@/hooks/useDialog";
import { useShortcutPrompt } from "@/hooks/useShortcutPrompt";
import { useSnaptrade } from "@/hooks/useSnaptrade";

interface AddAssetDialogProps {
  enableCmdK?: boolean;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  className?: string;
  commandKeyVariant?: ComponentProps<typeof CommandKey>["variant"];
  setPlaidCustomLinkNameAndResetToken: (plaidCustomLinkName: string) => void;
  showIcon?: boolean;
  buttonLabel?: string;
  setOpenEndowus: (open: boolean, accountId?: number) => void;
  setOpenStashAway: (open: boolean, accountId?: number) => void;
  setOpenEmereg: (
    open: boolean,
    customLink: string,
    accountId?: number,
  ) => void;
}

export function AddAssetDialog({
  enableCmdK = true,
  variant = "secondary",
  className,
  commandKeyVariant,
  setPlaidCustomLinkNameAndResetToken,
  setOpenEndowus,
  setOpenStashAway,
  setOpenEmereg,
}: AddAssetDialogProps) {
  const [accountType, setAccountType] = useState<CustomSearchItems>();
  const [accountName, setAccountName] = useState("");
  const { isDialogOpen, setDialogOpen } = useDialog();
  const { showShortcutToast } = useShortcutPrompt();

  function clearInputs() {
    setAccountType(undefined);
    setAccountName("");
  }

  const snaptrade = useSnaptrade();

  return (
    <>
      <Button
        id="add-asset-button"
        className={cn("flex items-center gap-1", className)}
        variant={variant}
        size="sm"
        onClick={() => {
          showShortcutToast({
            shortcutKey: "K",
            requiresCmdKey: true,
            template: "Just hit {shortcutKey} next time to save time",
          });
          setDialogOpen("addAsset", true);
        }}
      >
        <PlusIcon className="h-4 w-4" />
        <span>Add new</span>
        {enableCmdK && (
          <CommandKey
            variant={commandKeyVariant}
            cmdKey="k"
            callback={() => setDialogOpen("addAsset", true)}
          />
        )}
      </Button>
      <CommandDialog
        open={isDialogOpen("addAsset")}
        onOpenChange={(open) => setDialogOpen("addAsset", open)}
        className="min-w-10/12 md:min-w-[900px]"
        onKeyDown={(e) => {
          // Prevent closing the dialog when category is set
          if (accountType && e.key === "Escape") e.preventDefault();
          // Prevent triggering enter listner
          if (!accountType && e.key === "Enter") e.stopPropagation();
        }}
        onInteractOutside={(e) => {
          if (accountType) e.preventDefault();
        }}
      >
        <div className="flex gap-x-2 px-4 pt-4">
          <span
            className={cn(
              buttonVariants({
                variant: "secondaryBreadcrumb",
                size: "breadcrumb",
              }),
              "cursor-pointer",
            )}
            onClick={clearInputs}
          >
            Home
          </span>
          {accountType ? (
            <span
              className={cn(
                buttonVariants({
                  variant: "secondaryBreadcrumb",
                  size: "breadcrumb",
                }),
                accountType &&
                  `hover:${searchItems[accountType].iconBackgroundColor} ${searchItems[accountType].iconBackgroundColor}`,
              )}
            >
              {searchItems[accountType].title}
            </span>
          ) : (
            <span
              className={cn(
                buttonVariants({
                  variant: "outline",
                  size: "breadcrumb",
                }),
                "hover:bg-background",
              )}
            >
              Asset/Liability
            </span>
          )}
          {accountName && (
            <span
              className={cn(
                buttonVariants({
                  variant: "secondaryBreadcrumb",
                  size: "breadcrumb",
                }),
                accountType &&
                  `hover:${searchItems[accountType].iconBackgroundColor} ${searchItems[accountType].iconBackgroundColor}`,
              )}
            >
              {accountName}
            </span>
          )}
        </div>
        <div className="absolute right-4 top-4 hidden text-xs text-muted-foreground md:block">
          Press ESC to {accountType ? "go back" : "close"}
        </div>
        {accountType ? (
          <AddAssetForm
            clearCategory={clearInputs}
            setAccountName={setAccountName}
            accountType={accountType}
            closeDialog={() => {
              clearInputs();
              setDialogOpen("addAsset", false);
            }}
          />
        ) : (
          <AddAssetCommand
            setAccountType={setAccountType}
            openZerion={() => {
              setDialogOpen("addAsset", false);
              setDialogOpen("zerion", true);
            }}
            openPlaid={(plaidCustomLink: string) => {
              setDialogOpen("addAsset", false);
              toast.info("Loading Plaid.. Hang tight!");
              setPlaidCustomLinkNameAndResetToken(plaidCustomLink);
            }}
            openSnaptrade={(broker: string) => {
              setDialogOpen("addAsset", false);
              toast.info("Loading SnapTrade.. Hang tight!");
              if (snaptrade) snaptrade.generateRedirectLink(broker);
            }}
            openFlanks={(bankId: string, bankName: string) => {
              setDialogOpen("addAsset", false);
              toast.info("Flanks' login screen will appear in a new tab.", {
                action: {
                  label: "Open",
                  onClick: () => {
                    window.open(
                      `/flanks-link?bankId=${bankId}&bankName=${bankName}`,
                      "_blank",
                    );
                  },
                },
              });
              window.open(
                `/flanks-link?bankId=${bankId}&bankName=${bankName}`,
                "_blank",
              );
            }}
            openSGFinDex={(orgName: string) => {
              setDialogOpen("addAsset", false);
              window.open(`/sgfindex-link?orgName=${orgName}`, "_blank");
            }}
            closeDialog={() => setDialogOpen("addAsset", false)}
            openEmereg={(customLink: string) => {
              setDialogOpen("addAsset", false);
              setOpenEmereg(true, customLink, undefined);
            }}
            openCustom={(customLink: string) => {
              setDialogOpen("addAsset", false);
              if (customLink === "endowus") {
                // Let's open Endowus Dialog here.
                setOpenEndowus(true);
              } else if (customLink === "stashaway") {
                setOpenStashAway(true);
              }
            }}
          />
        )}
      </CommandDialog>
    </>
  );
}
