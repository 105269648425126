import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { showHideColumns } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { VisibilityState } from "@tanstack/react-table";
import { Eye, EyeOff, MoreVertical } from "lucide-react";

type ColumnVisibilityPopupProps = {
  visibleColumns: VisibilityState;
  mandatoryVisibleColumns: string[];
  toggleColumnVisibility: (id: string) => void;
  hideAll: () => void;
  showAll: () => void;
  icons: Record<string, React.ElementType>;
};

export function ColumnVisibilityPopup({
  visibleColumns,
  mandatoryVisibleColumns,
  toggleColumnVisibility,
  hideAll,
  showAll,
  icons,
}: ColumnVisibilityPopupProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" variant="ghost">
          <MoreVertical size={14} className="text-gray-600" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="mr-10 w-60 border border-gray-200 bg-white">
        <div className="">
          <div className="flex flex-row items-center justify-between">
            <span className="font-medium text-gray-700">Columns</span>
          </div>

          <div className="my-2 flex flex-row items-center justify-between gap-y-1">
            <span className="text-xs font-medium text-gray-500">
              Shown in table
            </span>
            <button
              className="text-xs font-medium text-orange-500"
              onClick={hideAll}
            >
              Hide all
            </button>
          </div>

          <ul>
            {Object.entries(visibleColumns)
              .filter(([_, show]) => show === true)
              .map(([id]) => {
                const iconName = showHideColumns[id]?.icon ?? "Menu";
                const LucideIcon = icons[iconName as keyof typeof icons];

                return (
                  <li
                    key={id}
                    className={cn(
                      "flex flex-row items-center justify-between p-1 hover:bg-gray-100 rounded-lg",
                      !mandatoryVisibleColumns.includes(id) &&
                        "hover:cursor-pointer",
                    )}
                    onClick={() => {
                      if (!mandatoryVisibleColumns.includes(id)) {
                        toggleColumnVisibility(id);
                      }
                    }}
                  >
                    <span className="flex flex-row items-center gap-x-1 text-sm font-normal text-gray-700">
                      <LucideIcon size={12} />
                      {showHideColumns[id]?.label}
                    </span>
                    {mandatoryVisibleColumns.includes(id) ? (
                      <Eye size={16} className="text-gray-300" />
                    ) : (
                      <Eye size={16} className="text-gray-700" />
                    )}
                  </li>
                );
              })}
          </ul>

          {Object.entries(visibleColumns).some(([_, visible]) => !visible) && (
            <>
              <div className="my-2 flex flex-row items-center justify-between gap-y-1">
                <span className="text-xs font-medium text-gray-500">
                  Hidden in table
                </span>
                <button
                  className="text-xs font-medium text-orange-500"
                  onClick={showAll}
                >
                  Show all
                </button>
              </div>

              <ul>
                {Object.entries(visibleColumns)
                  .filter(([_, visible]) => !visible)
                  .map(([id]) => {
                    const iconName = showHideColumns[id]?.icon ?? "Menu";
                    const LucideIcon = icons[iconName as keyof typeof icons];

                    return (
                      <li
                        key={id}
                        className="flex flex-row items-center justify-between py-1 hover:cursor-pointer"
                        onClick={() => toggleColumnVisibility(id)}
                      >
                        <span className="flex flex-row items-center gap-x-1 text-sm font-normal text-gray-700">
                          <LucideIcon size={12} /> {showHideColumns[id]?.label}
                        </span>
                        <EyeOff size={16} className="text-gray-300" />
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
