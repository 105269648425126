import axios from "@/lib/axios";
import config from "@/lib/config";
import {
  GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
  GET_SYNC_TASKS_QUERY_KEY,
} from "@/lib/constants";
import { queryClient } from "@/lib/queryClient";
import { AssetLiabilityV2 } from "@/lib/types";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { usePeekUser } from "./usePeekUser";

export function useGetAssetLiabilitiesAccountsQuery() {
  const { getToken } = useAuth();
  const peekUserContext = usePeekUser();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
    queryFn: () => getAssetLiabilitiesAccounts(getToken),
    enabled: peekUserContext.state === "SUCCESS",
  });

  return { assetLiabilitiesAccounts: data ?? [], isLoading, isError, error };
}

async function getAssetLiabilitiesAccounts(
  getToken: (options?: { template: string }) => Promise<string | null>,
) {
  const token = await getToken();
  if (!token) throw new Error("User not authenticated");
  const { data } = await axios.get<AssetLiabilityV2[]>(
    `${config.backendUrl}/user/asset-liabilities-accounts`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  // TODO handle this better using nested query refreshes
  await queryClient.refetchQueries({
    queryKey: [GET_SYNC_TASKS_QUERY_KEY],
  });
  return data;
}
