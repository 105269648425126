import { useState } from "react";
import { DateRange } from "react-day-picker";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ListFilter } from "lucide-react";
import { DateRangePicker } from "./DateRangePicker";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

type FiltersProps = {
  filters: {
    account: number[];
    assetClass: string[];
    currency: string[];
    tag: string[];
  };
  setFilters: (value: {
    account: number[];
    assetClass: string[];
    currency: string[];
    tag: string[];
  }) => void;
  accountFilters: { label: string; value: number }[];
  assetClassFilters: { label: string; value: string }[];
  currencyFilters: { label: string; value: string }[];
  tagFilters: { label: string; value: string }[];
  date: DateRange | undefined;
  setDate: (value: DateRange | undefined) => void;
  grouping: "day" | "week" | "month";
  setGrouping: (value: "day" | "week" | "month") => void;
};

export function Filters({
  filters,
  setFilters,
  accountFilters,
  assetClassFilters,
  currencyFilters,
  tagFilters,
  date,
  setDate,
  grouping,
  setGrouping,
}: FiltersProps) {
  const tabList = [
    { value: "assetClass", label: "Asset Class" },
    { value: "account", label: "Account" },
    { value: "currency", label: "Currency" },
    { value: "tag", label: "Tag" },
  ];
  const [currentTab, setCurentTab] = useState("assetClass");

  let filterList: { label: string; value: string | number }[] = [];
  let textLabel = "";

  switch (currentTab) {
    case "assetClass":
      filterList = assetClassFilters;
      textLabel = "asset classes";
      break;
    case "account":
      filterList = accountFilters;
      textLabel = "accounts";
      break;
    case "currency":
      filterList = currencyFilters;
      textLabel = "currencies";
      break;
    case "tag":
      filterList = tagFilters;
      textLabel = "tags";
      break;
  }

  const handleFilterChange = (value: string | number) => {
    const currentFilter = filters[currentTab as keyof typeof filters] as (
      | string
      | number
    )[];
    if (currentFilter.includes(value)) {
      setFilters({
        ...filters,
        [currentTab]: currentFilter.filter((v) => v !== value),
      });
    } else {
      setFilters({
        ...filters,
        [currentTab]: [...currentFilter, value],
      });
    }
  };

  const filterBadges = {
    assetClass: assetClassFilters.filter(
      (item) => !filters.assetClass.includes(item.value),
    ),
    account: accountFilters.filter(
      (item) => !filters.account.includes(item.value),
    ),
    currency: currencyFilters.filter(
      (item) => !filters.currency.includes(item.value),
    ),
    tag: tagFilters.filter((item) => !filters.tag.includes(item.value)),
  };

  const hanndleAllButton = () => {
    if (
      filters[currentTab as keyof typeof filters].length === filterList.length
    ) {
      setFilters({
        ...filters,
        [currentTab]: [],
      });
    } else {
      setFilters({
        ...filters,
        [currentTab]: filterList.map((filter) => filter.value),
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-between bg-gray-50 py-8 lg:flex-row">
      <h3 className="text-2xl text-gray-700">My Performance</h3>
      <div className="flex flex-col items-center gap-y-2 lg:flex-row lg:gap-x-1">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <ListFilter size={12} className="mr-1" /> Filters
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="p-0">
            <>
              <div className="flex flex-row items-center gap-x-2 border-b-[1px] border-b-gray-100 px-2 py-3">
                <span className="text-sm text-gray-500">Filters Applied:</span>
                <div className="flex flex-row gap-x-2">
                  <FilterBadges
                    filterBadges={filterBadges.assetClass}
                    label="Asset Class"
                  />
                  <FilterBadges
                    filterBadges={filterBadges.account}
                    label="Account"
                  />
                  <FilterBadges
                    filterBadges={filterBadges.currency}
                    label="Currency"
                  />
                  <FilterBadges filterBadges={filterBadges.tag} label="Tag" />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col gap-y-1 border-r-[1px] border-r-gray-200 p-4">
                  {tabList.map((tab) => (
                    <Button
                      key={tab.value}
                      size="sm"
                      variant="ghost"
                      className={cn(
                        "justify-start",
                        currentTab === tab.value && "bg-gray-100",
                      )}
                      onClick={() => setCurentTab(tab.value)}
                    >
                      {tab.label}
                    </Button>
                  ))}
                </div>

                <div className="px-8 py-4">
                  <span className="mb-4 block text-sm text-gray-500">
                    Select {textLabel} to include in your calculations
                  </span>

                  <button onClick={hanndleAllButton} className="mb-2">
                    All
                  </button>

                  <div>
                    {filterList.map((filter, index) => (
                      <div
                        className="flex h-9 items-center space-x-2"
                        key={index}
                      >
                        <Checkbox
                          id={filter.value.toString()}
                          checked={
                            !(
                              filters[currentTab as keyof typeof filters] as (
                                | string
                                | number
                              )[]
                            ).includes(filter.value)
                          }
                          onClick={() => handleFilterChange(filter.value)}
                        />
                        <label
                          htmlFor={filter.value.toString()}
                          className="text-sm font-medium leading-none hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {filter.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          </DropdownMenuContent>
        </DropdownMenu>

        <DateRangePicker date={date} setDate={setDate} />

        <Select
          onValueChange={(value) =>
            setGrouping(value as "day" | "week" | "month")
          }
          value={grouping}
        >
          <SelectTrigger>
            <SelectValue placeholder="Group By" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="day">Daily</SelectItem>
            <SelectItem value="week">Weekly</SelectItem>
            <SelectItem value="month">Monthly</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

type FilterBadgesProps = {
  filterBadges: { label: string; value: string | number }[];
  label: string;
};

const FilterBadges = ({ filterBadges, label }: FilterBadgesProps) =>
  filterBadges.length > 0 && (
    <HoverCard openDelay={300}>
      <HoverCardTrigger>
        <span className="rounded-sm bg-gray-100 p-1 text-sm font-normal text-gray-600 hover:cursor-pointer">
          {label}:{" "}
          <span className="font-medium text-gray-700">
            {filterBadges[0].label}{" "}
            {filterBadges.length > 1 && `, +${filterBadges.length}...`}
          </span>
        </span>
      </HoverCardTrigger>
      <HoverCardContent>
        <div className="flex flex-col gap-y-2">
          {filterBadges.map((badge) => (
            <span key={badge.value} className="break-words text-sm">
              {badge.label}
            </span>
          ))}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
