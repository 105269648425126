import target from "/images/peek-home-page/target.svg";
import financialGoal from "/images/peek-home-page/financial-goal.svg";
import Loader from "@/components/common/Loader";
import { Input } from "./Input";
import { DisplayValue } from "@/components/ui/display-value";
import { Progress } from "@/components/ui/progress";
import { useCurrency } from "@/hooks/useCurrency";
import { useGetGoals, useUpdateGoal } from "@/hooks/useGoals";
import { useGetProfileQuery } from "@/hooks/useProfile";
import { Calendar as CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Popover, PopoverContent } from "../ui/popover";
import { PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";

export const FinancialGoal = ({ netWorth }: { netWorth: number }) => {
  const currencyContext = useCurrency();
  const { data: goals, status: goalStatus } = useGetGoals();
  const {
    mutateAsync: updateTargetNetWorth,
    isPending: isPendingTargetNetWorth,
  } = useUpdateGoal();
  const { mutateAsync: updateTargetDate, isPending: isPendingTargetDate } =
    useUpdateGoal();
  const { data: profile, status: profileStatus } = useGetProfileQuery();

  const goal = goals?.[0];
  if (
    goalStatus !== "success" ||
    profileStatus !== "success" ||
    currencyContext.state !== "SUCCESS" ||
    !goal
  ) {
    return null;
  }
  const targetDate = new Date(goal.targetDate);
  const rawPreferredCurrency = profile?.preferredCurrency ?? "USD";
  const targetNetWorth = Number(goal?.targetNetWorth) ?? 0;
  const convertedEstimatedNetWorth = Math.ceil(
    currencyContext.convertValueToSelectedCurrency(
      netWorth,
      rawPreferredCurrency,
      currencyContext.currency,
    ),
  );
  const convertedTargetNetWorth = Math.ceil(
    currencyContext.convertValueToSelectedCurrency(
      targetNetWorth,
      rawPreferredCurrency,
      currencyContext.currency,
    ),
  );

  const remainingToTarget =
    convertedTargetNetWorth - convertedEstimatedNetWorth;

  const percentage =
    convertedTargetNetWorth === 0
      ? 0
      : Math.min(
          (100 * convertedEstimatedNetWorth) / convertedTargetNetWorth,
          100,
        );

  return (
    <div className="flex flex-col items-center gap-4 rounded-sm border border-gray-200 bg-white p-5">
      <div className="flex flex-col items-center gap-2">
        <img src={target} alt="Target" className="h-16 w-16" />
        <h3 className="font-semibold text-gray-700">My Financial Goal</h3>
        <p className="text-gray-700">
          {percentage === 0
            ? "Great start! Keep pushing towards your goal."
            : percentage < 25
              ? "Great start! Keep pushing towards your goal."
              : percentage < 50
                ? "You're making progress! Keep up the good work."
                : percentage < 75
                  ? "You're more than halfway there! The finish line is in sight."
                  : percentage < 100
                    ? "Almost there! You're so close to achieving your goal."
                    : "Congratulations! You've reached your financial goal!"}
        </p>
      </div>
      <div className="flex items-center gap-2 self-stretch rounded-lg border border-offwhite bg-beige p-4 text-sm font-semibold">
        <img src={financialGoal} alt="Scout" className="h-6 w-6" />I want to
        achieve net worth of{" "}
        {isPendingTargetNetWorth ? (
          <Loader />
        ) : (
          <Input
            // use the key so that the component value field updates correctly when the currency change
            key={`${currencyContext.currency}-${targetNetWorth}`}
            name="inflow"
            value={convertedTargetNetWorth}
            onUpdate={(_key, value) => {
              const convertedNewValue =
                currencyContext.convertValueToSelectedCurrency(
                  value,
                  currencyContext.currency,
                  rawPreferredCurrency,
                );
              updateTargetNetWorth({
                ...goal,
                targetNetWorth: convertedNewValue,
              });
            }}
            renderAs={`${currencyContext.currency} ${convertedTargetNetWorth.toLocaleString()}`}
          />
        )}{" "}
        by{" "}
        {isPendingTargetDate ? (
          <Loader />
        ) : (
          <Popover>
            <PopoverTrigger asChild>
              <div className="inline-block hover:cursor-pointer">
                <span className="border-b-offset-2 mr-1 border-b-2 border-dashed border-darkCyan text-darkCyan">
                  {goal.targetDate
                    ? format(goal.targetDate, "dd MMM yyyy")
                    : "Pick a date"}
                </span>
                <CalendarIcon color="darkCyan" size={14} className="inline" />
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto bg-white p-0">
              <Calendar
                mode="single"
                disabled={(date) => date <= new Date()}
                selected={targetDate}
                onSelect={(date) => {
                  if (date) {
                    updateTargetDate({
                      ...goal,
                      targetNetWorth: Number(goal.targetNetWorth),
                      targetDate: date,
                    });
                  }
                }}
                defaultMonth={targetDate}
                today={new Date()}
              />
            </PopoverContent>
          </Popover>
        )}
      </div>
      <div className="flex flex-col items-start gap-2 self-stretch rounded-lg border border-gray-200 p-4">
        <div className="text-sm font-semibold text-gray-700">Goal progress</div>
        <div>
          <DisplayValue
            currency={currencyContext.currency}
            value={convertedEstimatedNetWorth || 0}
            size="large"
          />
        </div>
        <Progress
          value={percentage}
          className="h-2 rounded-md"
          progressBarColor="bg-cyan"
        />
        <div className="flex w-full justify-between text-sm text-gray-500">
          <span>{Math.floor(percentage)}% Completed</span>
          <span>
            {currencyContext.currency} {remainingToTarget.toLocaleString()} to
            go
          </span>
        </div>
      </div>
    </div>
  );
};
