import { Skeleton } from "@/components/ui/skeleton";
import InfoCard from "@/components/ui/info-card";
import { useCurrency } from "@/hooks/useCurrency";
import { useMemo, useState } from "react";
import { HomeChart } from "@/components/PeekHomePage/HomeChart";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { UserAggregatedMetric } from "@/lib/types";
import { useGetUserAggregatedMetrics } from "@/hooks/useGetUserAggregatedMetrics";
import {} from "@/lib/utils";
import { Insights } from "@/components/PeekHomePage/Insights";
import { usePeekUser } from "@/hooks/usePeekUser";
import { useDialog } from "@/hooks/useDialog";
import { Button } from "@/components/ui/button";
import { Settings } from "lucide-react";
import { SettingSheet } from "@/components/PeekHomePage/NWCalculationSetting/SettingSheet";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";

const today = new Date();

export function PeekHomePage() {
  const [grouping, setGrouping] = useState<"day" | "week" | "month">("day");
  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();
  const {
    data: rawUserAggregatedMetrics,
    status: userAggregatedMetricsStatus,
    error: userAggregatedMetricsError,
  } = useGetUserAggregatedMetrics();
  const currencyContext = useCurrency();
  const peekUser = usePeekUser();

  const { setDialogOpen } = useDialog();

  const computedAggregatedMetrics = useMemo(() => {
    if (!rawUserAggregatedMetrics) return [];
    const filteredUserAggregatedMetrics = rawUserAggregatedMetrics.map(
      (metric) => {
        if (
          metric.currency === currencyContext.currency ||
          currencyContext.state !== "SUCCESS"
        )
          return metric;
        return {
          ...metric,
          liquidTotalNetWorth: currencyContext.convertValueToSelectedCurrency(
            metric.liquidTotalNetWorth,
            metric.currency,
          ),
          illiquidTotalNetWorth: currencyContext.convertValueToSelectedCurrency(
            metric.illiquidTotalNetWorth,
            metric.currency,
          ),
          totalNetWorth: currencyContext.convertValueToSelectedCurrency(
            metric.totalNetWorth,
            metric.currency,
          ),
          investibleCash: currencyContext.convertValueToSelectedCurrency(
            metric.investibleCash,
            metric.currency,
          ),
        };
      },
    );

    if (grouping === "day") return filteredUserAggregatedMetrics;
    if (grouping === "week") {
      const weeklyData: UserAggregatedMetric[] = [];
      let lastDay = new Date(filteredUserAggregatedMetrics[0].date).getDay();
      for (
        let index = 0;
        index < filteredUserAggregatedMetrics.length;
        index++
      ) {
        const currentData = filteredUserAggregatedMetrics[index];
        if (!currentData) continue;
        const currentDay = new Date(currentData.date).getDay();
        if (currentDay < lastDay) {
          weeklyData.push(filteredUserAggregatedMetrics[index - 1]);
        }
        lastDay = currentDay;
      }
      const lastData = filteredUserAggregatedMetrics.at(-1);
      if (lastData) {
        weeklyData.push(lastData);
      }
      return weeklyData;
    }

    if (grouping === "month") {
      const montlyData: UserAggregatedMetric[] = [];
      let lastMonth = new Date(
        filteredUserAggregatedMetrics[0].date,
      ).getMonth();
      for (
        let index = 0;
        index < filteredUserAggregatedMetrics.length;
        index++
      ) {
        const currentData = filteredUserAggregatedMetrics[index];
        if (!currentData) continue;
        const currentMonth = new Date(currentData.date).getMonth();
        if (currentMonth !== lastMonth) {
          lastMonth = currentMonth;
          montlyData.push(filteredUserAggregatedMetrics[index - 1]);
        }
      }
      const lastData = filteredUserAggregatedMetrics.at(-1);
      if (lastData) {
        montlyData.push(lastData);
      }
      return montlyData;
    }
  }, [rawUserAggregatedMetrics, grouping, currencyContext]);

  if (
    assetLiabilitiesAccountsContext.state === "LOADING" ||
    userAggregatedMetricsStatus === "pending"
  )
    return <Skeleton className="h-screen w-full" />;

  if (assetLiabilitiesAccountsContext.state === "ERROR")
    throw new Error(assetLiabilitiesAccountsContext.message);

  if (userAggregatedMetricsStatus === "error")
    throw new Error(userAggregatedMetricsError.message);

  if (
    assetLiabilitiesAccountsContext.state !== "SUCCESS" ||
    currencyContext.state !== "SUCCESS"
  )
    return null;

  const lastMetric = rawUserAggregatedMetrics.at(-1);

  return (
    <div className="my-8 flex flex-col gap-y-4">
      <div className="flex flex-col items-center justify-between gap-y-2 lg:flex-row">
        <h3 className="text-2xl text-gray-700">Overview</h3>
      </div>

      {computedAggregatedMetrics && (
        <div className="mt-5 flex flex-col gap-4 rounded-sm border border-gray-200 bg-white p-5">
          {
            <div className="grid grid-cols-1 items-stretch gap-x-2 gap-y-2 md:grid-cols-1 lg:grid-cols-3">
              <InfoCard
                options={[
                  {
                    label: "Net Worth",
                    value: lastMetric
                      ? currencyContext.convertValueToSelectedCurrency(
                          lastMetric.totalNetWorth,
                          lastMetric.currency,
                        )
                      : 0,
                  },
                ]}
                currency={currencyContext.currency}
                context={
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setDialogOpen("networth-setting", true)}
                  >
                    <Settings size={16} />
                  </Button>
                }
              />
              <InfoCard
                options={[
                  {
                    label: "Liquid Net Worth",
                    value: lastMetric
                      ? currencyContext.convertValueToSelectedCurrency(
                          lastMetric.liquidTotalNetWorth,
                          lastMetric.currency,
                        )
                      : 0,
                  },
                ]}
                currency={currencyContext.currency}
                context={
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() =>
                      setDialogOpen("liquid-networth-setting", true)
                    }
                  >
                    <Settings size={16} />
                  </Button>
                }
              />
              <InfoCard
                options={[
                  {
                    label: "Investible Cash",
                    value: lastMetric
                      ? currencyContext.convertValueToSelectedCurrency(
                          lastMetric.investibleCash,
                          lastMetric.currency,
                        )
                      : 0,
                  },
                ]}
                currency={currencyContext.currency}
              />
            </div>
          }
          {computedAggregatedMetrics.length > 0 && (
            <>
              <div className="ml-auto">
                <Select
                  onValueChange={(value) =>
                    setGrouping(value as "day" | "week" | "month")
                  }
                  value={grouping}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Group By" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="day">Daily</SelectItem>
                    <SelectItem value="week">Weekly</SelectItem>
                    <SelectItem value="month">Monthly</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <HomeChart
                snapshots={computedAggregatedMetrics}
                endDate={today}
                grouping={grouping}
              />
            </>
          )}
        </div>
      )}

      {peekUser.state === "SUCCESS" &&
        peekUser.user.customFields.finishedOnboarding && (
          <Insights
            totalNetWorth={lastMetric?.totalNetWorth}
            peekUser={peekUser}
          />
        )}

      <SettingSheet data={assetLiabilitiesAccountsContext.data} />
    </div>
  );
}
