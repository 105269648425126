import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Button } from "../ui/button";
import axios from "axios";
import { toast } from "sonner";
import config from "@/lib/config";
import { useAuth } from "@clerk/clerk-react";
import { queryClient } from "@/lib/queryClient";
import {
  GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
  GET_SYNC_TASKS_QUERY_KEY,
} from "@/lib/constants";
import { useSyncTasks } from "@/hooks/useSyncTasks";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function IntegrationSettings() {
  const { getToken } = useAuth();
  const { syncTasks } = useSyncTasks();

  const handleDelete = async (accountId: number, accountName: string) => {
    const token = await getToken();
    const promise = axios
      .delete(
        `${config.integrationsUrl}/user/integrated-account/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        await Promise.all([
          queryClient.refetchQueries({
            queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
          }),
          queryClient.refetchQueries({
            queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
          }),
        ]);

        await queryClient.refetchQueries({
          queryKey: [GET_SYNC_TASKS_QUERY_KEY],
        });
      });
    toast.promise(promise, {
      loading: `Disconnecting ${accountName}...`,
      success: `Disconnected ${accountName}`,
      error: `Failed to disconnect ${accountName}. Contact support if this continues.`,
      duration: 3000,
      action: {
        label: "Support",
        onClick: () => {
          const email = "support@peek.money";
          const subject = encodeURIComponent(
            "[Request] Disconnect integration",
          );
          const body = encodeURIComponent(
            `Hello, I would like to disconnect my account: ${accountName}. Please assist me with this request.`,
          );
          window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
        },
      },
    });
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-xl font-medium">Integrations</h3>
      {syncTasks.length === 0 ? (
        <div className="my-6 flex-1 space-y-4">
          <p>No integrations found</p>
        </div>
      ) : (
        <div className="my-6 flex-1 space-y-4">
          <Table>
            <TableHeader>
              <TableRow className="text-gray-500">
                <TableHead>Account Name</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {syncTasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.account.name}</TableCell>
                  <TableCell className="text-right">
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() =>
                        handleDelete(task.account.id, task.account.name)
                      }
                    >
                      Disconnect
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}
