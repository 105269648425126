import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import StashAwayAPIClient from "./StashAwayAPIClient";
import { useEffect, useMemo, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import Loader from "@/components/common/Loader";
import { toast } from "sonner";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import stashAway from "/images/automaticConnections/stashaway.svg";
import { useAuth } from "@clerk/clerk-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import { usePostHog } from "posthog-js/react";
import { Account } from "@/lib/types";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useLocalStorage } from "@/hooks/useLocalStorage";

const schema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(1, "Password is required"),
  country: z.enum(["sg", "my"]),
});

type FormData = z.infer<typeof schema>;

export default function StashAwayLoginDialog({
  open,
  setOpen,
  accountId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  accountId?: number;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      country: "sg",
    },
  });
  const [showOtp, setShowOtp] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");

  const { getToken } = useAuth();
  const client = useMemo(() => new StashAwayAPIClient(), []);
  const posthog = usePostHog();
  const [storedCountry, setStoredCountry] = useLocalStorage<"sg" | "my">(
    "stashAwayCountry",
    "sg",
  );
  const [country, setCountry] = useState<"sg" | "my">(storedCountry);

  useEffect(() => {
    client.setCountry(country);
    setStoredCountry(country);
  }, [country, client, setStoredCountry]);

  useEffect(() => {
    // Clear error message whenever there is a loading
    if (loading) {
      setMessage("");
    }
  }, [loading]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    try {
      await client.setClerkHeaders(getToken); // set the headers with clerk's auth token
      const response = await client.login(data.email, data.password);
      if (
        response.data.id === "server.errors.auth.2fa_required" &&
        response.data.message === "2FA"
      ) {
        const otpResponse = await client.generateOtp();
        if (otpResponse) {
          setShowOtp(true);
          setLoading(false);
        }
      } else if (response.data.accessToken) {
        importAssetsFromStashAway();
      }
    } catch (error) {
      setLoading(false);
      setShowLogin(true);
      setMessage("Invalid email or password. Please try again.");
    }
  };

  const handleVerify = async () => {
    setLoading(true);
    client
      .verifyOtp(otpValue || "")
      .then(() => {
        setLoading(false);
        setShowLogin(false);
        setShowOtp(false);
        importAssetsFromStashAway();
      })
      .catch((error) => {
        setLoading(false);
        setShowOtp(false);
        setShowLogin(true);
        setMessage(`${error}`);
      });
  };

  const importAssetsFromStashAway = async () => {
    setLoading(true);
    try {
      if (accountId) {
        await client.updateStashAwayDataForAccount(accountId);
        posthog?.capture("stashaway_updated", {
          accountId,
        });
      } else {
        const { status, data } = await client.importNewStashAwayData();
        if (status === 200)
          posthog?.capture("stashaway_imported", {
            accounts: data.map((account: Account) => account.id),
          });
      }

      await Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
        }),
      ]);
      toast.success("Data imported successfully from StashAway");
      setLoading(false);
      setShowLogin(true);
      setShowOtp(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setShowLogin(true);
      setMessage(`${error}`);
    }
  };

  const faq = [
    {
      question: "How frequently will my data be synced?",
      answer:
        "Your data is synced on-demand for maximum security. We prioritize your privacy by not storing your credentials, which means automatic background syncing isn't possible.",
    },
    {
      question: "Are my credentials saved?",
      answer:
        "No, your credentials are not saved on Peek's servers. Your temporary StashAway access key is kept in your browser.",
    },
    {
      question: "Can Peek touch my money on StashAway?",
      answer:
        "No, Peek only has read-only access to your StashAway data. That means we cannot create withdrawal requests or funds transfers on your behalf.",
    },
    {
      question: "Is the data on Peek encrypted?",
      answer:
        "Your financial data is encrypted at-rest and in-transit. This means that your data is protected from unauthorized access. Even if the database files were to be stolen, the thieves wouldn't be able to decrypt the contents without the decryption keys. In addition, the decryption keys are secured by Amazon Web Service's Key Management Service, which makes use of industry standard hardware security modules. In addition, data transfers between your browser and our servers are also encrypted with secured HTTPS connections, so your data will still be secured even if it was intercepted by a hacker.",
    },
  ];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex flex-col gap-8">
            <img
              src={stashAway}
              alt="StashAway"
              className="size-16 rounded-md bg-offwhite p-1"
            />
            Login to StashAway
          </DialogTitle>
          <DialogDescription>
            Enter your credentials to access your account.
          </DialogDescription>
        </DialogHeader>
        {!showOtp && showLogin ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex flex-col gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  data-1p-ignore
                  autoComplete="off"
                  className="col-span-3"
                  id="email"
                  placeholder="Enter your email or username"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="col-span-4 text-sm text-red-500">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  data-1p-ignore
                  autoComplete="off"
                  className="col-span-3"
                  id="password"
                  placeholder="Enter your password"
                  type="password"
                  {...register("password")}
                />
                {errors.password && (
                  <span className="col-span-4 text-sm text-red-500">
                    {errors.password.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <Label htmlFor="country">Country</Label>
                <Select
                  onValueChange={(value) => setCountry(value as "sg" | "my")}
                  defaultValue={country}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select country" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="sg">🇸🇬 Singapore</SelectItem>
                    <SelectItem value="my">🇲🇾 Malaysia</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {message && (
                <span className="col-span-4 text-sm text-red-500">
                  {message}
                </span>
              )}
              {!loading ? (
                <Button className="w-full" type="submit">
                  Login
                </Button>
              ) : (
                <Loader />
              )}
            </div>
          </form>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <Label htmlFor="otp">Enter your OTP</Label>
            <InputOTP
              value={otpValue}
              onChange={(value) => setOtpValue(value)}
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            >
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            {message && (
              <span className="col-span-4 text-sm text-red-500">{message}</span>
            )}
            {!loading ? (
              <Button type="submit" onClick={() => handleVerify()}>
                Verify
              </Button>
            ) : (
              <Loader />
            )}
          </div>
        )}
        <Separator />
        <Accordion type="single" collapsible>
          {faq.map((item, index) => (
            <AccordionItem
              key={index}
              value={index.toString()}
              className="text-sm"
            >
              <AccordionTrigger className="text-left text-gray-700">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-500">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        <p className="text-xs italic text-gray-500">
          Disclaimer: This integration is a custom solution and is not
          officially endorsed by StashAway.
        </p>
      </DialogContent>
    </Dialog>
  );
}
