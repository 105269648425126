import {
  Banknote,
  Bitcoin,
  Bot,
  Building,
  Building2,
  CarFront,
  ClipboardPlus,
  Coins,
  CreditCard,
  FileText,
  Gem,
  HandCoins,
  Home,
  ImageIcon,
  Landmark,
  LucideIcon,
  Palette,
  Palmtree,
  Scale,
  TrendingUp,
} from "lucide-react";

import { CustomSearchItems } from "./types";

export const saveAssetTypeAsName: string[] = [
  "GOLD",
  "SILVER",
  "PALLADIUM",
  "PLATINUM",
  "ESOP",
  "RSU",
  "SHARE",
];

export const noFormatAssetType: string[] = ["ESOP", "RSU", "SHARE"];

export const searchItems: Record<
  CustomSearchItems,
  {
    title: string;
    description?: string;
    icon: LucideIcon;
    iconBackgroundColor: string;
    iconColor?: string;
    accountNameInputLabel?: string;
    assetNameInputPlaceholder?: string;
  }
> = {
  BANK_ACCOUNT: {
    title: "Cash & Cash Equivalents",
    description:
      "Cash, Current, Checking, Savings, Bank Accounts, Money Market Funds, and more",
    icon: Landmark,
    iconBackgroundColor: "bg-green-100",
    accountNameInputLabel: "Name of your bank (e.g. DBS Bank)",
    assetNameInputPlaceholder: "Account name or account number (optional)",
  },
  STOCK: {
    title: "Stocks",
    description: "Ownership in publicly traded companies from around the world",
    icon: TrendingUp,
    iconBackgroundColor: "bg-pink-100",
    accountNameInputLabel: "Name of your brokerage (e.g. Interactive Brokers)",
  },
  ETF: {
    title: "ETFs",
    description:
      "Diversified baskets of securities that track various global markets and sectors",
    icon: Scale,
    iconBackgroundColor: "bg-violet-100",
    accountNameInputLabel: "Name of your brokerage (e.g. Interactive Brokers)",
  },
  TOKEN: {
    title: "Cryptocurrency",
    description: "Digital or virtual currency.",
    icon: Bitcoin,
    iconBackgroundColor: "bg-teal-100",
    accountNameInputLabel:
      "Name of your crypto exchange or wallet (e.g. Coinbase, Metamask)",
  },
  MUTUAL_FUND: {
    title: "Mutual Funds",
    description:
      "Professionally managed portfolios investing in global stocks, bonds, and other securities",
    icon: Coins,
    iconBackgroundColor: "bg-fuchsia-100",
    accountNameInputLabel: "Name of your brokerage (e.g. Interactive Brokers)",
  },
  BOND: {
    title: "Bonds",
    description:
      "Debt securities issued by governments and corporations globally",
    icon: FileText,
    iconBackgroundColor: "bg-rose-100",
    accountNameInputLabel: "Name of your brokerage (e.g. Interactive Brokers)",
  },
  RETIREMENT_FUND: {
    title: "Retirement Funds",
    description: "CPF, Provident Funds",
    icon: Palmtree,
    iconBackgroundColor: "bg-amber-100",
    accountNameInputLabel: "Name of your retirement fund (e.g. CPF)",
  },
  ROBO_ACCOUNT: {
    title: "Robo Accounts",
    icon: Bot,
    iconBackgroundColor: "bg-yellow-100",
    accountNameInputLabel: "Name of your robo advisor (e.g. Wealthfront)",
  },
  REAL_ESTATE: {
    title: "Real Estate",
    description: "Properties",
    icon: Home,
    iconBackgroundColor: "bg-orange-100",
    accountNameInputLabel:
      "What is the type of your property? (e.g. Condo, Landed)",
    assetNameInputPlaceholder: "Address of your property",
  },
  REIT: {
    title: "REITs",
    description:
      "Publicly traded companies that own and operate income-generating real estate worldwide",
    icon: Building,
    iconBackgroundColor: "bg-emerald-100",
    accountNameInputLabel: "Name of your brokerage (e.g. Interactive Brokers)",
  },
  NFT: {
    title: "NFTs",
    description: "Non-fungible tokens",
    icon: ImageIcon,
    iconBackgroundColor: "bg-slate-100",
    accountNameInputLabel: "Name of your NFT wallet",
  },
  INSURANCE: {
    title: "Insurance",
    description:
      "Health, Disability, Critical illness, Life, Personal accident",
    icon: ClipboardPlus,
    iconBackgroundColor: "bg-indigo-100",
    accountNameInputLabel: "Name of your insurance provider (e.g. AIA)",
  },
  PRECIOUS_METAL: {
    title: "Precious Metals",
    description: "Gold, Silver, Precious metals",
    icon: Gem,
    iconBackgroundColor: "bg-purple-100",
    accountNameInputLabel: "Where are you holding this precious metal?",
  },
  PRIVATE_COMPANY: {
    title: "Private Companies",
    description:
      "Restricted Stock Units (RSU), Employee stock ownership (ESOP), Shares",
    icon: Building2,
    iconBackgroundColor: "bg-red-100",
    accountNameInputLabel: "Name of the company",
  },
  COLLECTIBLE: {
    title: "Collectibles",
    description: "Art, Wine, Watches",
    icon: Palette,
    iconBackgroundColor: "bg-cyan-100",
    accountNameInputLabel: "Where are you keeping this collectible?",
    assetNameInputPlaceholder:
      "Name of your collectible (e.g. Suzanne Deal Booth)",
  },
  ALTERNATIVE_INVESTMENT: {
    title: "Alternative Investments",
    description:
      "Private equity, Angel investments, Hedge funds, Private credit",
    icon: HandCoins,
    iconBackgroundColor: "bg-lime-100",
    accountNameInputLabel:
      "Name of the company / fund (e.g. Renaissance Technologies)",
    assetNameInputPlaceholder: "Name of your investment (e.g. Series A)",
  },
  OTHER: {
    title: "Other Assets",
    icon: Banknote,
    iconBackgroundColor: "bg-zinc-100",
    accountNameInputLabel: "Where are you holding this asset?",
    assetNameInputPlaceholder: "Name of your asset",
  },
  CREDIT_CARD: {
    title: "Credit Cards",
    description: "Credit cards",
    icon: CreditCard,
    iconBackgroundColor: "bg-sky-400",
    iconColor: "text-white",
    accountNameInputLabel: "Name of your credit card company (e.g. Citibank)",
    assetNameInputPlaceholder: "Name of your credit card (e.g. Prestige)",
  },
  LOAN: {
    title: "Loans",
    description: "Mortgages, Student loans, Lines of credit, Cars, etc",
    icon: CarFront,
    iconBackgroundColor: "bg-slate-400",
    iconColor: "text-white",
    accountNameInputLabel: "Name of your bank / lender",
  },
};
