import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import { useAuth } from "@clerk/clerk-react";
import googlyEyes from "/images/icon-googly-eyes.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Plans } from "./Plans";
import { usePostHog } from "posthog-js/react";
import debounce from "lodash/debounce";
import { doublePaymentDebounceDelay } from "@/lib/constants";
import { useDeleteUserMutation } from "@/hooks/useDeleteUserMutation";
import { useDialog } from "@/hooks/useDialog";
import { toast } from "sonner";
import { DeleteConfirmationDialog } from "../common/DeleteConfirmationDialog";
import { usePeekUser } from "@/hooks/usePeekUser";

interface SubscriptionEndedProps {
  className?: string;
}

export function SubscriptionEnded({ className }: SubscriptionEndedProps) {
  const { signOut } = useAuth();
  const [yearly, setYearly] = useState(false);
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { deleteUser, isPending } = useDeleteUserMutation();
  const { setDialogOpen } = useDialog();
  const peekUserContext = usePeekUser();

  const handleNavigationWithDebounce = debounce((path) => {
    navigate(path);
  }, doublePaymentDebounceDelay);

  const handleDeleteUser = async () => {
    if (isPending) return;
    try {
      await deleteUser();
      localStorage.removeItem("onboardingData");
      signOut();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user account");
    }
  };

  if (peekUserContext.state !== "SUCCESS") return null;

  const { email } = peekUserContext.user;

  return (
    <div
      className={cn(
        "bg-border/50 bg-offwhite flex h-full flex-col gap-4 rounded-lg p-4",
        className,
      )}
    >
      <div className="flex flex-col items-center justify-between gap-y-4 p-6">
        <img src={googlyEyes} alt="Eyes" className="h-24 w-24" />
        <h2 className="text-center font-libre text-4xl">
          Your subscription expired.
        </h2>
        <p className="text-center text-lg text-muted-foreground">
          Renew now to keep the insights flowing and your financial goals on
          track.
        </p>

        <Plans yearly={yearly} setYearly={setYearly} />

        <div className="flex items-center gap-x-4">
          <Button
            size="xl"
            className="uppercase"
            onClick={() =>
              handleNavigationWithDebounce(
                yearly ? "/checkout-annual" : "/checkout-monthly",
              )
            }
          >
            Upgrade Now
          </Button>
        </div>
        <div className="flex items-center gap-x-1">
          <Button
            variant="link"
            size="xl"
            className="text-red-500"
            disabled={isPending}
            onClick={() => setDialogOpen("deleteUser", true)}
          >
            Delete My Account & Data
          </Button>

          <Button
            size="xl"
            variant="link"
            onClick={() => {
              localStorage.removeItem("onboardingData");
              posthog?.capture("user_signed_out");
              posthog?.reset();
              signOut();
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>

      <DeleteConfirmationDialog
        name="deleteUser"
        confirmCallback={() => {
          setDialogOpen("deleteUser", false);
          handleDeleteUser();
        }}
        isPending={isPending}
        confirmationWord={email ?? "delete"}
        title="This action cannot be undone."
        description={
          <>
            <p>
              Deleting your account will permanently remove all your data from
              Peek and its partners, including:
            </p>
            <ul className="list-disc pl-5 pt-2">
              <li>Your account information</li>
              <li>Credentials for connected services</li>
              <li>Payment details stored with Stripe</li>
            </ul>
          </>
        }
      />
    </div>
  );
}
