import sanitize from "sanitize-html";

export const BlogPostContent = ({ content }: { content: string }) => {
  return (
    <div
      className="prose lg:prose-lg dark:prose-invert blog-content mx-auto max-w-none post-content"
      dangerouslySetInnerHTML={{
        __html: sanitize(content, {
          allowedTags: [
            "b",
            "i",
            "em",
            "strong",
            "a",
            "img",
            "h1",
            "h2",
            "h3",
            "code",
            "pre",
            "p",
            "li",
            "ul",
            "ol",
            "blockquote",
            "td",
            "th",
            "table",
            "tr",
            "tbody",
            "thead",
            "tfoot",
          ],
        }),
      }}
    ></div>
  );
};
