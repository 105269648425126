import { usePeekUser } from "@/hooks/usePeekUser";
import { useGetProfileQuery } from "@/hooks/useProfile";
import { useEffect, useState, useCallback } from "react";
import { useCurrency } from "@/hooks/useCurrency";
import { ProfileBanner } from "./ProfileBanner";
import { ProfileDetails } from "./Sections/ProfileDetails";
import { Profile } from "@/lib/types";
import FamilyDetails from "./Sections/FamilyDetails";
import { EmploymentSavings } from "./Sections/EmploymentSavings";
import { FinancialProfile } from "./Sections/FinancialProfile";
import { useUpdateProfileMutation } from "@/hooks/useUpdateProfileMutation";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { DeleteConfirmationDialog } from "@/components/common/DeleteConfirmationDialog";
import { useDialog } from "@/hooks/useDialog";
import { useDeleteUserMutation } from "@/hooks/useDeleteUserMutation";
import { useAuth } from "@clerk/clerk-react";
import { toast } from "sonner";
import { useSubscriptions } from "@/hooks/useSubscriptions";
import { Link } from "react-router-dom";

export function ProfileSettings() {
  const [formData, setFormData] = useState<Profile>({
    birthday: new Date(),
    gender: "male",
    maritalStatus: "",
    numberOfDependents: 0,
    occupation: "",
    income: 0,
    incomeFrequency: "annually",
    approximateSavingsRate: 0,
    financialLiteracy: "beginner",
    riskTolerance: "",
  });

  const { signOut } = useAuth();
  const peekUserContext = usePeekUser();
  const { currency } = useCurrency();
  const { setDialogOpen } = useDialog();

  const { isLoading, error, data: profile } = useGetProfileQuery();

  const { updateProfile, isPending } = useUpdateProfileMutation();

  const { deleteUser, isPending: deleteUserIsPending } =
    useDeleteUserMutation();

  const subscriptionsContext = useSubscriptions();

  const handleDeleteUser = async () => {
    if (deleteUserIsPending) return;
    try {
      await deleteUser();
      localStorage.removeItem("onboardingData");
      signOut();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user account");
    }
  };

  const updateFormData = useCallback((profile: Profile) => {
    setFormData((prevData) => ({
      ...prevData,
      birthday: profile.birthday ?? prevData.birthday,
      gender: profile.gender ?? prevData.gender,
      maritalStatus: profile.maritalStatus ?? prevData.maritalStatus,
      numberOfDependents:
        profile.numberOfDependents ?? prevData.numberOfDependents,
      occupation: profile.occupation ?? prevData.occupation,
      income: profile.income ? Number(profile.income) : prevData.income,
      incomeFrequency: profile.incomeFrequency ?? prevData.incomeFrequency,
      approximateSavingsRate: profile.approximateSavingsRate
        ? Number(profile.approximateSavingsRate)
        : prevData.approximateSavingsRate,
      financialLiteracy:
        profile.financialLiteracy ?? prevData.financialLiteracy,
      riskTolerance: profile.riskTolerance ?? prevData.riskTolerance,
    }));
  }, []);

  useEffect(() => {
    if (profile) {
      updateFormData(profile);
    }
  }, [profile, updateFormData]);

  if (isLoading) {
    return <Skeleton className="flex h-[520px] w-full" />;
  }

  if (
    peekUserContext.state !== "SUCCESS" ||
    isLoading ||
    error ||
    subscriptionsContext.state !== "SUCCESS"
  ) {
    return null;
  }

  const { user } = peekUserContext;

  return (
    <div className="flex flex-col gap-y-8">
      <h3 className="text-xl font-medium">Profile</h3>

      <ProfileBanner user={user} />

      <ProfileDetails formData={formData} setFormData={setFormData} />

      <FamilyDetails formData={formData} setFormData={setFormData} />

      <EmploymentSavings
        currency={currency}
        formData={formData}
        setFormData={setFormData}
      />

      <FinancialProfile formData={formData} setFormData={setFormData} />

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-1">
          {subscriptionsContext.subscribed &&
          !subscriptionsContext.cancelAtPeriodEnd ? (
            <Link to="/billing-portal" target="_blank">
              <Button variant="link" className="p-0 text-red-500">
                Please cancel your subscription before deleting your account
              </Button>
            </Link>
          ) : (
            <Button
              variant="link"
              onClick={() => setDialogOpen("deleteUser", true)}
              className="p-0 text-red-500"
              disabled={deleteUserIsPending}
            >
              Delete My Account & Data
            </Button>
          )}
        </div>

        <Button
          variant="default"
          onClick={() => updateProfile(formData)}
          disabled={isPending || deleteUserIsPending}
        >
          Save
        </Button>
      </div>

      <DeleteConfirmationDialog
        name="deleteUser"
        confirmCallback={() => {
          setDialogOpen("deleteUser", false);
          handleDeleteUser();
        }}
        isPending={deleteUserIsPending}
        confirmationWord={user.email}
        title="This action cannot be undone."
        description={
          <>
            <p>
              Deleting your account will permanently remove all your data from
              Peek and its partners, including:
            </p>
            <ul className="list-disc pl-5 pt-2">
              <li>Your account information</li>
              <li>Credentials for connected services</li>
              <li>Payment details stored with Stripe</li>
            </ul>
          </>
        }
      />
    </div>
  );
}
