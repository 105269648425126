import { useLayoutEffect, useMemo, useState } from "react";
import { Currency, getLocaleForCurrency } from "@/hooks/useCurrency";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { PanelTopOpen } from "lucide-react";
import peekCoach from "/images/coach.svg";
import { AddAssetDialog } from "@/components/AddAsset/AddAssetDialog";
import { AssetLiabilityV2 } from "@/lib/types";
import { getGroupedLegendData } from "@/lib/portfolio.utils";
import { truncate } from "@/lib/text.utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import MotionNumber from "motion-number";
import { easeOut } from "framer-motion";

type LegendsProps = {
  data: AssetLiabilityV2[];
  overviewBy: string;
  currency: string;
  totalAssets: number;
  totalLiabilities: number;
  setPlaidCustomLinkNameAndResetToken: (value: string) => void;
  setOpenEndowus: (value: { isOpen: boolean; accountId?: number }) => void;
  setOpenStashAway: (value: { isOpen: boolean; accountId?: number }) => void;
  setOpenEmereg: (value: {
    isOpen: boolean;
    customLink: string;
    accountId?: number;
  }) => void;
};

export function Legends({
  data,
  overviewBy,
  currency,
  totalAssets,
  totalLiabilities,
  setPlaidCustomLinkNameAndResetToken,
  setOpenEndowus,
  setOpenStashAway,
  setOpenEmereg,
}: LegendsProps) {
  const [totalAssetsValue, setTotalAssetsValue] = useState<number>(0);
  const [totalLiabilitiesValue, setTotalLiabilitesValue] = useState<number>(0);

  const [hoverActiveLegend, setHoverActiveLegend] = useState<string | null>(
    null,
  );

  const { groupedAssetLegends, groupedLiabilityLegends } = useMemo(
    () => getGroupedLegendData(data, overviewBy),
    [data, overviewBy],
  );

  const topAssets = groupedAssetLegends.slice(0, 4);
  const topLiabilities = groupedLiabilityLegends.slice(0, 4);

  useLayoutEffect(() => {
    setTotalAssetsValue(totalAssets);
    setTotalLiabilitesValue(totalLiabilities);
  }, [totalAssets, totalLiabilities]);

  return (
    <div className="flex w-full flex-col items-stretch gap-2 md:flex-col lg:flex-row">
      <div className="w-full rounded-lg bg-beige p-6">
        <div className="flex flex-col gap-y-2">
          <span className="block text-sm text-gray-500">Assets</span>
          <span className="block text-2xl text-gray-700">
            {currency}{" "}
            <MotionNumber
              value={totalAssetsValue}
              format={{ style: "decimal", maximumFractionDigits: 2 }}
              locales={getLocaleForCurrency(currency as Currency)}
              transition={{
                // Applied to layout animations on individual characters:
                layout: { type: "spring", duration: 0.7, bounce: 0 },
                // Used for the digit animations:
                y: { type: "spring", duration: 0.7, bounce: 0.4 },

                // Opacity applies to entering/exiting characters.
                // Note the use of the times array, explained below:
                opacity: { duration: 0.7, ease: easeOut, times: [0, 0.3] }, // 0.3s perceptual duration
              }}
            />
          </span>
        </div>

        <div className="my-4 flex flex-row items-center gap-x-0.5">
          {groupedAssetLegends.map((account, index: number) => (
            <div
              key={index}
              className="h-10 rounded-sm"
              style={{
                width: `${(+account.value / totalAssets) * 100}%`,
                backgroundColor: account.colorCode,
                opacity:
                  hoverActiveLegend !== null &&
                  hoverActiveLegend !== account.label
                    ? 0.2
                    : 1,
              }}
              onMouseEnter={() => setHoverActiveLegend(account.label)}
              onMouseLeave={() => setHoverActiveLegend(null)}
            ></div>
          ))}
        </div>

        <div className="grid gap-y-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {topAssets.map((account, index) => (
            <LegendLabel
              key={index}
              label={account.label}
              value={account.value}
              currency={currency}
              colorCode={account.colorCode}
              hoverActiveLegend={hoverActiveLegend}
              setHoverActiveLegend={setHoverActiveLegend}
            />
          ))}
        </div>

        {topAssets.length >= 4 && (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                className="mt-4 w-full gap-x-1 text-gray-700"
              >
                <PanelTopOpen size={16} />
                Show all
              </Button>
            </DialogTrigger>
            <DialogContent className="w-full md:max-w-3xl">
              <DialogHeader>
                <DialogTitle>Assets</DialogTitle>
              </DialogHeader>
              <div className="my-4 flex flex-row items-center gap-x-0.5">
                {groupedAssetLegends.map((account, index: number) => (
                  <div
                    key={index}
                    className="h-10 rounded-sm"
                    style={{
                      width: `${(+account.value / totalAssets) * 100}%`,
                      backgroundColor: account.colorCode,
                      opacity:
                        hoverActiveLegend !== null &&
                        hoverActiveLegend !== account.label
                          ? 0.2
                          : 1,
                    }}
                    onMouseEnter={() => setHoverActiveLegend(account.label)}
                    onMouseLeave={() => setHoverActiveLegend(null)}
                  ></div>
                ))}
              </div>

              <div className="grid gap-y-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
                {groupedAssetLegends.map((account, index) => (
                  <LegendLabel
                    key={index}
                    label={account.label}
                    value={account.value}
                    currency={currency}
                    colorCode={account.colorCode}
                    hoverActiveLegend={hoverActiveLegend}
                    setHoverActiveLegend={setHoverActiveLegend}
                  />
                ))}
              </div>
            </DialogContent>
          </Dialog>
        )}

        {groupedAssetLegends.length === 0 && (
          <div className="rounded-md bg-white p-4">
            <div className="flex flex-row items-start justify-start gap-x-3">
              <img src={peekCoach} alt="Coach" />
              <p className="text-sm text-gray-700">
                No assets added yet. To start tracking your net worth and
                progress towards your financial goals, add your assets here.
              </p>
            </div>

            <AddAssetDialog
              enableCmdK={false}
              variant="outline"
              size="sm"
              setPlaidCustomLinkNameAndResetToken={
                setPlaidCustomLinkNameAndResetToken
              }
              buttonLabel="Add assets"
              className="ml-9 mt-3"
              setOpenEndowus={(isOpen: boolean, accountId?: number) =>
                setOpenEndowus({ isOpen, accountId })
              }
              setOpenStashAway={(isOpen: boolean, accountId?: number) =>
                setOpenStashAway({ isOpen, accountId })
              }
              setOpenEmereg={(
                isOpen: boolean,
                customLink: string,
                accountId?: number,
              ) => setOpenEmereg({ isOpen, customLink, accountId })}
            />
          </div>
        )}
      </div>

      <div className="h-m-full w-full rounded-lg bg-beige p-6">
        <div className="flex flex-col gap-y-2">
          <span className="block text-sm text-gray-500">Liabilities</span>
          <span className="block text-2xl text-gray-700">
            {currency}{" "}
            <MotionNumber
              value={totalLiabilitiesValue}
              format={{ style: "decimal", maximumFractionDigits: 2 }}
              locales={getLocaleForCurrency(currency as Currency)}
              transition={{
                // Applied to layout animations on individual characters:
                layout: { type: "spring", duration: 0.7, bounce: 0 },
                // Used for the digit animations:
                y: { type: "spring", duration: 0.7, bounce: 0.4 },

                // Opacity applies to entering/exiting characters.
                // Note the use of the times array, explained below:
                opacity: { duration: 0.7, ease: easeOut, times: [0, 0.3] }, // 0.3s perceptual duration
              }}
            />
          </span>
        </div>

        <div className="my-4 flex flex-row justify-center gap-x-0.5">
          {groupedLiabilityLegends.map((account, index) => (
            <div
              key={index}
              className="h-10 rounded-sm"
              style={{
                width: `${(+account.value / totalLiabilities) * 100}%`,
                backgroundColor: account.colorCode,
                opacity:
                  hoverActiveLegend !== null &&
                  hoverActiveLegend !== account.label
                    ? 0.2
                    : 1,
              }}
              onMouseEnter={() => setHoverActiveLegend(account.label)}
              onMouseLeave={() => setHoverActiveLegend(null)}
            ></div>
          ))}
        </div>

        <div className="grid gap-y-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {groupedLiabilityLegends.map((account, index) => (
            <LegendLabel
              key={index}
              label={account.label}
              value={account.value}
              currency={currency}
              colorCode={account.colorCode}
              hoverActiveLegend={hoverActiveLegend}
              setHoverActiveLegend={setHoverActiveLegend}
            />
          ))}
        </div>

        {topLiabilities.length >= 4 && (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                className="mt-4 w-full gap-x-1 text-gray-700"
              >
                <PanelTopOpen size={16} />
                Show all
              </Button>
            </DialogTrigger>
            <DialogContent className="w-full md:max-w-3xl">
              <DialogHeader>
                <DialogTitle>Liabilities</DialogTitle>
              </DialogHeader>
              <div className="my-4 flex flex-row justify-center gap-x-0.5">
                {groupedLiabilityLegends.map((account, index) => (
                  <div
                    key={index}
                    className="h-10 rounded-sm"
                    style={{
                      width: `${(+account.value / totalLiabilities) * 100}%`,
                      backgroundColor: account.colorCode,
                      opacity:
                        hoverActiveLegend !== null &&
                        hoverActiveLegend !== account.label
                          ? 0.2
                          : 1,
                    }}
                    onMouseEnter={() => setHoverActiveLegend(account.label)}
                    onMouseLeave={() => setHoverActiveLegend(null)}
                  ></div>
                ))}
              </div>

              <div className="grid gap-y-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
                {groupedLiabilityLegends.map((account, index) => (
                  <LegendLabel
                    key={index}
                    label={account.label}
                    value={account.value}
                    currency={currency}
                    colorCode={account.colorCode}
                    hoverActiveLegend={hoverActiveLegend}
                    setHoverActiveLegend={setHoverActiveLegend}
                  />
                ))}
              </div>
            </DialogContent>
          </Dialog>
        )}

        {groupedLiabilityLegends.length === 0 && (
          <div className="rounded-md bg-white p-4">
            <div className="flex flex-row items-start justify-start gap-x-3">
              <img src={peekCoach} alt="Coach" />
              <p className="text-sm text-gray-700">
                You're all clear! No liabilities to report. If you have any
                liabilities, you can add them here.
              </p>
            </div>

            <AddAssetDialog
              enableCmdK={false}
              variant="outline"
              size="sm"
              setPlaidCustomLinkNameAndResetToken={
                setPlaidCustomLinkNameAndResetToken
              }
              buttonLabel="Add liabilities"
              className="ml-9 mt-3"
              setOpenEndowus={(isOpen: boolean, accountId?: number) =>
                setOpenEndowus({ isOpen, accountId })
              }
              setOpenStashAway={(isOpen: boolean, accountId?: number) =>
                setOpenStashAway({ isOpen, accountId })
              }
              setOpenEmereg={(
                isOpen: boolean,
                customLink: string,
                accountId?: number,
              ) => setOpenEmereg({ isOpen, customLink, accountId })}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const LegendLabel = ({
  label,
  value,
  currency,
  colorCode,
  hoverActiveLegend,
  setHoverActiveLegend,
}: {
  label: string;
  value: number;
  currency: string;
  colorCode: string;
  hoverActiveLegend: string | null;
  setHoverActiveLegend: (value: string | null) => void;
}) => {
  return (
    <div
      className={cn(
        "hover:cursor-pointer",
        hoverActiveLegend !== null &&
          hoverActiveLegend !== label &&
          "opacity-20",
      )}
      onMouseEnter={() => setHoverActiveLegend(label)}
      onMouseLeave={() => setHoverActiveLegend(null)}
    >
      <div className="flex flex-row items-center gap-x-1">
        <span
          className="block size-1.5 rounded-full"
          style={{ background: colorCode }}
        ></span>{" "}
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="text-sm text-gray-500">
                {truncate(label, 10)}
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{label}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex flex-row justify-start gap-x-1">
        <span className="text-[10px] leading-5 text-gray-600">{currency}</span>
        <MotionNumber
          className="font-normal text-gray-600"
          value={value}
          format={{
            notation: "compact",
            style: "decimal",
            maximumFractionDigits: 2,
            maximumSignificantDigits: 3,
          }}
          locales={getLocaleForCurrency(currency as Currency)}
          transition={{
            // Applied to layout animations on individual characters:
            layout: { type: "spring", duration: 0.7, bounce: 0 },
            // Used for the digit animations:
            y: { type: "spring", duration: 0.7, bounce: 0.4 },

            // Opacity applies to entering/exiting characters.
            // Note the use of the times array, explained below:
            opacity: { duration: 0.7, ease: easeOut, times: [0, 0.3] }, // 0.3s perceptual duration
          }}
        />
      </div>
    </div>
  );
};
