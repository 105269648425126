import { COLOUR_PALLETE, UNKNOWN_TAG } from "@/lib/constants";
import {
  cn,
  generateDivergingColorPalette,
  getColourCode,
  truncateText,
} from "@/lib/utils";
import chroma from "chroma-js";
import { Badge } from "../ui/badge";
import { useAssetLiabilitiesAccounts } from "@/hooks/useAssetLiabilitiesAccounts";

type TagProps = {
  isTag?: boolean;
  label?: string;
  className?: string;
  newlyAddedTags?: string[];
};

export function Tag({
  isTag = false,
  label,
  newlyAddedTags,
  className,
}: TagProps) {
  let colourMap: Record<string, string> = {};
  const assetLiabilitiesAccountsContext = useAssetLiabilitiesAccounts();

  if (assetLiabilitiesAccountsContext.state === "SUCCESS") {
    const uniqueTags = assetLiabilitiesAccountsContext.tagFilters.map(
      (tag) => tag.label,
    );

    if (isTag) {
      colourMap = getColorMapForTags(uniqueTags);
    } else {
      colourMap = generateDivergingColorPalette(uniqueTags);
    }
  }

  const colour = getColourCode(label || UNKNOWN_TAG, colourMap, newlyAddedTags);

  const truncatedLabel = truncateText(label || UNKNOWN_TAG, 13);

  return (
    <Badge
      className={cn("rounded-sm px-2", className)}
      style={{
        backgroundColor: colour,
        color: isLightColour(colour) ? "black" : "white",
      }}
    >
      {isTag && "#"}{" "}
      <span className="sm:hidden">{truncatedLabel || UNKNOWN_TAG}</span>
      <span className="hidden sm:block">{label || UNKNOWN_TAG}</span>
    </Badge>
  );
}

function isLightColour(colour: string) {
  const bgColor = chroma(colour);
  const lightness = bgColor.get("lab.l") as number;

  return lightness > 60;
}

function getColorMapForTags(tags: string[]) {
  const colorMap: Record<string, string> = {};

  tags.forEach((tag, index) => {
    const colorIndex = index % COLOUR_PALLETE.length;
    colorMap[tag] = COLOUR_PALLETE[colorIndex];
  });
  return colorMap;
}
