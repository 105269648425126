import { InputRowData } from "@/components/AddAsset/QuickPaste/types";
import { getCryptoPrice, getStockPrice } from "./stocks.utils";
import { publicMakets, crypto } from "./constants";

export const excelColumnHeaders = [
  "accountname",
  "accounttype",
  "assetname",
  "assettype",
  "fullname",
  "currency",
  "quantity",
  "unitvalue",
];

export async function updateTickerPrice(
  item: string,
  priceCache: Map<string, number>,
) {
  try {
    const [assetType, assetName] = item.split("|");
    if (publicMakets.includes(assetType)) {
      const { price } = await getStockPrice(assetName);
      if (price) priceCache.set(item, price);
    } else if (crypto.includes(assetType)) {
      const { price } = await getCryptoPrice(assetName);
      if (price) priceCache.set(item, price);
    }
  } catch (e) {
    console.error(e);
  }
}

export function isPublicMarketOrCrypto(assetType: string) {
  return publicMakets.includes(assetType) || crypto.includes(assetType);
}

export async function getAssetUnitValues(data: InputRowData[]) {
  // Extract unique tickers
  const uniqueTickers = new Set<string>();

  data.forEach((dataItem) => {
    if (
      isPublicMarketOrCrypto(dataItem.assetType) &&
      (!dataItem.unitValue || dataItem.unitValue === "0")
    ) {
      uniqueTickers.add(`${dataItem.assetType}|${dataItem.assetName}`);
    }
  });

  // Update ticker prices concurrently
  const priceCache = new Map<string, number>();
  const promisesToUpdateTickerPrices = [...uniqueTickers].map(
    async (ticker) => await updateTickerPrice(ticker, priceCache),
  );
  await Promise.all(promisesToUpdateTickerPrices);

  // Update unit values based on cached prices
  data.forEach((dataItem) => {
    if (
      isPublicMarketOrCrypto(dataItem.assetType) &&
      (!dataItem.unitValue || dataItem.unitValue === "0")
    ) {
      const price = priceCache.get(
        `${dataItem.assetType}|${dataItem.assetName}`,
      );
      if (price) {
        dataItem.unitValue = price;
      }
    }
  });
  return data;
}
