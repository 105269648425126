import config from "@/lib/config";
import axios, { AxiosResponse } from "axios";

class StashAwayAPIClient {
  private username: string = "";
  private loginMfaToken: string = "";
  private headers: { [key: string]: string } = {};
  private country: string = "sg"; // Default to Singapore

  setCountry(country: string) {
    this.country = country;
  }

  async setClerkHeaders(getClerkToken: () => Promise<string | null>) {
    const token = await getClerkToken();
    this.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  async login(username: string, password: string): Promise<AxiosResponse> {
    this.username = username;
    return axios
      .post(
        `${config.integrationsUrl}/stashaway/login`,
        { username, password, country: this.country },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        if (response.data.id === "server.errors.auth.2fa_required") {
          this.loginMfaToken = response.data.token;
        }
        // Use the new auth token
        if (response.data.accessToken) {
          this.saveStashAwayAuthToken(response.data.accessToken);
        }
        return response;
      });
  }

  async generateOtp(): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/stashaway/generate-otp`,
        {
          loginMfaToken: this.loginMfaToken,
          username: this.username,
          country: this.country,
        },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        return response;
      });
  }

  async verifyOtp(otpCode: string): Promise<AxiosResponse> {
    return axios
      .post(
        `${config.integrationsUrl}/stashaway/verify-otp`,
        {
          otpCode,
          loginMfaToken: this.loginMfaToken,
          country: this.country,
        },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        this.saveStashAwayAuthToken(response.data.token);
        return response;
      })
      .catch((error) => {
        this.resetStashAwayAuthToken();
        throw error;
      });
  }

  async importNewStashAwayData(): Promise<AxiosResponse> {
    if (!this.hasStashAwayAuthToken()) {
      throw new Error("Authorization token not found");
    }

    return axios
      .post(
        `${config.integrationsUrl}/stashaway/new-sync`,
        {
          accessToken: this.getStashAwayAuthToken(),
          country: this.country,
        },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        // We actually don't need to use this repsonse for now. Since refetchQuery should handle the table refresh by pulling fresh data.
        return response;
      });
  }

  async updateStashAwayDataForAccount(
    accountId: number,
  ): Promise<AxiosResponse> {
    if (!this.hasStashAwayAuthToken()) {
      throw new Error("Authorization token not found");
    }

    return axios
      .post(
        `${config.integrationsUrl}/stashaway/update-sync`,
        {
          accessToken: this.getStashAwayAuthToken(),
          accountId: Number(accountId), // Not sure why it sends out as string if there's no explicit casting
          country: this.country,
        },
        { headers: this.headers },
      )
      .then((response: AxiosResponse) => {
        // We actually don't need to use this repsonse for now. Since refetchQuery should handle the table refresh by pulling fresh data.
        return response;
      });
  }

  getStashAwayAuthToken() {
    return localStorage.getItem("stashaway_token_data");
  }

  hasStashAwayAuthToken() {
    return localStorage.getItem("stashaway_token_data") !== null;
  }

  resetStashAwayAuthToken() {
    localStorage.removeItem("stashaway_token_data");
  }

  private saveStashAwayAuthToken(accessToken: string) {
    localStorage.setItem("stashaway_token_data", accessToken);
  }
}

export default StashAwayAPIClient;
