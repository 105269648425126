import { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import { useAuth } from "@clerk/clerk-react";
import telescoPeek from "/images/icon-telescopeek.svg";
import { useNavigate } from "react-router-dom";
import { Plans } from "./Plans";
import { usePostHog } from "posthog-js/react";
import debounce from "lodash/debounce";
import { doublePaymentDebounceDelay } from "@/lib/constants";

interface NoTrialNoSubProps {
  className?: string;
}

export function NoTrialNoSub({ className }: NoTrialNoSubProps) {
  const { signOut } = useAuth();
  const [yearly, setYearly] = useState(true);
  const posthog = usePostHog();
  const navigate = useNavigate();

  const handleNavigationWithDebounce = debounce((path) => {
    navigate(path);
  }, doublePaymentDebounceDelay);

  return (
    <div
      className={cn(
        "bg-border/50 bg-offwhite flex h-full flex-col gap-4 rounded-lg p-4",
        className,
      )}
    >
      <div className="flex flex-col items-center justify-between gap-y-4 p-6">
        <img src={telescoPeek} alt="Eyes" className="h-24 w-24" />
        <h2 className="text-center font-libre text-4xl">
          Try Peek's features for USD 1 over 14 days.
        </h2>
        <p className="text-center text-lg text-gray-500">
          Get unlimited full access to Peek. Connect to your banks, brokerages,
          and more to start tracking your net worth. Decide if Peek is right for
          you.
        </p>
        <p className="text-center text-sm text-muted-foreground">
          No auto-renewals. You decide after 14 days.
        </p>
        <Button
          size="xl"
          className="mb-8 uppercase"
          onClick={() => handleNavigationWithDebounce("/checkout-trial")}
        >
          Start 14-day trial
        </Button>

        <Plans yearly={yearly} setYearly={setYearly} />

        <Button
          variant="link"
          size="lg"
          className="mt-4"
          onClick={() => {
            localStorage.removeItem("onboardingData");
            posthog?.capture("user_signed_out");
            posthog?.reset();
            signOut();
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
