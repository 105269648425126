import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { PeekUser } from "@/lib/types";
import { Lock } from "lucide-react";
import { ExternalLink } from "../SettingsLayout";

type ProfileHeaderProps = {
  user: PeekUser;
};

export function ProfileBanner({ user }: ProfileHeaderProps) {
  return (
    <>
      <div className="relative rounded-2xl bg-gradient-to-b from-sage to-white p-5 shadow-md">
        <Avatar className="size-16">
          <AvatarFallback className="bg-customOrange text-white">
            {user.name[0]}
          </AvatarFallback>
        </Avatar>

        <span className="ml-1 mt-4 block font-bold text-gray-700">
          {user.name}
        </span>
        <span className="ml-1 block text-sm font-medium text-gray-500">
          {user.email}
        </span>

        <img
          src="/images/profile-setting-banner-peek.svg"
          className="absolute bottom-0 right-4"
        />
      </div>
      <ExternalLink to="/profile" icon={Lock} label="Security Settings" />
    </>
  );
}
