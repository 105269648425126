import { ArrowUp, Plus } from "lucide-react";
import { EnterKey } from "../common/EnterKey";
import { Button } from "../ui/button";

export default function AddAssetButton({
  handleAdd,
  disabled,
}: {
  handleAdd: () => void;
  disabled?: boolean;
}) {
  return (
    <Button
      variant="outline"
      type="button"
      onClick={handleAdd}
      size="sm"
      disabled={disabled}
    >
      <Plus className="h-4 w-4" />
      Add
      <div className="ml-2 flex gap-x-1">
        <div className="hidden aspect-square h-5 w-5 items-center justify-center rounded-sm border border-border p-1 text-[8px] md:flex">
          <ArrowUp />
        </div>
        <EnterKey className="ml-0 border-border" />
      </div>
    </Button>
  );
}
