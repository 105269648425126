import { handlePortal } from "@/components/Billing/utils";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToBillingPortal() {
  const { getToken } = useAuth();
  const posthog = usePostHog();
  posthog?.capture("clicked_billing_portal");
  handlePortal(getToken);
  return <></>;
}
