import {
  ColumnDef,
  ExpandedState,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useEffect, useRef, useState, useMemo } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { ChevronDown, ChevronRight } from "lucide-react";
import { beautifyAccountType, cn } from "@/lib/utils";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PortfolioTableRow } from "@/components/Portfolio/Table/types";
import { groupDataByOverview } from "@/lib/portfolio.utils";

const retirementTypes = [
  "SG_CPF_OA",
  "SG_CPF_RA",
  "SG_CPF_SA",
  "SG_CPF_MA",
  "SG_SRS",
  "US_401K",
  "US_IRA",
  "US_529",
];

type SettingTableProps = {
  data: ReturnType<typeof groupDataByOverview>["assets" | "liabilities"];
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
  expanded: ExpandedState;
  setExpanded: OnChangeFn<ExpandedState>;
};

export function SettingTable({
  data,
  rowSelection,
  setRowSelection,
  expanded,
  setExpanded,
}: SettingTableProps) {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "asset",
      desc: false,
    },
  ]);

  const columns: ColumnDef<PortfolioTableRow>[] = useMemo(
    () => [
      {
        accessorKey: "asset",
        sortingFn: (rowA, rowB) => {
          const dataA = rowA.original;
          const dataB = rowB.original;

          if (dataA.rowType === "asset" && dataB.rowType === "asset") {
            const nameA = dataA.fullName
              ? `${dataA.fullName} (${dataA.name})`
              : dataA.name;

            const nameB = dataB.fullName
              ? `${dataB.fullName} (${dataB.name})`
              : dataB.name;

            return nameA > nameB ? 1 : -1;
          }

          return dataA.name > dataB.name ? 1 : -1;
        },
        cell: ({ row }) => {
          const rowData = row.original;
          let label = "";

          if (
            rowData.rowType === "section" ||
            rowData.rowType === "subSection"
          ) {
            const isSection = rowData.rowType === "section";
            const totalSubRows = isSection
              ? row.subRows.reduce((acc, sr) => acc + sr.subRows.length, 0)
              : row.subRows.length;

            label = beautifyAccountType(rowData.name);
            if (row.subRows.length && !row.getIsExpanded()) {
              label += ` (${totalSubRows})`;
            }
          }

          return (
            <div
              className={cn(
                "flex items-center gap-x-1",
                (rowData.rowType === "section" ||
                  rowData.rowType === "subSection") &&
                  "font-semibold",
                rowData.rowType === "subSection" && "pl-7",
              )}
            >
              {row.getCanExpand() ? (
                <div className="p-2">
                  {row.getIsExpanded() ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </div>
              ) : null}
              <Checkbox
                className={cn(rowData.rowType === "asset" && "ml-20")}
                checked={
                  ["section", "subSection"].includes(rowData.rowType)
                    ? row.getIsAllSubRowsSelected()
                    : row.getIsSelected()
                }
                onCheckedChange={(checked) => {
                  row.getToggleSelectedHandler()(checked);
                }}
              />

              {rowData.rowType === "asset" ? (
                <div className="flex flex-row items-center gap-x-1">
                  <img
                    src={
                      rowData.company?.logo ||
                      `https://ui-avatars.com/api/?background=F4EEE3&color=4B5563&name=${
                        rowData.name
                      }`
                    }
                    className="mr-2 h-8 w-8 rounded-full bg-beige p-1"
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = "";
                    }}
                  />

                  {rowData.fullName ? (
                    <>
                      <span>
                        {retirementTypes.includes(rowData.type)
                          ? rowData.fullName.replaceAll("_", " ")
                          : rowData.fullName}

                        <span className="ml-1 font-normal text-gray-500">
                          (
                          {retirementTypes.includes(rowData.type)
                            ? rowData.name.replaceAll("_", " ")
                            : rowData.name}
                          )
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      {retirementTypes.includes(rowData.type)
                        ? rowData.name.replaceAll("_", " ")
                        : rowData.name}
                    </>
                  )}
                </div>
              ) : (
                <span className="flex flex-row items-center gap-x-1">
                  {label}
                </span>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableSubRowSelection: true,
    enableMultiRowSelection: true,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getRowId: (row) => row.id.toString(),
    getSubRows: (row) => {
      if (row.rowType === "section" || row.rowType === "subSection") {
        return row.subRows;
      }
      return [];
    },
    state: {
      expanded,
      rowSelection,
      sorting,
    },
  });

  const { rows } = table.getRowModel();
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => parentRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    rows.forEach((row) => {
      const allSubRowsSelected =
        row.subRows.length > 0 &&
        row.subRows.every(({ getIsSelected }) => getIsSelected());
      if (allSubRowsSelected) {
        row.toggleSelected(true);
      }
    });
  }, [rows]);

  return (
    <div
      ref={parentRef}
      className="max-h-[80vh] overflow-auto rounded-md border bg-white"
    >
      <Table>
        <TableBody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: "relative", //needed for absolute positioning of rows
          }}
        >
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            return (
              <TableRow
                key={row.id}
                data-index={virtualRow.index} //needed for dynamic row height measurement
                className={`cursor-pointer border-b-0 hover:bg-gray-100 [&:not(:last-child)]:shadow-line`}
                ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                style={{
                  display: "flex",
                  position: "absolute",
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: "100%",
                }}
                {...(row.getCanExpand() && {
                  onClick: (event) => {
                    const target = event.target as HTMLElement;

                    if (target.tagName.toLowerCase() === "button") {
                      // Do not take any action if the click was on a button / checkbox
                      return;
                    }
                    row.getToggleExpandedHandler()();
                  },
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="text-left">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
