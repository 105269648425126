import { Check, X } from "lucide-react";
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { cn } from "../../lib/utils";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

export const CustomReactSelect = forwardRef<
  ElementRef<typeof CreatableSelect>,
  ComponentPropsWithoutRef<typeof CreatableSelect>
>((props, ref) => {
  const styles: StylesConfig = {
    input: (initialStyle) => ({
      ...initialStyle,
      input: {
        position: "absolute",
      },
    }),
  };

  return (
    <CreatableSelect
      ref={ref}
      {...props}
      isClearable
      unstyled
      components={{ DropdownIndicator, ClearIndicator, MenuList, Option }}
      classNames={{
        control: ({ isFocused }) =>
          cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
            isFocused && "outline-none ring-2 ring-ring ring-offset-2 ",
            props.className,
          ),
        placeholder: () => "text-muted-foreground",
        dropdownIndicator: () => "",
        menu: () => "mt-1 border bg-white rounded-md",
      }}
      onBlur={() => {
        const continueEditing = document.getElementById("continue-editing");
        if (continueEditing) {
          continueEditing?.focus();
        } else {
          const cancel = document.getElementById("cancel");
          cancel?.focus();
        }
      }}
      styles={styles}
    />
  );
});

function DropdownIndicator<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: DropdownIndicatorProps<OptionType, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      &nbsp;
    </components.DropdownIndicator>
  );
}

function ClearIndicator<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: ClearIndicatorProps<OptionType, IsMulti, Group>) {
  return (
    <components.ClearIndicator {...props}>
      <X className="h-4 w-4 text-muted-foreground" />
    </components.ClearIndicator>
  );
}

function MenuList<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: MenuListProps<OptionType, IsMulti, Group>) {
  return <components.MenuList {...props} className="space-y-1 p-1 text-sm" />;
}

function Option<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: OptionProps<OptionType, IsMulti, Group>) {
  return (
    <components.Option
      {...props}
      className={cn(
        "relative cursor-default select-none items-center rounded-sm px-2 py-1.5 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        props.isFocused && "rounded-md bg-gray-100",
      )}
    >
      <div className="flex">
        <Check
          className={cn(
            "mr-2 h-4 w-4 opacity-0",
            props.isSelected && "opacity-100",
          )}
        />
        {props.label}
      </div>
    </components.Option>
  );
}
