import { useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";

export function RedirectToSGFinDex() {
  const { user } = useUser();
  const posthog = usePostHog();

  // only redirect to sgfindex when user.id available
  if (!user) {
    return <></>;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const orgName = searchParams.get("orgName");
  posthog?.capture(`clicked_sgfindex_${orgName}`);
  window.location.href = `https://www.sgfindex.gov.sg`;
  return <></>;
}
