import config from "@/lib/config";
import axios, { AxiosResponse } from "axios";

class EndowusAPIClient {

  private username: string = "";
  private loginMfaToken: string = "";
  private challengeId: string = "";
  private mfaMethodId: string = "";
  private headers: { [key: string]: string } = {};

  async setClerkHeaders(getClerkToken: () => Promise<string | null>) {
    const token = await getClerkToken();
    this.headers = {
      Authorization: `Bearer ${token}`,
    }
  }

  async login(username: string, password: string): Promise<AxiosResponse> {
    this.username = username;
    return axios.post(
      `${config.integrationsUrl}/endowus/login`,
      { username, password },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      if (response.data.statusCode === 400) {
        this.loginMfaToken = response.data.data.loginMfaToken;
      }
      // Use the new auth token
      if (response.data.accessToken) {
        this.saveEndowusAuthToken(response.data.accessToken);
      }
      return response;
    })
  }

  async generateOtp(): Promise<AxiosResponse> {
    return axios.post(
      `${config.integrationsUrl}/endowus/generate-otp`,
      { loginMfaToken: this.loginMfaToken, username: this.username },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      this.mfaMethodId = response.data.methods[0].id;
      return response;
    });
  }

  async sendChallengeOtp(): Promise<AxiosResponse> {
    return axios.post(
      `${config.integrationsUrl}/endowus/challenge-otp`,
      {
        loginMfaToken: this.loginMfaToken,
        mfaMethodId: this.mfaMethodId,
        username: this.username
      },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      this.challengeId = response.data.challengeId;
      return response;
    });
  }

  async verifyOtp(otpCode: string): Promise<AxiosResponse> {
    return axios.post(
      `${config.integrationsUrl}/endowus/verify-otp`,
      {
        username: this.username,
        otpCode,
        loginMfaToken: this.loginMfaToken,
        challengeId: this.challengeId,
        shouldTrustUntilExpiry: true
      },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      this.saveEndowusAuthToken(response.data.accessToken);
      return response;
    }).catch((error) => {
      this.resetEndowusAuthToken();
      throw error;
    });
  }

  async importNewEndowusData(): Promise<AxiosResponse> {
    if (!this.hasEndowusAuthToken()) {
      throw new Error("Authorization token not found");
    }

    return axios.post(
      `${config.integrationsUrl}/endowus/new-sync`,
      {
        accessToken: this.getEndowusAuthToken()
      },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      // We actually don't need to use this repsonse for now. Since refetchQuery should handle the table refresh by pulling fresh data.
      return response;
    });
  }

  async updateEndowusDataForAccount(accountId: number): Promise<AxiosResponse> {
    if (!this.hasEndowusAuthToken()) {
      throw new Error("Authorization token not found");
    }

    return axios.post(
      `${config.integrationsUrl}/endowus/update-sync`,
      {
        accessToken: this.getEndowusAuthToken(),
        accountId: Number(accountId) // Not sure why it sends out as string if there's no explicit casting
      },
      { headers: this.headers }
    ).then((response: AxiosResponse) => {
      // We actually don't need to use this repsonse for now. Since refetchQuery should handle the table refresh by pulling fresh data.
      return response;
    });
  }

  getEndowusAuthToken() {
    return localStorage.getItem("endowus_token_data");
  }

  hasEndowusAuthToken() {
    return localStorage.getItem("endowus_token_data") !== null;
  }

  resetEndowusAuthToken() {
    localStorage.removeItem("endowus_token_data");
  }

  private saveEndowusAuthToken(accessToken: string) {
    localStorage.setItem("endowus_token_data", accessToken);
  }
}

export default EndowusAPIClient;
