import { toast } from "sonner";
import { useCallback, useEffect } from "react";
import { useKnockFeed } from "@knocklabs/react";
import { FeedItem } from "@knocklabs/client";
import { queryClient } from "@/lib/queryClient";
import {
  GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY,
  GET_SYNC_TASKS_QUERY_KEY,
} from "@/lib/constants";
import { useSyncTasks } from "@/hooks/useSyncTasks";
import { KnockSyncTask } from "@/lib/types";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

const NotificationToaster = () => {
  const { feedClient } = useKnockFeed();
  const { addSyncTask, removeSyncTask } = useSyncTasks();

  const onNotificationsReceived = useCallback(
    ({ items }: { items: FeedItem[] }) => {
      // Whenever we receive a new notification from our real-time stream, show a toast
      // (note here that we can receive > 1 items in a batch)
      items.forEach(async (notification) => {
        if (
          notification.blocks[0].type === "markdown" ||
          notification.blocks[0].type === "text"
        ) {
          // Show sync status
          if (notification.source.key === "sync-start" && notification.data) {
            const syncTasks: KnockSyncTask[] =
              notification.data.accountSyncTask;
            if (Array.isArray(syncTasks)) {
              syncTasks.forEach((syncTask) => {
                addSyncTask(syncTask);
              });
            } else {
              Object.values(syncTasks).forEach((syncTask) => {
                if (
                  typeof syncTask === "object" &&
                  syncTask !== null &&
                  "id" in syncTask
                ) {
                  addSyncTask(syncTask as KnockSyncTask);
                }
              });
            }
            toast.info(
              notification.blocks[0].rendered.replace(/<[^>]*>?/gm, ""),
              {
                id: notification.id,
                duration: 5000, // Increased duration to 5 seconds
              },
            );
          } else if (
            notification.source.key === "sync-notifications" &&
            notification.data
          ) {
            // Don't need to wait for this.
            queryClient.refetchQueries({
              queryKey: [GET_SYNC_TASKS_QUERY_KEY],
            });

            // Check if we need to refetch the asset liabilities accounts query after a long standing sync task
            await Promise.all([
              queryClient.refetchQueries({
                queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
              }),
              queryClient.refetchQueries({
                queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
              }),
            ]);

            toast.success(
              notification.data.assetArray.length > 0
                ? `We've successfully synced ${notification.data.assetArray.length} assets into ${notification.data.name}`
                : `We've successfully synced ${notification.data.name}`,
            );
            const syncTasks: KnockSyncTask[] =
              notification.data.accountSyncTask;
            if (Array.isArray(syncTasks)) {
              syncTasks.forEach((syncTask) => {
                removeSyncTask(syncTask);
              });
            } else {
              Object.values(syncTasks).forEach((syncTask) => {
                if (
                  typeof syncTask === "object" &&
                  syncTask !== null &&
                  "id" in syncTask
                ) {
                  removeSyncTask(syncTask as KnockSyncTask);
                }
              });
            }
          } else {
            toast.info(
              notification.blocks[0].rendered.replace(/<[^>]*>?/gm, ""),
              {
                id: notification.id,
              },
            );
          }
        }
      });
    },
    [addSyncTask, removeSyncTask],
  );

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on("items.received.realtime", onNotificationsReceived);

    // Cleanup
    return () =>
      feedClient.off("items.received.realtime", onNotificationsReceived);
  }, [feedClient, onNotificationsReceived]);
  return <></>;
};

export default NotificationToaster;
