import { PortfolioTableRow } from "@/components/Portfolio/Table/types";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { useDialog } from "@/hooks/useDialog";
import { getTotalValues, groupDataByOverview } from "@/lib/portfolio.utils";
import { AssetLiabilityV2 } from "@/lib/types";
import { ExpandedState, RowSelectionState } from "@tanstack/react-table";
import { useState, useEffect, useRef } from "react";
import { SettingTable } from "./SettingTable";
import { useUpdateCalculationSettings } from "@/hooks/useUpdateNetWorthCalculation";
import { useQueryClient } from "@tanstack/react-query";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";
import Loader from "@/components/common/Loader";

type SettingSheetProps = {
  data: AssetLiabilityV2[];
};

export function SettingSheet({ data }: SettingSheetProps) {
  const { isDialogOpen, setDialogOpen } = useDialog();
  const queryClient = useQueryClient();

  let sheetType: "networth" | "liquid-networth" = "networth";

  if (isDialogOpen("networth-setting")) {
    sheetType = "networth";
  } else if (isDialogOpen("liquid-networth-setting")) {
    sheetType = "liquid-networth";
  }

  const { assetTotalValue, liabilityTotalValue } = getTotalValues(data);

  const { assets, liabilities } = groupDataByOverview(
    data,
    "asset",
    assetTotalValue,
    liabilityTotalValue,
  );

  const [loading, setLoading] = useState(false);
  const [networthAssetsRowSelection, setNetworthAssetsRowSelection] =
    useState<RowSelectionState>({});
  const [networthLiabilitiesRowSelection, setNetworthLiabilitiesRowSelection] =
    useState<RowSelectionState>({});
  const [
    liquidNetworthAssetsRowSelection,
    setLiquidNetworthAssetsRowSelection,
  ] = useState<RowSelectionState>({});
  const [
    liquidNetworthLiabilitiesRowSelection,
    setLiquidNetworthLiabilitiesRowSelection,
  ] = useState<RowSelectionState>({});

  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!isInitializedRef.current && isDialogOpen(`${sheetType}-setting`)) {
      setNetworthAssetsRowSelection(
        getInitialSettingRowSelection(assets, "networth"),
      );
      setNetworthLiabilitiesRowSelection(
        getInitialSettingRowSelection(liabilities, "networth"),
      );
      setLiquidNetworthAssetsRowSelection(
        getInitialSettingRowSelection(assets, "liquid-networth"),
      );
      setLiquidNetworthLiabilitiesRowSelection(
        getInitialSettingRowSelection(liabilities, "liquid-networth"),
      );
      isInitializedRef.current = true;
    }
  }, [isDialogOpen, sheetType, assets, liabilities]);

  const assetsRowSelection =
    sheetType === "networth"
      ? networthAssetsRowSelection
      : liquidNetworthAssetsRowSelection;
  const liabilitiesRowSelection =
    sheetType === "networth"
      ? networthLiabilitiesRowSelection
      : liquidNetworthLiabilitiesRowSelection;

  const setAssetsRowSelection =
    sheetType === "networth"
      ? setNetworthAssetsRowSelection
      : setLiquidNetworthAssetsRowSelection;
  const setLiabilitiesRowSelection =
    sheetType === "networth"
      ? setNetworthLiabilitiesRowSelection
      : setLiquidNetworthLiabilitiesRowSelection;

  const [assetsExpanded, setAssetsExpanded] = useState<ExpandedState>(true);
  const [liabilitiesExpanded, setLiabilitiesExpanded] =
    useState<ExpandedState>(true);

  const { updateNetWorthCalculation, updateLiquiditySettings } =
    useUpdateCalculationSettings();

  const handleSave = async () => {
    setLoading(true);
    const selectedAssetIds = new Set(
      [
        ...Object.keys(assetsRowSelection),
        ...Object.keys(liabilitiesRowSelection),
      ].map(Number),
    );

    const { includeIds, excludeIds } = data.reduce(
      (acc, asset) => {
        const isSelected = selectedAssetIds.has(asset.id);
        const isTrueInDB =
          sheetType === "networth"
            ? asset.isIncludedInNWCalculation
            : asset.isLiquid;

        if (isSelected && !isTrueInDB) {
          acc.includeIds.push(asset.id);
        } else if (!isSelected && isTrueInDB) {
          acc.excludeIds.push(asset.id);
        }

        return acc;
      },
      { includeIds: [], excludeIds: [] } as {
        includeIds: number[];
        excludeIds: number[];
      },
    );

    if (includeIds.length || excludeIds.length) {
      sheetType === "liquid-networth"
        ? await updateLiquiditySettings({
            liquidIds: includeIds,
            illiquidIds: excludeIds,
          })
        : await updateNetWorthCalculation({ includeIds, excludeIds });
    }

    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
      }),
      queryClient.invalidateQueries({
        queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
      }),
    ]);

    setLoading(false);
    setDialogOpen(`${sheetType}-setting`, false);
  };

  return (
    <Sheet
      open={isDialogOpen(`${sheetType}-setting`)}
      onOpenChange={(value) => {
        if (!loading) {
          setDialogOpen(`${sheetType}-setting`, value);
          if (!value) {
            isInitializedRef.current = false; // Reset when closing the sheet
          }
        }
      }}
    >
      <SheetContent
        className="w-full space-y-3 bg-gray-100 sm:max-w-full md:max-w-[50vw] md:rounded-bl-xl md:rounded-tl-xl"
        disableCloseButton={loading}
      >
        <SheetHeader>
          <SheetTitle>
            <span className="text-lg font-medium text-gray-700">
              {sheetType === "liquid-networth"
                ? "Liquid Assets & Liabilities"
                : "Net Worth Calculation"}
            </span>
          </SheetTitle>
        </SheetHeader>

        <span className="text-sm text-gray-500">
          {sheetType === "liquid-networth"
            ? "Select assets & liabilites you consider to be readily accessible."
            : "Select assets & liabilities to include in your net worth calculation."}
        </span>

        <Tabs defaultValue="assets">
          <TabsList>
            <TabsTrigger value="assets">Assets</TabsTrigger>
            <TabsTrigger value="liabilities">Liabilities</TabsTrigger>
          </TabsList>
          <TabsContent value="assets">
            <SettingTable
              data={assets}
              rowSelection={assetsRowSelection}
              setRowSelection={setAssetsRowSelection}
              expanded={assetsExpanded}
              setExpanded={setAssetsExpanded}
            />
          </TabsContent>
          <TabsContent value="liabilities">
            <SettingTable
              data={liabilities}
              rowSelection={liabilitiesRowSelection}
              setRowSelection={setLiabilitiesRowSelection}
              expanded={liabilitiesExpanded}
              setExpanded={setLiabilitiesExpanded}
            />
          </TabsContent>
        </Tabs>

        <SheetFooter>
          <SheetClose asChild>
            <Button
              className=" border border-orange-500 bg-transparent text-orange-500 hover:bg-white"
              disabled={loading}
            >
              Close
            </Button>
          </SheetClose>
          <Button variant="default" onClick={handleSave} disabled={loading}>
            {loading ? <Loader /> : "Save"}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

function getInitialSettingRowSelection(
  data: PortfolioTableRow[],
  type: "networth" | "liquid-networth",
): RowSelectionState {
  const isIncluded = (asset: PortfolioTableRow) =>
    asset.rowType === "asset" &&
    (type === "networth" ? asset.isIncludedInNWCalculation : asset.isLiquid);

  return data.reduce((acc, section) => {
    if (section.rowType === "section") {
      section.subRows.forEach((subSection) => {
        if (subSection.rowType === "subSection") {
          subSection.subRows.forEach((asset) => {
            if (asset.rowType === "asset" && isIncluded(asset)) {
              acc[asset.id] = true;
            }
          });
        }
      });
    }
    return acc;
  }, {} as RowSelectionState);
}
