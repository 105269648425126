import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import googlyEyes from "/images/icon-googly-eyes.svg";
import { useNavigate } from "react-router-dom";
import { Plans } from "./Plans";
import { useState } from "react";
import debounce from "lodash/debounce";
import { doublePaymentDebounceDelay } from "@/lib/constants";

interface TrialEndingProps {
  trialTimeLeftString: string;
  className?: string;
}

export function TrialEnding({
  className,
  trialTimeLeftString,
}: TrialEndingProps) {
  const [yearly, setYearly] = useState(true);
  const navigate = useNavigate();

  const handleNavigationWithDebounce = debounce((path) => {
    navigate(path);
  }, doublePaymentDebounceDelay);

  return (
    <div
      className={cn(
        "bg-border/50 bg-offwhite flex h-full flex-col gap-4 rounded-lg p-4",
        className,
      )}
    >
      <div className="flex flex-col items-center justify-between gap-y-4 p-6">
        <img src={googlyEyes} alt="Eyes" className="h-24 w-24" />
        <h2 className="text-center font-libre text-4xl">
          Your trial is ending in {trialTimeLeftString}.
        </h2>
        <p className="text-center text-lg text-muted-foreground">
          Upgrade now to continue enjoying Peek's features.
        </p>
        <Plans yearly={yearly} setYearly={setYearly} />

        <div className="flex items-center gap-x-4">
          <Button
            size="xl"
            className="uppercase"
            onClick={() =>
              handleNavigationWithDebounce(
                yearly ? "/checkout-annual" : "/checkout-monthly",
              )
            }
          >
            Upgrade Now
          </Button>
        </div>
      </div>
    </div>
  );
}
