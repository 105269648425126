import { useState } from "react";
import { MotionConfig, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import Loader from "@/components/common/Loader";
import { Badge } from "@/components/ui/badge";
import { useSyncTasks } from "@/hooks/useSyncTasks";
import { AccountSyncTaskIntegrator, KnockSyncTask } from "@/lib/types";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { formatDistance } from "date-fns";
import { truncate } from "@/lib/text.utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { AlertTriangle, RefreshCcw } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Link } from "react-router-dom";

type OverviewByListProps = {
  overviewByList: {
    label: string;
    value: string;
  }[];
  overviewBy: string;
  setOverviewBy: (overviewBy: string) => void;
  handleReloginBasedOnIntegrator: (
    accountId: number,
    integrator: AccountSyncTaskIntegrator,
  ) => void;
  handleSync: (
    accountId: number,
    accountName: string,
    integrator?: AccountSyncTaskIntegrator,
  ) => void;
};

export function OverviewByList({
  overviewByList,
  overviewBy,
  setOverviewBy,
  handleReloginBasedOnIntegrator,
  handleSync,
}: OverviewByListProps) {
  const [selected, setSelected] = useState(overviewBy);

  const { pendingSyncTasks, errorSyncTasks } = useSyncTasks();

  return (
    <div className="my-8 flex flex-col items-center justify-between gap-y-2 lg:flex-row">
      <div className="flex flex-row items-center gap-x-2">
        <h3 className="text-2xl text-gray-700">My Portfolio</h3>
        <div className="flex flex-row items-center gap-x-1">
          {pendingSyncTasks.length > 0 && (
            <SyncIndicator pendingSyncTasks={pendingSyncTasks} />
          )}
          {errorSyncTasks.length > 0 && (
            <ErrorSyncIndicator
              errorSyncTasks={errorSyncTasks}
              handleReloginBasedOnIntegrator={handleReloginBasedOnIntegrator}
              handleSync={handleSync}
            />
          )}
        </div>
      </div>
      <MotionConfig transition={{ type: "spring", bounce: 0.2, duration: 0.2 }}>
        <motion.ul
          layout
          className="flex flex-row items-center gap-x-1 font-medium"
        >
          {overviewByList.map((overview, index) => (
            <motion.li
              layout
              key={index}
              className={cn(
                "relative cursor-pointer px-3 py-2 text-muted-foreground text-sm outline-none transition-colors",
                selected === overview.value && "text-foreground",
              )}
              onClick={() => setOverviewBy(overview.value)}
              onFocus={() => setSelected(overview.value)}
              onMouseOver={() => setSelected(overview.value)}
              onMouseLeave={() =>
                setSelected(
                  overviewByList.find((o) => o.value === overviewBy)?.value ??
                    overviewByList[0].value,
                )
              }
            >
              {selected === overview.value ? (
                <motion.div
                  layoutId="tab-indicator"
                  className="absolute inset-0 rounded-lg bg-gray-300/50"
                />
              ) : null}
              <span className="relative text-inherit">{overview.label}</span>
            </motion.li>
          ))}
        </motion.ul>
      </MotionConfig>
    </div>
  );
}

function SyncIndicator({
  pendingSyncTasks,
}: {
  pendingSyncTasks: KnockSyncTask[];
}) {
  return (
    <Badge variant="orange">
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className="flex flex-row items-center gap-x-2 hover:cursor-pointer">
            <Loader />
            <span>
              Syncing in progress{" "}
              {pendingSyncTasks.length > 1
                ? `: ${pendingSyncTasks.length} accounts`
                : ""}
            </span>
          </div>
        </HoverCardTrigger>
        <HoverCardContent sideOffset={16} className="w-[500px]">
          <div className="flex flex-col gap-y-2">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-full">Account</TableHead>
                  <TableHead className="text-right">Integrator</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pendingSyncTasks.map((task) => (
                  <TableRow key={task.id}>
                    <TableCell className="font-medium">
                      <div className="flex flex-row items-center gap-x-2">
                        <Loader />
                        <div className="flex flex-col">
                          <p>{truncate(task.account.name, 20)}</p>
                          <p className="text-xs text-muted-foreground">
                            {"Last updated: "}
                            {formatDistance(
                              new Date(task.updatedAt),
                              new Date(),
                              {
                                addSuffix: true,
                              },
                            )}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="text-right">
                      <Badge variant="outline">{task.integrator}</Badge>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </HoverCardContent>
      </HoverCard>
    </Badge>
  );
}

function ErrorSyncIndicator({
  errorSyncTasks,
  handleReloginBasedOnIntegrator,
  handleSync,
}: {
  errorSyncTasks: KnockSyncTask[];
  handleReloginBasedOnIntegrator: (
    accountId: number,
    integrator: AccountSyncTaskIntegrator,
  ) => void;
  handleSync: (
    accountId: number,
    accountName: string,
    integrator?: AccountSyncTaskIntegrator,
  ) => void;
}) {
  return (
    <Badge variant="destructive">
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className="flex flex-row items-center gap-x-2 hover:cursor-pointer">
            <AlertTriangle className="h-4 w-4" />
            <span>
              {errorSyncTasks.length > 1
                ? `${errorSyncTasks.length} errors`
                : `${errorSyncTasks.length} error`}
            </span>
          </div>
        </HoverCardTrigger>
        <HoverCardContent sideOffset={16} className="w-[500px]">
          <div className="flex flex-col gap-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">Account</TableHead>
                  <TableHead className="text-right">Integrator</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {errorSyncTasks.map((task) => (
                  <TableRow key={task.id}>
                    <TableCell className="font-medium">
                      <p>{truncate(task.account.name, 20)}</p>
                      <p className="text-xs text-muted-foreground">
                        {"Last updated: "}
                        {formatDistance(new Date(task.updatedAt), new Date(), {
                          addSuffix: true,
                        })}
                      </p>
                    </TableCell>
                    <TableCell className="text-right">
                      <Badge variant="outline">{task.integrator}</Badge>
                    </TableCell>
                    <TableCell className="text-right">
                      {task.status === "EXPIRED" && (
                        <Button
                          className="h-6 rounded-sm bg-red-100 px-2 py-0 text-red-500 shadow hover:bg-red-500 hover:text-white"
                          size="sm"
                          onClick={() =>
                            handleReloginBasedOnIntegrator(
                              task.account.id,
                              task.integrator,
                            )
                          }
                        >
                          <AlertTriangle size={12} className="mr-1" />
                          Re-login
                        </Button>
                      )}
                      {task.status === "FAILED" && (
                        <Button
                          className="h-6 rounded-sm bg-orange-100 px-2 py-0 text-orange-500 shadow hover:bg-orange-500 hover:text-white"
                          size="sm"
                          onClick={() =>
                            handleSync(
                              task.account.id,
                              task.account.name,
                              task.integrator,
                            )
                          }
                        >
                          <RefreshCcw size={12} className="mr-1" />
                          Retry
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Alert className="border-orange-200 bg-orange-50">
              <AlertTitle>
                If you are facing these errors consistently
              </AlertTitle>
              <AlertDescription>
                <div className="flex flex-col gap-y-2 text-gray-500">
                  <p>You can try to disconnect and add these accounts again.</p>
                  <Button variant="outline" size="sm">
                    <Link to="/preferences/integrations">
                      Manage Integrations
                    </Link>
                  </Button>
                  <div className="flex flex-row items-center ">
                    <p>Get in touch with us if you need help.</p>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        const email = "support@peek.money";
                        const subject = encodeURIComponent(
                          "[Request] Disconnect integration",
                        );
                        const body = encodeURIComponent(
                          `Hello, I am having issues with my integrations. Please assist me with this request.`,
                        );
                        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
                      }}
                    >
                      Contact Support
                    </Button>
                  </div>
                </div>
              </AlertDescription>
            </Alert>
          </div>
        </HoverCardContent>
      </HoverCard>
    </Badge>
  );
}
