import { useState } from "react";
import { Goal, Profile } from "@/lib/types";

export const defaultFormData: Omit<Profile, "birthdate"> = {
  name: "",
  gender: "male",
  preferredCurrency: "USD",
  maritalStatus: "",
  numberOfDependents: 0,
  occupation: "",
  income: 0,
  incomeFrequency: "annually",
  approximateSavingsRate: 0,
  financialLiteracy: "beginner",
  riskTolerance: "",
};

export const useOnboardingData = () => {
  return useState<{
    currentStep: number;
    data: Profile;
    goal: Goal;
  }>(() => {
    // Load from localStorage on initial render
    const savedOnboarding = localStorage.getItem("onboardingData");
    return savedOnboarding
      ? JSON.parse(savedOnboarding)
      : {
          currentStep: 1,
          data: defaultFormData,
          goal: {
            name: "",
            targetNetWorth: 0,
            targetDate: new Date(),
          },
        };
  });
};
