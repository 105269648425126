import { useCallback, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from "react-plaid-link";
import { queryClient } from "@/lib/queryClient";
import { GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY } from "@/lib/constants";
import config from "@/lib/config";
import { toast } from "sonner";
import { GET_USER_AGGREGATED_METRICS_QUERY_KEY } from "@/hooks/useGetUserAggregatedMetrics";

export function usePlaidIntegration() {
  const posthog = usePostHog();
  const [plaidCustomLinkName, setPlaidCustomLinkName] = useState<string | null>(
    null,
  );
  const [plaidUpdateModeAccountId, setPlaidUpdateModeAccountId] = useState<
    number | null
  >(null);
  const [plaidToken, setPlaidToken] = useState<string | null>(null);

  const { getToken } = useAuth();

  const setPlaidCustomLinkNameAndResetToken = (plaidCustomLinkName: string) => {
    setPlaidToken(null);
    setPlaidCustomLinkName(plaidCustomLinkName);
  };

  const onSuccessNewAccount = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const token = await getToken();

      if (!token) return;

      const accountName = metadata.institution?.name;
      posthog?.capture(`plaid_connected_${accountName}`);

      const promise = axios
        .post(
          `${config.integrationsUrl}/plaid/add-account-sync-task`,
          {
            publicToken,
            accountName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async () => {
          // refetch account info

          await Promise.all([
            queryClient.refetchQueries({
              queryKey: [GET_ASSET_LIABILITIES_ACCOUNTS_QUERY_KEY],
            }),
            queryClient.refetchQueries({
              queryKey: GET_USER_AGGREGATED_METRICS_QUERY_KEY,
            }),
          ]);

          setPlaidToken(null);
          setPlaidCustomLinkName(null);
        });
      toast.promise(promise, {
        loading: `Connecting to ${accountName}...`,
        success: `Successfully connected to ${accountName}! Starting to import your data...`,
        error: "Oops! Something went wrong. Please retry.",
      });
    },
    [getToken, posthog],
  );

  // refetch account info
  const onSuccessExistingAccount = useCallback<PlaidLinkOnSuccess>(async () => {
    if (!plaidUpdateModeAccountId) return;
    const token = await getToken();

    if (!token) return;

    try {
      const promise = axios
        .get(
          `${config.integrationsUrl}/update-account-sync-task/${plaidUpdateModeAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          setPlaidToken(null);
          setPlaidUpdateModeAccountId(null);
        });

      toast.promise(promise, {
        loading: "Syncing account with Plaid...",
        success: "Account synced successfully!",
        error: "Oops! Something went wrong. Please retry.",
      });
    } catch (error) {
      toast.error("Error syncing account.");
    }
  }, [getToken, plaidUpdateModeAccountId]);

  const [plaidSuccessCallback, setPlaidSuccessCallback] =
    useState<PlaidLinkOnSuccess>(() => onSuccessNewAccount);

  // Creates a Link token
  const createLinkToken = useCallback(
    async (plaidCustomLinkName: string) => {
      // For OAuth, use previously generated Link token
      if (window.location.href.includes("?oauth_state_id=")) {
        const linkToken = localStorage.getItem("link_token");
        setPlaidToken(linkToken);
      } else {
        const token = await getToken();
        const { data } = await axios.get(
          `${config.integrationsUrl}/plaid/create-link-token/${
            plaidCustomLinkName === "default" ? "" : plaidCustomLinkName
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setPlaidToken(data.link_token);
        localStorage.setItem("link_token", data.link_token);
      }
    },
    [setPlaidToken, getToken],
  );

  // Creates a Link token in Update Mode
  const createLinkTokenUpdateMode = useCallback(
    async (accountId: number) => {
      const token = await getToken();
      const { data } = await axios.get(
        `${config.integrationsUrl}/plaid/create-link-token-update-mode/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setPlaidToken(data.link_token);
      localStorage.setItem("link_token", data.link_token);
    },
    [setPlaidToken, getToken],
  );

  const plaidConfig = {
    token: plaidToken,
    onSuccess: plaidSuccessCallback,
    onExit: () => {
      setPlaidToken(null);
      setPlaidCustomLinkName(null);
      setPlaidUpdateModeAccountId(null);
    },
  };

  const { open, ready } = usePlaidLink(plaidConfig);

  useEffect(() => {
    // create link token for new account
    if (!plaidToken && plaidCustomLinkName) {
      setPlaidSuccessCallback(() => onSuccessNewAccount);
      createLinkToken(plaidCustomLinkName);
    }
    // create link token in update mode for existing account
    if (!plaidToken && plaidUpdateModeAccountId) {
      setPlaidSuccessCallback(() => onSuccessExistingAccount);
      createLinkTokenUpdateMode(plaidUpdateModeAccountId);
    }
    if (ready && plaidToken) {
      if (plaidCustomLinkName) {
        posthog?.capture(`clicked_plaid_${plaidCustomLinkName}`);
      } else if (plaidUpdateModeAccountId) {
        posthog?.capture(`clicked_plaid_relogin`);
      }
      open();
    }
  }, [
    plaidToken,
    ready,
    open,
    createLinkToken,
    plaidCustomLinkName,
    plaidUpdateModeAccountId,
    posthog,
    createLinkTokenUpdateMode,
    onSuccessExistingAccount,
    onSuccessNewAccount,
  ]);

  return {
    setPlaidCustomLinkNameAndResetToken,
    setPlaidUpdateModeAccountId,
  };
}
