import { Badge } from "@/components/ui/badge";
import InfoCard from "@/components/ui/info-card";
import { AssetLiabilityV2 } from "@/lib/types";
import { shortNumber } from "@/lib/utils";
import { ReturnOption } from ".";

type ValuesProps = {
  assetLiability: AssetLiabilityV2;
  assetTotalValue: number;
  liabilityTotalValue: number;
  isTradable: boolean;
  returnOptions: ReturnOption[];
  currency: string;
};

export function Values({
  assetLiability,
  assetTotalValue,
  liabilityTotalValue,
  isTradable,
  returnOptions,
  currency,
}: ValuesProps) {
  const snapshot = assetLiability.snapshots.day.slice(-1)[0];

  const deposits = assetLiability.snapshots.month.reduce(
    (acc, snapshot) =>
      acc + (snapshot.capitalFlow > 0 ? snapshot.capitalFlow : 0),
    0,
  );

  const withdrawals = Math.abs(snapshot.totalCapitalInvested - deposits);

  return (
    <div className="flex flex-wrap items-center space-x-1">
      {assetLiability.company?.industry && (
        <Badge variant="outlineGray" className="font-normal text-gray-500">
          {assetLiability.company?.industry}
        </Badge>
      )}
      {assetLiability.company?.sector && (
        <Badge variant="outlineGray" className="font-normal text-gray-500">
          {assetLiability.company?.sector}
        </Badge>
      )}
      {assetLiability.company?.country && (
        <Badge variant="outlineGray" className="font-normal text-gray-500">
          {assetLiability.company?.country}
        </Badge>
      )}
      <div className="mt-4 flex w-full flex-col space-y-2">
        <div className="flex w-full flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          {snapshot.totalValue && (
            <InfoCard
              options={[
                {
                  label: "Total Value",
                  value: snapshot.original?.totalValue || 0,
                  tooltip:
                    "Total value is the sum of your total capital invested and total profit and loss, representing your overall net worth.",
                  formula:
                    "\\({\\text{Total Capital Invested}} + \\text{Total Profit/Loss}\\)",
                  calculation: `\\(${assetLiability.snapshots.day
                    .slice(-1)[0]
                    .original?.totalCapitalInvested.toFixed(
                      2,
                    )} + ${assetLiability.snapshots.day
                    .slice(-1)[0]
                    .original?.pnl.toFixed(2)} = ${assetLiability.snapshots.day
                    .slice(-1)[0]
                    .original?.totalValue.toFixed(2)}\\)`,
                },
              ]}
              context={
                currency !== assetLiability.currency
                  ? `${currency} ${shortNumber(snapshot.totalValue || 0)}`
                  : ""
              }
              currency={assetLiability.currency}
            />
          )}

          {isTradable && snapshot.totalCapitalInvested && (
            <InfoCard
              options={[
                {
                  label: "Total Capital Invested",
                  value: snapshot.original?.totalCapitalInvested || 0,
                  tooltip:
                    "Total capital invested is the sum of all the money you've put into your investments, including any additional contributions you've made.",
                  formula:
                    "\\(\\text{Total Deposits} - \\text{Total Withdrawals}\\)",
                  calculation: `\\(${deposits.toFixed(2)} - ${withdrawals.toFixed(2)} = ${snapshot.original?.totalCapitalInvested.toFixed(2) || 0}\\)`,
                },
              ]}
              context={
                currency !== assetLiability.currency
                  ? `${currency} ${shortNumber(
                      snapshot.totalCapitalInvested || 0,
                    )}`
                  : ""
              }
              currency={assetLiability.currency}
            />
          )}
        </div>
        <div className="flex w-full flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          {snapshot.quantity > 1 && (
            <InfoCard
              options={[
                {
                  label: "Quantity",
                  value: shortNumber(snapshot.quantity),
                },
              ]}
            />
          )}

          {snapshot.averageCost > 0 && (
            <InfoCard
              options={[
                {
                  label: "Average Cost",
                  value: snapshot.original?.averageCost || 0,
                  tooltip:
                    "Average cost is the total capital of all your investments divided by the total quantity of all your investments.",
                  formula:
                    "\\(\\text{Total Capital Invested} / \\text{Total Quantity}\\)",
                  calculation: `\\(${
                    snapshot.original?.totalCapitalInvested.toFixed(2) || 0
                  } / ${snapshot.quantity || 0} = ${
                    snapshot.original?.averageCost.toFixed(2) || 0
                  }\\)`,
                },
              ]}
              context={
                currency !== assetLiability.currency
                  ? `${currency} ${shortNumber(snapshot.averageCost || 0)}`
                  : ""
              }
              currency={assetLiability.currency}
            />
          )}
          {snapshot.totalValue > 0 && (
            <InfoCard
              options={[
                {
                  label: "% of portfolio",
                  value: `${
                    assetLiability.assetOrLiability === "ASSET"
                      ? ((snapshot.totalValue / assetTotalValue) * 100).toFixed(
                          2,
                        )
                      : (
                          (snapshot.totalValue / liabilityTotalValue) *
                          100
                        ).toFixed(2)
                  }%`,
                },
              ]}
            />
          )}
        </div>

        <div className="flex w-full flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          {isTradable && returnOptions.length > 0 && (
            <>
              <InfoCard
                options={[
                  {
                    label: "Profit/Loss",
                    value: snapshot.original?.pnl || 0,
                    tooltip:
                      "Total profit and loss is the sum of all the gains and losses from your investments over time.",
                    formula:
                      "\\(\\text{Total Value} - \\text{Total Capital Invested}\\)",
                    calculation: `\\(${snapshot.original?.totalValue.toFixed(
                      2,
                    )} - ${snapshot.original?.totalCapitalInvested.toFixed(
                      2,
                    )} = ${snapshot.original?.pnl.toFixed(2)}\\)`,
                  },
                ]}
                context={
                  currency !== assetLiability.currency
                    ? `${currency} ${shortNumber(snapshot.pnl || 0)}`
                    : ""
                }
                currency={assetLiability.currency}
              />
              <InfoCard options={returnOptions} highlightNumbers={true} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
